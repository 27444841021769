import styles from './commentitem.module.css'

import React, { useState, useEffect, useRef } from 'react'
import SingleComment from './components/SingleComment/SingleComment'
import { paginationNext } from '../../actions/core-actions'
import { types, status } from '../../constants/action-types'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '../Icon'
import { useTheme } from '../../hooks/theme'

function CommentItem({ comment, className, contentUid, contentType }) {
    //General
    const dispatch = useDispatch()
    const { colors } = useTheme()

    //Data
    const createCommentStatus = useSelector(state => state.comments.createCommentStatus)
    const lastCreatedCommentRootUid = useSelector(state => state.comments.lastCreatedCommentRootUid)

    //Blocks
    const myBlocks = useSelector(state => state.me.blocks)
    const cachedBlocks = useSelector(state => state.me.cachedBlocks)
    const blocks = [...myBlocks, ...cachedBlocks]
    const blockObj = blocks.findIndex(block => block.blocked_user === comment.creator.uid) !== -1 ? blocks[blocks.findIndex(block => block.blocked_user === comment.creator.uid)] : null

    //blockedBy
    const blockedByObj = useSelector(state => 
        state.me.blockedBy && state.me.blockedBy.find(block => block.creator === comment.creator.uid)
    )

    //State
    const [showReplies, setShowReplies] = useState(false)
    const [reversedReplies, setReversedReplies] = useState([])

    //Update reversed replies
    useEffect(() => {
        const arr = comment.replies && comment.replies.results ? [ ...comment.replies.results ] : []
        const newArr = arr.reverse()

        setReversedReplies(newArr)

    }, [ comment ])
    
    //Show replies when replied to comment
    const firstUpdate = useRef(true)
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (createCommentStatus === status.SUCCESS && lastCreatedCommentRootUid === comment.uid) {
            setShowReplies(true)
        }
    }, [ createCommentStatus ])

    if (blockedByObj) {
        return null
    }
    
    if (blockObj) {
        return null
    }

    return (
        <div className={className}>
            <SingleComment comment={comment} rootCommentContentUid={contentUid} rootCommentContentType={contentType} />

            {comment.replies && (comment.replies.next || (comment.replies.results.length > 1 && !showReplies) || comment.replies.status === status.BEGIN) &&
                <div className="margin-top-12 margin-left-48 flex-row">
                    <div
                    className="flex-row cursor"
                    onClick={() => {
                        if (!showReplies) {
                            setShowReplies(true)
                        } else {
                            dispatch(
                                paginationNext(comment.replies.next, types.GET_MORE_REPLIES, { commentUid: comment.uid, uid: contentUid, pluralType: `${contentType}s` })
                            )
                        }
                    }}
                    >
                        <p className={`${styles.loadMoreText}`}>
                            <span className="margin-right-8">
                                ―
                            </span>
                            Show more replies
                            <span className="margin-left-8">
                                {comment.replies.count - (showReplies ? comment.replies.results.length : 1)}
                            </span>
                        </p>

                        {comment.replies.status === status.BEGIN &&
                            <Icon size={16} className="margin-left-8" icon="loading" color={colors.highDarkGrey} />
                        }
                    </div>
                </div>
            }

            {showReplies && reversedReplies.map((item, index) => (
                <SingleComment
                rootCommentUid={comment.uid}
                rootCommentContentUid={contentUid}
                rootCommentContentType={contentType}
                key={`comment-${comment.uid}-reply-${item.uid}`}
                comment={item}
                isReply
                />
            ))}

            {!showReplies && reversedReplies.length > 0 &&
                <SingleComment 
                comment={reversedReplies[reversedReplies.length-1]} 
                rootCommentUid={comment.uid}
                rootCommentContentUid={contentUid}
                rootCommentContentType={contentType}
                isReply
                />
            }
        </div>
    )
}

export default React.memo(CommentItem)