import styles from './userprofilepicture.module.css'

import React from 'react'

import ProfilePicture from '../ProfilePicture'

const frames = {
    0: "https://cdn.musicboard.app/musicboard/assets/frames/blue-transform-loop-min.gif",
    1: "https://cdn.musicboard.app/musicboard/assets/frames/pulse-loop-min.gif",
    2: "https://cdn.musicboard.app/musicboard/assets/frames/connected-small-2-min.gif",
    3: "https://cdn.musicboard.app/musicboard/assets/frames/digital-fire-min.gif",
    4: "https://cdn.musicboard.app/musicboard/assets/frames/evil-2-min.gif",
    5: "https://cdn.musicboard.app/musicboard/assets/frames/fire-ring-mid-min.gif",
    6: "https://cdn.musicboard.app/musicboard/assets/frames/glitch-smile-small-min.gif",
    7: "https://cdn.musicboard.app/musicboard/assets/frames/hearts_3-min.gif",
    8: "https://cdn.musicboard.app/musicboard/assets/frames/hologram-2-min.gif",
    9: "https://cdn.musicboard.app/musicboard/assets/frames/mana-small-min.gif",
    10: "https://cdn.musicboard.app/musicboard/assets/frames/move-2-min.gif",
    11: "https://cdn.musicboard.app/musicboard/assets/frames/pride-min.gif",
    12: "https://cdn.musicboard.app/musicboard/assets/frames/radar-min.gif",
    13: "https://cdn.musicboard.app/musicboard/assets/frames/red-transform-loop-min.gif",
    14: "https://cdn.musicboard.app/musicboard/assets/frames/smoke-3-min.gif",
    15: "https://cdn.musicboard.app/musicboard/assets/frames/sun-2-min.gif",
    16: "https://cdn.musicboard.app/musicboard/assets/frames/water-mid-min.gif",
}

export default function UserProfilePicture({
    skipOuter,
    profilePicture=null,
    profileFrame=null,
    altText,
    size=60,
    className="",
    noShadow=false,
    clickable,
    forcedClickable,
    style,
    paddingTop,
    noBorder,
    skipFrameTitle,
}) {

    const content = (
        <div 
        className={`flex-center`} 
        style={{ 
            position: 'relative', 
            height: typeof size === "number" ? size : "initial",
            width: size,
            minWidth: size,
            ...style 
        }}
        >
            <ProfilePicture
            profilePicture={profilePicture}
            size={size}
            noShadow={noShadow}
            clickable={clickable}
            forcedClickable={forcedClickable}
            paddingTop={paddingTop}
            noBorder={noBorder}
            altText={altText}
            skipFrameTitle={skipFrameTitle}
            />

            {(profileFrame || profileFrame === 0) &&
                <div 
                className={`${styles.frame}`} 
                style={{
                    backgroundImage: `url(${frames[profileFrame]})`,
                    width: size*1.2272727273,
                    minWidth: size*1.2272727273,
                    maxWidth: size*1.2272727273,
                    height: size*1.2272727273,
                    minHeight: size*1.2272727273,
                    maxHeight: size*1.2272727273,
                }}
                />
            }
        </div>
    )

    if (skipOuter) {
        return content
    }
    
    return (
        <div className={`${className}`} >
            {content}
        </div>
    )
}
