import styles from './stars.module.css'

import React from 'react'
import Icon from '../Icon';

export default function Stars({
    iconSize = 24, // 24, 20, 18, 16, 14
    style={},
    rating=1,
    appendKey="",
    className="",
}) {
    
    return (
        <div style={style} className={`${styles.container} ${styles[`i${iconSize}`]} ${className}`}>
            <div className={`${styles.bg} flex-row`}>
                <Icon color={"var(--grey)"} size={iconSize} icon="star" />
                <Icon color={"var(--grey)"} size={iconSize} icon="star" />
                <Icon color={"var(--grey)"} size={iconSize} icon="star" />
                <Icon color={"var(--grey)"} size={iconSize} icon="star" />
                <Icon color={"var(--grey)"} size={iconSize} icon="star" />
            </div>

            <div className={`${iconSize <= 14 ? styles.mg : ""} flex-row`}>
                {[1,2,3,4,5,6,7,8,9,10].map((_, i) => (
                    <div 
                    key={`starsStar-${appendKey}-${i}`} 
                    className={`${styles.star} ${i < rating ? styles.show : ""}`}
                    >
                        <Icon 
                        className={`${styles.starIcon} ${i%2 === 0 ? styles.first : ""}`}
                        color={"var(--yellow)"} 
                        size={iconSize} 
                        icon="star" 
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}