import React, { useState, useEffect, useRef } from 'react';
import { status } from '../constants/action-types';

export function useStatusHandler({
    effectStatus, 
    extraBeginCondition=true,
    extraSuccessCondition=true,
    extraErrorCondition=true,
    beginCallback,
    successCallback,
    errorCallback,
}) {

    const firstUpdate = useRef(true)
    useEffect(() => {
        if(firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if(effectStatus === status.BEGIN && extraBeginCondition) {
            if (beginCallback) {
                beginCallback()
            }
        } else if(effectStatus === status.SUCCESS && extraSuccessCondition) {
            if (successCallback) {
                successCallback()
            }
        } else if(effectStatus === status.ERROR && extraErrorCondition) {
            if (errorCallback) {
                errorCallback()
            }
        }

    }, [ effectStatus ])
  
}