import './StickerItem.css'

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { stickerClassEnums, stickerNameEnums } from '../../constants/enums'

import { usePopup } from '../../hooks/popup/popup'

import UserRedirect from '../UserRedirect'
import ProfilePicture from '../ProfilePicture'
import UserProfilePicture from '../UserProfilePicture'

function StickerItem({ sticker, count, latest, reviewUid, ratingUid, threadUid, listUid }) {
    //General
    const { openPopup } = usePopup()
    //State
    const [randomRotate] = useState((Math.random() * 24) - 12)

    //Prepare for render
    const stickerName = stickerClassEnums[sticker]

    return (
        <div className='StickerItem-sticker-item'>
            <div className={`StickerItem-sticker-image ${stickerName}`} style={{ transform: `rotate(${randomRotate}deg)` }} />

            <div className="StickerItem-count-wrapper">
                <h6 className="StickerItem-count-text">{count}</h6>

                <div className='StickerItem-count-overlay' />
            </div>
            
            <div className='StickerItem-hover-box' />

            <div className='StickerItem-content'>
                <div className='StickerItem-padding'>
                    <div className={`StickerItem-sticker-image big ${stickerName}`} />

                    <h5 className='black margin-top-16'>{stickerNameEnums[sticker]}</h5>
                </div>

                {latest && latest?.creator &&
                <>
                    <div className={`StickerItem-sticker-divider`} />

                    <div className='StickerItem-padding'>
                        <UserRedirect 
                        pictureSize={24} 
                        fontSize={16} 
                        user={latest?.creator}
                        />
                    </div>
                </>
                }

                <div className={`StickerItem-sticker-divider`} />

                {count <= 1 &&
                    <p className='text-center padding-top-12 padding-bottom-12 highDarkGrey'>
                        {moment(latest?.created_at).format("DD MMM YYYY, hh:mm A")}
                    </p>
                }

                {count > 1 &&
                    <div 
                    onClick={() => openPopup("StickerDetails", { stickerId: sticker, reviewUid, ratingUid, threadUid, listUid })}
                    className='StickerItem-sticker-btn'
                    >
                        <h6 className='black'>View all users (+{count-1})</h6>
                    </div>
                }
            </div>
        </div>
    )
}

export default React.memo(StickerItem)
