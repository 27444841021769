/* eslint-disable no-param-reassign */
import { createAction } from 'redux-actions';
import { status } from '../constants/action-types';
import * as config from '../config';

export const success = (actionType, params, statusCode = null, controller) =>
  createAction(actionType,
    payload => payload,
    _action => ({
      status: status.SUCCESS,
      statusCode: statusCode,
      params,
      controller,
    })
  );

export const error = (actionType, params, statusCode = null, controller) =>
  createAction(actionType,
    payload => payload,
    _payload => ({
      status: status.ERROR,
      statusCode: statusCode,
      params,
      controller,
    })
  );

export const begin = (actionType, params, controller) =>
  createAction(actionType,
    payload => payload,
    _payload => ({
      status: status.BEGIN,
      params,
      controller,
    })
  );


export const ajaxRequest = (actionType, path, opts, params = null, transform = (_ => _)) =>
  // dispatch and getState are given to us by the redux-thunk middleware
  (dispatch, getState) => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(begin(actionType, params, controller)(opts.rawBody));

    const state = getState();

    if (state.auth && state.auth.token) {
        opts.headers = {
          ...opts.headers,
          Authorization: `Token ${state.auth.token}`, 
        };
    } else if (state.auth && state.auth.tokenType && state.auth.accessToken) {
        opts.headers = {
          ...opts.headers,
          Authorization: `${state.auth.tokenType} ${state.auth.accessToken}`,
        };
    }

    let statusCode = -1;
    const url = (typeof path === 'function') ? path(config, state) : path;
    console.log("try to fetch", url, opts)
    fetch(url, { ...opts, signal: signal })
      .then((res) => {
        statusCode = res.status;
        return res.text();
      })
      .then((response) => {
        console.log({response}, actionType, statusCode)
        try {
          const data = response
            ? transform(JSON.parse(response))
            : null;

          if (statusCode >= 200 && statusCode < 300) {
            console.log('feftch data', data);
            dispatch(success(actionType, params, statusCode, controller)(data, opts.headers));
          } else {
            dispatch(error(actionType, params, statusCode, controller)(data));
          }
        } catch (err) {
          console.log(err);
          dispatch(error(actionType, params, statusCode, controller)({ error: 'Unable to communicate with server.' }));
        }
      })
      .catch((errorMessage) => {
        if (errorMessage.name === "AbortError") {
          return;
        }

        console.log("failed to fetch", JSON.stringify(errorMessage), errorMessage);
        dispatch(error(actionType, params, statusCode, controller)({ error: errorMessage }));
      });

  };

const commonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-New-Payment-System': 'true',
};

const commonHeadersRaw = {
  'X-New-Payment-System': 'true',
};

const opts = {
  get: () => ({
    method: 'GET',
    headers: { ...commonHeaders },
  }),
  head: () => ({
    method: 'HEAD',
    headers: { ...commonHeaders },
  }),
  put: data => ({
    method: 'PUT',
    body: data && JSON.stringify(data),
    rawBody: data,
    headers: { ...commonHeaders },
  }),
  patch: data => ({
    method: 'PATCH',
    body: data && JSON.stringify(data),
    rawBody: data,
    headers: { ...commonHeaders },
  }),
  patchRaw: data => ({
    method: 'PATCH',
    body: data,
    rawBody: data,
    headers: { ...commonHeadersRaw, },
  }),
  post: data => ({
    method: 'POST',
    body: JSON.stringify(data),
    rawBody: data,
    headers: { ...commonHeaders },
  }),
  delete: () => ({
    method: 'DELETE',
    headers: { ...commonHeaders },
  }),
  postRaw: (data, headers) => ({
    method: 'POST',
    body: data,
    rawBody: data,
    headers: { ...commonHeadersRaw, ...headers },
  }),
};

export const post = (actionType, path, body, params, transform = (_ => _)) =>
  ajaxRequest(actionType, path, opts.post(body), params, transform);

export const patch = (actionType, path, body, params, transform = (_ => _)) =>
  ajaxRequest(actionType, path, opts.patch(body), params, transform);

export const patchRaw = (actionType, path, body, params, transform = (_ => _)) =>
  ajaxRequest(actionType, path, opts.patchRaw(body), params, transform);

export const postRaw = (actionType, path, body, headers, params, transform = (_ => _)) =>
  ajaxRequest(actionType, path, opts.postRaw(body, headers), params, transform);

export const get = (actionType, path, params, transform = (_ => _)) =>
  ajaxRequest(actionType, path, opts.get(), params, transform);

export const put = (actionType, path, body, params, transform = (_ => _)) =>
  ajaxRequest(actionType, path, opts.put(body), params, transform);

export const delete_ = (actionType, path, params, transform = (_ => _)) =>
  ajaxRequest(actionType, path, opts.delete(), params, transform);