import { get, patch, post, delete_ } from "../utils/fetch";
import { types } from "../constants/action-types";
import { url } from '../config'

export const createComment = (contentUid, contentType, comment, commentUid = null,) => 
    post(types.CREATE_COMMENT, `${url}/v2/comments/`, {
        content_type: commentUid ? "comment" : contentType,
        content_uid: commentUid ? commentUid : contentUid,
        comment,
    }, {
        uid: contentUid,
        pluralType: `${contentType}s`,
        comment,
        commentUid
    })

export const updateComment = (contentUid, contentType, commentUid, comment, rootCommentUid = null) => 
    patch(types.UPDATE_COMMENT, `${url}/v2/comments/${commentUid}/`, {
        comment,
    }, {
        uid: contentUid,
        pluralType: `${contentType}s`,
        commentUid,
        comment,
        rootCommentUid
    })

export const deleteComment = (contentUid, contentType, commentUid, rootCommentUid = null) => 
    delete_(types.DELETE_COMMENT, `${url}/v2/comments/${commentUid}/`, {
        uid: contentUid,
        pluralType: `${contentType}s`,
        commentUid,
        rootCommentUid
    })

export const getComments = (contentUid, contentType) => 
    get(types.GET_COMMENTS, `${url}/v2/comments/?content_type=${contentType}&content_uid=${contentUid}&limit_replies=3`, {
        uid: contentUid,
        pluralType: `${contentType}s`,
        reset: true
    })