import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useDispatch } from 'react-redux'

import { paginationNext } from '../../../../actions/core-actions'

import CommentItem from '../../../../components/CommentItem'
import Heading from '../../../../components/Heading'
import Icon from '../../../../components/Icon'
import WriteComment from '../../../../components/WriteComment'
import { status, types } from '../../../../constants/action-types'

export default function ReviewCommentSection({ review, comments }) {
    const dispatch = useDispatch()
    
    return (
        <div>
            <div id={"comment-title"} style={{ transform: 'translateY(-56px)' }} />

            <Heading
            className="margin-top-32"
            text={`Comments`}
            />

            <div className={`${(review.comments_privacy === 2 || review.comments_privacy === 1 || (comments && comments.data && comments.data.length > 0)) && "padding-bottom-16 bottom-divider"}`}>
                <WriteComment 
                contentType={"review"} 
                contentUid={review.uid} 
                style={{ pointerEvents: review.comments_privacy === 2 ? 'none' : 'initial', opacity: review.comments_privacy === 2 ? 0.5 : 1 }}
                />
            </div>

            {review.comments_privacy === 2 &&
                <div className={`flex-center flex-column empty-text`}>
                    <Icon icon="comments-disabled" size={40} color={"var(--grey)"} />
                        
                    <p className="highDarkGrey margin-top-24" style={{ fontSize: 16 }}>{review.creator.name !== "" ? review.creator.name : review.creator.usern} has turned off comments for this review.</p>
                </div>
            }
            
            {review.comments_privacy === 1 &&
                <div className="flex-center padding-top-12 padding-bottom-12 bottom-divider">
                    <p className="highDarkGrey margin-left-8">
                        {review.creator.name !== "" ? review.creator.name : review.creator.username} has limited comments to friends only.
                    </p>
                </div>
            }

            {review.comments_privacy !== 2 && comments && comments.data && comments.data.length > 0 &&
                <InfiniteScroll
                initialLoad={false}
                threshold={200}
                loadMore={() => {
                    if (comments.next) {
                        dispatch( paginationNext(comments.next, types.GET_COMMENTS, { uid: review.uid, pluralType: "reviews" }) )
                    }
                }}
                hasMore={comments.next || (comments.status === status.BEGIN && !comments.reset)}
                loader={
                    <div className="flex-center margin-top-16">
                        <Icon icon="loading" disableLoadingAnimation />
                    </div>
                }
                >
                    {comments.data.map((item, index) => (
                        <CommentItem
                        key={`review-${review.uid}-commentitem-${item.uid}`}
                        contentType="review" 
                        contentUid={review.uid} 
                        comment={item}
                        />
                    ))}
                </InfiniteScroll>
            }
        </div>
    )
}
