import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import moment from 'moment';


const defaultActivity = {
	activity: {
        data: [],
        status: status.DEFAULT,
        next: null,
        count: 0,
    },
	getActivityStatus: status.DEFAULT,
	errorMessage: null,

	resetFlatLists: null,
};

const activity = (state = defaultActivity, action) => {
	switch (action.type) {

		case types.GET_ACTIVITY:
			let reset = action.meta && action.meta.params ? action.meta.params.reset : false
			let speedup = action.meta && action.meta.params ? action.meta.params.speedup : false
			let limit = action.meta && action.meta.params ? action.meta.params.limit : false

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						activity: {
							...state.activity,
							next: null,
							reset: action.meta && action.meta.params ? action.meta.params.reset : false,
							status: status.BEGIN,
						},
					}
				case status.SUCCESS:
					return {
						...state,
						activity: {
							...state.activity,
							data: reset ? action.payload.results : [ ...state.activity.data, ...action.payload.results ],
							count: action.payload.count,
							next: speedup ? (action.payload.results.length < limit) ? null : action.payload.next : action.payload.next,
							status: status.SUCCESS,
						},
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						activity: {
							...state.activity,
                            data: [],
                            status: status.ERROR,
                            next: null,
                            count: 0,
                        },
					}
			}

		case types.RESET_ACTIVITY_FLATLISTS:
			return {
				...state,
				resetFlatLists: new Date().toISOString()
			}

		default:
			return state;
	}
};

export default activity;