import { types, status } from '../constants/action-types'
import { post, put, get, patch, patchRaw } from '../utils/fetch'
import { hostDomain, url, deezerHostDomain, deezerHostUrl, corsUrl, serverHostDomain } from '../config'
import { getNormal } from '../utils/fetch-normal';

// Content & User
export const getSearchReviews = ({
    search="",
    isStatic=false
}) => 
    get(isStatic ? types.GET_STATIC_SEARCH_REVIEWS : types.GET_SEARCH_REVIEWS, `${url}/v2/reviews/?search=${search}&limit=16&speedup=true`, { reset: true, speedup: true, limit: 16 });

export const clearSearchReviews = () => (dispatch, getState) => dispatch({ type: types.CLEAR_SEARCH_REVIEWS })


export const getSearchLists = ({
    search="",
    isStatic=false
}) => 
    get(isStatic ? types.GET_STATIC_SEARCH_LISTS : types.GET_SEARCH_LISTS, `${url}/v2/lists/?limit_content=10&limit=12&search=${search}&speedup=true`, { reset: true, speedup: true, limit: 12 });

export const clearSearchLists = () => (dispatch, getState) => dispatch({ type: types.CLEAR_SEARCH_LISTS })


export const getSearchUsers = ({ search="", isStatic=false }) => (dispatch, getState) => {
        const controller = new AbortController();
        const signal = controller.signal;
    
        // Send BEGIN to reducers
        dispatch({ type: isStatic ? types.GET_STATIC_SEARCH_USERS : types.GET_SEARCH_USERS, meta: { status: status.BEGIN, controller: controller, params: { reset: true, speedup: true, limit: 24 } } });
    
        // Make requests both to Deezer and our API
        Promise.all([
            getNormal(`${url}/v2/users/top/?advanced_search=${search}&speedup=true&limit=100`, 15000, signal), 
            getNormal(`${url}/v2/users/top/?search=${search}&speedup=true`, 15000, signal)
        ])
        .then((responses) => {
    
            // Get individual responses
            const advancedResponse = responses[0]
            const containsResponse = responses[1]
            
            // Filter the results
            const results = [ 
                ...advancedResponse.results,
                ...containsResponse.results.filter((user) => advancedResponse.results.findIndex((compare) => user.uid === compare.uid) === -1)
            ];
    
            // Handle pagination logic
            const next = containsResponse.next
            
            // Finished payload
            const payload = {
                next,
                results
            }
    
            // Send SUCCESS to reducers
            dispatch({ type: isStatic ? types.GET_STATIC_SEARCH_USERS : types.GET_SEARCH_USERS, meta: { status: status.SUCCESS, controller: controller, params: { reset: true, speedup: true, limit: 24 } }, payload });
            
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
    
            // Send ERROR to reducers
            dispatch({ type: isStatic ? types.GET_STATIC_SEARCH_USERS : types.GET_SEARCH_USERS, meta: { status: status.ERROR, controller: controller, params: { reset: true, speedup: true, limit: 24 } }, payload: err });
            console.warn(err)
    
        });
    }

export const clearSearchUsers = () => (dispatch, getState) => dispatch({ type: types.CLEAR_SEARCH_USERS })


// Merged albums
export const searchMergedContent = (query, type, limit=16, isStatic) => (dispatch, getState) => {
    const controller = new AbortController();
    const signal = controller.signal;
    
    // Support for all 3 content types
    const constantType = (
        type === "album" ? 
            isStatic ? types.SEARCH_STATIC_MERGED_ALBUMS : types.SEARCH_MERGED_ALBUMS
        : type === "track" ? 
            isStatic ? types.SEARCH_STATIC_MERGED_TRACKS : types.SEARCH_MERGED_TRACKS 
        : 
            isStatic ? types.SEARCH_STATIC_MERGED_ARTISTS : types.SEARCH_MERGED_ARTISTS
    )
    
    // Send BEGIN to reducers
    dispatch({ type: constantType, meta: { status: status.BEGIN, controller: controller, params: { reset: true } } });

    // Make requests both to Deezer and our API
    Promise.all([
        getNormal(`${url}/v2/${type}s/contributed/?limit=${limit}&search=${query}&order_by=-ratings_count&speedup=true`, 15000, signal), 
        getNormal(`${deezerHostUrl}/search/${type}/?q=${query}&limit=${limit}`, 15000, signal, null, null, true),
    ])
    .then((responses) => {

        // Get individual responses
        const apiResponse = responses[0]
        const deezerResponse = responses[1]
        
        // Filter the results from Deezer
        const filteredDeezerResults = [ ...deezerResponse.data ]
        
        // Filter the results from our API
        const filteredApiResults = [
            ...apiResponse.results
            // Only take albums that are not duplicates
            .filter(content => 
                !content.duplicate_of_id || 
                apiResponse.results.findIndex((compare) => compare.id === content.duplicate_of_id) === -1
            )
            // Only take albums that does not exist in Deezer's results (checks title and artist name)
            .filter(content =>
                filteredDeezerResults.findIndex((compare) => (
                    type === "artist" ?
                        compare.name === content.name
                    :
                        (
                            compare.title === content.title && 
                            compare.artist && 
                            content.artist && 
                            compare.artist.name === content.artist.name
                        )
                )) === -1
            )
            // Only get result with a rank of over 0.8
            .filter(content => content.combined_rank > 0.8)
        ]

        // Handle pagination logic
        const next = (
            deezerResponse.next ?
                deezerResponse.next
            : (apiResponse.next && apiResponse.results.length >= limit) ?
                apiResponse.next
            :
                null
        )
        
        // This variable is used to save the pagination url from Deezer for future paginations
        const secondaryNext = (
            deezerResponse.next ?
                (apiResponse.next && apiResponse.results.length >= limit) ?
                    apiResponse.next
                :
                    null
            : 
                null
        )

        // Handle results logic
        const results = [
            ...filteredApiResults,
            ...filteredDeezerResults,
        ]
        
        // This variable is used to save the results from Deezer for future paginations
        const secondaryResults = (
            deezerResponse.next ?
                filteredApiResults
            : 
                []
        )
        
        // Finished payload
        const payload = {
            next,
            results,
            secondaryNext,
            secondaryResults,
            limit
        }

        // Send SUCCESS to reducers
        dispatch({ type: constantType, meta: { status: status.SUCCESS, controller: controller, params: { reset: true } }, payload });
        
    })
    .catch((err) => {
        if (err.name === "AbortError") {
            return;
        }

        // Send ERROR to reducers
        dispatch({ type: constantType, meta: { status: status.ERROR, controller: controller, params: { reset: true } }, payload: err });
        console.warn(err)

    });
}

export const paginateMergedContent = (dataObject, type, isStatic) => (dispatch, getState) => {

    // Support for all 3 content types
    const constantType = (
        type === "album" ? 
            isStatic ? types.SEARCH_STATIC_MERGED_ALBUMS : types.SEARCH_MERGED_ALBUMS
        : type === "track" ? 
            isStatic ? types.SEARCH_STATIC_MERGED_TRACKS : types.SEARCH_MERGED_TRACKS 
        : 
            isStatic ? types.SEARCH_STATIC_MERGED_ARTISTS : types.SEARCH_MERGED_ARTISTS
    )
    
    // Send BEGIN to reducers
    dispatch({ type: constantType, meta: { status: status.BEGIN, params: {} } });

    // Get all required variables
    const {
        next,
        limit,
        secondaryNext,
        data,
        secondaryResults
    } = dataObject

    console.log(dataObject)

    // Variable
    const isApi = next.includes(hostDomain)

    // Make pagination request
    getNormal(
        ((next.includes(serverHostDomain) || next.includes(deezerHostDomain)) && next.includes("http://")) ?
            `${corsUrl}/${next.replace("http://", "https://")}`
        :
            `${corsUrl}/${next}`
    )
    .then((response) => {
        
        // Filter the results with the same logic as above
        let filteredResults;
        if (isApi) {
            const rankedApiResults = [ ...response.results ];
            
            filteredResults = [
                ...rankedApiResults
                    .filter(content => data.findIndex(compare => compare.id === content.id) === -1)
                    .filter(content => 
                        !content.duplicate_of_id || 
                        rankedApiResults.findIndex((compare) => compare.id === content.duplicate_of_id) === -1
                    )
                    .filter(content =>
                        data.findIndex((compare) => (
                            type === "artist" ?
                                compare.name === content.name
                            :
                                (
                                    compare.title === content.title && 
                                    compare.artist && 
                                    content.artist && 
                                    compare.artist.name === content.artist.name
                                )
                        )) === -1
                    )
            ]
        } else {
            filteredResults = [ ...response.data ]
        }

        const apiHasMore = isApi ? Boolean(response.next && response.results && response.results.length >= limit) : Boolean(response.next);

        // Handle pagination logic
        const newNext = (
            (!isApi) ?
                apiHasMore ?
                    response.next
                :
                    secondaryNext
            :
                response.next ?
                    response.next
                :
                    null
        )
        
        // Decide if we should continue saving secondaryNext
        const newSecondaryNext = (
            (!isApi) ?
                apiHasMore ?
                    secondaryNext
                :
                    null
            :
                null
        )
        
        // Handle results logic
        const newResults = (
            (!isApi) ?
                apiHasMore ?
                    filteredResults
                :
                    [ 
                        ...filteredResults, 
                        ...secondaryResults.filter(content =>
                            filteredResults.findIndex((compare) => (
                                    type === "artist" ?
                                        compare.name === content.name
                                    :
                                        (
                                            compare.title === content.title && 
                                            compare.artist && 
                                            content.artist && 
                                            compare.artist.name === content.artist.name
                                        )
                            )) === -1
                        )
                    ]
            :
                filteredResults
        )

        // Decide if we should continue saving secondaryResults
        const newSecondaryResults = (
            (!isApi) ?
                apiHasMore ?
                    secondaryResults.filter(content =>
                        filteredResults.findIndex((compare) => (
                            type === "artist" ?
                                compare.name === content.name
                            :
                                (
                                    compare.title === content.title && 
                                    compare.artist && 
                                    content.artist && 
                                    compare.artist.name === content.artist.name
                                )
                        )) === -1
                    )
                :
                    []
            :
                []
        )
        
        const payload = {
            next: newNext,
            results: newResults,
            secondaryNext: newSecondaryNext,
            secondaryResults: newSecondaryResults,
            limit
        }

        dispatch({ type: constantType, meta: { status: status.SUCCESS, params: {} }, payload });

    })
    .catch((err) => {
        console.warn(err) 
        dispatch({ type: constantType, meta: { status: status.ERROR, params: {} }, payload: err });

    });
}

export const clearSearchAlbums = () => (dispatch, getState) => dispatch({ type: types.CLEAR_SEARCH_ALBUMS })
export const clearSearchTracks = () => (dispatch, getState) => dispatch({ type: types.CLEAR_SEARCH_TRACKS })
export const clearSearchArtists = () => (dispatch, getState) => dispatch({ type: types.CLEAR_SEARCH_ARTISTS })
