import styles from './smallartistitem.module.css'

import React from 'react'
import ProfilePicture from '../ProfilePicture'
import { Link } from 'react-router-dom'
import { checkDeezerId } from '../../utils/deezer-utils'

function SmallArtistItem({ artist }) {
    let href = checkDeezerId(artist) ? `/artist/${artist.id}?dz` : artist.url_slug

    return (
        <Link to={href}>
            <div className={`${styles.container} flex-row cursor`}>
                <ProfilePicture clickable size={60} profilePicture={artist.picture_small || artist.picture} altText={artist.name} skipFrameTitle />
                
                <div className={`${styles.alignCenter} margin-left-16`}>
                    <div className="grid">
                        <h6 className={`${styles.title} one-line`}>{artist.name}</h6>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default React.memo(
    SmallArtistItem,
    (prevProps, nextProps) => {
        if (prevProps.artist.id !== nextProps.artist.id) {
            return true;
        }
        if (prevProps.artist.name !== nextProps.artist.name) {
            return true;
        }
        if (prevProps.artist.picture !== nextProps.artist.picture) {
            return true;
        }
        return false;
    }
)