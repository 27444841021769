import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { handleNoPaginationReducer } from '../utils/reducer-helpers';

const defaultPayments = {
	products: {
        count: null,
        data: [],
        next: null,
        reset: null,
        controller: null,
        status: status.DEFAULT,
    },
	
	proGifts: {
        count: null,
        data: [],
        next: null,
        reset: null,
        controller: null,
        status: status.DEFAULT,
    },
	
    createSubscriptionStatus: status.DEFAULT,
	
    subscription: null,
	getSubscriptionStatus: status.DEFAULT,
	
	portalSessionUrl: null,
	createPortalSessionStatus: status.DEFAULT,
	
	lastCreatedProGift: null,
	createProGiftStatus: status.DEFAULT,

    errorMessage: null,
};

const payments = (state = defaultPayments, action) => {
	switch (action.type) {
		case types.GET_PRODUCTS:
            return handleNoPaginationReducer(state, action, "products")
		
		case types.GET_PRO_GIFTS:
            return handleNoPaginationReducer(state, action, "proGifts")
        
		case types.GET_SUBSCRIPTION:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						getSubscriptionStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						errorMessage: null,
						subscription: action.payload.results[0] ? action.payload.results[0] : null,
						getSubscriptionStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						getSubscriptionStatus: status.ERROR,
					}
            }
		
		case types.CREATE_PORTAL_SESSION:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createPortalSessionStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						errorMessage: null,
						portalSessionUrl: action.payload.url,
						createPortalSessionStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createPortalSessionStatus: status.ERROR,
					}
            }
		
		case types.CREATE_PRO_GIFT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createProGiftStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						errorMessage: null,
						lastCreatedProGift: action.payload,
						createProGiftStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						lastCreatedProGift: null,
						errorMessage: errorToString(action.payload),
						createProGiftStatus: status.ERROR,
					}
            }
		
		case types.UPDATE_PRO_GIFT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						updateProGiftStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						errorMessage: null,
						updateProGiftStatus: status.SUCCESS,
						proGifts: {
							...state.proGifts,
							data: state.proGifts.data.map((gift) => {
								if (gift.uid !== action.meta.params.giftUid) return gift;
	
								return ({
									...gift,
									...action.payload
								})
							}),
						}
					}
				case status.ERROR:
					return {
						...state,
						updateProGiftStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
            }
		
		case types.LOG_OUT:
			return {
				...state,
				...defaultPayments,
				products: state.products,
			}
			
		default:
			return state;
	}
};

export default payments;