import styles from './bigbackenditem.module.css'

import React, { useState } from 'react'
import AlbumCover from '../AlbumCover'
import ProfilePicture from '../ProfilePicture'
import Icon from '../Icon'
import Stars from '../Stars'
import { useTheme } from '../../hooks/theme'
import { Link } from 'react-router-dom'
import ContentOptionsTooltip from '../ContentOptionsTooltip'
import UserRedirect from '../UserRedirect'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

function BigBackendItem({
    content,
    useNumbers, 
    number, 
    largeImage,
    width='100%',
    rating, 
    ratingObj,
    hideText,
    creator,
    showArtistName,
    iconSizes=16,
    textSize,
    newTab,
    showExplicit,
    onClicked
}) {
    //General
    const { colors } = useTheme()

    //Responsive
    const isMinimum = useMediaQuery({ maxWidth: 450 })

    //Variables
    const contentType = content.type
    const isSuggested = (contentType === "suggested_album" || contentType === "suggested_artist" || contentType === "suggested_track")
    const formattedContentType = isSuggested ? contentType.substr(10, 100) : contentType
    const contentTitle = content && (
        (content.type === "artist" || content.type === "suggested_artist") ? 
            content.name 
        : 
            content.title
    )
    const contentImage = content && (
        content.type === "artist" ? 
            (largeImage ? content.picture_large : content.picture_medium) || content.picture
        : content.type === "album" ? 
            (largeImage ? content.cover_large : content.cover_medium) || content.cover
        : content.type === "track" ? 
            (largeImage ? content.album.cover_large : content.album.cover_medium) || content.album.cover
        : content.type === "suggested_artist" ?
            content.picture ?
                content.picture
            :
                content.picture_url
        : content.type === "suggested_album" ?
            content.cover ?
                content.cover
            :
                content.cover_url
        : content.type === "suggested_track" ?
            content.album ? 
                content.album.cover ?
                    content.album.cover
                :
                    content.album.cover_url
            :
                content.suggested_album.cover ?
                    content.suggested_album.cover
                :
                    content.suggested_album.cover_url
        : null
    )
    
    let href = ""
    if(ratingObj) {
        if(ratingObj.review_url_slug) {
            href = ratingObj.review_url_slug
        } else if(ratingObj.review_uid) {
            href = `/review/${ratingObj.review_uid}`
        } else {
            href = `/rating/${ratingObj.uid}`
        }
    } else {
        if(content.type === "artist") {
            href = content.url_slug
        }
        if(content.type === "track") {
            href = `${content.album.url_slug}?tracks=${content.id}`
        }
        if(content.type === "album") {
            href = content.url_slug
        }
    }

    //Blocks
    const myBlocks = useSelector(state => state.me.blocks)
    const cachedBlocks = useSelector(state => state.me.cachedBlocks)
    const blocks = [...myBlocks, ...cachedBlocks]
    const blockObj = blocks.findIndex(block => ratingObj && block.blocked_user === ratingObj.creator.uid) !== -1 ? blocks[blocks.findIndex(block => ratingObj && block.blocked_user === ratingObj.creator.uid)] : null

    //blockedBy
    const blockedByObj = useSelector(state => 
        state.me.blockedBy && state.me.blockedBy.find(block => ratingObj && block.creator === ratingObj.creator.uid)
    )

    if (blockedByObj) {
        return null
    }

    if (blockObj) {
        return null
    }
    
    return (
        <div>
            <div className={`content-options-wrapper`}>
                <div className={styles.center}>
                    <div style={{ position: "relative" }}>
                        {!(content.type === "artist" || content.type === "suggested_artist") &&
                            <AlbumCover 
                            clickable 
                            size={width} 
                            albumCover={contentImage} 
                            altText={content.title} 
                            skipBorder={true}
                            />
                        }

                        {(content.type === "artist" || content.type === "suggested_artist") &&
                            <ProfilePicture 
                            clickable 
                            size={width} 
                            profilePicture={contentImage} 
                            altText={content.name} 
                            skipBorder={true}
                            />
                        }
                        
                        <Link
                        target={newTab ? "_blank" : null}
                        to={href}
                        onClick={(e) => {
                            if (onClicked) {
                                e.preventDefault()
                                onClicked(content)
                            }
                        }}
                        >
                            <div className={`${styles.border} ${(content.type === "artist" || content.type === "suggested_artist") ? styles.round : ""} ${!Boolean(contentImage) ? styles.empty : ""}`}>
                                <span className={styles.borderTitle}>{content.title}</span>
                            </div>
                        </Link>
                    </div>

                    <div className={styles.alignCenter}>
                        {useNumbers &&
                            <div className={styles.numberContainer}>
                                <h6 className={styles.number}>{number}</h6>
                            </div>
                        }
                        
                        {!isSuggested &&
                            <ContentOptionsTooltip newTab={newTab} content={content} appendId={`bigbackend`} />
                        }
                    </div>
                </div>

                {!hideText &&
                    <div 
                    className={`${styles.alignCenter} ${styles.textContainer}`} 
                    style={{ width: width }}
                    >
                        <div className={`flex-row flex-center ${styles.textContent}`}>
                            {showExplicit && content.explicit_lyrics &&
                                <Icon icon="explicit" style={{ marginRight: 6 }} color="var(--grey)" size={14} />
                            }

                            <div className="grid">
                                <h6 className={`${styles.title} one-line`} style={{ fontSize: textSize && textSize }}>
                                    <Link 
                                    target={newTab ? "_blank" : null} 
                                    to={href} 
                                    onClick={(e) => {
                                        if (onClicked) {
                                            e.preventDefault()
                                            onClicked(content)
                                        }
                                    }}
                                    >
                                        {(content.type === "artist" || content.type === "suggested_artist") ? content.name : content.title}
                                    </Link>
                                </h6>
                            </div>
                        </div>

                        {!rating && content.type !== "artist" && showArtistName &&
                            <div className="grid">
                                <p className={`${styles.artist} one-line`}>
                                    {!onClicked &&
                                        <Link
                                        target={newTab ? "_blank" : null}
                                        className={styles.artistLink}
                                        to={content.artist.url_slug}
                                        >
                                            {content.artist.name}
                                        </Link>
                                    }
                                    
                                    {onClicked &&
                                        content.artist.name
                                    }
                                </p>
                            </div>
                        }

                        {(rating || ratingObj) &&
                            <div style={{ marginTop: 4 }} className="flex-row">
                                {rating &&
                                    <Stars rating={rating} iconSize={iconSizes} />
                                }

                                {!rating &&
                                    <Icon icon="headphones" size={iconSizes-1} color={colors.grey} />
                                }

                                {!isMinimum && ratingObj && ratingObj.first_listen === false &&
                                    <Icon icon="sync" size={iconSizes-1} style={{ transform: "rotate(45deg)", marginLeft: 4 }} color={colors.grey} />
                                }

                                {(ratingObj.private || ratingObj.private_locked) &&
                                    <Icon icon="lock" size={iconSizes-1} style={{ marginLeft: 4 }} color={colors.grey} />
                                }

                                {(ratingObj.review_url_slug || ratingObj.review_uid) &&
                                    <Icon icon="subject" size={iconSizes} style={{ marginLeft: 4 }} color={colors.grey} />
                                }
                            </div>
                        }
                    </div>
                }

                {creator &&
                    <div className="flex-column flex-center margin-top-8">
                        <UserRedirect user={creator} />
                    </div>
                }
            </div>
        </div>
    )
}

export default React.memo(BigBackendItem)