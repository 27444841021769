import styles from './alert.module.css'

import React, { useEffect, useReducer, useContext, useState } from 'react'
import { v4 } from 'uuid'
import { useTheme } from '../theme';
import Icon from '../../components/Icon';
import { Link } from 'react-router-dom';

const AlertContext = React.createContext();

export const useAlert = () => {
    const { state, dispatch } = useContext(AlertContext)
    const { colors } = useTheme()

    return {
      alert: state,
      openAlert: ({
        p="",
        a="",
        onClick=null,
        href=null,
        style="blue",
        timer=4000,
        target,
      }) => {
            const uuid = v4()

            if (timer) {
                setTimeout(() => {
                    dispatch({
                        type: "CLOSE_ALERT",
                        payload: uuid
                    })
                }, timer)
            }
            dispatch({
                type: 'OPEN_ALERT',
                payload: {
                    uuid,
                    p, 
                    a,
                    onClick,
                    href,
                    style,
                    timer,
                    target,
                    hasStartedTimer: false
                }
            })
      },
      closeAlert: () => dispatch({
        type: 'CLOSE_ALERT',
      }),
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'OPEN_ALERT':
            return {
                ...state,
                alerts: [
                    action.payload,
                    ...state.alerts,
                ]
            }
        case 'CLOSE_ALERT':
            return {
                ...state,
                alerts: state.alerts.filter((alert) => alert.uuid !== action.payload)
            }
        default:
            throw new Error()
    }
}

const defaultAlert = { 
    alerts: [],
}
  
const AlertProvider = ({ init, children }) => {
    const [ state, dispatch ] = useReducer(reducer, defaultAlert);

    const { alerts } = state;

    useEffect(() => {
        
    }, [ alerts ])

    return (
        <AlertContext.Provider value={{ state, dispatch }}>
            {children}

            {alerts.length !== 0 &&
                <div className={styles.fixed}>
                    {alerts.map((alert, index) => (
                        <div 
                        key={`alert-item-${alert.uuid}`}
                        className={`${styles.lineContainer} ${index !== 0 && "margin-top-8"}`} 
                        style={{ 
                            backgroundColor: 
                                alert.style === "red" ? 
                                    "var(--red)" :
                                alert.style === "green" ?
                                    "var(--green)" :
                                    "var(--blue)"
                        }}
                        >
                            <div className={`flex-row ${styles.alertContainer}`}>
                                <div className="col-1">
                                    <p 
                                    className={styles.p}
                                    style={{ fontSize: 16, color: "white" }} 
                                    >
                                        {alert.p}{" "}
                                        {alert.a && alert.href && !alert.href.includes("http") &&
                                            <Link className={`cursor ${styles.a}`} to={alert.href} target={alert.target} rel="noopener noreferrer" onClick={alert.onClick ? alert.onClick() : () => {}}>{alert.a}</Link>
                                        }
                                        {alert.a && alert.href && alert.href.includes("http") &&
                                            <a className={`cursor ${styles.a}`} href={alert.href} target={alert.target} rel="noopener noreferrer" onClick={alert.onClick ? alert.onClick() : () => {}}>{alert.a}</a>
                                        }
                                    </p>
                                </div>
            
                                <div
                                onClick={() => {
                                    dispatch({
                                        type: "CLOSE_ALERT",
                                        payload: alert.uuid
                                    })
                                }}
                                className="margin-left-32"
                                >
                                    <Icon icon="close" size={20} className="cursor" color="white" />
                                </div>
                            </div>
                            
                            {alert.timer &&
                                <div
                                className={styles.timer}
                                style={{
                                    animationDuration: `${alert.timer}ms`
                                }}
                                />
                            }
                        </div>
                    ))}
                </div>
            }
        </AlertContext.Provider>
    )
}
export default AlertProvider;