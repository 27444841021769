import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'

const defaultAuth = {
	firstOpen: true,
	openedProVersion: false,
	openedContributorVersion: false,
	seenStoryTutorial: false,
	seenContributeTutorial: false,

	inLoadingState: true,
	isLoggedIn: false,
	token: null,
	tokenType: null,
	accessToken: null,
	uid: null,
    errorMessage: null,
    logInStatus: status.DEFAULT,

	userExists: {
        result: false,
		message: null,
        status: status.DEFAULT
	},

	healthCheck: {
        allow_anon_users: true,
        allow_sign_up: true,
        allow_pro: false,
        allow_newsletter: false,
        allow_version_3: false,
        allow_web_ads: false,
        only_show_web_pro_ads: false,
        allow_mobile_ads: false,
        only_show_mobile_pro_ads: false,
        show_news_ad: false,
        news_ad_date: null,
        show_pro_ad: false,
		show_announcement: false,
		announcement_text: null,
		free_message_limit: null,
		free_limit_notice: null, 
		supported_languages: null, 
        status: status.DEFAULT
	},
	
	forgotPasswordStatus: status.DEFAULT,
	changePasswordStatus: status.DEFAULT
};

const auth = (state = defaultAuth, action) => {
	switch (action.type) {
        case "INIT_STATE_LOADED":
            return {
                ...state,
				inLoadingState: false,
				errorMessage: null,
				logInStatus: status.DEFAULT,
				forgotPasswordStatus: status.DEFAULT,
				changePasswordStatus: status.DEFAULT,
				userExists: {
					result: false,
					reason: null,
					status: status.DEFAULT
				},
            }
        case "OPENED_APP":
            return {
                ...state,
				firstOpen: false,
				openedProVersion: true,
				openedContributorVersion: true,
            }
		case "OPENED_PRO_VERSION":
			return {
				...state,
				openedProVersion: true,
			}
		case "OPENED_CONTRIBUTOR_VERSION":
			return {
				...state,
				openedContributorVersion: true,
			}
		case "SEEN_CONTRIBUTE_TUTORIAL":
            return {
                ...state,
				seenContributeTutorial: true,
            }
		case types.LOG_IN:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						logInStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,

						token: action.payload.token,
						tokenType: action.payload.auth_info.token_type,
						accessToken: action.payload.auth_info.access_token,

						uid: action.payload.uid,
						isLoggedIn: true,
						errorMessage: null,
						logInStatus: status.SUCCESS,
						firstOpen: false,
					}
				case status.ERROR:
					return {
						...state,
                        isLoggedIn: false,

						token: null,
						tokenType: null,
						accessToken: null,

						errorMessage: errorToString(action.payload),
						logInStatus: status.ERROR,
						firstOpen: false,
					}
			}
		case types.LOG_OUT:
			return {
				...state,
				...defaultAuth,
				
				localUid: state.localUid,
				healthCheck: state.healthCheck,

				isLoggedIn: false,
				
				token: null,
				tokenType: null,
				accessToken: null,

				uuid: null,
				inLoadingState: false,
				firstOpen: false,
				openedProVersion: true,
				openedContributorVersion: true,
				seenContributeTutorial: state.seenContributeTutorial ? true : false,
			}
		case types.FORGOT_PASSWORD:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						forgotPasswordStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						forgotPasswordStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						forgotPasswordStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}
		case types.CHANGE_PASSWORD:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						changePasswordStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						changePasswordStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						changePasswordStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}
		case types.USER_EXISTS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						userExists: {
							...state.userExists,
							status: status.BEGIN,
						},
					}
				case status.SUCCESS:
					return {
						...state,
						userExists: {
							...state.userExists,
							result: action.payload.status.exists,
							message: action.payload.status.message,
							status: status.SUCCESS,
						},
						errorMessage: null,
					}
				case status.ERROR:
					return {
						...state,
						userExists: {
							...state.userExists,
							status: status.ERROR,
						},
						errorMessage: errorToString(action.payload),
					}
			}

		case types.GET_HEALTH_CHECK:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						healthCheck: {
							...state.healthCheck,
							status: status.BEGIN,
						},
					}
				case status.SUCCESS:
					return {
						...state,
						healthCheck: {
							...state.healthCheck,
							allow_sign_up: action.payload.allow_sign_up,
							allow_anon_users: action.payload.allow_anon_users,
							allow_pro: action.payload.allow_pro,
							allow_newsletter: action.payload.allow_newsletter,
							allow_version_3: action.payload.allow_version_3,
							allow_web_ads: action.payload.allow_web_ads,
							only_show_web_pro_ads: action.payload.only_show_web_pro_ads,
							allow_mobile_ads: action.payload.allow_mobile_ads,
							only_show_mobile_pro_ads: action.payload.only_show_mobile_pro_ads,
							show_news_ad: action.payload.show_news_ad_web,
							news_ad_date: action.payload.news_ad_date,
							show_pro_ad: action.payload.show_pro_ad,
							show_announcement: action.payload.show_announcement,
							announcement_text: action.payload.announcement_text,
							free_message_limit: action.payload.free_message_limit,
							free_limit_notice: action.payload.free_limit_notice,
							supported_languages: action.payload.supported_languages,
							status: status.SUCCESS,
						},
						errorMessage: null,
					}
				case status.ERROR:
					return {
						...state,
						healthCheck: {
							...state.healthCheck,
							status: status.ERROR,
						},
						errorMessage: errorToString(action.payload),
					}
			}

		case "CLEAR_AUTH_ERRORS":
			return {
				...state,
				errorMessage: null
			}

		default:
			return state;
	}
};

export default auth;