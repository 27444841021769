import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { handleReducer } from '../utils/reducer-helpers';

function handleMergedReducer(state, action, nestedName) {
	//This function is a generalized solution for handling the status and more for the requests from search-actions.
	let reset = action.meta && action.meta.params ? action.meta.params.reset : false

	switch (action.meta.status) {
		case status.BEGIN:
			return {
				...state,
				[nestedName]: {
					...state[nestedName],
					next: null,
					reset: action.meta && action.meta.params ? action.meta.params.reset : false,
					status: status.BEGIN,
                    controller: action.meta ? action.meta.controller : null
				},
			}
		case status.SUCCESS:
			return {
				...state,
				[nestedName]: {
					data: reset ? action.payload.results : [ ...state[nestedName].data, ...action.payload.results ],
					count: action.payload.count,
					next: action.payload.next,
					status: status.SUCCESS,
                    secondaryNext: action.payload.secondaryNext,
                    secondaryResults: action.payload.secondaryResults,
                    limit: action.payload.limit,
                    controller: null
				},
			}
		case status.ERROR:
			return {
				...state,
				[nestedName]: {
					...state[nestedName],
					data: [],
					count: null,
					next: null,
					status: status.ERROR,
                    controller: null
				},
			}
	}
}

const defaultSearch = {
	reviews: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },
    users: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },
    lists: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },

    mergedAlbums: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },
    mergedTracks: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },
    mergedArtists: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },

	staticReviews: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },
    staticUsers: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },
    staticLists: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },

    staticMergedAlbums: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },
    staticMergedTracks: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },
    staticMergedArtists: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },

	errorMessage: null,
};

const search = (state = defaultSearch, action) => {
	let reset = action.meta && action.meta.params ? action.meta.params.reset : false
    let uid = action.meta && action.meta.params ? action.meta.params.uid : null
	switch (action.type) {
		//Get any reviews for search and update the reviews object
		case types.GET_SEARCH_REVIEWS:
			return handleReducer(state, action, 'reviews')

		case types.GET_STATIC_SEARCH_REVIEWS:
			return handleReducer(state, action, 'staticReviews')
			
		case types.CLEAR_SEARCH_REVIEWS:
			return {
                ...state,
                reviews: defaultSearch.reviews,
            }

		//Get any lists for search and update the lists object
		case types.GET_SEARCH_LISTS:
			return handleReducer(state, action, 'lists')
		
		case types.GET_STATIC_SEARCH_LISTS:
			return handleReducer(state, action, 'staticLists')

		case types.CLEAR_SEARCH_LISTS:
			return {
                ...state,
                lists: defaultSearch.lists,
            }

		//Get any users for search and update the users object
		case types.GET_SEARCH_USERS:
			return handleReducer(state, action, 'users')
		
		case types.GET_STATIC_SEARCH_USERS:
			return handleReducer(state, action, 'staticUsers')

		case types.CLEAR_SEARCH_USERS:
			return {
                ...state,
                users: defaultSearch.users,
            }
        
        // Merged content
		case types.SEARCH_MERGED_ALBUMS:
			return handleMergedReducer(state, action, 'mergedAlbums')
		
		case types.SEARCH_STATIC_MERGED_ALBUMS:
			return handleMergedReducer(state, action, 'staticMergedAlbums')

		case types.CLEAR_SEARCH_ALBUMS:
			return {
                ...state,
                mergedAlbums: defaultSearch.mergedAlbums,
            }
		
        case types.SEARCH_MERGED_TRACKS:
			return handleMergedReducer(state, action, 'mergedTracks')
		
		case types.SEARCH_STATIC_MERGED_TRACKS:
			return handleMergedReducer(state, action, 'staticMergedTracks')

		case types.CLEAR_SEARCH_TRACKS:
			return {
                ...state,
                mergedTracks: defaultSearch.mergedTracks,
            }
        
        case types.SEARCH_MERGED_ARTISTS:
			return handleMergedReducer(state, action, 'mergedArtists')

		case types.SEARCH_STATIC_MERGED_ARTISTS:
			return handleMergedReducer(state, action, 'staticMergedArtists')

		case types.CLEAR_SEARCH_ARTISTS:
			return {
                ...state,
                mergedArtists: defaultSearch.mergedArtists,
            }
		
		default:
			return state;
	}
};

export default search;