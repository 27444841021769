import styles from './trackitem.module.css'

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { oneDecimal } from '../../utils/format-text'
import { checkDeezerId } from '../../utils/deezer-utils'

import { usePopup } from '../../hooks/popup/popup'

import Icon from '../Icon'
import Tooltip from '../Tooltip';
import PlayTracksButton from '../PlayTracksButton'
import ContentOptionsTooltip from '../ContentOptionsTooltip'

function TrackItem({
    track,
    album,
    artist,
    number,
    highlight,
    playTracks
}) {
    //General
    const { openPopup } = usePopup()
    const dispatch = useDispatch()

    //Responsive
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

    //Data
    const backend = useSelector(state => state.tracks.tracks[track.id])

    //Render
    return (
        <div
        className={`${styles.container} ${highlight ? styles.highlight : ""} link-wrapper content-options-wrapper`}
        >
            <Link 
            to={checkDeezerId(track) ? `/track/${track.id}?dz` : track.url_slug} 
            className="link-overlay" 
            >
                <span className='link-overlay-title'>{track.title}</span>
            </Link>

            <div className="col-1 flex-row link-inner">
                <div className={styles.itemContainer}>
                    <p className="grey">{number}</p>
                </div>

                <div className="grid col-1">
                    <p className="col-1 one-line black" style={{ lineHeight: '20px' }}>{track.title}</p>
                </div>

                {!isMobile &&
                    <div className="link-clickable margin-right-8 margin-left-8">
                        <ContentOptionsTooltip content={{ ...track, album, artist }} appendId={`trackitem-`} absoluteDesign={false} />
                    </div>
                }

                {track.preview && playTracks &&
                    <PlayTracksButton 
                    playTracks={playTracks}
                    trackId={track.id}
                    />
                }

                <div 
                onClick={() => {
                    if (!isLoggedIn) {
                        openPopup("SignUp")
                        return;
                    }

                    openPopup("CreateReview", { content: { ...track, album, artist }, cacheRating: false })
                }} 
                id={`track-${track.id}-stars`} 
                className={`flex-row cursor margin-left-16 margin-right-16 ${!isMobile && "link-clickable"}`}
                >
                    <Icon icon="star" size={16} color={(backend && backend.average_rating) ? "var(--yellow)" : "var(--highDarkGrey)"} className={"margin-right-8"} />

                    <div className={styles.ratingContainer}>
                        <p className="black">{(backend && backend.average_rating) ? oneDecimal(backend.average_rating/2) : 0} <span className="grey">/ 5</span></p>
                    </div>
                </div>

                <Tooltip id={`track-${track.id}-stars`} text="Rate this track" offset={-6} />
            </div>
        </div>
    )
}

export default React.memo(TrackItem)