import styles from './activityitem.module.css'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { stickerClassEnums } from '../../../../../../constants/enums'

import { followUser } from '../../../../../../actions/users-actions'

import { formatTimeAgo } from '../../../../../../utils/format-time'

import Icon from '../../../../../../components/Icon'
import Button from '../../../../../../components/Button'
import AlbumCover from '../../../../../../components/AlbumCover'
import ProfilePicture from '../../../../../../components/ProfilePicture'
import UserProfilePicture from '../../../../../../components/UserProfilePicture'
import { useTrack } from '../../../../../../hooks/tracking'

function ActivityItem({ notification, className, onClick=()=>{} }) {
    //General
    const dispatch = useDispatch()
    const track = useTrack()

    //followObj
    const myFollows = useSelector(state => state.me.follows)
    const cachedFollows = useSelector(state => state.me.cachedFollows)
    const follows = [ ...myFollows, ...cachedFollows ]
    const followIndex = follows.findIndex(follow => follow.followed_user === (notification.sender ? notification.sender.uid : null))
    const followObj = followIndex !== -1 ? follows[followIndex] : null

    //href
    let href = null
    let hrefText = null
    if (notification.data.content) {
        if (notification.data.content.type === "user") {
            href = `/${notification.data.content.username}`
        } else if (
            notification.data.content.type === "rating"
        ) {
            if (notification.data.content.patch_note) {
                href = `/patch-notes/${notification.data.content.patch_note.uid}`
            } else {
                href = `/${notification.data.content.type}/${notification.data.content.uid}`
            }
        } else if (
            notification.data.content.type === "list" ||
            notification.data.content.type === "review" ||
            notification.data.content.type === "thread"
        ) {
            if (notification.data.content.url_slug) {
                href = notification.data.content.url_slug
            } else {
                href = `/${notification.data.content.type}/${notification.data.content.uid}`
            }
        } else if (
            notification.data.content.type === "sticker"
        ) {
            if (notification.data.content.review) {
                href = notification.data.content.review.url_slug
            } else if (notification.data.content.list) {
                href = notification.data.content.list.url_slug
            } else if (notification.data.content.thread) {
                href = notification.data.content.thread.url_slug
            } else if (notification.data.content.rating) {
                href = `/rating/${notification.data.content.rating.uid}`
            }
        } else if (
            notification.data.content.type === "album" ||
            notification.data.content.type === "artist"
        ) {
            if (notification.data.content.url_slug) {
                href = notification.data.content.url_slug
            } else {
                href = `/${notification.data.content.type}/${notification.data.content.id}`
            }
        } else if (
            notification.data.content.type === "track"
        ) {
            if (notification.data.content.album.url_slug) {
                href = `${notification.data.content.album.url_slug}?tracks=${notification.data.content.id}`
            } else {
                href = `/album/${notification.data.content.album.id}?tracks=${notification.data.content.id}`
            }
        }
    }

    return (
        <div className={`link-wrapper ${styles.container} ${className}`}>
            <Link className="link-overlay" to={href} onClick={onClick}>
                <span className='link-overlay-title'>{notification.sender.username} {notification.data.message}  {formatTimeAgo(notification.created_at)}</span>
            </Link>

            <div className="link-inner flex-row justify-between">
                <div className="col-1 flex-row ">
                    {!notification.sender &&
                        <div className={styles.exclamation}>
                            <Icon icon="exclamation" size={16} />
                        </div>
                    }

                    {notification.sender &&
                        <Link to={`/${notification.sender.username}`} onClick={onClick}>
                            <UserProfilePicture 
                            clickable 
                            profilePicture={notification.sender.profile_picture?.includes(".gif") ? notification.sender.profile_picture : notification.sender.profile_picture_small || notification.sender.profile_picture} 
                            profileFrame={notification.sender.is_pro && notification.sender.tier === 1 ? notification.sender.profile_frame : null} 
                            size={50} 
                            altText={notification.sender.name !== "" ? notification.sender.name : notification.sender.username}
                            />
                        </Link>
                    }

                    <p className="black margin-left-12 col-1">
                        {notification.sender &&
                            <Link onClick={onClick} to={`/${notification.sender.username}`} className={styles.a} style={{ fontWeight: 'bold' }}>{notification.sender.username} </Link>
                        }

                        {notification.data.message}
                        {"  "}

                        <span className="grey">
                            {formatTimeAgo(notification.created_at)}
                        </span>
                    </p>
                </div>

                {notification.data.content && notification.data.content.type === "user" && (
                    <div className="margin-left-16">
                    {
                        (followObj) ?
                            <Link to={`/${notification.data.content.username}`} onClick={onClick}>
                                <Button
                                type="secondary"
                                height={30}
                                style={{ width: 110 }}
                                textStyle={{ fontSize: 13 }}
                                textClassName="highDarkGrey"
                                text={"Go To User"}
                                />
                            </Link>
                            :
                            <Button
                            onClicked={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                    
                                track(
                                    "Follow user", 
                                    { 
                                        "user_uid": notification.sender.uid,
                                        "referrer": "ActivityItem",
                                    }
                                );

                                dispatch( 
                                    followUser(notification.sender.uid) 
                                )
                            }}
                            height={30}
                            style={{ width: 112 }}
                            textStyle={{ fontSize: 14 }}
                            text={"Follow"}
                            className="link-clickable"
                            />
                        }
                    </div>
                )}

                {notification.data.content && notification.data.content.type !== "user" &&
                    <Link to={href} className="margin-left-16" onClick={onClick}>
                        {notification.data.content.type === "review" && (
                            notification.data.content.rating.content.type === "artist" ?
                                <ProfilePicture
                                clickable
                                size={50}
                                profilePicture={notification.data.content.rating.content.picture_small || notification.data.content.rating.content.picture}
                                altText={notification.data.content.rating.content.name}
                                />
                            :
                                <AlbumCover 
                                clickable
                                size={50}
                                albumCover={notification.data.content.rating.content.type === "album" ? notification.data.content.rating.content.cover_small || notification.data.content.rating.content.cover : notification.data.content.rating.content.album.cover_small || notification.data.content.rating.content.album.cover}
                                altText={notification.data.content.rating.content.type === "album" ? notification.data.content.rating.content.title : notification.data.content.rating.content.title}
                                />
                        )}
                        {notification.data.content.type === "rating" && !notification.data.content.patch_note && (
                            notification.data.content.content.type === "artist" ?
                                <ProfilePicture
                                clickable
                                size={50}
                                profilePicture={notification.data.content.content.picture_small || notification.data.content.content.picture}
                                altText={notification.data.content.content.name}
                                />
                            :
                                <AlbumCover 
                                clickable
                                size={50}
                                albumCover={notification.data.content.content.type === "album" ? notification.data.content.content.cover_small || notification.data.content.content.cover : notification.data.content.content.album.cover_small || notification.data.content.content.album.cover}
                                altText={notification.data.content.content.type === "album" ? notification.data.content.content.title : notification.data.content.content.title}
                                />
                        )}
                        {notification.data.content.type === "list" &&
                            <AlbumCover 
                            clickable
                            size={50}
                            albumCover={notification.data.content.contents.map(content => {
                                if(content.type === "artist") {
                                    return content.picture_small || content.picture
                                } else if(content.type === "album") {
                                    return content.cover_small || content.cover
                                } else if(content.type === "track") {
                                    return content.album.cover_small || content.album.cover
                                } else {
                                    return ""
                                }
                            })}
                            altText={notification.data.content.contents.map(content => {
                                if(content.type === "artist") {
                                    return content.name
                                } else if(content.type === "album") {
                                    return content.title
                                } else if(content.type === "track") {
                                    return content.title
                                } else {
                                    return ""
                                }
                            })}
                            />
                        }
                        {notification.data.content.type === "sticker" &&
                            <div className={`${styles.sticker} ${styles[stickerClassEnums[notification.data.content.sticker]]}`} />
                        }
                        {notification.data.content.type === "album" &&
                            <AlbumCover 
                            clickable
                            size={50}
                            albumCover={notification.data.content.cover_small || notification.data.content.cover}
                            altText={notification.data.content.title}
                            />
                        }
                        {notification.data.content.type === "track" &&
                            <AlbumCover
                            clickable
                            size={50}
                            albumCover={notification.data.content.album.cover_small || notification.data.content.album.cover}
                            altText={notification.data.content.title}
                            />
                        }
                        {notification.data.content.type === "artist" &&
                            <ProfilePicture
                            clickable
                            size={50}
                            profilePicture={notification.data.content.picture_small || notification.data.content.picture}
                            altText={notification.data.content.name}
                            />
                        }
                        {notification.data.content.type === "thread" &&
                            <div className={styles.threadBox}>
                                <Icon icon='forum' size={22} color={"var(--grey)"} />
                            </div>
                        }
                        {notification.data.content.type === "rating" && notification.data.content.patch_note && 
                            <div className={styles.threadBox}>
                                <Icon icon='handyman' size={22} color={"var(--grey)"} />
                            </div>
                        }
                    </Link>
                }
            </div>
        </div>
    )
}

export default React.memo(ActivityItem)
