import styles from './contentoptionstooltip.module.css'

import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { status } from '../../constants/action-types'

import { createWant, deleteWant } from '../../actions/me-actions'
import { getMyContentRating } from '../../actions/content-actions'

import { useAlert } from '../../hooks/alert/alert'
import { usePopup } from '../../hooks/popup/popup'

import { checkDeezerId } from '../../utils/deezer-utils'

import Icon from '../Icon'
import Tooltip from '../Tooltip'
import ChangeContentRating from '../ChangeContentRating'

function ContentOptionsTooltip({
    appendId,
    content,
    absoluteDesign=true,
    newTab
}) {
    //General
    const dispatch = useDispatch()
    const { openPopup } = usePopup()
    const { openAlert } = useAlert()

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const username = useSelector(state => state.me.username)
    
    //State
    const [visible, setVisible] = useState(false)
    const [clicked, setClicked] = useState(false)

    //Data
    const backend = useSelector(state => state[`${content.type}s`][`${content.type}s`][content.id])

    //Wants
    const deleteWantStatus = useSelector(state => state.me.deleteWantStatus)
    const lastDeletedWantContentId = useSelector(state => state.me.lastDeletedWantContentId)
    const myWants = useSelector(state => state.me.wants)
    const cachedWants = useSelector(state => state.me.cachedWants)
    const wants = [ ...myWants, ...cachedWants ]
    const wantIndex = wants.findIndex(want => want.content_id == content.id && want.content_ct === content.type)
    const wantObj = wantIndex !== -1 ? wants[wantIndex] : null

    //Refs
    const toolTipRef = useRef()

    //When loaded user's rating => open the menu
    const firstUpdate = useRef(true)
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if(backend && backend.myRatings && backend.myRatings.status === status.SUCCESS) {
            setClicked(false)
            if (toolTipRef.current && clicked) {
                toolTipRef.current.open()
            }
        } else if (backend && backend.myRatings && backend.myRatings.status === status.ERROR) {
            setClicked(false)
            if (toolTipRef.current && clicked) {
                toolTipRef.current.open()
            }
        }

    }, [ backend ])

    if (!isLoggedIn) {
        return null
    }

    const hasReview = backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0] && (backend.myRatings.data[0].review_url_slug || backend.myRatings.data[0].review_uid);
    const hasRating = backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0];

    //Options in the menu
    const menu = [
        {
            label: "Write review",
            icon: "edit",
            onClick: () => {
                if (!isLoggedIn) {
                    openPopup("SignUp")
                    return;
                }

                openPopup("CreateReview", { content: content, cacheRating: false })
            },
        },
        !hasReview && hasRating && {
            label: backend.myRatings.data[0].rating ? "Go to your rating" : "Go to your log entry",
            icon: backend.myRatings.data[0].rating ? "star" : "headphones",
            redirect: `/rating/${backend.myRatings.data[0].uid}`,
        },
        hasReview && {
            label: "Read your review",
            icon: "remove-red-eye",
            redirect: backend.myRatings.data[0].review_url_slug ? backend.myRatings.data[0].review_url_slug : `/review/${backend.myRatings.data[0].review_uid}`,
        },
        {
            label: wantObj ? "Remove from Listen Later" : "Add to Listen Later",
            onClick: () => {
                if (!isLoggedIn) {
                    openPopup("SignUp")
                    return;
                }

                if (wantObj) {
                    dispatch(
                        deleteWant(wantObj.uid)
                    )
                } else {
                    dispatch(
                        createWant(content.id, content.type, checkDeezerId(content))
                    )
                }

                toolTipRef.current.close()
            },
            icon: (deleteWantStatus === status.BEGIN && lastDeletedWantContentId == content.id) ? "loading" : wantObj ? "cancel" : "access-time",
        },
        content.type !== "track" && {
            label: `Go to ${content.type}`,
            icon: content.type === "album" ? "album" : "person",
            redirect: checkDeezerId(content) ? `/${content.type}/${content.id}?dz` : content.url_slug
        },
        content.type === "track" && {
            label: `Read reviews`,
            icon: "music-note",
            redirect: checkDeezerId(content) ? `/track/${content.id}/?dz` : content.url_slug
        },
        {
            label: `Add ${content.type} to a list`,
            icon: "playlist-add",
            onClick: () => {
                if (!isLoggedIn) {
                    openPopup("SignUp")
                    return;
                }
                
                openPopup("AddToList", { popupTitle: `Add '${content.type === "artist" ? content.name : content.title}' to a List`, content: content })
            },
        }
    ]

    return (
        <>
            {absoluteDesign &&
                <div
                className={`${styles.absoluteContainer} ${styles.onHover}`}
                onMouseDown={() => {
                    setClicked(true)
                    dispatch(
                        getMyContentRating(content.id, content.type, checkDeezerId(content))
                    )
                    toolTipRef.current.close()
                }}
                id={`${appendId}-${content.type}-${content.id}-menu`}
                >
                    <div
                    style={{ height: 20 }}
                    className="flex-center"
                    id={`${appendId}-${content.type}-${content.id}-tip`} 
                    >
                        <Icon
                        className={styles.absoluteIcon} 
                        icon={backend && backend.myRatings && backend.myRatings.status === status.BEGIN && clicked ? "loading" : "more-horiz"} 
                        size={backend && backend.myRatings && backend.myRatings.status === status.BEGIN && clicked ? 16 : 20} 
                        />
                    </div>  
                </div>
            }
            
            {!absoluteDesign &&
                <div
                className={`${styles.normalContainer}`}
                onMouseDown={() => {
                    setClicked(true)
                    dispatch(
                        getMyContentRating(content.id, content.type, checkDeezerId(content))
                    )
                    toolTipRef.current.close()
                }}
                id={`${appendId}-${content.type}-${content.id}-menu`}
                >
                    <div
                    style={{ height: 24 }}
                    className="flex-center"
                    id={`${appendId}-${content.type}-${content.id}-tip`} 
                    >
                        <Icon
                        className={`${styles.normalIcon}`}
                        icon={backend && backend.myRatings && backend.myRatings.status === status.BEGIN && clicked ? "loading" : "more-horiz"} 
                        size={backend && backend.myRatings && backend.myRatings.status === status.BEGIN && clicked ? 20 : 24} 
                        />
                    </div>  
                </div>
            }

            <Tooltip
            offset={12}
            disabled={visible}
            id={`${appendId}-${content.type}-${content.id}-tip`}
            text="Show menu"
            />

            <Tooltip
            ref={toolTipRef}
            id={`${appendId}-${content.type}-${content.id}-menu`}

            offset={12.5}
            place="top"
            delayShow={100}

            className={styles.menuContainer}

            afterHide={() => setVisible(false)}
            afterShow={() => setVisible(true)}

            clickable={true}
            openOnClick
            globalEventOff
            
            element={
                <div>
                    <div className="flex-column flex-center padding-around">
                        <ChangeContentRating contentId={content.id} contentType={content.type} fromDeezer={checkDeezerId(content)} iconSize={24} />
                    </div>

                    <div className={styles.line} />

                    <div className="padding-around-8">
                        {menu.filter(i => i).map(item => (
                            <Link
                            to={item.redirect ? item.redirect : ""}
                            target={newTab ? "_blank" : null}
                            onClick={(e) => {
                                toolTipRef.current.close()
                                if (item.onClick) {
                                    e.preventDefault()
                                    item.onClick()
                                }
                            }}
                            key={`${appendId}-${content.type}-${content.id}-menu-item-${item.label}`}
                            className={`flex-row cursor ${styles.menuItem}`}
                            >
                                <Icon icon={item.icon} size={20} className={styles.menuItemIcon} color={"var(--black)"} />
                                <h6 className={`${styles.menuItemText} margin-left-16 black`}>{item.label}</h6>
                            </Link>
                        ))}
                    </div>
                </div>
            }
            />
        </>
    )
}

export default React.memo(ContentOptionsTooltip)