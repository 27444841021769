import React, { Suspense, lazy } from 'react'

import { BrowserRouter as Router, Route, Switch, Redirect, useRouteMatch } from "react-router-dom";

import NotFound from '../../../../routes/NotFound';

import List from '../../../../routes/List';

const UpdateList = lazy(() => import('../../../../routes/UpdateList'));
const CreateList = lazy(() => import('../../../../routes/CreateList'));

export default function ListNavigator(props) {
    const match = useRouteMatch()
    return (
        <Switch>
            <Route exact path={`${match.url}/new`} component={CreateList} />

            <Route exact path={`${match.url}/:uid`} component={List} />
            <Route exact path={`${match.url}/:uid/edit`} component={UpdateList} />

            <Route path="*" component={NotFound} />
        </Switch>
    )
}
