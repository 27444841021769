import styles from './bigreleaseitem.module.css'

import React from 'react'
import AlbumCover from '../AlbumCover'
import ProfilePicture from '../ProfilePicture'
import Icon from '../Icon'
import Stars from '../Stars'
import { useTheme } from '../../hooks/theme'
import { firstLetterCapital } from '../../utils/captialization'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import ContentOptionsTooltip from '../ContentOptionsTooltip'
import { displayRecordType } from '../../utils/record-type-utils'
import { checkDeezerId } from '../../utils/deezer-utils'

function BigReleaseItem({ release }) {
    const { colors } = useTheme()

    let href = checkDeezerId(release) ? `/album/${release.id}?dz` : release.url_slug

    const isMobile = useMediaQuery({ maxWidth: 630 })

    return (
        <div className={`content-options-wrapper cursor`}>
            <div className={styles.center}>
                <Link to={href}>
                    <AlbumCover clickable size={'100%'} albumCover={release.cover_medium || release.cover_large || release.cover_big || release.cover} altText={release.title} />
                </Link>

                <div className={styles.alignCenter}>
                    <ContentOptionsTooltip content={release} appendId={`bigreleaseitem`} />
                </div>
            </div>
            <Link to={href}>
                <div className={`${styles.textContainer} grid`}>
                    <h6 className={`${styles.title} one-line margin-top-12`}>{release.title}</h6>

                    <div className="flex-row align-center justify-center">
                        <p className={`${styles.subText} margin-top-4`}>{!isMobile && release.record_type && displayRecordType(release.record_type)}{!isMobile && release.record_type && release.release_date && " • "}{release.release_date && release.release_date.substr(0,4)}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default React.memo(
    BigReleaseItem,
    (prevProps, nextProps) => {
        if (prevProps.release.id !== nextProps.release.id) {
            return true;
        }
        if (prevProps.release.title !== nextProps.release.title) {
            return true;
        }
        if (prevProps.release.explicit_lyrics !== nextProps.release.explicit_lyrics) {
            return true;
        }
        if (prevProps.release.record_type !== nextProps.release.record_type) {
            return true;
        }
        if (prevProps.release.release_date !== nextProps.release.release_date) {
            return true;
        }
        return false;
    }
)