import React from "react"
import ContentLoader from "react-content-loader"

export default function ReviewItemLoader(props) {
    return(
        <ContentLoader 
        speed={2}
        width={320}
        height={260}
        backgroundColor="var(--lightGrey)"
        foregroundColor="var(--darkLightGrey)"
        {...props}
        >
            <rect x="16" y="16" rx={props.roundImage ? "44" : "4"} ry={props.roundImage ? "44" : "4"} width="88" height="88" />

            <rect x="120" y="43" rx="4" ry="4" width="160" height="10" />
            
            <rect x="120" y="68" rx="2" ry="2" width="64" height="6" />

            <rect x="16" y="124" rx="2" ry="2" width="200" height="10" />

            <circle cx="25" cy="158" r="8" /> 
            <circle cx="45" cy="158" r="8" /> 
            <circle cx="65" cy="158" r="8" /> 
            <circle cx="85" cy="158" r="8" /> 
            <circle cx="105" cy="158" r="8" /> 

            <rect x="16" y="188" rx="2" ry="2" width="304" height="6" />
            
            <circle cx="32" cy="228" r="16" /> 

            <rect x="64" y="223" rx="4" ry="4" width="75" height="10" />
        </ContentLoader>
    )
}