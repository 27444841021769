import React from "react"
import ContentLoader from "react-content-loader"

export default function TrackItemLoader(props) {
    return(
        <ContentLoader 
        speed={2}
        width={280}
        height={48}
        backgroundColor="var(--lightGrey)"
        foregroundColor="var(--darkLightGrey)"
        {...props}
        >
            <circle cx="24" cy="24" r="5" /> 
            <rect x="45" y="19" rx="4" ry="4" width="254" height="10" />
        </ContentLoader>
    )
}