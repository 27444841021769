import styles from './searchalbum.module.css'

import React from 'react'
import AlbumCover from '../../../../components/AlbumCover'
import Icon from '../../../../components/Icon'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import ContentOptionsTooltip from '../../../../components/ContentOptionsTooltip'
import { checkDeezerId } from '../../../../utils/deezer-utils'

export default function SearchAlbum({ album, style, size=88, className, overrideClick, active, useContentOptions, overwriteButton }) {

    //Responsive
    const isTablet = useMediaQuery({ maxWidth: 768 })

    return (
        <div className={`${styles.container} cursor link-wrapper content-options-wrapper ${className} ${active ? styles.active : ""}`} style={style}>
            <Link
            className="link-overlay"
            to={checkDeezerId(album) ? `/album/${album.id}?dz` : album.url_slug}
            onClick={(e) => {
                if (overrideClick) {
                    e.preventDefault()
                    overrideClick()
                }
            }}
            >
                <span className='link-overlay-title'>{album.title}</span>
            </Link>
            
            <div className="flex-row link-inner">
                <Link
                className="link-clickable"
                to={checkDeezerId(album) ? `/album/${album.id}?dz` : album.url_slug}
                onClick={(e) => {
                    if (overrideClick) {
                        e.preventDefault()
                        overrideClick()
                    }
                }}
                >
                    <AlbumCover size={isTablet ? 56 : size} clickable albumCover={album.cover_medium || album.cover} altText={album.title} />
                </Link>
                
                <div className="col-1 margin-left-16">
                    <div className="flex-row">
                        <div className="grid">
                            <h5 className="black one-line" style={{marginRight: 6}}>{album.title}</h5>
                        </div>
                        {album.explicit_lyrics &&
                            <Icon icon="explicit" className="margin-right-8" style={{ marginTop: 0 }} color="var(--grey)" size={14} />
                        }
                    </div>

                    <div className="grid margin-top-4">
                            <p className="highDarkGrey">
                                <Link className={styles.artistLink} to={checkDeezerId(album.artist) ? `/artist/${album.artist.id}?dz` : album.artist.url_slug}>
                                    {album.artist.name}
                                </Link>
                            </p>
                    </div>
                </div>

                {overwriteButton &&
                    <div className='link-clickable margin-left-8'>
                        {overwriteButton}
                    </div>
                }

                {useContentOptions && !isTablet &&
                    <div className="link-clickable margin-right-8 margin-left-8">
                        <ContentOptionsTooltip content={album} appendId={`searchalbum-`} absoluteDesign={false} />
                    </div>
                }
            </div>
        </div>
    )
}
