import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';

import * as reducers from '../reducers/';

import logoutHandle from '../middleware/logout-handle'

const persistConfig = {
    key: 'musicboard/v1.58',
    storage: storage,
    whitelist: ['auth', 'me', 'activity', 'users', 'local', 'payments'], /* 'genres', 'lists', 'reviews', 'albums', 'artists', 'tracks', 'ratings', 'comments', 'likes', */
};

const reducer = combineReducers(reducers)
const pReducer = persistReducer(persistConfig, reducer);


const createStoreWithMiddleware = applyMiddleware(thunk, logoutHandle)(createStore);
export const store = createStoreWithMiddleware(pReducer);

export const persistor = persistStore(store);

store.subscribe(() => console.log(store.getState()))
