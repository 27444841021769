import { recordTypeLabels, recordTypes } from '../constants/record-type-constants'

export const displayRecordType = (type, lowerCase, key="label") => {
    const index = recordTypeLabels.findIndex(obj => obj.value === type)
    
    if (index !== -1) {
        if (lowerCase) {
            if (recordTypeLabels[index][key] === "EP") {
                return "EP"
            } else {
                return recordTypeLabels[index][key].toLowerCase()
            }
        } else {
            return recordTypeLabels[index][key]
        }
    } else {
        return null
    }
}

export const isRecordType = (type) => {
    return recordTypes.indexOf(type) !== -1 ? true : false
}