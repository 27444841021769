import styles from './pincontentpopup.module.css'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'

import { paginationNext } from '../../actions/core-actions'
import { status, types } from '../../constants/action-types'
import Icon from '../../components/Icon'
import { getUsersLists } from '../../actions/users-actions'
import Button from '../../components/Button'
import { usePopup } from '../../hooks/popup/popup'
import { useAlert } from '../../hooks/alert/alert'
import TextInput from '../../components/TextInput'
import { createListConnectionsForContent } from '../../actions/lists-actions'
import { useMediaQuery } from 'react-responsive'
import ListCheckItem from '../../components/ListCheckItem'
import NewListItem from './components/NewListItem'
import IconButton from '../../components/IconButton'

export default function AddToListPopup({ scrollRef, options: { content }, requestClose }) {
    //General
    const { openPopup } = usePopup()
    const { openAlert } = useAlert()
    const dispatch = useDispatch()

    //Responsive
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Auth
    const myUsername = useSelector(state => state.me.username)
    const myUid = useSelector(state => state.auth.uid)
    const username = useSelector(state => state.me.username)

    //State
    const [search, setSearch] = useState("")
    const [selectedLists, setSelectedLists] = useState([])

    //Data
    const lists = useSelector(state => 
        state.users.users[myUsername]  && state.users.users[myUsername].lists ?
            state.users.users[myUsername].lists
        : null
    )
    const createListConnectionsForContentStatus = useSelector(state => state.lists.createListConnectionsForContentStatus)
    const errorMessage = useSelector(state => state.lists.errorMessage)

    //Get data
    useEffect(() => {
        dispatch(
            getUsersLists({
                username: myUsername,
                uid: myUid,
                order_by: '-created_at',
                title: search
            })
        )
    }, [ search ])

    //Redirect on done
    const firstUpdate = useRef(true)
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (createListConnectionsForContentStatus === status.SUCCESS) {
            openPopup(null)
            openAlert({
                p: `Added ${content.type === "artist" ? content.name : content.title} to ${selectedLists.length} ${selectedLists.length === 1 ? "list" : "lists"}.`,
                style: "green",
                timer: 6000
            })
        } else if (createListConnectionsForContentStatus === status.ERROR) {
            openAlert({
                p: `Could not add ${content.type}. Error: ${errorMessage}`,
                style: "red",
                timer: 6000
            })
        }

    }, [ createListConnectionsForContentStatus ])

    //Callback funciton for clicking content
    const addList = useCallback((item) => {
        setSelectedLists(selectedLists => [ ...selectedLists, item ])
    }, [])
    
    const removeList = useCallback((item) => {
        setSelectedLists(selectedLists => selectedLists.filter(list => list.uid !== item.uid))
    }, [])

    //Handle request close
    const firstUpdateForRequestClose = useRef(true)
    useEffect(() => {
        if (firstUpdateForRequestClose.current) {
            firstUpdateForRequestClose.current = false;
            return;
        }

        if (requestClose) {
            if (selectedLists.length > 0) {
                if (window.confirm("Are you sure you want to continue? All changes will be lost.")) {
                    openPopup(null)
                }
            } else {
                openPopup(null)
            }
        }

    }, [ requestClose ])

    return (
        <div>
            {/* Header */}
            <div className={`flex-row justify-between padding-around-8 ${styles.header}`}>
                <div className="col-1">
                    <IconButton
                    type="transparent-bright"
                    icon="close"
                    onClick={() => {
                        if (selectedLists.length > 0) {
                            if (window.confirm("Are you sure you want to continue? All changes will be lost.")) {
                                openPopup(null)
                            }
                        } else {
                            openPopup(null)
                        }
                    }}
                    />
                </div>

                <h5 className="col-2 text-center black" style={{ fontWeight: 700 }}>
                    {selectedLists.length <= 0 ? "Select Lists" : `${selectedLists.length} Selected`}
                </h5>
                
                <div className="col-1 flex-row justify-end">
                    <Button
                    text="Save"
                    height={38}
                    textStyle={{ fontSize: 14 }}
                    disabled={selectedLists.length <= 0 || createListConnectionsForContentStatus === status.BEGIN}
                    loading={createListConnectionsForContentStatus === status.BEGIN}
                    onClicked={() => {
                        dispatch(
                            createListConnectionsForContent(content, selectedLists)
                        )
                    }}
                    />
                </div>
            </div>

            {lists && lists.data && lists.data.length > 0 &&
                <InfiniteScroll
                className="padding-around-8"
                getScrollParent={() => scrollRef && scrollRef.current}
                useWindow={false}
                initialLoad={false}
                threshold={200}
                loadMore={() => {
                    if (lists && lists.next) {
                        dispatch( paginationNext(lists.next, types.GET_USERS_LISTS, { uid: myUid, username: myUsername, speedup: true, limit: 12 }) )
                    }
                }}
                hasMore={Boolean(lists.next || (lists.status === status.BEGIN && !lists.reset))}
                loader={
                    <div key={`addtolist-load-more`} className="flex-center margin-top-16">
                        <Icon icon="loading" disableLoadingAnimation />
                    </div>
                }
                >
                    <NewListItem content={content} />
                    
                    {lists.data.map((item, index) => (
                        <ListCheckItem
                        key={`addToList-list-${item.uid}`}
                        list={item}
                        onAdd={addList}
                        onRemove={removeList}
                        selected={selectedLists.findIndex(list => list.uid === item.uid) !== -1}
                        checked={selectedLists.findIndex(list => list.uid === item.uid) !== -1}
                        />
                    ))}
                </InfiniteScroll>
            }

            {lists && (lists.status === status.BEGIN && lists.data.length <= 0) &&
                <div className="flex-center empty-text margin-top-16 margin-bottom-16">
                    <Icon icon="loading" />
                </div>
            }
            
            {lists && (lists.status === status.SUCCESS && lists.data.length <= 0) &&
                <p className="empty-text-light margin-top-16">You have no lists yet.</p>
            }
        </div>
    )
}
