import styles from './newsad.module.css'

import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function NewsAd({ className }) {
    const news_ad_date = useSelector(state => state.auth.healthCheck.news_ad_date)
    const allPatchNotes = useSelector(state => state.administration.allPatchNotes)

    const patchNote = allPatchNotes && allPatchNotes.data ? allPatchNotes.data[0] : null

    if (!patchNote) {
        return null
    }

    return (
        <Link to={`/patch-notes/${patchNote.uid}`}>
            <div className={`section-block margin-bottom-32 ${className}`}>
                <div className='flex-row background-clickable-bright padding-around-24'>
                    <div className='flex-row'>
                        <div className={styles.new} />
                    </div>

                    <p className='margin-left-16'>
                        We just launched many new features and improvements! Click here to find out what's new.
                    </p>
                </div>
            </div>
        </Link>
    )
}
