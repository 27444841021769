import styles from './createreview.module.css'

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive'

import { useTheme } from '../../hooks/theme'
import { usePopup } from '../../hooks/popup/popup'
import { useAlert } from '../../hooks/alert/alert'

import { getMyContentRating, createArtist, createAlbum, createTrack } from '../../actions/content-actions'
import { status, types } from '../../constants/action-types'
import { getUsersReviews, getUsersRatings } from '../../actions/users-actions'
import { createRating } from '../../actions/ratings-actions'
import { createReview } from '../../actions/reviews-actions'

import { firstLetterCapital } from '../../utils/captialization'
import { displayRecordType } from '../../utils/record-type-utils';
import { checkDeezerId } from '../../utils/deezer-utils';

import AlbumCover from '../../components/AlbumCover'
import ProfilePicture from '../../components/ProfilePicture'
import ClickableStars from '../../components/ClickableStars'
import Icon from '../../components/Icon'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox';
import DateInput from '../../components/DateInput';
import Tooltip from '../../components/Tooltip';
import { updateDraft } from '../../actions/drafts-actions';
import { useTrack } from '../../hooks/tracking';

export default function CreateReview({ 
    options: { 
        content, 
        cacheRating, 
        newTab, 
        initRating=0, 
        initPinned=false, 
        initFirstListen=true, 
        initIsPrivate=false, 
        initDate=new Date(), 
        initShowListenedAt=true,
        initTitle="", 
        initDescription="", 
        draftUid=null 
    }, 
    requestClose 
}) {
    //General
    const { colors, mode } = useTheme()
    const { openPopup } = usePopup()
    const { openAlert } = useAlert()
    const track = useTrack()
    const dispatch = useDispatch()

    //Responsive
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isTablet = useMediaQuery({ maxWidth: 768 })
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Auth
    const myUsername = useSelector(state => state.me.username)
    const myUid = useSelector(state => state.auth.uid)
    const is_pro = useSelector(state => state.me.is_pro)

    //State
    const [rating, setRating] = useState(initRating)
    const [pinned, setPinned] = useState(initPinned)
    const [firstListen, setFirstListen] = useState(initFirstListen)
    const [isPrivate, setIsPrivate] = useState(initIsPrivate)
    const [date, setDate] = useState(initShowListenedAt ? initDate : null)
    const [title, setTitle] = useState(initTitle)
    const [description, setDescription] = useState(initDescription)

    //Ask for save draft when closing popup
    const firstUpdateForRequestClose = useRef(true)
    useEffect(() => {
        if (firstUpdateForRequestClose.current) {
            firstUpdateForRequestClose.current = false;
            return;
        }

        if (requestClose) {
            if (title !== "" || description !== "") {
                openPopup("SaveDraft", {
                    draftUid: draftUid,
                    data: {
                        content: content,
                        rating: rating,
                        title: title,
                        description: description,
                        pinned: pinned,
                        firstListen: firstListen,
                        private: false,
                        date: date ? date.toISOString() : new Date().toISOString(),
                        showListenedAt: Boolean(date),
                    },
                    type: "review",
                })
            } else {
                openPopup(null)
            }
        }

    }, [ requestClose ])

    //Create confirmation message for closing website
    useEffect(() => {
        if (title !== "" || description !== "") {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }

        return(() => {
            window.onbeforeunload = undefined
        })
    }, [title, description])

    //Data
    const createArtistStatus = useSelector(state => state.artists.createArtistStatus)
    const lastCreatedArtist = useSelector(state => state.artists.lastCreatedArtist)

    const createAlbumStatus = useSelector(state => state.albums.createAlbumStatus)
    const lastCreatedAlbum = useSelector(state => state.albums.lastCreatedAlbum)

    const createTrackStatus = useSelector(state => state.tracks.createTrackStatus)
    const lastCreatedTrack = useSelector(state => state.tracks.lastCreatedTrack)

    const createRatingStatus = useSelector(state => state.ratings.createRatingStatus)
    const lastCreatedRating = useSelector(state => state.ratings.lastCreatedRating)
    const ratingErrorMessage = useSelector(state => state.ratings.errorMessage)

    const createReviewStatus = useSelector(state => state.reviews.createReviewStatus)
    const lastCreatedReview = useSelector(state => state.reviews.lastCreatedReview)
    const reviewErrorMessage = useSelector(state => state.reviews.errorMessage)

    //Prepare content variables
    const contentType = content.type
    const isSuggested = (contentType === "suggested_album" || contentType === "suggested_artist" || contentType === "suggested_track")
    const formattedContentType = isSuggested ? firstLetterCapital(contentType.substr(10, 100)) : firstLetterCapital(contentType)
    const contentId = content.id
    const contentTitle = (contentType === "artist" || contentType === "suggested_artist") ? content.name : content.title
    let contentImage = "";
    let href = null;
    if(contentType === "artist") {
        href = checkDeezerId(content) ? `/artist/${contentId}?dz` : content.url_slug
        contentImage = content.picture_big ? content.picture_big : content.picture_large || content.picture
    } else if(contentType === "album") {
        href = checkDeezerId(content) ? `/album/${contentId}?dz` : content.url_slug
        contentImage = content.cover_big ? content.cover_big : content.cover_large || content.cover
    } else if(contentType === "track") {
        href = checkDeezerId(content) ? `/album/${content.album.id}?tracks=${contentId}&dz` : `${content.album.url_slug}?tracks=${contentId}`
        contentImage = content.album.cover_big ? content.album.cover_big : content.album.cover_large || content.album.cover
    } else if(contentType === "suggested_artist") {
        contentImage = content.picture ? content.picture : content.picture_url
    } else if(contentType === "suggested_album") {
        contentImage = content.cover ? content.cover : content.cover_url
    } else if(contentType === "suggested_track") {
        contentImage = (
            content.album ? 
                content.album.cover ? 
                    content.album.cover 
                : 
                    content.album.cover_url 
            : content.suggested_album ? 
                content.suggested_album.cover ? 
                    content.suggested_album.cover 
                : 
                    content.suggested_album.cover_url : null
        )
    }

    //My content rating data
    const lastFetchedRatedContent = useSelector(
        state => 
        (
            (contentType === "album" || contentType === "track" || contentType === "artist") &&
            state[`${contentType}s`][`${contentType}s`][contentId] && 
            state[`${contentType}s`][`${contentType}s`][contentId].myRatings &&
            state[`${contentType}s`][`${contentType}s`][contentId].myRatings.data &&
            state[`${contentType}s`][`${contentType}s`][contentId].myRatings.data[0]
        ) ? state[`${contentType}s`][`${contentType}s`][contentId].myRatings.data[0]
            :   null
    )

    //Get my content rating
    useEffect(() => {
        dispatch(
            getMyContentRating(contentId, contentType, checkDeezerId(content))
        )
    }, [])

    //Update rating from myContentRating
    useEffect(() => {
        if (initRating === 0) {
            if(lastFetchedRatedContent && (lastFetchedRatedContent.content.id === contentId || lastFetchedRatedContent.content.deezer_id === contentId) && lastFetchedRatedContent.content.type === contentType) {
                setRating(lastFetchedRatedContent.rating)
            } else if (!lastFetchedRatedContent) {
                setRating(0)
            }
        }
    }, [ lastFetchedRatedContent ])

    //Is review
    const isReview = (title.trim() !== "" || description.trim() !== "")

    //Update pinned from isReview
    useEffect(() => {
        if(!isReview) {
            setPinned(false)
        }
    }, [ isReview ])

    //Fetch data and close when done
    function dispatchAndNavigate() {
        if (draftUid) {
            dispatch(
                updateDraft(
                    draftUid,
                    { is_created: true }
                )
            )
        }

        dispatch(
            getMyContentRating(contentId, contentType, checkDeezerId(content))
        )
        
        dispatch( 
            getUsersRatings({
                username: myUsername,
                uid: myUid, 
                type: types.GET_USERS_RECENT_ACTIVITY,
                order_by: "-listened_at", 
            }) 
        )
        
        if(pinned && isReview) {
            dispatch( 
                getUsersReviews({
                    username: myUsername,
                    uid: myUid,
                    type: types.GET_USERS_PINNED_REVIEWS,
                    pinned: true
                }) 
            )
        }

        if (isReview) {
            openPopup(null)
            setTimeout(() => {
                openPopup("ShareReview", { 
                    review: lastCreatedReview, 
                    //popupTitle: `Thanks for ${isReview ? "reviewing" : rating ? "rating" : "logging"} ${contentTitle}!` 
                })
            }, 120)
        } else {
            openPopup(null)
            openAlert({
                p: `Thanks for ${isReview ? "reviewing" : rating ? "rating" : "logging"} ${contentTitle}!`,
                a: `View ${isReview ? "review" : "rating"}.`,
                href: isReview ? lastCreatedReview.url_slug : `/rating/${lastCreatedRating.uid}`,
                target: newTab ? "_blank" : null,
                style: "green",
                timer: 6000,
            })
        }
    }
    
    //Alert error
    function alertError(type=null) {
        openAlert({
            p: type === "rating" ? !rating ? `Could not log ${formattedContentType}. Error: ${ratingErrorMessage}` : `Could not create rating. Error: ${ratingErrorMessage}` : type === "review" ? `Could not create review. Error: ${reviewErrorMessage}` : `Could not rate this ${contentType}.`,
            style: "red",
            timer: 6000,
        })
    }

    //When content created, rate content
    const firstUpdateForRating = useRef(true)
    useEffect(() => {
        if(firstUpdateForRating.current) {
            firstUpdateForRating.current = false;
            return;
        }

        if(createArtistStatus === status.SUCCESS && (lastCreatedArtist.id === contentId || lastCreatedArtist.deezer_id === contentId)) {
            dispatch( 
                createRating(
                    lastCreatedArtist.id,
                    'artist',
                    rating === 0 ? null : rating,
                    isPrivate,
                    date ? date.toISOString() : new Date().toISOString(),
                    firstListen,
                    Boolean(date),
                    cacheRating
                ) 
            )
        } else if(createArtistStatus === status.ERROR) {
            alertError()
        }
        if(createAlbumStatus === status.SUCCESS && (lastCreatedAlbum.id === contentId || lastCreatedAlbum.deezer_id === contentId)) {
            dispatch( 
                createRating(
                    lastCreatedAlbum.id,
                    'album',
                    rating === 0 ? null : rating,
                    isPrivate,
                    date ? date : new Date(),
                    firstListen,
                    Boolean(date),
                    cacheRating
                ) 
            )
        } else if(createAlbumStatus === status.ERROR) {
            alertError()
        }
        if(createTrackStatus === status.SUCCESS && (lastCreatedTrack.id === contentId || lastCreatedTrack.deezer_id === contentId)) {
            dispatch( 
                createRating(
                    lastCreatedTrack.id,
                    'track',
                    rating === 0 ? null : rating,
                    isPrivate,
                    date ? date.toISOString() : new Date().toISOString(),
                    firstListen,
                    Boolean(date),
                    cacheRating
                ) 
            )
        } else if(createTrackStatus === status.ERROR) {
            alertError()
        }
        
    }, [ createArtistStatus, createAlbumStatus, createTrackStatus ])

    //When rating created, review content
    const firstUpdateForReview = useRef(true)
    useEffect(() => {
        if(firstUpdateForReview.current) {
            firstUpdateForReview.current = false;
            return;
        }

        if(createRatingStatus === status.SUCCESS && (lastCreatedRating.content.id === contentId || lastCreatedRating.content.deezer_id === contentId)) {
            if(isReview) {
                dispatch(
                    createReview(
                        title,
                        description,
                        lastCreatedRating.uid,
                        pinned
                    )
                )

            } else {
                dispatchAndNavigate()
            }

        } else if(createRatingStatus === status.ERROR) {
            alertError("rating")
        }
        
    }, [ createRatingStatus ])

    //When review created, close
    const firstUpdateForClose = useRef(true)
    useEffect(() => {
        if(firstUpdateForClose.current) {
            firstUpdateForClose.current = false;
            return;
        }

        if(createReviewStatus === status.SUCCESS && lastCreatedReview && lastCreatedReview.rating && (lastCreatedReview.rating.content.id === contentId || lastCreatedReview.rating.content.deezer_id === contentId)) {
             dispatchAndNavigate()
        } else if(createReviewStatus === status.ERROR) {
            alertError("review")
        }

    }, [ createReviewStatus ])

    console.log({date})

    return (
        <div className={`flex-row align-top`}>
            {!isSmallScreen &&
                <a target="_blank" rel="noopener noreferrer" href={isSuggested ? null : href}>
                    {!(contentType === "artist" || contentType === "suggested_artist") &&
                        <AlbumCover clickable={!isSuggested} size={150} albumCover={contentImage} altText={contentTitle} />
                    }

                    {(contentType === "artist" || contentType === "suggested_artist") &&
                        <ProfilePicture clickable={!isSuggested} size={150} profilePicture={contentImage} altText={contentTitle} />
                    }
                </a>
            }

            <div className={`${!isSmallScreen && "margin-left-24"} col-1`}>
                <div className="flex-row">
                    {isSmallScreen &&
                        <a className="margin-right-24" target="_blank" rel="noopener noreferrer" href={isSuggested ? null : href}>
                            {!(contentType === "artist" || contentType === "suggested_artist") &&
                                <AlbumCover clickable={!isSuggested} size={90} albumCover={contentImage} altText={contentTitle} />
                            }

                            {(contentType === "artist" || contentType === "suggested_artist") &&
                                <ProfilePicture clickable={!isSuggested} size={90} profilePicture={contentImage} altText={contentTitle} />
                            }
                        </a>
                    }

                    <div>
                        <div className="flex-row">
                            <div className="grid">
                                <a target="_blank" href={isSuggested ? null : href} className='one-line'>
                                    <h4 className={`${isSuggested ? "black" : styles.title} text-lg one-line`}>{contentTitle}</h4>
                                </a>
                            </div>

                            {content.explicit_lyrics &&
                                <Icon icon="explicit" className="margin-left-8" style={{ marginTop: 2 }} color="var(--grey)" size={14} />
                            }
                        </div>

                        <p style={{ fontSize: 16 }} className="margin-top-4 highDarkGrey">
                            {content.artist &&
                                <a className={styles.artistLink} target="_blank" href={checkDeezerId(content.artist) ? `/artist/${content.artist.id}?dz` : content.artist.url_slug}>
                                    {content.artist.name}
                                </a>
                            }

                            {content.suggested_artist && content.suggested_artist.name}

                            {(content.artist || content.suggested_artist) && " • "}
                            
                            {content.record_type ? displayRecordType(content.record_type) : formattedContentType}
                        </p>
                    </div>
                </div>

                <div className={`${isMobile ? "flex-column" : "flex-row align-top"} margin-top-24`}>

                    <div className="col-1">
                        <div style={{ display: 'inline-flex', flexDirection: 'column', }}>
                            <div className="flex-row justify-between">
                                <h5 className="black">Rating</h5>

                                {rating !== 0 &&
                                    <p className="grey">{rating/2} of 5</p>
                                }
                            </div>
                            
                            <div className={`${styles.ratingContainer} flex-row`}>
                                <ClickableStars setRating={(rating) => setRating(rating)} rating={rating} iconSize={32} />

                                <div onClick={() => setRating(0)} className={`${styles.clearRatingBtn} ${rating ? styles.active : styles.disabled}`}>
                                    <Icon icon='close' size={20} className={styles.clearRatingIcon} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`col-1 ${isMobile && "margin-top-16"}`}>
                        <h5 className="black">Pinned</h5>
                        
                        <div style={{ height: 30, marginTop: 7 }} className='flex-row'>
                            <Checkbox
                            checked={pinned}
                            onChange={setPinned}
                            text={isReview ? "Pin to my profile" : "Review to pin"}
                            allowed={isReview}
                            lighterStyle
                            />
                        </div>
                    </div>
                    
                    <div className={`col-1 ${isMobile && "margin-top-16"}`}>
                        <h5 className="black">Re-listen</h5>
                        
                        <div style={{ height: 30, marginTop: 7 }} className='flex-row'>
                            <Checkbox
                            checked={!firstListen}
                            onChange={() => setFirstListen(!firstListen)}
                            text={"Mark as re-listen"}
                            allowed={true}
                            lighterStyle
                            />
                        </div>
                    </div>

                    <div className={`col-1 ${isMobile && "margin-top-16"}`}>
                        <h5 className="black">Listened at</h5>
                        
                        <div style={{ height: 30, marginTop: 7 }} className='flex-row'>
                            <DateInput
                            value={date}
                            onChange={setDate}
                            />
                        </div>
                    </div>

                </div>

                <h5 className="black margin-top-24">Review</h5>

                <TextInput 
                text={title}
                lighterStyle
                onTextChange={(t) => setTitle(t)}
                maxLength={255} 
                className="margin-top-8" 
                placeholder="Review title"
                />

                <TextInput
                text={description}
                lighterStyle
                onTextChange={(t) => setDescription(t)}
                maxLength={is_pro ? 524288 : 5000} 
                showMaxLength={!is_pro}
                className="margin-top-16" 
                textarea 
                textareaHeight={160} 
                showUpgradePro
                placeholder={"Add a review..."}
                renderLeftText={
                    <h6 
                    onClick={() => openPopup("EditText", { 
                        text: description,
                        onRequestClose: (t) => {
                            openPopup("CreateReview", {
                                initDescription: t,
                                initTitle: title,
                                content: content,
                                initIsPrivate: isPrivate,
                                initPinned: pinned,
                                initFirstListen: firstListen,
                                initDate: date,
                                initRating: rating
                            })
                        },
                        maxLength: is_pro ? 524288 : 5000,
                        placeholder: "Write a review...",
                        showMaxLength: !is_pro
                    })} 
                    className={styles.maximize}
                    >
                        Maximize
                    </h6>
                }
                />

                <Button
                className="margin-top-16"
                text={(lastFetchedRatedContent && rating && !isReview) ? "Rate Again" : `${!rating ? "Log" : isReview ? "Review" : "Rate"} ${content.record_type ? displayRecordType(content.record_type) : formattedContentType}`}
                disabled={
                    createArtistStatus === status.BEGIN ||
                    createAlbumStatus === status.BEGIN ||
                    createTrackStatus === status.BEGIN ||
                    createRatingStatus === status.BEGIN ||
                    createReviewStatus === status.BEGIN
                }
                loading={
                    createArtistStatus === status.BEGIN ||
                    createAlbumStatus === status.BEGIN ||
                    createTrackStatus === status.BEGIN ||
                    createRatingStatus === status.BEGIN ||
                    createReviewStatus === status.BEGIN
                }
                onClicked={() => {
                    if (isReview) {
                        track(
                            "Created review", 
                            {
                                "title": title,
                                "description": description,
                                "rating": rating,
                                "content_type": contentType,
                                "content_title": contentTitle,
                                "content_id": contentId,
                                "private": isPrivate,
                                "listened_at": date,
                                "pinned": pinned,
                                "first_listen": firstListen,
                            }
                        );
                    } else if (rating) {
                        track(
                            "Created rating", 
                            {
                                "rating": rating,
                                "content_type": contentType,
                                "content_title": contentTitle,
                                "content_id": contentId,
                                "private": isPrivate,
                                "listened_at": date,
                                "first_listen": firstListen,
                            }
                        );
                    } else {
                        track(
                            "Logged content", 
                            {
                                "content_type": contentType,
                                "content_title": contentTitle,
                                "content_id": contentId,
                                "private": isPrivate,
                                "listened_at": date,
                                "first_listen": firstListen,
                            }
                        );
                    }

                    if(contentType === "artist") {
                        dispatch( createArtist(contentId, checkDeezerId(content)) )
                    } else if(contentType === "album") {
                        dispatch( createAlbum(contentId, checkDeezerId(content)) )
                    } else if(contentType === "track") {
                        dispatch( createTrack(contentId, checkDeezerId(content)) )
                    } else if(contentType === "suggested_artist" || contentType === "suggested_album" || contentType === "suggested_track") {
                        dispatch( 
                            createRating(
                                content.id,
                                contentType,
                                rating === 0 ? null : rating,
                                isPrivate,
                                date ? date.toISOString() : new Date().toISOString(),
                                firstListen,
                                Boolean(date),
                                cacheRating
                            ) 
                        )
                    } else {
                        openAlert({
                            p: "Could not create review, please contact us if the error remains.",
                            style: "red",
                            timer: 6000,
                        })
                    }

                }}
                />
            </div>
        </div>
    )
}
