import styles from './proad.module.css'

import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function ProAd({ className, addMargin=true }) {

    return (
        <Link to={`/pro?ref=ProAdHome`}>
            <div className={`section-block ${addMargin ? "margin-bottom-32" : ""} ${className}`}>
                <div className='flex-row background-clickable-bright padding-around-24'>
                    <div className='flex-row'>
                        <div className={styles.pro} />
                    </div>

                    <p className='black margin-left-16'>
                        {"Unlock animated GIF avatars, custom colors and banners, sticker reactions, remove all ads, and much more!"}
                    </p>
                </div>
            </div>
        </Link>
    )
}
