import React from "react"
import ContentLoader from "react-content-loader"

export default function ListItemLoader(props) {
    return(
        <ContentLoader 
        speed={2}
        width={320}
        height={270}
        backgroundColor="var(--lightGrey)"
        foregroundColor="var(--darkLightGrey)"
        {...props}
        >
            <rect x="16" y="16" rx="4" ry="4" width="88" height="88" />

            <rect x="16" y="124" rx="4" ry="4" width="160" height="10" />

            <rect x="16" y="158" rx="4" ry="4" width="304" height="6" />
            <rect x="16" y="178" rx="4" ry="4" width="304" height="6" />
            <rect x="16" y="198" rx="4" ry="4" width="150" height="6" />

            <circle cx="32" cy="238" r="16" /> 

            <rect x="64" y="233" rx="4" ry="4" width="75" height="10" />
        </ContentLoader>
    )
}