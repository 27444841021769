import React, { useEffect, useMemo } from 'react';

export default function Adsense({
    className='',
    style={ display: 'block' },
    client,
    slot,
    layout='',
    layoutKey='',
    format='auto',
    responsive='false',
    pageLevelAds=false,
    adTest,
    children,
    onAdLoaded=()=>{},
    onAdFailed=()=>{},
    ...rest
}) {

    // Generate a random id for the ad
    const randomId = useMemo(() => Math.random().toString(36).substring(10), []);

    // Load the adsense script
    useEffect(() => {
        const p = {};
        if (pageLevelAds) {
            p.google_ad_client = client;
            p.enable_page_level_ads = true;
        }

        try {
            if (typeof window === 'object') {
                ((window).adsbygoogle = (window).adsbygoogle || []).push(p);
            }
        } catch {
            // Pass
        }
    }, []);

    //Subscribe to the "data-ad-status" attribute of the ad, and call the onAdLoaded function when the ad is loaded
    useEffect(() => {
        const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-ad-status') {
                    if (mutation.target.getAttribute('data-ad-status') === 'filled') {
                        onAdLoaded();
                    }
                    if (mutation.target.getAttribute('data-ad-status') === 'unfilled') {
                        onAdFailed();
                    }
                }
            }
        });
        observer.observe(document.getElementById(`adsense-${randomId}`), { attributes: true });
        return () => observer
    }, []);
    
    return (
        <ins
        key={`adsense-${randomId}`}
        id={`adsense-${randomId}`}
        className={`adsbygoogle ${className}`}
        style={style}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-layout={layout}
        data-ad-layout-key={layoutKey}
        data-ad-format={format}
        data-full-width-responsive={responsive}
        data-adtest={adTest}
        {...rest}
        >
            {children}
        </ins>
    );
}