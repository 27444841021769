import { types, status } from '../constants/action-types'
import { post, put, get, patch, patchRaw, delete_ } from '../utils/fetch'
import { postNormal, patchNormal, deleteNormal } from '../utils/fetch-normal'
import { url } from '../config'
import { checkDeezerId } from '../utils/deezer-utils';

export const fetchList = (uid, limit_content=24, offset_content=0, skipTheContent) => 
    get(types.FETCH_LIST, `${url}/v2/lists/${uid}/?limit_content=${limit_content}&offset_content=${offset_content}`, { skipTheContent });

export const fetchListFromSlug = (urlSlug, limit_content=24, offset_content=0, skipTheContent) => 
    get(types.FETCH_LIST, `${url}/v2/lists/from_url_slug/?url_slug=${urlSlug}&limit_content=${limit_content}&offset_content=${offset_content}`, { urlSlug, skipTheContent });

export const fetchListContent = ({
    uid,
    limit=24,
    offset=0,
    order_by="priority",
    content_type="",
    genres__id="",
    styles__id="",
    release_date__year="",
    release_date__lte="",
    release_date__gte="",
    record_type="",
}) => 
    get(types.FETCH_LIST_CONTENT, `${url}/v2/list_connections/?list=${uid}&limit=${limit}&offset=${offset}&order_by=${order_by}&content_type=${content_type}&genres__id=${genres__id}&styles__id=${styles__id}&release_date__year=${release_date__year}&release_date__lte=${release_date__lte}&release_date__gte=${release_date__gte}&record_type=${record_type}`, { uid, reset: true });
    

export const updateList = (uid, body) => 
    patch(types.UPDATE_LIST, `${url}/v2/lists/${uid}/`, body, { uid, reset: true });

export const deleteList = (uid) => 
    delete_(types.DELETE_LIST, `${url}/v2/lists/${uid}/`, { uid, reset: true });

export const createList = (title, description, pinned=false, numbered=false, isPrivate=false, bannerUid=null, contents) => (dispatch, getState) => {
    dispatch({ 
        type: types.CREATE_LIST, 
        meta: { 
            status: status.BEGIN, 
            params: {  } } 
        }
    );

    postNormal(`${url}/v2/lists/`, {
        title,
        description,
        pinned,
        numbered,
        private: isPrivate,
        background_upload: bannerUid,
    })
        .then((res) => {
            Promise.all(
                contents.map((content, i) => (
                    postNormal(`${url}/v2/${checkDeezerId(content) ? "dz_" : ""}${content.type}s/`, { id: content.id })
                    .then((validatedContent) => (
                        postNormal(`${url}/v2/list_connections/`, {
                            content_id: validatedContent.id,
                            content_type: validatedContent.type,
                            priority: i,
                            list: res.uid
                        })
                    ))
                ))
            )
            .then((results) => {
                //Send SUCCESS to reducer
                dispatch({ type: types.CREATE_LIST, meta: { status: status.SUCCESS }, payload: res });

            })
            .catch((err) => {
                //Delete the list
                //Send ERROR to reducer
                dispatch(deleteList(res.uid))
                dispatch({ type: types.CREATE_LIST, meta: { status: status.ERROR }, payload: err });

            });
        })
        .catch((err) => {
            //Send ERROR to reducer
            dispatch({ type: types.CREATE_LIST, meta: { status: status.ERROR }, payload: err });

        });
};

export const bulkUpdateList = (uid, body, createContents, deleteContents, changedPriorities) => (dispatch, getState) => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch({ 
        type: types.UPDATE_LIST, 
        meta: { status: status.BEGIN, params: { uid } } }
    );

    function saveList() {
        patchNormal(`${url}/v2/lists/${uid}/`, body)
        .then((res) => {
            dispatch({ 
                type: types.UPDATE_LIST, 
                payload: res, 
                meta: { status: status.SUCCESS, params: { uid } } 
            })
        })
        .catch((err) => {
            dispatch({ 
                type: types.UPDATE_LIST, 
                payload: err, 
                meta: { status: status.ERROR, params: { uid } } 
            })
        })
    }

    function updatePriorities() {
        if (changedPriorities.length > 0) {
            postNormal(`${url}/v2/list_connections/update_priorities/`, {
                list_connections: changedPriorities.map((item) => ({
                    priority: item.number,
                    uid: item.list_connection_uid,
                }))
            }, 60000, signal)
            .then((res) => {
                saveList()
            })
            .catch((err) => {
                dispatch({ 
                    type: types.UPDATE_LIST, 
                    payload: err, 
                    meta: { status: status.ERROR, params: { uid } } 
                })
            })
        } else {
            saveList()
        }
    }

    function deleteContent() {
        if (deleteContents.length > 0) {
            Promise.all(
                deleteContents.map((content) => {
                    return deleteNormal(`${url}/v2/list_connections/${content.list_connection_uid}/`, 60000, signal)
                })
            )
            .then((res) => {
                updatePriorities()
            })
            .catch((err) => {
                dispatch({ 
                    type: types.UPDATE_LIST, 
                    payload: err, 
                    meta: { status: status.ERROR, params: { uid } } 
                })
            });
        } else {
            updatePriorities()
        }
    }

    function createContent() {
        if (createContents.length > 0) {
            Promise.all(
                createContents.map((content, i) => (
                    postNormal(`${url}/v2/${checkDeezerId(content) ? "dz_" : ""}${content.type}s/`, { id: content.id })
                    .then((validatedContent) => (
                        postNormal(`${url}/v2/list_connections/`, {
                            content_id: validatedContent.id,
                            content_type: validatedContent.type,
                            priority: content.number,
                            list: uid
                        })
                    ))
                ))
            )
            .then((res) => {
                deleteContent()
            })
            .catch((err) => {
                dispatch({ 
                    type: types.UPDATE_LIST, 
                    payload: err, 
                    meta: { status: status.ERROR, params: { uid } } 
                })
            });
        } else {
            deleteContent()
        }
    }

    createContent()

}

//Create MULTIPLE list connections from ONE CONTENT
export const createListConnectionsForContent = (content, lists) => (dispatch, getState) => {
    dispatch({ type: types.CREATE_LIST_CONNECTIONS_FOR_CONTENT, meta: { status: status.BEGIN } });

    postNormal(`${url}/v2/${checkDeezerId(content) ? "dz_" : ""}${content.type}s/`, {
        id: content.id
    })
    .then((res) => (
        Promise.all(
            lists.map((list) => {
                return postNormal(`${url}/v2/list_connections/`, {
                    content_id: res.id,
                    content_type: content.type,
                    priority: list.list_connections.count,
                    list: list.uid
                })
            })
        )
        .then((res) => {
            dispatch({ type: types.CREATE_LIST_CONNECTIONS_FOR_CONTENT, meta: { status: status.SUCCESS }, payload: res });
            
        })
    ))
    .catch((err) => {
        dispatch({ type: types.CREATE_LIST_CONNECTIONS_FOR_CONTENT, meta: { status: status.ERROR }, payload: err });

    });

}

//Create MULTIPLE list connections from ONE LIST
export const createListConnectionsForList = (contents, listUid) => (dispatch, getState) => {
    const params = {
        listUid,
        contents,
    }

    dispatch({ type: types.CREATE_LIST_CONNECTIONS_FOR_LIST, meta: { status: status.BEGIN, params } });
    
    Promise.all(
        contents.map((content) => {
            return postNormal(`${url}/v2/${checkDeezerId(content) ? "dz_" : ""}${content.type}s/`, {
                id: content.id
            })
            .then((res) => {
                return postNormal(`${url}/v2/list_connections/`, {
                    content_id: res.id,
                    content_type: content.type,
                    priority: content.number,
                    list: listUid
                })
            })
        })
    )
    .then((res) => {
        dispatch({ type: types.CREATE_LIST_CONNECTIONS_FOR_LIST, meta: { status: status.SUCCESS, params }, payload: res });
        
    })
    .catch((err) => {
        dispatch({ type: types.CREATE_LIST_CONNECTIONS_FOR_LIST, meta: { status: status.ERROR, params }, payload: err });

    });

}


//listConnections are objects with { priority, uid }
export const updateListConnections = (listConnections) =>
    post(types.UPDATE_LIST_CONNECTIONS, `${url}/v2/list_connections/update_priorities/`, { list_connections: listConnections })


//Delete list connection
export const deleteListConnections = (contents) => (dispatch, getState) => {
    const params = {
        contents
    }

    dispatch({ type: types.DELETE_LIST_CONNECTIONS, meta: { status: status.BEGIN, params } });

    Promise.all(
        contents.map((content, i) => {
            return deleteNormal(`${url}/v2/list_connections/${content.list_connection_uid}/`);
        })
    )
    .then((results) => {
        dispatch({ type: types.DELETE_LIST_CONNECTIONS, meta: { status: status.SUCCESS, params } });

    })
    .catch((err) => {
        console.warn("error here!!!!", err)
        dispatch({ type: types.DELETE_LIST_CONNECTIONS, meta: { status: status.ERROR, params }, payload: err });

    });
}


//Get all lists
export const getAllLists = ({
    order_by="-created_at",
    pinned="",
    title="",
    has_reports="",
    most_liked=false,
    weekly=false,
    offset=0
}) => 
    get(types.GET_ALL_LISTS, `${url}/v2/${weekly? "most_liked_lists/weekly" : most_liked ? "most_liked_lists" : "lists"}/?limit=16&offset=${offset}&limit_content=10&order_by=${order_by}&pinned=${pinned}&title=${title}&has_reports=${has_reports}&speedup=true`, { reset: true, speedup: true, limit: 16 });

//Translations
export const translateList = (uid, text, from, to) =>
    post(types.TRANSLATE_LIST, `${url}/v2/translate/`, { text, from, to }, { uid })

export const detectListLanguage = (uid) =>
    get(types.DETECT_LIST_LANGUAGE, `${url}/v2/lists/${uid}/lang_detect/`, { uid })

export const getRandomListItem = (uid) => 
    get(types.GET_RANDOM_LIST_ITEM, `${url}/v2/lists/${uid}/get_random/`, { uid });
