import styles from './playtracksbutton.module.css'

import React from 'react'

import playingGifAnimation from '../../assets/animations/playing-gif-white-resize.gif'
import playingDarkGifAnimation from '../../assets/animations/playing-gif-dark-resize.gif'

import { useTheme } from '../../hooks/theme'
import { useAudio } from '../../hooks/audio/audio'
 
import Icon from '../Icon'
import Tooltip from '../Tooltip'

function PlayTracksButton({ playTracks, trackId, deezerId }) {
    //General
    const { mode } = useTheme()
    const { playing, activeTrack, pause, resume } = useAudio()

    console.info({ playing, activeTrack })

    return (
        <div>
            <div 
            id={`play-track-${trackId}`}
            className='cursor link-clickable margin-right-8 margin-left-8 flex-center' 
            style={{ height: 60 }}
            onClick={() => {
                if ((activeTrack && activeTrack.id === trackId) || (activeTrack && activeTrack.id === deezerId)) {
                    if (playing) {
                        pause()
                    } else {
                        resume()
                    }
                    return
                }
                
                playTracks(trackId)
            }}
            >
                {playing && activeTrack && (activeTrack.id === trackId || activeTrack.id === deezerId) &&
                    <div style={{ width: 22, height: 22 }} className='flex-center'>
                        <img
                        className={styles.playingGif}
                        src={mode === "light" ? playingDarkGifAnimation : playingGifAnimation}
                        />
                    </div>
                }
                
                {!(playing && activeTrack && (activeTrack.id === trackId || activeTrack.id === deezerId)) &&
                    <Icon icon="play-circle" size={22} color={"var(--black)"} />
                }
            </div>

            <Tooltip id={`play-track-${trackId}`} text="Preview track" offset={-6} />
        </div>
    )
}

export default React.memo(PlayTracksButton)
