import './assets/css/normalize.css';
import './assets/css/fonts.css';
import './assets/css/style.css';
import './assets/css/date-picker-overrides.css';

import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { store, persistor } from './store';

import Segment from './shared/Segment';
import Navigator from './shared/Navigator';
import CacheData from './shared/CacheData';
import HealthCheck from './shared/HealthCheck';
import ScrollToTop from './shared/ScrollToTop';
import ErrorHandler from './shared/ErrorHandler';
import FixedRightAd from './shared/FixedRightAd';
import FixedBottomAd from './shared/FixedBottomAd';
import GeneralFetching from './shared/GeneralFetching';
import StatusAlertHandler from './shared/StatusAlertHandler';

import ThemeProvider from './hooks/theme';
import PopupProvider from './hooks/popup/popup';
import AlertProvider from './hooks/alert/alert';
import AudioProvider from './hooks/audio/audio';
import ScrollbarProvider from './hooks/scrollbar';
import PlayVideoProvider from './hooks/play-video/play-video';

if (process.env.REACT_APP_STAGE === 'production') {
	console.log = console.info = console.error = console.warn = console.debug = console.trace = () => {};
}

//console.log = 
//console.info = 
//console.error = 
//console.warn = 
//console.debug = 
//console.trace = () => {};

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<GoogleOAuthProvider clientId="312560736458-88o2l36f9sg2bhd4udnnt0c75s4sqtmj.apps.googleusercontent.com">
					<ThemeProvider>
						<ScrollbarProvider>
							<ErrorHandler>
								<PlayVideoProvider>
									<Router>
										<CacheData>
											<ScrollToTop>
												<AlertProvider>
													<PopupProvider>
														<AudioProvider>
															<HealthCheck>	
																<StatusAlertHandler>
																	<Segment>
																		<GeneralFetching>
																			<Navigator />

																			{/* <FixedBottomAd /> */}

																			{/* <FixedRightAd /> */}
																		</GeneralFetching>
																	</Segment>
																</StatusAlertHandler>
															</HealthCheck>
														</AudioProvider>
													</PopupProvider>
												</AlertProvider>
											</ScrollToTop>
										</CacheData>
									</Router>
								</PlayVideoProvider>
							</ErrorHandler>
						</ScrollbarProvider>
					</ThemeProvider>
				</GoogleOAuthProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
