import styles from './list.module.css'

import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch, connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'

import { ogImageUrl, twitterImageUrl } from '../../config'
import { themeEnums } from '../../constants/enums'
import { status, types } from '../../constants/action-types'

import { getUsersLists } from '../../actions/users-actions'
import { getComments } from '../../actions/comments-actions'
import { deleteLike, likeContent } from '../../actions/likes-actions'
import { fetchList, fetchListFromSlug, fetchListContent, translateList, detectListLanguage, getRandomListItem } from '../../actions/lists-actions'

import { createBreadcrumbSchema, createListDiscussionForumSchema, createListSchema } from '../../data/structured-data'

import { useTheme } from '../../hooks/theme'
import { useAlert } from '../../hooks/alert/alert'
import { usePopup } from '../../hooks/popup/popup'
import { useStatusHandler } from '../../hooks/status-handler'

import ContentListLoader from '../../loaders/ContentListLoader'

import DefaultHelmet from '../../shared/DefaultHelmet'
import StructuredData from '../../shared/StructuredData'
import ColorOverwrite from '../../shared/ColorOverwrite'

import { mapDecades } from '../../utils/filter-utils'
import interpolation from '../../utils/interpolation'
import { addEndingS, checkNull, checkNullNumber } from '../../utils/format-text'

import Icon from '../../components/Icon'
import Heading from '../../components/Heading'
import Button from '../../components/Button'
import Tooltip from '../../components/Tooltip'
import ListItem from '../../components/ListItem'
import SignUpAd from '../../components/SignUpAd'
import ReadMoreText from './components/ReadMoreText'
import MiniFooter from '../../components/MiniFooter'
import OptionsMenu from '../../components/OptionsMenu'
import AdComponent from '../../components/AdComponent'
import UserRedirect from '../../components/UserRedirect'
import ShareElement from '../../components/ShareElement'
import PageControls from '../../components/PageControls'
import ParseUserText from '../../components/ParseUserText'
import BigBackendItem from '../../components/BigBackendItem'
import FullBackground from '../../components/FullBackground'
import ReportDropdown from '../../components/ReportDropdown'
import StickerSection from '../../components/StickerSection'
import StickyScrolling from '../../components/StickyScrolling'
import SmallBackendItem from '../../components/SmallBackendItem'

import ListFilterBar from './components/ListFilterBar'
import ListCommentSection from './components/ListCommentSection'

import NotFound from '../NotFound'
import { useTrack } from '../../hooks/tracking'

export default function List(props) {
    //General
    const { openPopup } = usePopup()
    const { openAlert } = useAlert()
    const { colors } = useTheme()
    const { uid, usernameSlug, titleSlug } = useParams()
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const track = useTrack()

    //Params
    const fromRedirect = new URLSearchParams(location.search).get("rd") !== null

    //Responsive
    const isMidScreen = useMediaQuery({ maxWidth: 1100 })
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isTablet = useMediaQuery({ maxWidth: 768 })
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Variable
    const urlSlug = useMemo(() => 
        !uid && titleSlug ?
            `/${usernameSlug}/list/${titleSlug}/`
        : null
    , [ uid, titleSlug, usernameSlug ])

    //Prefetched review uid
    const urlSlugConnectionUid = useSelector(state => !uid && titleSlug ? state.local.listUrlSlugResult[urlSlug] : null)

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const isModerator = useSelector(state => state.me.is_moderator)
    const myUid = useSelector(state => state.auth.uid)

    //Params
    const shareParam = new URLSearchParams(location.search).get("share")

    //Query params
    const page = new URLSearchParams(location.search).get("page") ? Number(new URLSearchParams(location.search).get("page")) : 1
    const view = new URLSearchParams(location.search).get("view") ? new URLSearchParams(location.search).get("view") : null
    //Normal filters
    const orderBy = new URLSearchParams(location.search).get("order_by") ? new URLSearchParams(location.search).get("order_by") : null
    const contentType = new URLSearchParams(location.search).get("content_type") ? new URLSearchParams(location.search).get("content_type") : ""
    //PRO filters
    const genre = new URLSearchParams(location.search).get("genre") ? new URLSearchParams(location.search).get("genre") : ""
    const release_year = new URLSearchParams(location.search).get("release_year") ? new URLSearchParams(location.search).get("release_year") : ""

    const hasFilters = (genre !== "" || release_year !== "" || contentType !== "" || orderBy !== null)

    //Support to update the list uid if using slugs
    const [listUid, setListUid] = useState(urlSlugConnectionUid || uid)

    //likeObj
    const deleteLikeContentUid = useSelector(state => state.likes.deleteLikeContentUid)
    const deleteLikeStatus = useSelector(state => state.likes.deleteLikeStatus)
    const myLikes = useSelector(state => state.me.likes);
    const cachedLikes = useSelector(state => state.me.cachedLikes);
    const likes = [ ...myLikes, ...cachedLikes ];
    const likeIndex = likes.findIndex(like => like.content_ct === "list" && like.content_uid === listUid);
    const likeObj = likeIndex !== -1 ? likes[likeIndex] : null;

    //Data
    const list = useSelector(state => state.lists.lists[listUid])
    const comments = useSelector(state => state.comments.lists[listUid])
    const fetchListStatus = useSelector(state => state.lists.fetchListStatus)
    const lastFetchedListUid = useSelector(state => state.lists.lastFetchedListUid)
    const errorMessage = useSelector(state => state.lists.errorMessage)
    
    const getRandomListItemUid = useSelector(state => state.lists.getRandomListItemUid)
    const getRandomListItemResult = useSelector(state => state.lists.getRandomListItemResult)
    const getRandomListItemStatus = useSelector(state => state.lists.getRandomListItemStatus)

    //blockedBy
    const blockedByObj = useSelector(state => 
        state.me.blockedBy && state.me.blockedBy.find(block => block.creator === list?.creator?.uid)
    )

    //Data when list is fetched
    const userLists = useSelector(state => 
        list && list.creator && state.users.users[list.creator.username] && state.users.users[list.creator.username].lists ? 
            state.users.users[list.creator.username].lists
        : null
    )
    
    //Translation data
    const translatedList = useSelector(state => state.lists.translatedLists[listUid])
    const translateListStatus = useSelector(state => state.lists.translateListStatus)
    const primaryLanguage = useSelector(state => state.me.primary_language)
    const supportedLanguages = useSelector(state => state.auth.healthCheck.supported_languages)
    
    //Translation variables
    const listLangDisplay = (
        supportedLanguages && list && supportedLanguages.find((i) => i.code === list.detected_language) ? 
            supportedLanguages.find((i) => i.code === list.detected_language).lang 
        : 
            null
    )
    const primaryLangDisplay = (
        supportedLanguages && primaryLanguage && supportedLanguages.find((i) => i.code === primaryLanguage) ? 
            supportedLanguages.find((i) => i.code === primaryLanguage).lang 
        : 
            null
    )
    
    //Variable
    const limit = useMemo(() => (
        isMobile ? 24 : isTablet ? 24 : isSmallScreen ? 25 : isMidScreen ? 24 : 25
    ), [])

    //State
    const [showTranslation, setShowTranslation] = useState(false)
    const [initWait, setInitWait] = useState(true)

    //Refs
    const hasFetched = useRef(false)

    //Get data
    useEffect(() => {
        //Set init wait to hide error
        setInitWait(true)
        setTimeout(() => {
            setInitWait(false)
        }, 75)

        //Reset the hasFetched ref when we change the list
        hasFetched.current = false

        //Reset the state uid
        if (urlSlug) {
            if (urlSlugConnectionUid) { setListUid(urlSlugConnectionUid) } else { setListUid(null) }
        } else {
            setListUid(uid)
        }

        if (urlSlug) {
            if (!fromRedirect || !(list && list.uid)) {
                dispatch( 
                    fetchListFromSlug(urlSlug, limit, page > 1 ? (page-1)*limit : 0, hasFilters ? true : false) 
                )
            }
        } else {
            dispatch( 
                fetchList(uid, limit, page > 1 ? (page-1)*limit : 0, hasFilters ? true : false) 
            )
        }

    }, [ uid, usernameSlug, titleSlug ])

    //Potentially update the listUid if our backend return a different
    useStatusHandler({
        effectStatus: fetchListStatus,
        successCallback: () => {
            if (lastFetchedListUid && lastFetchedListUid !== listUid) {
                setListUid(lastFetchedListUid)
            }
        }
    })

    //Navigate based on random item
    useStatusHandler({
        effectStatus: getRandomListItemStatus,
        successCallback: () => {
            if (getRandomListItemUid === listUid && getRandomListItemResult) {
                if (getRandomListItemResult.content.type === "track") {
                    history.push(`${getRandomListItemResult.content.album.url_slug}?tracks=${getRandomListItemResult.content.id}`)
                } else {
                    history.push(getRandomListItemResult.content.url_slug)
                }
            }
        },
        errorCallback: () => {
            openAlert({
                p: `Could not get a random list item. Error: ${errorMessage}`,
                style: "red",
                timer: 6000
            })
        },
    })

    const firstUpdate = useRef(true)
    useEffect(() =>  {
        const width = window.innerWidth;
        const calculatedMarginHeight = list?.background?.background_large ? interpolation(0, 1200, 80, 350, width, true) : 0;
        if (listUid && hasFilters) {
            window.scrollTo(0, calculatedMarginHeight)
            dispatch(
                fetchListContent({
                    uid: listUid,
                    limit: limit,
                    offset: page > 1 ? (page-1)*limit : 0,
                    order_by: orderBy ? orderBy : "priority",
                    content_type: contentType,
                    ...(contentType === "album" || contentType == "track") && {
                        genres__id: genre,
                        release_date__year: mapDecades(release_year) ? "" : release_year,
                        release_date__lte: mapDecades(release_year) ? mapDecades(release_year).release_date__lte : "",
                        release_date__gte: mapDecades(release_year) ? mapDecades(release_year).release_date__gte : "",
                    }
                })
            )
        } else if (listUid) {
            if (firstUpdate.current) {
                firstUpdate.current = false;
                return;
            }
            window.scrollTo(0, calculatedMarginHeight)
            dispatch(
                fetchListContent({
                    uid: listUid,
                    limit: limit,
                    offset: page > 1 ? (page-1)*limit : 0,
                })
            )
        }

        if (!listUid) {
            return
        }

        firstUpdate.current = false;

    }, [ listUid, orderBy, contentType, genre, release_year, page ])

    //Get data when list is fetched
    //To only get it once, we use the ref to keep track.
    //Otherwise we would refetch all this when liking and updating the list's like_count
    useEffect(() => {
        if (list && list.creator && !hasFetched.current) {
            //Set ref
            hasFetched.current = true;

            //Open share popup if param
            if (shareParam === "true") {
                openPopup("ShareList", { list: list })
            }

            //If we have the ID, replace url with the URL slug
            if (uid && !titleSlug) {
                const locationSearchList = location.search ? location.search.replace("?", "").split("&") : []
                if (locationSearchList.indexOf("rd") === -1) {
                    locationSearchList.push("rd")
                }
                history.replace(`${list.url_slug}${locationSearchList.length > 0 ? "?" : ""}${locationSearchList.join("&")}`)
            } 
            
            //Get comments
            dispatch( 
                getComments(list.uid, 'list') 
            )

            //Get user's lists
            dispatch( 
                getUsersLists({
                    username: list.creator.username,
                    uid: list.creator.uid,
                    limit: 5
                })
            )

            //Get language detection if missing
            if(!list.detected_language) {
                dispatch(
                    detectListLanguage(list.uid)
                )
            }
        }

    }, [ list && list.uid ])

    //Scroll on param
    const scrollCommentsParam = new URLSearchParams(location.search).get("comments")
    const element = document.getElementById("comment-title")
    useEffect(() => {
        if (list && element && scrollCommentsParam === "true") {
            element.scrollIntoView({ behavior: "smooth" })
        }
    }, [ element ])

    //calculate the height of the description, which in turn desides if ReadMoreText is necessary
    const [descriptionHeight, setDescriptionHeight] = useState(null)
    useEffect(() => {
        const element = document.getElementById("description")
        if (element) {
            setDescriptionHeight(element.clientHeight)
        }
    }, [ list ])

    //Render loading or error
    if(!list || !list.uid) {
        if(!initWait && (fetchListStatus === status.SUCCESS || fetchListStatus === status.ERROR)) {
            return (
                <NotFound />
            )
        } else {
            return (
                <div className="full-height">
                    <div className="full-center">
                        <Icon className="margin-auto" icon="loading" color={colors.darkLightGrey} size={32} />
                    </div>
                </div>
            )
        }
    }

    if (blockedByObj) {
        return (
            <NotFound />
        )
    }

    //Auth
    const isMe = list.creator.uid === myUid;

    //Prepare render data
    const filteredUsersLists = userLists ?
        userLists.data
        .filter(userList => userList.uid !== list.uid)
        .filter((_, i) => i < 4)
    : []

    return (
        <div>
            {list && list.list_connections && list.list_connections.results && list.list_connections.results.length > 0 &&
                <DefaultHelmet
                title={`${list.title}, a list by ${list.creator.username}${list.url_slug.match(/\-\d+\/$/) ? ` - ${list.url_slug.match(/\-(\d+)\/$/)[1]}` : ''} - Musicboard`}
                description={
                    `A music list by ${list.creator.name !== "" ? list.creator.name : list.creator.username}, ${list.title}. ${list.list_connections.count} list entries, including ${list.list_connections.results.filter((_, i) => i < 5).map((i) => i.content.type === "artist" ? i.content.name : i.content.title).join(", ")}. ${list.description !== "" ? `About this list: ${list.description.substr(0, 150)}${list.description.length > 150 ? "..." : "."}` : ""} View the full list on Musicboard.`
                }
                canonical={`https://musicboard.app${list.url_slug}`}
                ogImage={
                    list.background ? 
                        list.background.background_original?.includes(".gif") ? list.background.background_original : list.background.background_large 
                    : list.list_connections.results[0]?.content?.type ? 
                        list.list_connections.results[0].content.type === 'artist' ? 
                            list.list_connections.results[0].content.picture_large || list.list_connections.results[0].content.picture
                        : list.list_connections.results[0].content.type === 'album' ? 
                            list.list_connections.results[0].content.cover_large || list.list_connections.results[0].content.cover
                        : 
                            list.list_connections.results[0].content.album.cover_large || list.list_connections.results[0].content.album.cover
                    : 
                        ogImageUrl
                }
                ogImageAlt={list.title}
                ogImageWidth={list.background ? 1200 : list.list_connections.results[0]?.content?.type ? 500 : 2400}
                ogImageHeight={list.background ? 600 : list.list_connections.results[0]?.content?.type ? 500 : 1440}
                twitterImage={
                    list.background ? 
                        list.background.background_original?.includes(".gif") ? list.background.background_original : list.background.background_large 
                    : list.list_connections.results[0]?.content?.type ? 
                        list.list_connections.results[0].content.type === 'artist' ? 
                            list.list_connections.results[0].content.picture_large || list.list_connections.results[0].content.picture
                        : list.list_connections.results[0].content.type === 'album' ? 
                            list.list_connections.results[0].content.cover_large || list.list_connections.results[0].content.cover
                        : 
                            list.list_connections.results[0].content.album.cover_large || list.list_connections.results[0].content.album.cover
                    : 
                        twitterImageUrl
                }
                twitterImageWidth={list.background ? 1200 : list.list_connections.results[0]?.content?.type ? 500 : 1428}
                twitterImageHeight={list.background ? 600 : list.list_connections.results[0]?.content?.type ? 500 : 824}
                deepLink={`musicboard://list/${list.uid}`}
                headingOne={list.title}
                headingTwo={`A List and Ranking by ${list.creator.username} on Musicboard`}
                />
            }

            {list && list.list_connections && list.list_connections.results && list.list_connections.results.length > 0 &&
                <StructuredData
                key={`List-Data-${list.uid}`}
                id={`List-Data-${list.uid}`}
                data={createListSchema(list)}
                />
            }

            {comments && comments.data && (comments.data.length > 0 || comments.status === status.SUCCESS) &&
                <StructuredData
                key={`List-DiscussionForum-Data-${list.uid}`}
                id={`List-DiscussionForum-Data-${list.uid}`}
                data={createListDiscussionForumSchema(list, { count: comments.count, results: comments.data })}
                />
            }

            <StructuredData
            key={`List-Breadcrumb-Data-${list.uid}`}
            id={`List-Breadcrumb-Data-${list.uid}`}
            data={createBreadcrumbSchema([
				{ "name": "Musicboard", "item": "https://musicboard.app/" },
				{ "name": "Lists", "item": "https://musicboard.app/lists/" },
				{ "name": list.creator.username, "item": `https://musicboard.app/${list.creator.username}/` },
				{ "name": list.title, "item": `https://musicboard.app${list.url_slug}` }
			])}
            />

            {list.creator.is_pro && list.creator.tier === 1 && list.creator.color_theme !== null && list.creator.color_theme !== undefined && list.creator.use_theme_on_content &&
                <ColorOverwrite
                colorTheme={themeEnums[list.creator.color_theme]}
                styleName={"list-color-overwrite"}
                setBackground
                />
            }

            <div className='list-color-overwrite'>
                <div style={{ backgroundColor: 'var(--lightestGrey)', position: 'relative', zIndex: 1 }}>
                    {list.creator.is_pro && list.creator.tier === 1 && list.background &&
                        <FullBackground
                        image={list.background.background_original?.includes(".gif") ? list.background.background_original : list.background.background_large}
                        placeholder={list.background.background_small}
                        marginHeight={350}
                        altText={"List banner"}
                        />
                    }
                    
                    <div className="page-container">
                        <div className="section-handler">
                            <div className="section-2 section-margin margin-top-64">
                                <div className="flex-row space-between">
                                    <UserRedirect className="col-1" user={list.creator} pictureSize={isTablet ? 32 : 48} fontSize={isTablet ? 15 : 16} verifiedSize={13} iconStyles={{ marginTop: 1 }} />
                                    
                                    <div className="flex-row">
                                        <div className="grid">
                                            <p className="grey one-line">{moment(list.created_at).format("DD MMM YYYY")}</p>
                                        </div>

                                        {!isTablet &&
                                            <ReportDropdown content={list} type="list" className="margin-left-8" />
                                        }
                                    </div>
                                </div>

                                {list.title !== "" &&
                                    <div className="flex-row margin-top-16">
                                        <h4 className="black">{list.title}</h4>

                                        {(list.private || list.private_locked) &&
                                            <Link to={`/list/${list.uid}/edit`}>
                                                <div 
                                                id={`list-${list.uid}-private-tip`} 
                                                style={{ height: 18 }} 
                                                className="margin-left-8 cursor"
                                                >
                                                    <Icon icon="lock" size={18} color={"var(--grey)"} />
                                                </div>
                                            </Link>
                                        }
                                    </div>
                                }

                                <Tooltip
                                id={`list-${list.uid}-private-tip`}
                                text={`This list is private`}
                                offset={12}
                                />

                                <ReadMoreText disabled={!descriptionHeight || descriptionHeight < 240} className="margin-top-8">
                                    <div id="description">
                                        <ParseUserText 
                                        className={`${showTranslation && translatedList ? "highDarkGrey" :  "textColor"} margin-right-16`}
                                        userTextClassName="blue hover-underline"
                                        style={{ fontSize: 16, lineHeight: 1.5 }}
                                        text={list.description}
                                        appendId={"List-description"}
                                        />
                                    </div>
                                </ReadMoreText>
                                
                                {list.description !== "" && list.detected_language && primaryLanguage && list.detected_language !== primaryLanguage &&
                                    <div className="flex-row margin-top-16">
                                        <div 
                                        className={`flex-row ${styles.translateBtn}`}
                                        onClick={() => {
                                            if (translatedList) {
                                                setShowTranslation(!showTranslation)
                                            }
                                            else if (!translatedList && translateListStatus !== status.BEGIN) {
                                                setShowTranslation(true)
                                                dispatch(
                                                    translateList(
                                                        list.uid, 
                                                        list.description, 
                                                        list.detected_language, 
                                                        primaryLanguage
                                                    )
                                                )
                                            }
                                        }}
                                        >
                                            <div className="flex-center margin-right-8" style={{ width: 16, height: 20 }}>
                                                {translateListStatus === status.BEGIN &&
                                                    <Icon icon="loading" className="icon-small black" />
                                                }
                                                
                                                {translateListStatus !== status.BEGIN &&
                                                    <Icon icon="language" color={"var(--blue)"} size={20} />
                                                }
                                            </div>
                                            
                                            <h6 className="blue">
                                                {showTranslation && translatedList ? `Translated From ${listLangDisplay} To ${primaryLangDisplay}` : "Translate This List"}
                                            </h6>
                                        </div>
                                    </div>
                                }
                                
                                {list.description !== "" && showTranslation && translatedList &&
                                    <ParseUserText 
                                    className="textColor margin-top-16 margin-right-16"
                                    userTextClassName="blue hover-underline"
                                    style={{ fontSize: 16, lineHeight: 1.5 }}
                                    text={translatedList}
                                    appendId={"List-translated-description"}
                                    />
                                }

                                <StickerSection 
                                listUid={list.uid}
                                appendKey={`list-${list.uid}`}
                                />

                                <ListFilterBar
                                className="margin-top-32"
                                view={view}
                                orderBy={orderBy}
                                contentType={contentType}
                                genre={genre}
                                release_year={release_year}
                                />

                                {view !== "detailed" && list.list_connections && list.list_connections.results && list.list_connections.results.length > 0 &&
                                    <div className={`grid-view ${isMobile ? "three" : isTablet ? "four" : isSmallScreen ? "five" : isMidScreen ? "four" : "five"} margin-top-16`}>
                                        {list.list_connections.results.map((item, index) => (
                                                <BigBackendItem
                                                key={`list-${list.uid}-grid-connection-${item.uid}`}
                                                content={item.content}
                                                showArtistName
                                                useNumbers={list.numbered}
                                                number={item.priority+1}
                                                />
                                        ))}
                                    </div>
                                }

                                {view !== "detailed" && list.list_connections && list.list_connections.results && list.list_connections.results.length <= 0 && list.list_connections.status === status.BEGIN &&
                                    <div className="section-block flex-center empty-text margin-top-16">
                                        <div className="padding-around-8 flex-column flex-center">
                                            <Icon icon="loading" disableLoadingAnimation />
                                        </div>
                                    </div>
                                }

                                {view !== "detailed" && list.list_connections && list.list_connections.results && list.list_connections.results.length <= 0 && list.list_connections.status !== status.BEGIN &&
                                    <div className="section-block flex-center empty-text margin-top-16">
                                        <p className="highDarkGrey text-center padding-around-8" style={{ height: 24 }}>List is empty with the current filters.</p>
                                    </div>
                                }

                                {/*view !== "detailed" && (list.list_connections && (list.list_connections.next || (list.list_connections.status === status.BEGIN && !list.list_connections.reset))) &&
                                    <Button
                                    type="secondary"
                                    style={{ height: 40 }}
                                    textStyle={{ fontSize: 14 }}
                                    className="margin-top-16"
                                    text="View more..."
                                    loading={list.list_connections.status === status.BEGIN}
                                    onClicked={() => {
                                        if (list.list_connections.next) {
                                            dispatch( paginationNext(list.list_connections.next, types.GET_MORE_LIST_CONTENT, { uid: list.uid }) )
                                        }
                                    }}
                                    />
                                */}

                                {view === "detailed" &&
                                    <div className="section-block padding-around-8 margin-top-16">
                                        {list.list_connections && list.list_connections.results && list.list_connections.results.length > 0 &&
                                            list.list_connections.results.map((item, index) => (
                                                <SmallBackendItem
                                                key={`list-${list.uid}-detailed-connection-${item.uid}`}
                                                content={item.content}
                                                showArtistName
                                                useNumbers={list.numbered}
                                                number={item.priority+1}
                                                size={isTablet ? 60 : 80}
                                                showAverageRating={orderBy && orderBy.includes("average_rating")}
                                                />
                                            ))
                                        }
                                        
                                        {list.list_connections && list.list_connections.results && list.list_connections.results.length <= 0 && list.list_connections.status === status.BEGIN &&
                                            <div className="flex-column">
                                                {new Array(12).fill(null).map((_, i) => (
                                                    <ContentListLoader
                                                    key={`list-detailed-loader-${i}`}
                                                    size={isTablet ? 60 : 80}
                                                    />
                                                ))}
                                            </div>
                                        }
                                        
                                        {list.list_connections && list.list_connections.results && list.list_connections.results.length <= 0 && list.list_connections.status !== status.BEGIN &&
                                            <div className="flex-column">
                                                <p className="highDarkGrey text-center empty-text" style={{ height: 24 }}>List is empty with the current filters.</p>
                                            </div>
                                        }

                                        {/*list.list_connections && (list.list_connections.next || (list.list_connections.status === status.BEGIN && !list.list_connections.reset)) &&
                                            <Button
                                            type="transparent"
                                            style={{ height: 40 }}
                                            textStyle={{ fontSize: 14 }}
                                            text="View more..."
                                            loading={list.list_connections.status === status.BEGIN}
                                            onClicked={() => {
                                                if (list.list_connections.next) {
                                                    dispatch( paginationNext(list.list_connections.next, types.GET_MORE_LIST_CONTENT, { uid: list.uid }) )
                                                }
                                            }}
                                            />
                                        */}        
                                    </div>
                                }

                                <PageControls
                                hasNext={list?.list_connections?.count && (!page || page < Math.ceil(list.list_connections.count / limit))}
                                hasPrev={page && page > 1}
                                currentPage={page}
                                pageAmount={list?.list_connections?.count && Math.ceil(list.list_connections.count / limit)}
                                className={"margin-top-16"}
                                />

                                <AdComponent
                                className="margin-top-48"
                                type="large"
                                adChannel={`List/Primary`}
                                />

                                {!isSmallScreen &&
                                    <ListCommentSection
                                    list={list}
                                    comments={comments}
                                    />
                                }
                            </div>

                            <div className="section-1 margin-top-32">
                                <StickyScrolling enabled={!isSmallScreen}>
                                    {!isSmallScreen &&
                                        <div className="padding-top-32" />
                                    }

                                    {!isLoggedIn &&
                                        <SignUpAd type="list" username={list.creator.username} className="margin-bottom-32" />
                                    }
                                    
                                    <OptionsMenu
                                    options={[
                                        {
                                            text: likeObj ? "Liked list" : "Like list",
                                            onPress: () => {
                                                if (isLoggedIn) {
                                                    if (likeObj) {
                                                        dispatch(
                                                            deleteLike("list", list.uid, likeObj.uid, true)
                                                        )
                                                    } else {
                                                        track("Liked content", { type: "list" })

                                                        dispatch(
                                                            likeContent("list", list.uid, true)
                                                        )
                                                    }
                                                } else {
                                                    openPopup("SignUp")
                                                }
                                            },
                                            icon: likeObj ? (deleteLikeStatus === status.BEGIN && deleteLikeContentUid === list.uid) ? "loading" : "favorite" : "favorite-border",
                                            iconColor: likeObj ? (deleteLikeStatus === status.BEGIN && deleteLikeContentUid === list.uid) ? "var(--black)" : "var(--red)" : "var(--black)",
                                            /*
                                            subText: checkNull(list.like_count),
                                            onSpanPress: () => openPopup("ListLikes", { contentType: "list", contentUid: list.uid }),
                                            */
                                        },
                                        {
                                            text: "I'm feeling lucky",
                                            onPress: () => {
                                                dispatch(
                                                    getRandomListItem(list.uid)
                                                )
                                            },
                                            icon: getRandomListItemStatus === status.BEGIN ? "loading" : "dice",
                                            iconColor: "var(--red)",
                                            iconSize: 20
                                        },
                                        {
                                            text: `${checkNull(list.like_count)} ${checkNullNumber(list.like_count) !== 1 ? "Likes" : "Like"}`,
                                            onPress: () => openPopup("ListLikes", { contentType: "list", contentUid: list.uid }),
                                        },
                                        isMe && {
                                            text: "Edit or delete list",
                                            redirect: `/list/${list.uid}/edit`
                                        },
                                        isMe && {
                                            text: `Manage who can comment`,
                                            onPress: () => {
                                                openPopup("ManageComments", { content: list, type: "list" })
                                            }
                                        },
                                        /*
                                        {
                                            text: "Create a list",
                                            onPress: () => alert("go to create list screen")
                                        },
                                        */
                                        isLoggedIn && {
                                            text: "Share on Musicboard",
                                            onPress: () => openPopup("SendContent", { type: "list", content: list }),
                                        },
                                        {
                                            onPress: () => {
                                                if (list.private || list.private_locked) {
                                                    return;
                                                }

                                                openPopup("ShareList", { list: list, popupTitle: "Share List" })
                                            },
                                            element: 
                                                <div 
                                                id={(list.private || list.private_locked) ? `list-${list.uid}-disabled-share-tip` : null}
                                                >
                                                    <div 
                                                    className={(list.private || list.private_locked) ? styles.disabledShare : ""}
                                                    >
                                                        <ShareElement 
                                                        type="list"
                                                        image={
                                                            (!list.list_connections || !list.list_connections.results) ? 
                                                                null 
                                                            : 
                                                                list.list_connections.results[0] ? 
                                                                    list.list_connections.results[0].content.cover ? 
                                                                        list.list_connections.results[0].content.cover 
                                                                    : list.list_connections.results[0].content.picture ? 
                                                                        list.list_connections.results[0].content.picture 
                                                                    : 
                                                                        null 
                                                                : 
                                                                    null
                                                        } 
                                                        title={`${list.title}, a list by ${list.creator.username}`} 
                                                        />
                                                    </div>
                                                </div>
                                        },
                                    ]}
                                    />

                                    <Tooltip
                                    id={`list-${list.uid}-disabled-share-tip`}
                                    text={`Make this list public to share`}
                                    offset={10}
                                    />

                                    {filteredUsersLists.length > 0 &&
                                        <Heading
                                        className="margin-top-32"
                                        text={`More lists by ${list.creator.username}`}
                                        redirect={`/${list.creator.username}/lists`}
                                        />
                                    }

                                    <div className="section-block">
                                        {filteredUsersLists.map((item, index) => (
                                            <ListItem
                                            list={item}
                                            isFirst={index === 0}
                                            isLast={index === filteredUsersLists.length-1}
                                            isSmall
                                            key={`list-${list.uid}-relatedlists-item-${item.uid}`}
                                            />
                                        ))}
                                    </div>

                                    {isModerator &&
                                    <>
                                        <Heading
                                        text="Moderator"
                                        className="margin-top-32"
                                        />

                                        <div className='section-block padding-around'>
                                            <h5 className='black'>ID: {list.id}</h5>
                                            <p className='highDarkGrey margin-top-8'>UID: {list.uid}</p>

                                            <a 
                                            href={`https://api.musicboard.app/admin/reviews/list/${list.id}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            >
                                                <Button
                                                className='margin-top-16'
                                                type="secondary"
                                                text="Open in Admin"
                                                height={41}
                                                textStyle={{ fontSize: 14 }}
                                                />
                                            </a>
                                        </div>
                                    </>
                                    }

                                    {isSmallScreen &&
                                        <ListCommentSection
                                        list={list}
                                        comments={comments}
                                        />
                                    }

                                    <AdComponent
                                    className="margin-top-32"
                                    type="small"
                                    adChannel={`List/Small`}
                                    />

                                    <MiniFooter className={"margin-top-24"} />
                                </StickyScrolling>
                            </div> 
                        </div>
                    </div>

                    <div style={{ height: 64 }} />
                </div>
            </div>
        </div>
    )
}
