import React from 'react';

import { useLocation } from "react-router-dom";

export function useHref() {
    const location = useLocation()
    
    return {
        getHref: (value, parameter, allowPage=false) => {
            const search = new URLSearchParams(location.search)
            
            if (!allowPage) {
                if (location.search.includes("page=")) {
                    search.delete("page")
                }
            }

            if (value) {
                search.set(parameter, value)
            } else {
                search.delete(parameter)
            }

            if (search && search.toString() === "") {
                return ""
            } else {
                return "?" + search.toString()
            }
        },
        getPagination: (value, parameter) => {
            const search = new URLSearchParams(location.search)

            if (value && value > 1) {
                search.set(parameter, value)
            } else {
                search.delete(parameter)
            }

            if (search && search.toString() === "") {
                return ""
            } else {
                return "?" + search.toString()
            }
        },
    }

}