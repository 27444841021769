export const recordTypeLabels = [
    {
        value: "album",
        label: "Album",
        discography: "Albums by "
    },
    {
        value: "ep",
        label: "EP",
        discography: "EPs by "
    },
    {
        value: "single",
        label: "Single",
        discography: "Singles by "
    },
    {
        value: "mixtape",
        label: "Mixtape",
        discography: "Mixtapes by "
    },
    {
        value: "live",
        label: "Live",
        discography: "Live by "
    },
    {
        value: "acoustic",
        label: "Acoustic",
        discography: "Acoustics by "
    },
    {
        value: "instrumental",
        label: "Instrumental",
        discography: "Instrumentals by "
    },
    {
        value: "soundtrack",
        label: "Soundtrack",
        discography: "Soundtracks by "
    },
    {
        value: "theme_song",
        label: "Theme Song",
        discography: "Theme Songs by "
    },
    {
        value: "anthem",
        label: "Anthem",
        discography: "Anthems by "
    },
    {
        value: "demo",
        label: "Demo",
        discography: "Demos by "
    },
    {
        value: "deluxe",
        label: "Reissue",
        discography: "Reissues by "
    },
    {
        value: "remix",
        label: "Remix",
        discography: "Remixes of "
    },
    {
        value: "cover",
        label: "Cover",
        discography: "Covers of "
    },
    {
        value: "dj_mix",
        label: "DJ Mix",
        discography: "DJ Mixes by "
    },
    {
        value: "compile",
        label: "Compilation",
        discography: "Compilations by "
    },
    {
        value: "unofficial",
        label: "Unofficial Release",
        discography: "Unofficial Releases by "
    },
    {
        value: "miscellaneous",
        label: "Miscellaneous",
        discography: "Miscellaneous by "
    },
]

export const recordTypes = [
    "album",
    "ep",
    "single",
    "mixtape",
    "live",
    "acoustic",
    "instrumental",
    "soundtrack",
    "theme_song",
    "anthem",
    "demo",
    "deluxe",
    "remix",
    "cover",
    "dj_mix",
    "compile",
    "unofficial",
    "miscellaneous",
]
