import styles from './stats.module.css'

import React from 'react'

import Icon from '../Icon'
import { useTheme } from '../../hooks/theme'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

export default function Stats({ statistics=[], hideIcons, smallerTitles, className, style }) {
    //General
    const { colors } = useTheme()

    //Responsive
    const isMinimum = useMediaQuery({ maxWidth: 490 })
    const isMobile = useMediaQuery({ maxWidth: 630 })
    
    return (
        <div className={`flex-row ${className}`} style={style}>
            {statistics.map((item, index) => (
                <Link
                to={item.redirect ? item.redirect : ""}
                key={`stat-${item.description}-${index}-${item.number}`}
                className={`${styles.col} ${item.redirect ? styles.active : ""}`}
                style={{ pointerEvents: item.redirect ? "initial" : "none" }}
                >
                    {index !== 0 &&
                        <div className={styles.divider} />
                    }
                    <div className={styles.colColumn}>
                        <div className="flex-row">
                            {item.star && !hideIcons &&
                                <Icon className="margin-right-8" icon="star" color={item.active ? colors.yellow : colors.highDarkGrey} />
                            }
                            {(item.number || item.subText) &&
                                <h4 className="black text-lg" style={{ fontSize: smallerTitles || isMobile ? 20 : 24 }}>
                                    {item.number}
                                    {item.subText && 
                                        <span className="grey" style={{fontWeight: 'normal'}}>{item.subText}</span>
                                    }
                                </h4>
                            }
                        </div>
                        <p style={{ fontSize: isMinimum ? 13 : 14 }} className={`grey ${smallerTitles || isMobile ? "margin-top-2" : "margin-top-4"}`}>{item.description}</p>
                    </div>
                </Link>
            ))}
        </div>
    )
}
