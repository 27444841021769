import React from 'react'
import Icon from '../Icon'
import { useTheme } from '../../hooks/theme'

export default function VerifiedIcon({ className="", size=18, style, whiteBg }) {
    const { colors } = useTheme()
    return (
        <div className={className} style={style}>
            <div style={{ position: 'relative' }} className="flex-center">
                {whiteBg &&
                    <div style={{ zIndex: -1, position: 'absolute', width: size-(size/3), height: size-(size/3), borderRadius: 1000, backgroundColor: 'white' }} />
                }
                <Icon icon="verified" size={size} color={colors.blue} />
            </div>
        </div>
    )
}
