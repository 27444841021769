import { types } from '../constants/action-types'
import { post, put, get, patch } from '../utils/fetch'
import { url } from '../config'

export const getProducts = () => get(types.GET_PRODUCTS, `${url}/v2/products/`);

export const getSubscription = () => get(types.GET_SUBSCRIPTION, `${url}/v2/subscriptions/`);

export const createPortalSession = (subscriptionId, flow, return_url) => 
    post(types.CREATE_PORTAL_SESSION, `${url}/v2/subscriptions/${subscriptionId}/create_session/`, { flow, return_url });

export const createProGift = (userUid, message, type) =>
    post(types.CREATE_PRO_GIFT, `${url}/v2/pro_gifts/`, { user: userUid, message, design_type: type });

export const updateProGift = (giftUid, body) =>
    patch(types.UPDATE_PRO_GIFT, `${url}/v2/pro_gifts/${giftUid}/`, body, { giftUid });

export const getProGifts = () => get(types.GET_PRO_GIFTS, `${url}/v2/pro_gifts/`);
