import React, { useEffect } from 'react'

export default function StructuredData({ id, data }) {

    useEffect(() => {
        // Create a script element
        const script = document.createElement('script')
        script.id = id
        script.type = 'application/ld+json'
        script.textContent = JSON.stringify(data)
        console.log(data)

        // Remove all initial schemas
        const elements = document.getElementsByClassName('Initial-Schema')
        if (elements && elements.length > 0) {
            Array.from(elements).forEach(element => element.remove())
        }

        // Insert the new schema to the head of the document
        document.head.appendChild(script)

        return () => {
            // Remove the schema when the component is unmounted
            document.head.removeChild(script)
        }
    }, [id, data])

    return null
}
