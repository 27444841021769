import styles from './playvideo.module.css'

import React, { useEffect, useReducer, useContext, useState } from 'react'
import { useScrollbar } from '../scrollbar';
import Icon from '../../components/Icon';

const PlayVideoContext = React.createContext();

export const usePlayVideo = () => {
    const { state, dispatch } = useContext(PlayVideoContext)

    return {
      ...state,
      openVideo: (video=null) => {
            dispatch({
                type: 'OPEN_VIDEO',
                payload: video
            })
      },
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'OPEN_VIDEO':
            return {
                ...state,
                video: action.payload
            }
        default:
            throw new Error()
    }
}

const defaultPlayVideo = { 
    video: null,
}
  
const PlayVideoProvider = ({ init, children }) => {
    const [ state, dispatch ] = useReducer(reducer, defaultPlayVideo);
    const { setScrollDisabled } = useScrollbar()

    const { video } = state;

    useEffect(() => {
        if (video) {
            document.body.style.overflow = "hidden"
            setScrollDisabled(true)
        } else {
            document.body.style.overflow = "initial"
            setScrollDisabled(false)
        }
    }, [ video ])

    function close() {
        dispatch({
            type: "OPEN_VIDEO",
            payload: null
        })
    }

    return (
        <PlayVideoContext.Provider value={{ state, dispatch }}>
            {children}

            {video &&
                <div className={styles.fixed}>
                    <div onClick={close} className={styles.bg} />

                    <iframe src={video} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className={styles.video} />

                    <div onClick={close} className={styles.close}>
                        <Icon icon="close" size={20} className={styles.icon} />
                    </div>
                </div>
            }
        </PlayVideoContext.Provider>
    )
}
export default PlayVideoProvider;