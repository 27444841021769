import React, { Suspense, lazy, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { status, types } from '../../../../constants/action-types'

import { createWant, deleteWant } from '../../../../actions/me-actions'
import { fetchTracksForArtist, getArtistDiscography, getArtistLists, getArtistRatingDistribution, getArtistRatings, getArtistReviews, getArtistTopTracks } from '../../../../actions/content-actions'

import { createArtistMusicGroupSchema, createBreadcrumbSchema } from '../../../../data/structured-data'

import { useDeezer } from '../../../../hooks/deezer'
import { useSpotify } from '../../../../hooks/spotify'
import { useAudio } from '../../../../hooks/audio/audio'
import { usePopup } from '../../../../hooks/popup/popup'

import { filterAndFill } from '../../../../utils/format-array'
import { numberWithCommas } from '../../../../utils/format-text'

import ListItemLoader from '../../../../loaders/ListItemLoader'
import TrackItemLoader from '../../../../loaders/TrackItemLoader'
import ReviewItemLoader from '../../../../loaders/ReviewItemLoader'
import RatingDistributionLoader from '../../../../loaders/RatingDistributionLoader'

import DefaultHelmet from '../../../../shared/DefaultHelmet'
import StructuredData from '../../../../shared/StructuredData'

import Button from '../../../../components/Button'
import Heading from '../../../../components/Heading'
import ListItem from '../../../../components/ListItem'
import TrackItem from '../../../../components/TrackItem'
import ReviewItem from '../../../../components/ReviewItem'
import MiniFooter from '../../../../components/MiniFooter'
import OptionsMenu from '../../../../components/OptionsMenu'
import AdComponent from '../../../../components/AdComponent'
import RatingsGraph from '../../../../components/RatingsGraph'
import ShareElement from '../../../../components/ShareElement'
import UserRatingItem from '../../../../components/UserRatingItem'
import BigReleaseItem from '../../../../components/BigReleaseItem'
import SmallArtistItem from '../../../../components/SmallArtistItem'
import StickyScrolling from '../../../../components/StickyScrolling'

import ArtistInformationSection from '../ArtistInformationSection';

export default function ArtistHome({ backend, biography, titleSlug }) {
    //General
    const dispatch = useDispatch()
    const deezer = useDeezer()
    const spotifyFetch = useSpotify()
    const { openPopup } = usePopup()
    const { playTracks } = useAudio()

    //Responsive
    const isMidScreen = useMediaQuery({ maxWidth: 1100 })
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isTablet = useMediaQuery({ maxWidth: 768 })
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const isModerator = useSelector(state => state.me.is_moderator)

    //Wants
    const deleteWantStatus = useSelector(state => state.me.deleteWantStatus)
    const lastDeletedWantContentId = useSelector(state => state.me.lastDeletedWantContentId)
    const myWants = useSelector(state => state.me.wants)
    const cachedWants = useSelector(state => state.me.cachedWants)
    const wants = [ ...myWants, ...cachedWants ]
    const wantIndex = wants.findIndex(want => want.content_id == backend.id && want.content_ct === "artist")
    const wantObj = wantIndex !== -1 ? wants[wantIndex] : null

    //State
    const [related, setRelated] = useState(null)
    const [top, setTop] = useState(null)
    const [spotify, setSpotify] = useState(null)

    //Get data
    const hasFetched = useRef(false)
    useEffect(() => {
        if (backend && backend.id && !hasFetched.current) {
            hasFetched.current = true;
            
            //Get artist's rating distribution
            dispatch(
                getArtistRatingDistribution(backend.id)
            )

            //Fetch top lists
            dispatch( 
                getArtistLists({
                    id: backend.id,
                    order_by: "-like_count",
                    limit: 3,
                    type: types.GET_ARTISTS_HOME_LISTS,
                })
            )

            //Get reviews
            dispatch( 
                getArtistReviews({
                    id: backend.id,
                    scored: true,
                    order_by: "",
                    limit: 6,
                    type: types.GET_ARTISTS_HOME_REVIEWS,
                }) 
            )

            //Get friends ratings
            if (isLoggedIn) {
                dispatch(
                    getArtistRatings({
                        id: backend.id,
                        following: true
                    })
                )
            }
    
            //Fetch the artist's discography (if we haven't already)
            if (!(backend.discography && backend.discography.data && backend.discography.data.length >= 0)) {
                dispatch(
                    getArtistDiscography(backend.id, backend.deezer_id)
                )
            }
    
            if (backend.deezer_id) {
                //Get top tracks from Deezer
                deezer.getArtistTopSongs(backend.deezer_id).then((res) => {
                    setTop(res)
                }).catch((err) => {
                    setTop({ data: [] })
                })
                
                //Get related artist from Deezer
                deezer.getArtistRelated(backend.deezer_id).then((res) => {
                    setRelated(res)
                }).catch((err) => {
                    setRelated({ data: [] })
                })

            } else {
                //Get top tracks from our API
                dispatch(
                    getArtistTopTracks(backend.id)
                )

            }
            
            //Get spotify link
            spotifyFetch.searchArtist(backend.name).then((res) => {
                setSpotify(res.artists.items[0].external_urls.spotify)
            }).catch((err) => {
                console.warn("Spotify error", { err })
            })

        }

    }, [ backend ])

    //When loaded top tracks from Deezer
    useEffect(() => {
        if(top && top.data) {
            const tracks = top.data
            let ids = ""
            tracks.forEach((track) => {
                ids = ids + track.id + ","
            })
            ids = ids.substr(0, ids.length-1)
            dispatch(fetchTracksForArtist(ids, true))
        }
    }, [ top ])

    //Helper functions
    const playTracksCallback = useCallback((trackId) => {
        // Render tracks from Deezer
        if (backend.deezer_id && top && top.data) {
            playTracks(
                top.data
                .filter((track) => track.preview)
                .map((track) => ({
                    id: track.id, 
                    deezer_id: track.deezer_id, 
                    url_slug: track.url_slug, 
                    name: track.title, 
                    singer: track.artist.name, 
                    cover: track.album.cover !== null && track.album.cover !== "" ? track.album.cover_medium || track.album.cover : backend.picture_medium || backend.picture, 
                    musicSrc: track.preview,
                    duration: track.duration,
                    album: {
                        id: track.album.id,
                    },
                    artist: {
                        id: backend.id,
                        deezer_id: backend.deezer_id,
                        url_slug: backend.url_slug,
                    }
                })),
                top.data.findIndex((track) => track.id == trackId)
            )
        }
    }, [top])

    //Variables
    const activeTracks = backend.deezer_id ? top && top.data ? top.data : [] : backend.topTracks && backend.topTracks.data ? backend.topTracks.data : []

    return (
        <div className="page-container">
            <DefaultHelmet
            title={`${backend.name}${titleSlug?.match(/\-\d+$/) ? ` - ${titleSlug.match(/\-(\d+)$/)[1]}` : ''} - Musicboard`}
            canonical={`https://musicboard.app${backend.url_slug}`}
            description={`${backend.name} discography, songs, reviews, and ratings. Music profile for ${backend.name}, ${backend.is_group ? "formed" : "born"} ${backend.birth_year ? `on ${moment(backend.birth_year).format("DD MMMM YYYY")}` : ""} ${backend.country && backend.country !== "" ? `in ${backend.country}` : ""}. Discography: ${backend.nb_albums} releases. Rated ${(backend.average_rating / 2).toFixed(2)} / 5 by ${numberWithCommas(backend.ratings_count || 0)} listeners. Explore their entire profile on Musicboard.`}
            ogImage={backend.picture_large || backend.picture}
            ogImageAlt={backend.name}
            ogImageWidth={500}
            ogImageHeight={500}
            twitterImage={backend.picture_large || backend.picture}
            twitterImageWidth={500}
            twitterImageHeight={500}
            labelOne={
                backend.country && backend.country !== "" ?
                    { label: "Country", data: backend.country }
                : backend.birth_year && backend.birth_year !== "" ?
                    { label: "Born", data: backend.birth_year }
                :
                    { label: "Number of Releases", data: `${backend.nb_albums} releases`}
            }
            labelTwo={{ label: "Average Rating", data: `${(backend.average_rating / 2).toFixed(2)} out of 5` }}
            deepLink={`musicboard://artist/${backend.id}`}
            headingOne={`${backend.name}`}
            headingTwo={`Reviews & Ratings on Musicboard`}
            />

            {(backend.homeReviews && backend.homeReviews.data && backend.homeReviews.data.length > 0) ?
                <StructuredData
                key={`Artist-MusicArtist-Data-${backend.id}`}
                id={`Artist-MusicArtist-Data-${backend.id}`}
                data={createArtistMusicGroupSchema(backend, backend.homeReviews.data.filter((review) => review.rating?.rating !== null))}
                />
            : null}

            <StructuredData
            key={`Artist-Breadcrumb-Data-${backend.id}`}
            id={`Artist-Breadcrumb-Data-${backend.id}`}
            data={createBreadcrumbSchema([
                { "name": "Musicboard", "item": "https://musicboard.app/" },
                { "name": "Artists", "item": "https://musicboard.app/artists/" },
                { "name": backend.name, "item": `https://musicboard.app${backend.url_slug}` }
            ])}
            />

            <div className="section-handler">
                {/* LEFT SIDE */}
                <div className="section-2 section-margin">
                    
                    <Heading
                    text="Most Popular Tracks"
                    className="margin-top-32"
                    />

                    {activeTracks.length > 0 &&
                        <div className="section-block padding-around-8">
                            {activeTracks.map((item, index) => (
                                <TrackItem
                                track={item}
                                album={item.album}
                                artist={backend}
                                number={index+1}
                                key={`artisthome-track-${item.id}`}
                                playTracks={backend.deezer_id && top && top.data ? playTracksCallback : null}
                                />
                            ))}
                        </div>
                    }

                    {!top && activeTracks.length <= 0 &&
                        <div className="section-block padding-around-8 flex-column">
                            <TrackItemLoader />
                            <TrackItemLoader />
                            <TrackItemLoader />
                            <TrackItemLoader />
                            <TrackItemLoader />
                        </div>
                    }
                    
                    {backend.discography && backend.discography.data && backend.discography.data.length > 0 &&
                        <Heading
                        text="Discography"
                        redirect={`${backend.url_slug}releases`}
                        className="margin-top-32"
                        />
                    }

                    {backend.discography && backend.discography.data && backend.discography.data.length > 0 &&
                        <div className={`grid-view ${isMobile ? "three" : isSmallScreen ? "four" : isMidScreen ? "three" : "four"} section-block padding-around`}>
                            {backend.discography.data.filter((_, i) => i < 8).map((item, index) => (
                                <div key={`artisthome-release-${index}`}>
                                    {item &&
                                        <BigReleaseItem release={item} />
                                    }
                                </div>
                            ))}
                        </div>
                    }

                    <AdComponent
                    className="margin-top-32"
                    type="large"
                    adChannel="Artist/LeftFirst"
                    />

                    {backend && backend.ratings && backend.ratings.data && backend.ratings.data.length > 0 &&
                        <Heading
                        text="Friends Ratings"
                        redirect={`${backend.url_slug}ratings?following=true`}
                        className="margin-top-32"
                        />
                    }

                    {backend && backend.ratings && backend.ratings.data && backend.ratings.data.length > 0 &&
                        <div className={`grid-view ${isMobile ? "four" : isTablet ? "six" : isSmallScreen ? "eight" : isMidScreen ? "six" : "eight"} section-block padding-around`}>
                            {filterAndFill(backend.ratings.data, isMobile ? 8 : isTablet ? 6 : isSmallScreen ? 8 : isMidScreen ? 6 : 8).map((item, index) => (
                                <div key={`artisthome-userrating-${item ? item.uid : index}`}>
                                    {item &&
                                        <UserRatingItem rating={item} />
                                    }
                                </div>
                            ))}
                        </div>
                    }
                    
                    <Heading
                    text="Top Reviews"
                    redirect={`${backend.url_slug}reviews?order_by=helpful`}
                    className="margin-top-32"
                    />

                    <div className="section-block">
                        {backend && backend.homeReviews && backend.homeReviews.data && backend.homeReviews.data.length > 0 && backend.homeReviews.data.filter((_, i) => i < 6).map((item, index) => (
                            <ReviewItem
                            review={item}
                            hideUser
                            presentUserReview
                            isFirst={index === 0}
                            isLast={index === backend.homeReviews.data.filter((_, i) => i < 6).length-1}
                            key={`artisthome-review-${item.uid}`}
                            />
                        ))}

                        {backend && backend.homeReviews && backend.homeReviews.data && backend.homeReviews.data.length <= 0 && backend.homeReviews.status !== status.BEGIN &&
                            <div className="empty-text">
                                <p className="text-center highDarkGrey">Seems like no one has gotten to this yet! Be the first to write a review for {backend.name}.</p>

                                <div className="flex-center">
                                    <Button
                                    text={isLoggedIn ? "Write a review" : "Sign up to write a review"}
                                    iconName={isLoggedIn ? 'edit' : "lock"}
                                    showIcon
                                    onClicked={() => {
                                        if (isLoggedIn) {
                                            openPopup("CreateReview", { content: backend, cacheRating: true })
                                        } else {
                                            openPopup("SignUp")
                                        }
                                    }}
                                    className="margin-top-16"
                                    style={{
                                        paddingLeft: 48,
                                        paddingRight: 48
                                    }}
                                    />
                                </div>
                            </div>
                        }

                        {backend && backend.homeReviews && backend.homeReviews.data && backend.homeReviews.data.length <= 0 && backend.homeReviews.status === status.BEGIN &&
                            [0,1,2,3].map((i) => (
                                <div key={`album-reviews-loader-${i}`} style={{ width: '100%', borderTop: i !== 0 ? '1px solid var(--lightGrey)' : '' }}>
                                    <ReviewItemLoader roundImage />
                                </div>
                            ))
                        }
                    </div>

                    <AdComponent
                    className="margin-top-32"
                    type="large"
                    adChannel="Artist/LeftSecond"
                    />

                    {((backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length > 0) || backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length <= 0 && backend.homeLists.status === status.BEGIN) && 
                        <Heading
                        text={`Lists With ${backend.name}`}
                        redirect={`${backend.url_slug}lists?order_by=-like_count`}
                        className="margin-top-32"
                        />
                    }

                    {((backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length > 0) || backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length <= 0 && backend.homeLists.status === status.BEGIN) && 
                        <div className="section-block">
                            {backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length > 0 && 
                            backend.homeLists.data.filter((_, i) => i < 3).map((item, index) => (
                                <ListItem
                                list={item}
                                isFirst={index === 0}
                                isLast={index === backend.homeLists.data.filter((_, i) => i < 3).length-1}
                                key={`artistList-${backend.id}-${item.uid}`}
                                />
                            ))}

                            {backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length <= 0 && backend.homeLists.status === status.BEGIN &&
                                [0,1,2,3].map((i) => (
                                    <div key={`artist-lists-loader-${i}`} style={{ width: '100%', borderTop: i !== 0 ? '1px solid var(--lightGrey)' : '' }}>
                                        <ListItemLoader roundImage />
                                    </div>
                                ))
                            }
                        </div>
                    }

                    <AdComponent
                    className="margin-top-32"
                    type="large"
                    adChannel="Artist/LeftThird"
                    skipProAd
                    />
                </div>
                
                {/* RIGHT SIDE */}
                <div className="section-1">
                    <StickyScrolling enabled={!isSmallScreen}>
                        <div className="padding-top-32" />
                        
                        <OptionsMenu
                        options={[
                            {
                                text: "Write review",
                                onPress: () => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }

                                    openPopup("CreateReview", { content: backend, cacheRating: true })
                                },
                            },
                            {
                                text: wantObj ? "Remove from Listen Later" : "Add to Listen Later",
                                onPress: () => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }

                                    if (wantObj) {
                                        dispatch(
                                            deleteWant(wantObj.uid)
                                        )
                                    } else {
                                        dispatch(
                                            createWant(backend.id, "artist", false)
                                        )
                                    }
                                },
                                icon: (deleteWantStatus === status.BEGIN && lastDeletedWantContentId == backend.id) ? "loading" : null,
                            },
                            {
                                text: "Add artist to a list",
                                onPress: () => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }

                                    openPopup("AddToList", { popupTitle: `Add '${backend.name}' to a List`, content: backend })
                                },
                            },
                            {
                                text: "Listen on Spotify",
                                redirect: `${spotify}`,
                                target: "_blank"
                            },
                            {
                                text: "Share on Musicboard",
                                onPress: () => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }

                                    openPopup("SendContent", { type: "artist", content: backend })
                                },
                            },
                            {
                                onPress: () => openPopup("ShareArtist", { artist: backend, biography: biography }),
                                element: (
                                    <ShareElement 
                                    type="artist" 
                                    image={backend.picture} 
                                    title={`${backend.name}`} 
                                    />
                                )
                            },
                        ]}
                        />

                        <Heading
                        text={`Information`}
                        className="margin-top-32"
                        />

                        <div className="section-block">
                            <div className="padding-around">
                                {backend.rating_distribution && backend.rating_distribution.length > 0 &&
                                    <RatingsGraph ratings={backend.rating_distribution} />
                                }
                                
                                {(!backend.rating_distribution || backend.rating_distribution.length <= 0) &&
                                    <RatingDistributionLoader />
                                }
                            </div>
                            
                            <Suspense fallback={<div />}>
                                <ArtistInformationSection artist={backend} />
                            </Suspense>
                        </div>

                        {related && related.data && related.data.length > 0 &&
                            <Heading
                            text="You Might Also Like"
                            className="margin-top-32"
                            />
                        }
                        
                        {related && related.data && related.data.length > 0 &&
                            <div className="section-block padding-around-8">
                                {related.data.map((item, index) => (
                                    <SmallArtistItem key={`artisthome-related-${item.id}`} artist={item} />
                                ))}
                            </div>
                        }

                        {isModerator &&
                        <>
                            <Heading
                            text={`Moderator`}
                            className="margin-top-32"
                            />

                            <div className='section-block padding-around'>
                                <h5 className='black'>ID: {backend.id}</h5>
                                <p className='highDarkGrey margin-top-8'>UID: {backend.uid}</p>
                                <p className='highDarkGrey margin-top-8'>Deezer ID: {backend.deezer_id}</p>

                                <a 
                                href={`https://api.musicboard.app/admin/content/artist/${backend.id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                >
                                    <Button
                                    className='margin-top-16'
                                    type="secondary"
                                    text="Open in Admin"
                                    height={41}
                                    textStyle={{ fontSize: 14 }}
                                    />
                                </a>
                            </div>
                        </>
                        }

                        {isLoggedIn &&
                            <div className="flex-row margin-top-16">
                                <Link to={`/artist/${backend.id}/edit`} className="col-1">
                                    <Button
                                    showIcon
                                    iconSize={21}
                                    iconName="edit"
                                    iconColor="var(--black)"
                                    type="secondary"
                                    text="Contribute to this artist" 
                                    height={41}
                                    textStyle={{ fontSize: 14 }}
                                    />
                                </Link>

                                {/*
                                <IconButton
                                style={{ borderRadius: 'var(--border-radius-small)' }}
                                icon="flag"
                                className="margin-left-8"
                                btnSize={41}
                                size={21}
                                onClick={() => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }

                                    openPopup("A")
                                }}
                                />
                                */}
                            </div>
                        }

                        <AdComponent
                        className="margin-top-32"
                        type="vertical"
                        adChannel="Artist/RightVertical"
                        />
                        
                        <MiniFooter className={"margin-top-24"} />
                    </StickyScrolling>
                </div>
            </div>
            
            <div style={{ height: 64 }} />
        </div>
    )
}
