import styles from './getstarted.module.css'

import getStartedImage from '../../../../assets/images/get-started-design.png'
import React from 'react'
import Button from '../../../../components/Button'
import { usePopup } from '../../../../hooks/popup/popup'
import { useMediaQuery } from 'react-responsive'

export default function GetStarted({ className, style }) {
    const { openPopup } = usePopup()

    const isTablet = useMediaQuery({ maxWidth: 768 })

    return (
        <div className={`${styles.container} flex-row ${className} mobile-block`} style={style}>
            <div className={`${styles.content} col-1`}>
                <h3 className="black text-xl">Create Your Account Now</h3>

                <p className="margin-top-16 highDarkGrey">A world of music is waiting for you. Create your account and start adding music to your collection today.</p>

                <div className={`${isTablet ? "margin-top-32" : "margin-top-24"} flex-row`}>
                    <Button
                    className="padding-left-64 padding-right-64"
                    text="Get started"
                    onClicked={() => openPopup("SignUp")}
                    />
                </div>
            </div>

            <img src={getStartedImage} className={`${styles.image} margin-top-16`} alt="Musicboard profile example" />

            <div className={styles.border} />
        </div>
    )
}
