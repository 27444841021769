export const createGeneralSchema = (json) => json

/* Helper functions */
/* ====================================================================================== */

function secondsToDuration(seconds) {
	const SECONDS_PER_SECOND = 1;
	const SECONDS_PER_MINUTE = 60;
	const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;
	const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;

	const designations = [
		['D', SECONDS_PER_DAY],
		['H', SECONDS_PER_HOUR],
		['M', SECONDS_PER_MINUTE],
		['S', SECONDS_PER_SECOND],
	];

	let duration = 'P';
	let remainder = seconds;

	designations.forEach(([sign, seconds]) => {
		const value = Math.floor(remainder / seconds);

		remainder = remainder % seconds;

		if (value) {
			duration += `${value}${sign}`;
		}
	});

	if (duration == 'P') {
		duration = 'P0S';
	}

	return duration;
}

/* Breadcrumb */
/* ====================================================================================== */
export const createBreadcrumbSchema = (list) => {
	return createGeneralSchema({
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [
			list.map((item, index) => ({
				"@type": "ListItem",
				"position": index+1,
				"name": item.name,
				"item": item.item
			}))
		]
	})
}

/* Music Album */
/* ====================================================================================== */
export const createAlbumMusicAlbumSchema = (album, reviews) => {
	return createGeneralSchema({
		"@context": "http://schema.org",
		"@type": "MusicAlbum",
		"identifier": album.uid,
		"albumProductionType": `https://schema.org/${album.record_type === "compile" ? "CompilationAlbum" : album.record_type === "dj_mix" ? "DJMixAlbum" : album.record_type === "demo" ? "DemoAlbum" : album.record_type === "live" ? "LiveAlbum" : album.record_type === "mixtape" ? "MixtapeAlbum" : album.record_type === "remix" ? "RemixAlbum" : album.record_type === "soundtrack" ? "SoundtrackAlbum" : "StudioAlbum"}`,
    	"albumReleaseType": `https://schema.org/${album.record_type === "ep" ? "EPRelease" : album.record_type === "single" ? "SingleRelease" : "AlbumRelease"}`,
		"name": album.title,
		"url": `https://musicboard.app${album.url_slug}`,
		"image": album.cover_large,
		...album.release_date ? { "datePublished": album.release_date } : {},
		...(album.genres.length > 0 || album.styles.length > 0) ? { "genre": [...album.genres, ...album.styles].map(i => i.name).join(", ") } : {},
		"numTracks": album.tracks.length,
		...album.country && album.country !== "" ? { "countryOfOrigin": album.country } : {},
		...album.duration && album.duration !== "" ? { "duration": secondsToDuration(Number(album.duration)) } : {},
		...album.label && album.label !== "" ? {
			"recordLabel": {
				"@type": "Organization",
				"name": album.label
			}
		} : {},
		...album.artist ? { "byArtist": {
			"@type": "MusicGroup",
			"identifier": album.artist.uid,
			"name": album.artist.name,
			"url": `https://musicboard.app${album.artist.url_slug}`,
			"image": album.artist.picture_large,
			//...(album.artist.average_rating && album.artist.average_rating > 0 && album.artist.ratings_count && album.artist.ratings_count > 0) ? { "aggregateRating": {
			//	"@type": "AggregateRating",
			//	"ratingCount": album.artist.ratings_count,
			//	"bestRating": 5,
			//	"worstRating": 0.5,
			//	"ratingValue": Number((album.artist.average_rating / 2).toFixed(1))
			//} } : {},
		} } : {},
		...(album.average_rating && album.average_rating > 0 && album.ratings_count && album.ratings_count > 0) ? { "aggregateRating": {
			"@type": "AggregateRating",
			"ratingCount": album.ratings_count,
			"bestRating": 5,
			"worstRating": 0.5,
			"ratingValue": Number((album.average_rating / 2).toFixed(1))
		} } : {},
		"track": [
			album.tracks.map((track) => ({
				"@type": "MusicRecording",
				"identifier": track.uid,
				"name": track.title,
				"url": `https://musicboard.app${track.url_slug}`,
				"position": track.track_position,
				"isFamilyFriendly": track.explicit_lyrics ? false : true,
				...album.title ? { "inAlbum": album.title } : {},
				...track.artist ? { "byArtist": {
					"@type": "MusicGroup",
					"identifier": track.artist.uid,
					"name": track.artist.name,
					"url": `https://musicboard.app${track.artist.url_slug}`,
					"image": track.artist.picture_large
				} } : {},
				//...(track.average_rating && track.average_rating > 0 && track.ratings_count && track.ratings_count > 0) ? { "aggregateRating": {
				//	"@type": "AggregateRating",
				//	"ratingCount": track.ratings_count,
				//	"bestRating": 5,
				//	"worstRating": 0.5,
				//	"ratingValue": Number((track.average_rating / 2).toFixed(1))
				//} } : {},
			}))
		],
		...reviews && reviews.length > 0 ? { "review": {
			"@type": "Review",
			"identifier": reviews[0].uid,
			"itemReviewed": { "@type": "MusicAlbum", "name": album.title, "url": `https://musicboard.app${album.url_slug}` },
			"author": { 
				"@type": "Person", 
				"identifier": reviews[0].creator.uid,
				"name": reviews[0].creator.username,
				...reviews[0].creator.profile_picture_large ? { "image": reviews[0].creator.profile_picture_large } : {},
				...reviews[0].creator.name ? { "alternateName": reviews[0].creator.name } : {},
				...reviews[0].creator.biography !== "" ? { "description": reviews[0].creator.biography } : {},
				"url": `https://musicboard.app/${reviews[0].creator.username}/`,
			},
			"dateCreated": reviews[0].created_at,
			"headline": reviews[0].title !== "" && reviews[0].description !== "" ? reviews[0].title : `Review of ${album.title} by ${reviews[0].creator.username}`,
			"name": reviews[0].title !== "" && reviews[0].description !== "" ? reviews[0].title : `Review of ${album.title} by ${reviews[0].creator.username}`,
			"reviewBody": reviews[0].description !== "" ? reviews[0].description : reviews[0].title,
			...reviews[0].detected_language && reviews[0].detected_language !== "" ? { "inLanguage": reviews[0].detected_language } : {},
			...reviews[0].rating && reviews[0].rating.rating ? { "reviewRating": {
				"@type": "Rating",
				"bestRating": 5,
				"worstRating": 0.5,
				"ratingValue": reviews[0].rating.rating / 2
			} } : {},
		} } : {},
		"isFamilyFriendly": album.explicit_lyrics ? false : true
	})
}

export const createAlbumAggregateRatingSchema = (album, reviews) => {
	return createGeneralSchema({
		"@context": "https://schema.org/",
		"@type": "AggregateRating",
		"itemReviewed": {
			"@type": "MusicAlbum",
			"identifier": album.uid,
			"albumProductionType": `https://schema.org/${album.record_type === "compile" ? "CompilationAlbum" : album.record_type === "dj_mix" ? "DJMixAlbum" : album.record_type === "demo" ? "DemoAlbum" : album.record_type === "live" ? "LiveAlbum" : album.record_type === "mixtape" ? "MixtapeAlbum" : album.record_type === "remix" ? "RemixAlbum" : album.record_type === "soundtrack" ? "SoundtrackAlbum" : "StudioAlbum"}`,
			"albumReleaseType": `https://schema.org/${album.record_type === "ep" ? "EPRelease" : album.record_type === "single" ? "SingleRelease" : "AlbumRelease"}`,
			"image": album.cover_large,
			"name": album.title,
			"url": `https://musicboard.app${album.url_slug}`,
			...album.release_date ? { "datePublished": album.release_date } : {},
			...(album.genres.length > 0 || album.styles.length > 0) ? { "genre": [...album.genres, ...album.styles].map(i => i.name).join(", ") } : {},
			"numTracks": album.tracks.length,
			...album.country && album.country !== "" ? { "countryOfOrigin": album.country } : {},
			...album.duration && album.duration !== "" ? { "duration": secondsToDuration(Number(album.duration)) } : {},
			...album.label && album.label !== "" ? {
				"recordLabel": {
					"@type": "Organization",
					"name": album.label
				}
			} : {},
			"isFamilyFriendly": album.explicit_lyrics ? false : true,
			...album.artist ? { "byArtist": {
				"@type": "MusicGroup",
				"identifier": album.artist.uid,
				"name": album.artist.name,
				"url": `https://musicboard.app${album.artist.url_slug}`,
				"image": album.artist.picture_large
			} } : {},
			...reviews && reviews.length > 0 ? { "review": {
				"@type": "Review",
				"identifier": reviews[0].uid,
				"itemReviewed": { "@type": "MusicAlbum", "name": album.title, "url": `https://musicboard.app${album.url_slug}` },
				"author": { 
					"@type": "Person", 
					"identifier": reviews[0].creator.uid,
					"name": reviews[0].creator.username,
					...reviews[0].creator.profile_picture_large ? { "image": reviews[0].creator.profile_picture_large } : {},
					...reviews[0].creator.name ? { "alternateName": reviews[0].creator.name } : {},
					...reviews[0].creator.biography !== "" ? { "description": reviews[0].creator.biography } : {},
					"url": `https://musicboard.app/${reviews[0].creator.username}/`,
				},
				"dateCreated": reviews[0].created_at,
				"headline": reviews[0].title !== "" && reviews[0].description !== "" ? reviews[0].title : `Review of ${album.title} by ${reviews[0].creator.username}`,
				"name": reviews[0].title !== "" && reviews[0].description !== "" ? reviews[0].title : `Review of ${album.title} by ${reviews[0].creator.username}`,
				"reviewBody": reviews[0].description !== "" ? reviews[0].description : reviews[0].title,
				...reviews[0].detected_language && reviews[0].detected_language !== "" ? { "inLanguage": reviews[0].detected_language } : {},
				...reviews[0].rating && reviews[0].rating.rating ? { "reviewRating": {
					"@type": "Rating",
					"bestRating": 5,
					"worstRating": 0.5,
					"ratingValue": reviews[0].rating.rating / 2
				} } : {},
			} } : {},
		},
		"ratingCount": album.ratings_count,
		"bestRating": 5,
		"worstRating": 0.5,
		"ratingValue": Number((album.average_rating / 2).toFixed(1))
	})
}

/* Artist */
/* ====================================================================================== */
export const createArtistMusicGroupSchema = (artist, reviews) => {
	const socials = [artist.website, artist.twitter, artist.facebook, artist.instagram].filter((i) => i && i !== "");

	return createGeneralSchema({
		"@context": "http://schema.org",
		"@type": "MusicGroup",
		"identifier": artist.uid,
		"name": artist.name,
		"url": `https://musicboard.app${artist.url_slug}`,
		"image": artist.picture_large,
		...(artist.average_rating && artist.average_rating > 0 && artist.ratings_count && artist.ratings_count > 0) ? { "aggregateRating": {
			"@type": "AggregateRating",
			"ratingCount": artist.ratings_count,
			"bestRating": 5,
			"worstRating": 0.5,
			"ratingValue": Number((artist.average_rating / 2).toFixed(1))
		} } : {},
		...artist.biography && artist.biography !== "" ? { "description": artist.biography } : {},
		...artist.birth_year && artist.birth_year !== "" ? { "foundingDate": artist.birth_year } : {},
		...!artist.is_active && artist.passing_year && artist.passing_year !== "" ? { "dissolutionDate": artist.passing_year } : {},
		...socials.length > 0 ? { "contactPoint": socials.map((i) => ({
			"@type": "ContactPoint",
			"url": i,
		})) } : {},
		...artist.country && artist.country !== "" ? { "foundingLocation": {
			"@type" : "Place",
			"name" : artist.country,
		}, } : {},
		...artist.country && artist.country !== "" ? { "location": {
			"@type" : "Place",
			"name" : artist.country,
		}, } : {},
		...reviews && reviews.length > 0 ? { "review": {
			"@type": "Review",
			"identifier": reviews[0].uid,
			"itemReviewed": { "@type": "MusicGroup", "name": artist.name, "url": `https://musicboard.app${artist.url_slug}` },
			"author": { 
				"@type": "Person", 
				"identifier": reviews[0].creator.uid,
				"name": reviews[0].creator.username,
				...reviews[0].creator.profile_picture_large ? { "image": reviews[0].creator.profile_picture_large } : {},
				...reviews[0].creator.name ? { "alternateName": reviews[0].creator.name } : {},
				...reviews[0].creator.biography !== "" ? { "description": reviews[0].creator.biography } : {},
				"url": `https://musicboard.app/${reviews[0].creator.username}/`,
			},
			"dateCreated": reviews[0].created_at,
			"headline": reviews[0].title !== "" && reviews[0].description !== "" ? reviews[0].title : `Review of ${artist.name} by ${reviews[0].creator.username}`,
			"name": reviews[0].title !== "" && reviews[0].description !== "" ? reviews[0].title : `Review of ${artist.name} by ${reviews[0].creator.username}`,
			"reviewBody": reviews[0].description !== "" ? reviews[0].description : reviews[0].title,
			...reviews[0].detected_language && reviews[0].detected_language !== "" ? { "inLanguage": reviews[0].detected_language } : {},
			...reviews[0].rating && reviews[0].rating.rating ? { "reviewRating": {
				"@type": "Rating",
				"bestRating": 5,
				"worstRating": 0.5,
				"ratingValue": reviews[0].rating.rating / 2
			} } : {},
		} } : {},
		// genre
		// album: [],
		// track: [],
	})
}

export const createArtistAggregateRatingSchema = (artist, reviews) => {
	const socials = [artist.website, artist.twitter, artist.facebook, artist.instagram].filter((i) => i && i !== "");

	return createGeneralSchema({
		"@context": "https://schema.org/",
		"@type": "AggregateRating",
		"itemReviewed": {
			"@type": "MusicGroup",
			"identifier": artist.uid,
			"image": artist.picture_large,
			"name": artist.name,
			"url": `https://musicboard.app${artist.url_slug}`,
			...artist.biography && artist.biography !== "" ? { "description": artist.biography } : {},
			...artist.birth_year && artist.birth_year !== "" ? { "foundingDate": artist.birth_year } : {},
			...!artist.is_active && artist.passing_year && artist.passing_year !== "" ? { "dissolutionDate": artist.passing_year } : {},
			...socials.length > 0 ? { "contactPoint": socials.map((i) => ({
				"@type": "ContactPoint",
				"url": i,
			})) } : {},
			...artist.country && artist.country !== "" ? { "foundingLocation": {
				"@type" : "Place",
				"name" : artist.country,
			}, } : {},
			...artist.country && artist.country !== "" ? { "location": {
				"@type" : "Place",
				"name" : artist.country,
			}, } : {},
			...reviews && reviews.length > 0 ? { "review": {
				"@type": "Review",
				"identifier": reviews[0].uid,
				"itemReviewed": { "@type": "MusicGroup", "name": artist.name, "url": `https://musicboard.app${artist.url_slug}` },
				"author": { 
					"@type": "Person", 
					"identifier": reviews[0].creator.uid,
					"name": reviews[0].creator.username,
					...reviews[0].creator.profile_picture_large ? { "image": reviews[0].creator.profile_picture_large } : {},
					...reviews[0].creator.name ? { "alternateName": reviews[0].creator.name } : {},
					...reviews[0].creator.biography !== "" ? { "description": reviews[0].creator.biography } : {},
					"url": `https://musicboard.app/${reviews[0].creator.username}/`,
				},
				"dateCreated": reviews[0].created_at,
				"headline": reviews[0].title !== "" && reviews[0].description !== "" ? reviews[0].title : `Review of ${artist.name} by ${reviews[0].creator.username}`,
				"name": reviews[0].title !== "" && reviews[0].description !== "" ? reviews[0].title : `Review of ${artist.name} by ${reviews[0].creator.username}`,
				"reviewBody": reviews[0].description !== "" ? reviews[0].description : reviews[0].title,
				...reviews[0].detected_language && reviews[0].detected_language !== "" ? { "inLanguage": reviews[0].detected_language } : {},
				...reviews[0].rating && reviews[0].rating.rating ? { "reviewRating": {
					"@type": "Rating",
					"bestRating": 5,
					"worstRating": 0.5,
					"ratingValue": reviews[0].rating.rating / 2
				} } : {},
			} } : {},
			// genre
			// album: [],
			// track: [],
		},
		"ratingCount": artist.ratings_count,
		"bestRating": 5,
		"worstRating": 0.5,
		"ratingValue": Number((artist.average_rating / 2).toFixed(1))
	})
}

/* Track */
/* ====================================================================================== */

export const createTrackMusicRecordingSchema = (track, reviews) => {
	return createGeneralSchema({
		"@context": "http://schema.org",
		"@type": "MusicRecording",
		"identifier": track.uid,
		"name": track.title,
		"url": `https://musicboard.app${track.url_slug}`,
		"image": track.album.cover_large,
		...(track.average_rating && track.average_rating > 0 && track.ratings_count && track.ratings_count > 0) ? { "aggregateRating": {
			"@type": "AggregateRating",
			"ratingCount": track.ratings_count,
			"bestRating": 5,
			"worstRating": 0.5,
			"ratingValue": Number((track.average_rating / 2).toFixed(1))
		} } : {},
		...track.artist ? { "byArtist": {
			"@type": "MusicGroup",
			"identifier": track.artist.uid,
			"name": track.artist.name,
			"url": `https://musicboard.app${track.artist.url_slug}`,
			"image": track.artist.picture_large,
			//...(track.artist.average_rating && track.artist.average_rating > 0 && track.artist.ratings_count && track.artist.ratings_count > 0) ? { "aggregateRating": {
			//	"@type": "AggregateRating",
			//	"ratingCount": track.artist.ratings_count,
			//	"bestRating": 5,
			//	"worstRating": 0.5,
			//	"ratingValue": Number((track.artist.average_rating / 2).toFixed(1))
			//} } : {},
		} } : {},
		"inAlbum": {
			"@type": "MusicAlbum",
			"identifier": track.album.uid,
			"albumProductionType": `https://schema.org/${track.album.record_type === "compile" ? "CompilationAlbum" : track.album.record_type === "dj_mix" ? "DJMixAlbum" : track.album.record_type === "demo" ? "DemoAlbum" : track.album.record_type === "live" ? "LiveAlbum" : track.album.record_type === "mixtape" ? "MixtapeAlbum" : track.album.record_type === "remix" ? "RemixAlbum" : track.album.record_type === "soundtrack" ? "SoundtrackAlbum" : "StudioAlbum"}`,
			"albumReleaseType": `https://schema.org/${track.album.record_type === "ep" ? "EPRelease" : track.album.record_type === "single" ? "SingleRelease" : "AlbumRelease"}`,
			"image": track.album.cover_large,
			"name": track.album.title,
			"url": `https://musicboard.app${track.album.url_slug}`,
			...track.album.release_date ? { "datePublished": track.album.release_date } : {},
			...track.album.country && track.album.country !== "" ? { "countryOfOrigin": track.album.country } : {},
			...track.album.duration && track.album.duration !== "" ? { "duration": secondsToDuration(Number(track.album.duration)) } : {},
			"isFamilyFriendly": track.album.explicit_lyrics ? false : true,
			...track.artist ? { "byArtist": {
				"@type": "MusicGroup",
				"identifier": track.artist.uid,
				"name": track.artist.name,
				"url": `https://musicboard.app${track.artist.url_slug}`,
				"image": track.artist.picture_large,
			} } : {},
			//...(track.album.average_rating && track.album.average_rating > 0 && track.album.ratings_count && track.album.ratings_count > 0) ? { "aggregateRating": {
			//	"@type": "AggregateRating",
			//	"ratingCount": track.album.ratings_count,
			//	"bestRating": 5,
			//	"worstRating": 0.5,
			//	"ratingValue": Number((track.album.average_rating / 2).toFixed(1))
			//} } : {},
		},
		...track.duration && track.duration !== "" ? { "duration": secondsToDuration(Number(track.duration)) } : {},
		...track.album.country && track.album.country !== "" ? { "countryOfOrigin": track.album.country } : {},
		...track.release_date ? { "datePublished": track.release_date } : {},
		"isFamilyFriendly": track.explicit_lyrics ? false : true,
		"position": track.track_position,
		...reviews && reviews.length > 0 ? { "review": {
			"@type": "Review",
			"identifier": reviews[0].uid,
			"itemReviewed": { "@type": "MusicRecording", "name": track.title, "url": `https://musicboard.app${track.url_slug}` },
			"author": { 
				"@type": "Person", 
				"identifier": reviews[0].creator.uid,
				"name": reviews[0].creator.username,
				...reviews[0].creator.profile_picture_large ? { "image": reviews[0].creator.profile_picture_large } : {},
				...reviews[0].creator.name ? { "alternateName": reviews[0].creator.name } : {},
				...reviews[0].creator.biography !== "" ? { "description": reviews[0].creator.biography } : {},
				"url": `https://musicboard.app/${reviews[0].creator.username}/`,
			},
			"dateCreated": reviews[0].created_at,
			"headline": reviews[0].title !== "" && reviews[0].description !== "" ? reviews[0].title : `Review of ${track.title} by ${reviews[0].creator.username}`,
			"name": reviews[0].title !== "" && reviews[0].description !== "" ? reviews[0].title : `Review of ${track.title} by ${reviews[0].creator.username}`,
			"reviewBody": reviews[0].description !== "" ? reviews[0].description : reviews[0].title,
			...reviews[0].detected_language && reviews[0].detected_language !== "" ? { "inLanguage": reviews[0].detected_language } : {},
			...reviews[0].rating && reviews[0].rating.rating ? { "reviewRating": {
				"@type": "Rating",
				"bestRating": 5,
				"worstRating": 0.5,
				"ratingValue": reviews[0].rating.rating / 2
			} } : {},
		} } : {},
	})
}

export const createTrackAggregateRatingSchema = (track, reviews) => {
	return createGeneralSchema({
		"@context": "https://schema.org/",
		"@type": "AggregateRating",
		"itemReviewed": {
			"@type": "MusicRecording",
			"identifier": track.uid,
			"name": track.title,
			"url": `https://musicboard.app${track.url_slug}`,
			"image": track.album.cover_large,
			...track.artist ? { "byArtist": {
				"@type": "MusicGroup",
				"identifier": track.artist.uid,
				"name": track.artist.name,
				"url": `https://musicboard.app${track.artist.url_slug}`,
				"image": track.artist.picture_large,
			} } : {},
			"inAlbum": {
				"@type": "MusicAlbum",
				"identifier": track.album.uid,
				"albumProductionType": `https://schema.org/${track.album.record_type === "compile" ? "CompilationAlbum" : track.album.record_type === "dj_mix" ? "DJMixAlbum" : track.album.record_type === "demo" ? "DemoAlbum" : track.album.record_type === "live" ? "LiveAlbum" : track.album.record_type === "mixtape" ? "MixtapeAlbum" : track.album.record_type === "remix" ? "RemixAlbum" : track.album.record_type === "soundtrack" ? "SoundtrackAlbum" : "StudioAlbum"}`,
				"albumReleaseType": `https://schema.org/${track.album.record_type === "ep" ? "EPRelease" : track.album.record_type === "single" ? "SingleRelease" : "AlbumRelease"}`,
				"image": track.album.cover_large,
				"name": track.album.title,
				"url": `https://musicboard.app${track.album.url_slug}`,
				...track.album.release_date ? { "datePublished": track.album.release_date } : {},
				...track.album.country && track.album.country !== "" ? { "countryOfOrigin": track.album.country } : {},
				...track.album.duration && track.album.duration !== "" ? { "duration": secondsToDuration(Number(track.album.duration)) } : {},
				"isFamilyFriendly": track.album.explicit_lyrics ? false : true,
				...track.artist ? { "byArtist": {
					"@type": "MusicGroup",
					"identifier": track.artist.uid,
					"name": track.artist.name,
					"url": `https://musicboard.app${track.artist.url_slug}`,
					"image": track.artist.picture_large,
				} } : {},
			},
			...track.duration && track.duration !== "" ? { "duration": secondsToDuration(Number(track.duration)) } : {},
			...track.album.country && track.album.country !== "" ? { "countryOfOrigin": track.album.country } : {},
			...track.release_date ? { "datePublished": track.release_date } : {},
			"isFamilyFriendly": track.explicit_lyrics ? false : true,
			"position": track.track_position,
			...reviews && reviews.length > 0 ? { "review": {
				"@type": "Review",
				"identifier": reviews[0].uid,
				"itemReviewed": { "@type": "MusicRecording", "name": track.title, "url": `https://musicboard.app${track.url_slug}` },
				"author": { 
					"@type": "Person", 
					"identifier": reviews[0].creator.uid,
					"name": reviews[0].creator.username,
					...reviews[0].creator.profile_picture_large ? { "image": reviews[0].creator.profile_picture_large } : {},
					...reviews[0].creator.name ? { "alternateName": reviews[0].creator.name } : {},
					...reviews[0].creator.biography !== "" ? { "description": reviews[0].creator.biography } : {},
					"url": `https://musicboard.app/${reviews[0].creator.username}/`,
				},
				"dateCreated": reviews[0].created_at,
				"headline": reviews[0].title !== "" && reviews[0].description !== "" ? reviews[0].title : `Review of ${track.title} by ${reviews[0].creator.username}`,
				"name": reviews[0].title !== "" && reviews[0].description !== "" ? reviews[0].title : `Review of ${track.title} by ${reviews[0].creator.username}`,
				"reviewBody": reviews[0].description !== "" ? reviews[0].description : reviews[0].title,
				...reviews[0].detected_language && reviews[0].detected_language !== "" ? { "inLanguage": reviews[0].detected_language } : {},
				...reviews[0].rating && reviews[0].rating.rating ? { "reviewRating": {
					"@type": "Rating",
					"bestRating": 5,
					"worstRating": 0.5,
					"ratingValue": reviews[0].rating.rating / 2
				} } : {},
			} } : {},
		},
		"ratingCount": track.ratings_count,
		"bestRating": 5,
		"worstRating": 0.5,
		"ratingValue": Number((track.average_rating / 2).toFixed(1))
	})
}

/* News */
/* ====================================================================================== */
export function createNewsArticleSchema(article) {
	return createGeneralSchema({
		"@context": "https://schema.org",
		"@type": "BlogPosting",
		"identifier": article.uid,
		"image": article.picture,
		"name": article.title,
		"headline": article.title,
		"description": article.summary,
		"articleBody": article.summary,
		"author": {
			"@type": "Person",
			"identifier": article.creator.uid,
			"name": article.creator.username,
			"url": `https://musicboard.app/${article.creator.username}/`,
			...article.creator.name !== "" ? { "alternateName": article.creator.name } : {},
			...article.creator.profile_picture_large ? { "image": article.creator.profile_picture_large } : {},
			...article.creator.biography !== "" ? { "description": article.creator.biography } : {},
		},
		"datePublished": article.published_at,
	})
}

/* List */
/* ====================================================================================== */
export function createListItemAlbumSchema(album) {
	return {
		"@type": "MusicAlbum",
		"identifier": album.uid,
		"albumProductionType": `https://schema.org/${album.record_type === "compile" ? "CompilationAlbum" : album.record_type === "dj_mix" ? "DJMixAlbum" : album.record_type === "demo" ? "DemoAlbum" : album.record_type === "live" ? "LiveAlbum" : album.record_type === "mixtape" ? "MixtapeAlbum" : album.record_type === "remix" ? "RemixAlbum" : album.record_type === "soundtrack" ? "SoundtrackAlbum" : "StudioAlbum"}`,
		"albumReleaseType": `https://schema.org/${album.record_type === "ep" ? "EPRelease" : album.record_type === "single" ? "SingleRelease" : "AlbumRelease"}`,
		"image": album.cover_large,
		"name": album.title,
		"url": `https://musicboard.app${album.url_slug}`,
		...album.release_date ? { "datePublished": album.release_date } : {},
		...album.duration && album.duration !== "" ? { "duration": secondsToDuration(Number(album.duration)) } : {},
		"isFamilyFriendly": album.explicit_lyrics ? false : true,
		...album.artist ? { "byArtist": {
			"@type": "MusicGroup",
			"identifier": album.artist.uid,
			"name": album.artist.name,
			"url": `https://musicboard.app${album.artist.url_slug}`,
			"image": album.artist.picture_large
		} } : {},
		//...(album.average_rating && album.average_rating > 0 && album.ratings_count && album.ratings_count > 0) ? { "aggregateRating": {
		//	"@type": "AggregateRating",
		//	"ratingCount": album.ratings_count,
		//	"bestRating": 5,
		//	"worstRating": 0.5,
		//	"ratingValue": Number((album.average_rating / 2).toFixed(1))
		//} } : {},
	}
}

export function createListItemArtistSchema(artist) {
	return {
		"@type": "MusicGroup",
		"identifier": artist.uid,
		"image": artist.picture_large,
		"name": artist.name,
		"url": `https://musicboard.app${artist.url_slug}`,
		//...(artist.average_rating && artist.average_rating > 0 && artist.ratings_count && artist.ratings_count > 0) ? { "aggregateRating": {
		//	"@type": "AggregateRating",
		//	"ratingCount": artist.ratings_count,
		//	"bestRating": 5,
		//	"worstRating": 0.5,
		//	"ratingValue": Number((artist.average_rating / 2).toFixed(1))
		//} } : {},
	}
}

export function createListItemTrackSchema(track) {
	return {
		"@type": "MusicRecording",
		"identifier": track.uid,
		"image": track.album.cover_large,
		"name": track.title,
		"url": `https://musicboard.app${track.url_slug}`,
		...track.artist ? { "byArtist": {
			"@type": "MusicGroup",
			"identifier": track.artist.uid,
			"name": track.artist.name,
			"url": `https://musicboard.app${track.artist.url_slug}`,
			"image": track.artist.picture_large,
		} } : {},
		...track.album ? { "inAlbum": {
			"@type": "MusicAlbum",
			"identifier": track.album.uid,
			"albumProductionType": `https://schema.org/${track.album.record_type === "compile" ? "CompilationAlbum" : track.album.record_type === "dj_mix" ? "DJMixAlbum" : track.album.record_type === "demo" ? "DemoAlbum" : track.album.record_type === "live" ? "LiveAlbum" : track.album.record_type === "mixtape" ? "MixtapeAlbum" : track.album.record_type === "remix" ? "RemixAlbum" : track.album.record_type === "soundtrack" ? "SoundtrackAlbum" : "StudioAlbum"}`,
			"albumReleaseType": `https://schema.org/${track.album.record_type === "ep" ? "EPRelease" : track.album.record_type === "single" ? "SingleRelease" : "AlbumRelease"}`,
			"image": track.album.cover_large,
			"name": track.album.title,
			"url": `https://musicboard.app${track.album.url_slug}`,
			...track.album.release_date ? { "datePublished": track.album.release_date } : {},
			...track.album.duration && track.album.duration !== "" ? { "duration": secondsToDuration(Number(track.album.duration)) } : {},
			"isFamilyFriendly": track.album.explicit_lyrics ? false : true,
			...track.artist ? { "byArtist": {
				"@type": "MusicGroup",
				"identifier": track.artist.uid,
				"name": track.artist.name,
				"url": `https://musicboard.app${track.artist.url_slug}`,
				"image": track.artist.picture_large,
			} } : {},
		} } : {},
		...track.duration && track.duration !== "" ? { "duration": secondsToDuration(Number(track.duration)) } : {},
		...track.album.release_date ? { "datePublished": track.album.release_date } : {},
		"isFamilyFriendly": track.explicit_lyrics ? false : true,
	}
}

export const createListSchema = (list) => {
	return createGeneralSchema({
		"@context": "https://schema.org",
		"@type": "ItemList",
		"identifier": list.uid,
		"name": list.title,
		"url": `https://musicboard.app${list.url_slug}`,
		...list.description !== "" ? { "description": list.description } : {},
		"numberOfItems": list.list_connections.count,
		"itemListElement": list.list_connections.results.map((connection) => ({
			"@type": "ListItem",
			"position": connection.priority+1,
			...["artist", "album", "track"].includes(connection.content.type) ? {
				"item": 
					connection.content.type === "artist" ? 
						createListItemArtistSchema(connection.content) : 
					connection.content.type === "album" ? 
						createListItemAlbumSchema(connection.content) 
					: 
						createListItemTrackSchema(connection.content)
			} : {}
		}))
	})
}

export function createListDiscussionForumSchema(list, comments) {
	return createGeneralSchema({
		"@context": "https://schema.org",
		"@type": "DiscussionForumPosting",
		"mainEntityOfPage": `https://musicboard.app${list.url_slug}`,
		"headline": list.title,
		...list.description !== "" ? { "text": list.description } : {},
		...(list.background || list.list_connections.results[0]) ? { "image": 
			list.background ? 
				list.background.background_original?.includes(".gif") ? list.background.background_original : list.background.background_large 
			: list.list_connections.results[0]?.content?.type ? 
				list.list_connections.results[0].content.type === 'artist' ? 
					list.list_connections.results[0].content.picture_large || list.list_connections.results[0].content.picture
				: list.list_connections.results[0].content.type === 'album' ? 
					list.list_connections.results[0].content.cover_large || list.list_connections.results[0].content.cover
				: 
					list.list_connections.results[0].content.album.cover_large || list.list_connections.results[0].content.album.cover
			: 
				null
		} : {},
		"url": `https://musicboard.app${list.url_slug}`,
		"author": {
			"@type": "Person",
			"name": list.creator.username,
			...list.creator.name !== "" ? { "alternateName": list.creator.name } : {},
			...list.creator.profile_picture_large ? { "image": list.creator.profile_picture_large } : {},
			...list.creator.biography !== "" ? { "description": list.creator.biography } : {},
			"url": `https://musicboard.app/${list.creator.username}/`
		},
		"datePublished": list.created_at,
		"interactionStatistic": {
			"@type": "InteractionCounter",
			"interactionType": "https://schema.org/LikeAction",
			"userInteractionCount": list.like_count || 0
		},
		...comments && comments.results ? { 
			"commentCount": comments.count,
			"comment": comments.results.map((comment) => ({
				"@type": "Comment",
				"text": comment.comment,
				"author": {
					"@type": "Person",
					"name": comment.creator.username,
					...comment.creator.name !== "" ? { "alternateName": comment.creator.name } : {},
					...comment.creator.profile_picture_large ? { "image": comment.creator.profile_picture_large } : {},
					...comment.creator.biography !== "" ? { "description": comment.creator.biography } : {},
					"url": `https://musicboard.app/${comment.creator.username}/`
				},
				"datePublished": comment.created_at,
				"interactionStatistic": {
					"@type": "InteractionCounter",
					"interactionType": "https://schema.org/LikeAction",
					"userInteractionCount": comment.like_count || 0
				},
				...comment.replies && comment.replies.results ? { 
					"commentCount": comment.replies.count,
					"comment": comment.replies.results.map((reply) => ({
						"@type": "Comment",
						"text": reply.comment,
						"author": {
							"@type": "Person",
							"name": reply.creator.username,
							...reply.creator.name !== "" ? { "alternateName": reply.creator.name } : {},
							...reply.creator.profile_picture_large ? { "image": reply.creator.profile_picture_large } : {},
							...reply.creator.biography !== "" ? { "description": reply.creator.biography } : {},
							"url": `https://musicboard.app/${reply.creator.username}/`
						},
						"datePublished": reply.created_at,
						"interactionStatistic": {
							"@type": "InteractionCounter",
							"interactionType": "https://schema.org/LikeAction",
							"userInteractionCount": reply.like_count || 0
						}
					}))
				} : {},
			}))
		} : {},
	})
}

/* Rating */
/* ====================================================================================== */
export function createItemReviewedAlbumSchema(album) {
	return ({
		"@type": "MusicAlbum",
		"identifier": album.uid,
		"albumProductionType": `https://schema.org/${album.record_type === "compile" ? "CompilationAlbum" : album.record_type === "dj_mix" ? "DJMixAlbum" : album.record_type === "demo" ? "DemoAlbum" : album.record_type === "live" ? "LiveAlbum" : album.record_type === "mixtape" ? "MixtapeAlbum" : album.record_type === "remix" ? "RemixAlbum" : album.record_type === "soundtrack" ? "SoundtrackAlbum" : "StudioAlbum"}`,
		"albumReleaseType": `https://schema.org/${album.record_type === "ep" ? "EPRelease" : album.record_type === "single" ? "SingleRelease" : "AlbumRelease"}`,
		"image": album.cover_large,
		"name": album.title,
		"url": `https://musicboard.app${album.url_slug}`,
		...album.artist ? { "byArtist": {
			"@type": "MusicGroup",
			"identifier": album.artist.uid,
			"image": album.artist.picture_large,
			"name": album.artist.name,
			"url": `https://musicboard.app${album.artist.url_slug}`,
		} } : {},
	})
}

export function createItemReviewedArtistSchema(artist) {
	return ({
		"@type": "MusicGroup",
		"identifier": artist.uid,
		"image": artist.picture_large,
		"name": artist.name,
		"url": `https://musicboard.app${artist.url_slug}`,
	})
}

export function createItemReviewedTrackSchema(track) {
	return ({
		"@type": "MusicRecording",
		"identifier": track.uid,
		"image": track.album.cover_large,
		"name": track.title,
		"url": `https://musicboard.app${track.url_slug}`,
		...track.album ? { "inAlbum": {
			"@type": "MusicAlbum",
			"albumProductionType": `https://schema.org/${track.album.record_type === "compile" ? "CompilationAlbum" : track.album.record_type === "dj_mix" ? "DJMixAlbum" : track.album.record_type === "demo" ? "DemoAlbum" : track.album.record_type === "live" ? "LiveAlbum" : track.album.record_type === "mixtape" ? "MixtapeAlbum" : track.album.record_type === "remix" ? "RemixAlbum" : track.album.record_type === "soundtrack" ? "SoundtrackAlbum" : "StudioAlbum"}`,
			"albumReleaseType": `https://schema.org/${track.album.record_type === "ep" ? "EPRelease" : track.album.record_type === "single" ? "SingleRelease" : "AlbumRelease"}`,
			"identifier": track.album.uid,
			"image": track.album.cover_large,
			"name": track.album.title,
			"url": `https://musicboard.app${track.album.url_slug}`,
			...track.album.artist ? { "byArtist": {
				"@type": "MusicGroup",
				"identifier": track.album.artist.uid,
				"image": track.album.artist.picture_large,
				"name": track.album.artist.name,
				"url": `https://musicboard.app${track.album.artist.url_slug}`,
			} } : {},
		} } : {},
		...track.artist ? { "byArtist": {
			"@type": "MusicGroup",
			"identifier": track.artist.uid,
			"image": track.artist.picture_large,
			"name": track.artist.name,
			"url": `https://musicboard.app${track.artist.url_slug}`,
		} } : {},
	})
}

export function createRatingSchema(rating) {
	const simpleContentTitle =
			(rating.content.type === "artist" || rating.content.type === "suggested_artist") ? 
				rating.content.name 
			: 
				rating.content.title

	return createGeneralSchema({
		"@context": "http://schema.org",
		"@type": "Review",
		"identifier": rating.uid,
		"url": `https://musicboard.app/rating/${rating.uid}/`,
		"name": `Rating of ${simpleContentTitle} by ${rating.creator.username}`,
		...(rating.rating && rating.rating > 0) ? { "reviewRating": {
			"@type": "Rating",
			"bestRating": 5,
			"worstRating": 0.5,
			"ratingValue": rating.rating / 2
		} } : {},
		"author": {
			"@type": "Person",
			"identifier": rating.creator.uid,
			"name": rating.creator.username,
			...rating.creator.name !== "" ? { "alternateName": rating.creator.name } : {},
			...rating.creator.profile_picture_large ? { "image": rating.creator.profile_picture_large } : {},
			...rating.creator.biography !== "" ? { "description": rating.creator.biography } : {},
			"url": `https://musicboard.app/${rating.creator.username}/`,
		},
		"datePublished": rating.created_at,
		...(["album", "artist", "track"].includes(rating.content.type)) ? { "itemReviewed": 
			rating.content.type === "artist" ? 
				createItemReviewedArtistSchema(rating.content) 
			: rating.content.type === "album" ? 
				createItemReviewedAlbumSchema(rating.content) 
			: 
				createItemReviewedTrackSchema(rating.content),
		} : {},
	})
}

/* Review */
/* ====================================================================================== */
export function createReviewSchema(review) {
	const simpleContentTitle =
			(review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") ? 
				review.rating.content.name 
			: 
				review.rating.content.title

	return createGeneralSchema({
		"@context": "http://schema.org",
		"@type": "Review",
		"identifier": review.uid,
		"url": `https://musicboard.app${review.url_slug}`,
		...(["album", "artist", "track"].includes(review.rating.content.type)) ? { "itemReviewed": 
			review.rating.content.type === "artist" ? 
				createItemReviewedArtistSchema(review.rating.content) 
			: review.rating.content.type === "album" ? 
				createItemReviewedAlbumSchema(review.rating.content) 
			: 
				createItemReviewedTrackSchema(review.rating.content),
		} : {},
		"author": {
			"@type": "Person",
			"identifier": review.creator.uid,
			"name": review.creator.username,
			...review.creator.name !== "" ? { "alternateName": review.creator.name } : {},
			...review.creator.profile_picture_large ? { "image": review.creator.profile_picture_large } : {},
			...review.creator.biography !== "" ? { "description": review.creator.biography } : {},
			"url": `https://musicboard.app/${review.creator.username}/`,
		},
		"datePublished": review.created_at,
		"headline": review.title !== "" && review.description !== "" ? review.title : `Review of ${simpleContentTitle} by ${review.creator.username}`,
		"name": review.title !== "" && review.description !== "" ? review.title : `Review of ${simpleContentTitle} by ${review.creator.username}`,
		"reviewBody": review.description !== "" ? review.description : review.title,
		...review.detected_language && review.detected_language !== "" ? { "inLanguage": review.detected_language } : {},
		...(review.rating.rating && review.rating.rating > 0) ? { "reviewRating": {
			"@type": "Rating",
			"bestRating": 5,
			"worstRating": 0.5,
			"ratingValue": review.rating.rating / 2
		} } : {},
	})
}

export function createReviewDiscussionForumSchema(review, comments) {
	const simpleContentTitle =
			(review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") ? 
				review.rating.content.name
			:
				`${review.rating.content.title} by ${review.rating.content.artist.name}`
	
	const contentImage = 
		review.rating.content.type === "artist" ? 
			review.rating.content.picture_large || review.rating.content.picture
		: review.rating.content.type === "album" ? 
			review.rating.content.cover_large || review.rating.content.cover
		: review.rating.content.type === "track" ? 
			review.rating.content.album.cover_large || review.rating.content.album.cover
		: review.rating.content.type === "suggested_artist" ?
			review.rating.content.picture ?
				review.rating.content.picture
			:
				review.rating.content.picture_url
		: review.rating.content.type === "suggested_album" ?
			review.rating.content.cover ?
				review.rating.content.cover
			:
				review.rating.content.cover_url
		: review.rating.content.type === "suggested_track" ?
			review.rating.content.album ? 
				review.rating.content.album.cover ?
					review.rating.content.album.cover
				:
					review.rating.content.album.cover_url
			:
				review.rating.content.suggested_album.cover ?
					review.rating.content.suggested_album.cover
				:
					review.rating.content.suggested_album.cover_url
		: null
	
	return createGeneralSchema({
		"@context": "https://schema.org",
		"@type": "DiscussionForumPosting",
		"mainEntityOfPage": `https://musicboard.app${review.url_slug}`,
		"headline": review.title !== "" && review.description !== "" ? review.title : `Review of ${simpleContentTitle} by ${review.creator.username}`,
		"text": review.description !== "" ? review.description : review.title,
		"url": `https://musicboard.app${review.url_slug}`,
		...contentImage ? { "image": contentImage } : {},
		"author": {
			"@type": "Person",
			"name": review.creator.username,
			...review.creator.name !== "" ? { "alternateName": review.creator.name } : {},
			...review.creator.profile_picture_large ? { "image": review.creator.profile_picture_large } : {},
			...review.creator.biography !== "" ? { "description": review.creator.biography } : {},
			"url": `https://musicboard.app/${review.creator.username}/`
		},
		"datePublished": review.created_at,
		"interactionStatistic": {
			"@type": "InteractionCounter",
			"interactionType": "https://schema.org/LikeAction",
			"userInteractionCount": review.like_count || 0
		},
		...comments && comments.results ? { 
			"commentCount": comments.count,
			"comment": comments.results.map((comment) => ({
				"@type": "Comment",
				"text": comment.comment,
				"author": {
					"@type": "Person",
					"name": comment.creator.username,
					...comment.creator.name !== "" ? { "alternateName": comment.creator.name } : {},
					...comment.creator.profile_picture_large ? { "image": comment.creator.profile_picture_large } : {},
					...comment.creator.biography !== "" ? { "description": comment.creator.biography } : {},
					"url": `https://musicboard.app/${comment.creator.username}/`
				},
				"datePublished": comment.created_at,
				"interactionStatistic": {
					"@type": "InteractionCounter",
					"interactionType": "https://schema.org/LikeAction",
					"userInteractionCount": comment.like_count || 0
				},
				...comment.replies && comment.replies.results ? { 
					"commentCount": comment.replies.count,
					"comment": comment.replies.results.map((reply) => ({
						"@type": "Comment",
						"text": reply.comment,
						"author": {
							"@type": "Person",
							"name": reply.creator.username,
							...reply.creator.name !== "" ? { "alternateName": reply.creator.name } : {},
							...reply.creator.profile_picture_large ? { "image": reply.creator.profile_picture_large } : {},
							...reply.creator.biography !== "" ? { "description": reply.creator.biography } : {},
							"url": `https://musicboard.app/${reply.creator.username}/`
						},
						"datePublished": reply.created_at,
						"interactionStatistic": {
							"@type": "InteractionCounter",
							"interactionType": "https://schema.org/LikeAction",
							"userInteractionCount": reply.like_count || 0
						}
					}))
				} : {},
			}))
		} : {},
	})
}

/* Thread */
/* ====================================================================================== */
export function createThreadDiscussionForumSchema(thread, comments) {
	return createGeneralSchema({
		"@context": "https://schema.org",
		"@type": "DiscussionForumPosting",
		"mainEntityOfPage": `https://musicboard.app${thread.url_slug}`,
		"headline": thread.question,
		...thread.description !== "" ? { "text": thread.description } : {},
		"url": `https://musicboard.app${thread.url_slug}`,
		"author": {
			"@type": "Person",
			"name": thread.creator.username,
			...thread.creator.name !== "" ? { "alternateName": thread.creator.name } : {},
			...thread.creator.profile_picture_large ? { "image": thread.creator.profile_picture_large } : {},
			...thread.creator.biography !== "" ? { "description": thread.creator.biography } : {},
			"url": `https://musicboard.app/${thread.creator.username}/`
		},
		"datePublished": thread.created_at,
		"interactionStatistic": {
			"@type": "InteractionCounter",
			"interactionType": "https://schema.org/LikeAction",
			"userInteractionCount": thread.like_count || 0
		},
		...comments && comments.results ? { 
			"commentCount": comments.count,
			"comment": comments.results.map((comment) => ({
				"@type": "Comment",
				"text": comment.comment,
				"author": {
					"@type": "Person",
					"name": comment.creator.username,
					...comment.creator.name !== "" ? { "alternateName": comment.creator.name } : {},
					...comment.creator.profile_picture_large ? { "image": comment.creator.profile_picture_large } : {},
					...comment.creator.biography !== "" ? { "description": comment.creator.biography } : {},
					"url": `https://musicboard.app/${comment.creator.username}/`
				},
				"datePublished": comment.created_at,
				"interactionStatistic": {
					"@type": "InteractionCounter",
					"interactionType": "https://schema.org/LikeAction",
					"userInteractionCount": comment.like_count || 0
				},
				...comment.replies && comment.replies.results ? { 
					"commentCount": comment.replies.count,
					"comment": comment.replies.results.map((reply) => ({
						"@type": "Comment",
						"text": reply.comment,
						"author": {
							"@type": "Person",
							"name": reply.creator.username,
							...reply.creator.name !== "" ? { "alternateName": reply.creator.name } : {},
							...reply.creator.profile_picture_large ? { "image": reply.creator.profile_picture_large } : {},
							...reply.creator.biography !== "" ? { "description": reply.creator.biography } : {},
							"url": `https://musicboard.app/${reply.creator.username}/`
						},
						"datePublished": reply.created_at,
						"interactionStatistic": {
							"@type": "InteractionCounter",
							"interactionType": "https://schema.org/LikeAction",
							"userInteractionCount": reply.like_count || 0
						}
					}))
				} : {},
			}))
		} : {},
	})
}

/* General */
/* ====================================================================================== */
// Searchbox schema.org for home page
export function createSearchboxSchema(schemaKey) {
	return createGeneralSchema({
		"@context": "https://schema.org",
		"@type": "WebSite",
		"url": "https://www.musicboard.app",
		"potentialAction": {
			"@type": "SearchAction",
			"target": {
				"@type": "EntryPoint",
				"urlTemplate": "https://musicboard.app/search/{search_term_string}/album"
			},
			"query-input": "required name=search_term_string"
		}
	})
}

// Organization schema.org for home page
export function createOrganizationSchema(schemaKey) {
	return createGeneralSchema({
		"@context": "https://schema.org",
		"@type": "Organization",
		"image": "https://cdn.musicboard.app/musicboard/media/musicboard-social-icon-round-spaced.png",
		"url": "https://musicboard.app",
		"sameAs": ["https://musicboard.app/musicboard"],
		"logo": "https://cdn.musicboard.app/musicboard/media/musicboard-social-icon-round-spaced.png",
		"name": "Musicboard",
		"description": "Musicboard is the world's #1 source for music reviews, ratings, lists and information.",
		"email": "team@musicboard.app"
	})
}

// FAQ schema.org for /faq page
export function createFAQSchema(schemaKey) {
	const questions = [
		{
			q: "What is Musicboard?",
			a: "As also mentioned in our About page, Musicboard is a social platform where people from all around the world can connect through their shared love for music. Users can rate and review the worlds music, sharing their opinions with friends and followers. Users can also create lists to collect and organize music, such as a list of your favorite albums or a list ranking an artist's discgraphy. Very quickly they'll have built up a solid collection, representing their life in music."
		},
		{
			q: "Do I need to create an account to use Musicboard?",
			a: "No. You may freely browse all of Musicboard without an account, but you’ll need one if you want to create ratings, reviews and lists."
		},
		{
			q: "Does it cost to use this service?",
			a: "No. Musicboard offers a Pro version that enable some extra features, but for general use the application is completely free. Read more information on Pro."
		},
		{
			q: "How do I close my account?",
			a: "If you no longer wish to use the site and want your account permanently deleted and closed, you can do so by visiting the Settings page and clicking the Delete Account button. You will be asked to confirm your decision, and once you do, your account will be deleted. This action is irreversible and all data will be lost, so make sure you are certain before proceeding."
		},
		{
			q: "Can I listen to music on this service?",
			a: "No. We don't provide the feature to stream any music, but we have built a quick integration to listen on Spotify, and will continue to add other platforms in the future."
		},
		{
			q: "What’s the difference between a rating and a review?",
			a: "You can rate music without writing a review, but not the other way around. A review also counts as a rating."
		},
		{
			q: "I rated an album, but now want to write a review for it, what are my options?",
			a: "You can either rate the album again and add a review, or you can edit your existing rating and add a review to that."
		},
		{
			q: "Why is my content on Musicboard not up to date?",
			a: "Musicboard uses caching to keep the server running and the performance fast. That can result in some delay when information is visible, but it's very rare."
		},
		{
			q: "My total count of albums is incorrect, why?",
			a: "The counts for albums, tracks and artists are referring to the amount of ratings and are not distinct. That means an album you've rated twice would be counted twice."
		},
		{
			q: "How do I pin reviews to my profile?",
			a: "If you don't have anything pinned yet you can pin your reviews and lists from your profile. Otherwise you'll find that option by navigating to your profile, then to Pinned."
		},
		{
			q: "How does banners work?",
			a: "Custom banners is functionality specifically built for Pro users. They let you spice up your profile and lists with custom backgrounds, and we have implemented a system where you can store and manage your banners."
		},
		{
			q: "My preferred username is unavailable, what can I do?",
			a: "Some usernames are reserved by us, but if that's not the case we do offer the ability to get the username, proving that the current user is inactive."
		},
		{
			q: "I'm getting logged out every time I reload the site, what can I do?",
			a: "This is a rare issue, but it means that your browser's storage has been corrupted and the fix is to simply reset it. Guides covering how to do it on the most popular browsers."
		},
		{
			q: "How do I find people to follow?",
			a: "We have a section on our home page either called Most Popular Users or Weekly Top Users. Those would be a good place to start."
		},
		{
			q: "What happens when I block a user?",
			a: "You will no longer see any of their content, and they will not see any of yours."
		},
		{
			q: "I deleted a review or list. Can I retrieve it again?",
			a: "Yes, this is possible in most cases. Contact us if you would like help with this."
		},
		{
			q: "My list automatically updates with changing anything, why?",
			a: "Our functionality for editing lists work a bit differently. When updating any musical content in the list it will automatically be updated when the change is made, but for the information a Save button has to be pressed."
		},
		{
			q: "What’s the difference between my lists and my Listen Later?",
			a: "Listen Later is a list we've prepared for you. The intention with Listen Later is to collect all the music you haven't heard but would like to discover. It has been seperated as a standalone feature to enable easier adding and removing from the list."
		},
		{
			q: "Why should I upgrade my account?",
			a: "Musicboard Pro is an optional power up, giving you more features for a small monthly subscription. You can check out all the added benefits in the Pro page, but some include in-depth statistics, personal banners on your profile and unlimited length on reviews. As a small team we rely on your support in order to expand Musicboard even further."
		},
		{
			q: "How do I upgrade my account?",
			a: "You can not subscribe directly from the app, but you can subscribe right now on our Pro page. Your status will then be updated both on web and in the app."
		},
		{
			q: "Do I need to subscribe in the app and on the web?",
			a: "Nope! Your subscription is account wide and you can enjoy the benefits on both the app and web without restriction."
		},
		{
			q: "I'm on the app, how do I manage my subscription?",
			a: "You can not subscribe or manage your subscription from the app, but we do offer a link in Settings which will redirect you to the web. From there you can easily manage your subscription."
		},
		{
			q: "What happens when my subscription expires?",
			a: "Subscriptions will automatically renew when the date expires. If you would like to cancel your subscription you can do so at any time and you will simply not be billed again when the expiration date hits."
		},
		{
			q: "What payment options do you accept?",
			a: "We have integrated with Stripe for easy and secure card payments. We support all major cards including VISA, VISA Debit, MasterCard, Discover, JCB, American Express."
		},
		{
			q: "Can I upgrade from monthly to yearly during my subscription?",
			a: "We haven't built any feature for automatically handling this, but be sure to contact us and we can help you with this."
		},
		{
			q: "Are my profile, reviews and lists publicly visible?",
			a: "Yes, all user content is publicly visible on Musicboard."
		},
		{
			q: "Can I delete my data permanently?",
			a: "Yes of course! We strongly value a sense of security around your data so contact us if you want specific data deleted, we can help you."
		},
	]

	return createGeneralSchema({
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": questions.map(question => ({
			"@type": "Question",
			"name": question.q,
			"acceptedAnswer": {
				"@type": "Answer",
				"text": question.a
			}
		}))
	})
}

// SoftwareApplication schema.org for /apps page
export function createSoftwareApplicationAndroidSchema(schemaKey) {
	return createGeneralSchema({
		"@context": "https://schema.org",
		"@type": "SoftwareApplication",
		"name": "Musicboard: Music Reviews",
		"url": "https://play.google.com/store/apps/details?id=com.musicboard&hl=en_US",
		"image": "https://play-lh.googleusercontent.com/FBMTgNLT_b5wjrNUq89L4T9wa4pyBN150P3wM3jg96liHvMZeuaQ1Qq6COr_PtArlnc",
		"description": "Rate and review albums, songs & artists.",
		"operatingSystem": "ANDROID",
		"applicationCategory": "MUSIC_AND_AUDIO",
		"aggregateRating": {
		  "@type": "AggregateRating",
		  "ratingValue": 4.6,
		  "ratingCount": 2033
		},
		"author": { "@type": "Person", "name": "Musicboard", "url": "https://musicboard.app" },
		"offers": [{ 
			"@type": "Offer", 
			"price": "0", 
			"priceCurrency": "SEK", 
			"availability": "https://schema.org/InStock" 
		}]
	})
}

export function createSoftwareApplicationIOSSchema(schemaKey) {
	return createGeneralSchema({
		"@context": "http://schema.org",
		"@type": "SoftwareApplication",
		"name": "Musicboard: Music Reviews",
		"description": "The social network for music lovers. Share your love for music with friends and the world.\n\nMusicboard is a social platform where people from all around the world connect through their shared love of music. Write reviews, rate music, and compile lists in music's fastest growing community.\n\nMusicboard lets you:\n• Write reviews and rate music to share your opinions with friends. Explore what others think and grow your passion.\n• Discover new music from real people. Musicboard curates a database of ratings and reviews for the world's music.\n• Compile music into lists, like your favorite albums, ranking an artist's discography, or showing off your hidden gems.\n• Keep track of all the music you want to listen to, and stay up to date as new albums are being released.\n\nSubscribe to Musicboard PRO:\n• Unlock in-depth statistics about your musical interests.\n• Spice up your profile a personal banner and a spot to share your favorite albums.\n\nTens of thousands already use Musicboard to share their thoughts and opinions in music. With a combination of ratings, reviews, and lists, their profile quickly becomes a central hub for their life in music.\n\nOur Terms of Use is available at https://musicboard.app/terms\n\nFor feedback on our app, let us know at feedback@musicboard.app",
		"screenshot": [
			"https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/c5/0f/6b/c50f6b18-28b6-fb67-b6f9-2c0e518e77ba/3a06d879-1334-4968-be69-29246f32db55_iPhone_Dynamic_-_8.png/300x0w.jpg",
			"https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/d0/b8/b9/d0b8b967-fa98-f0dc-4334-c4fe20cb44fa/dcec4377-17b8-4b1b-a583-8d48b6ea0bc8_iPhone_Dynamic_-_13.png/300x0w.jpg",
			"https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/54/c4/e0/54c4e09d-6c1d-53c7-9bfb-a747df6f8925/32ddf40e-7124-4e4f-a15b-f5debd2ba847_iPhone_Dynamic_-_9.png/300x0w.jpg",
			"https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/37/4e/3e/374e3ebd-34aa-11b4-03a2-40a3bc73f925/7090e8b7-d719-4e99-b306-eb0e422115cc_iPhone_Dynamic_-_16.png/300x0w.jpg",
			"https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/a3/9e/7d/a39e7de7-ddba-7fdb-ca3c-d2036138ff92/b7e8ad77-c681-4a0f-8695-24c79b310efc_iPhone_Dynamic_-_17.png/300x0w.jpg",
			"https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/8b/ee/9e/8bee9e87-9077-ea17-8e6f-7ab2d19fa27b/63ea75a7-fe86-4129-b9e4-3626067df3fb_iPhone_Dynamic_-_12.png/300x0w.jpg"
		],
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Purple221/v4/ba/31/65/ba3165cb-0846-3619-a1c8-fc63d76f4beb/AppIcon-0-0-1x_U007emarketing-0-5-0-85-220.png/1200x630wa.png",
		"applicationCategory": "Music",
		"datePublished": "Apr 14, 2020",
		"operatingSystem": "iOS",
		"url": "https://apps.apple.com/us/app/musicboard-music-reviews/id1503544789",
		"author": { "@type": "Person", "name": "Musicboard", "url": "https://musicboard.app" },
		"aggregateRating": { "@type": "AggregateRating", "ratingValue": 4.6, "reviewCount": 3185 },
		"offers": { "@type": "Offer", "price": 0, "priceCurrency": "USD", "category": "free" }
	})
}

/* User */
/* ====================================================================================== */
export function createUserProfilePageSchema(user) {
	const socials = [user.website, user.twitter].filter((i) => i && i !== "");

	return createGeneralSchema({
		"@context": "https://schema.org",
		"@type": "ProfilePage",
		"breadcrumb": "https://musicboard.app/welcome/",
		"mainEntity": {
			"@type": "Person",
			"identifier": user.uid,
			"name": user.username,
			...user.name !== "" ? { "alternateName": user.name } : {},
			...user.profile_picture_large ? { "image": user.profile_picture_large } : {},
			...user.biography !== "" ? { "description": user.biography } : {},
			...socials.length > 0 ? { "contactPoint": socials.map((i) => ({
				"@type": "ContactPoint",
				"url": i,
			})) } : {},
			"url": `https://musicboard.app/${user.username}/`,
			"interactionStatistic": [
				{
					"@type": "InteractionCounter",
					"interactionType": "https://schema.org/FollowAction",
					userInteractionCount: user.counts.follows || 0
				},
				{
					"@type": "InteractionCounter",
					"interactionType": "https://schema.org/ReviewAction",
					userInteractionCount: user.counts.rating_set || 0
				},
				{
					"@type": "InteractionCounter",
					"interactionType": "https://schema.org/WriteAction",
					userInteractionCount: (user.counts.review_set || 0) + (user.counts.list_set || 0)
				},
				{
					"@type": "InteractionCounter",
					"interactionType": "https://schema.org/WantAction",
					userInteractionCount: user.counts.want_list || 0
				},
				{
					"@type": "InteractionCounter",
					"interactionType": "https://schema.org/LikeAction",
					userInteractionCount: user.counts.likes || 0
				},
				{
					"@type": "InteractionCounter",
					"interactionType": "https://schema.org/CreateAction",
					userInteractionCount: user.counts.contributions || 0
				}
			],
			"agentInteractionStatistic": {
				"@type": "InteractionCounter",
				"interactionType": "https://schema.org/ReviewAction",
				userInteractionCount: user.counts.rating_set || 0
			},
		}
	})
}

export function createUserPersonSchema(user) {
	const socials = [user.website, user.twitter].filter((i) => i && i !== "");

	return createGeneralSchema({
		"@context": "https://schema.org",
		"@type": "Person",
		"identifier": user.uid,
		"name": user.username,
		...user.name !== "" ? { "alternateName": user.name } : {},
		...user.profile_picture_large ? { "image": user.profile_picture_large } : {},
		...user.biography !== "" ? { "description": user.biography } : {},
		...socials.length > 0 ? { "contactPoint": socials.map((i) => ({
			"@type": "ContactPoint",
			"url": i,
		})) } : {},
		"url": `https://musicboard.app/${user.username}/`,
	})
}
