import styles from './heading.module.css'

import React from 'react'
import Icon from '../Icon'
import { useTheme } from '../../hooks/theme'
import Filter from './components/Filter'
import { Link } from 'react-router-dom'

export default function Heading({
    text, 
    icon="chevron-right",
    renderRightContent,
    redirect, 
    onPress, 
    className, 
    textClassName, 
    animationDirection, 
    subText,
    filters,
    subTextBefore,
    subTextRedirect,
    style,
    showLine
}) {
    const { colors } = useTheme()

    return (
        <div 
        onClick={onPress ? onPress : () => {}}
        className={`${(redirect || onPress) ? styles.a : ""} ${onPress ? "cursor" : ""} link-wrapper ${className}`}
        >
            {redirect && !onPress &&
                <Link to={redirect} className="link-overlay">
                    <span className='link-overlay-title'>
                        {subText && subTextBefore ? subText : ""}
                        {text}
                        {subText && !subTextBefore ? subText : ""}
                    </span>
                </Link>
            }

            <div className={`${styles.container} ${showLine ? styles.showLine : ""} link-inner`} style={style}>
                <div className="grid margin-right-8">
                    <h3 className={`text-md one-line ${styles.text} ${textClassName}`}>
                        {subText && subTextBefore &&
                            <Link to={subTextRedirect}>
                                <span className={`${styles.subText} ${subTextRedirect ? styles.hover : ""}`}>{subText}</span>
                            </Link>
                        }
                        {text}
                        {subText && !subTextBefore &&
                            <Link to={subTextRedirect}>
                                <span className={`${styles.subText} ${subTextRedirect ? styles.hover : ""}`}>{subText}</span>
                            </Link>
                        }
                    </h3>
                </div>

                {(redirect || onPress) && !filters && !renderRightContent &&
                    <Icon
                    className={`${styles.animation} ${animationDirection === "up" ? styles.up : animationDirection === "down" ? styles.down : animationDirection === "left" ? styles.left : ""}`} 
                    icon={icon} 
                    />
                }
                
                {!renderRightContent && filters && 
                    <div className="flex-row link-clickable">
                        {filters.map((filter, index) => (
                            <Filter filter={filter} />
                        ))}
                    </div>
                }
                
                {renderRightContent &&
                    <div className="link-clickable">
                        {renderRightContent}
                    </div>
                }
            </div>
        </div>
    )
}
