import { status, types } from '../constants/action-types'
import { post, put, get } from '../utils/fetch'
import { socialAuthClientGrantType, socialAuthClientId, socialAuthClientSecret, url } from '../config'
import { getNormal, patchNormal, postNormal } from '../utils/fetch-normal';

//Auth
export const logIn = (username, email, password) => post(types.LOG_IN, `${url}/v2/login/`, {
    username,
    email,
    password
});

export const logInWithSocial = (token, backend, name) => (dispatch, getState) => { // backend can be: "google-oauth2" or "apple-id"
    const controller = new AbortController()
    const signal = controller.signal
    console.log("backend", backend)
    const body = {
        grant_type: socialAuthClientGrantType,
        client_id: socialAuthClientId,
        client_secret: socialAuthClientSecret,
        token: token,
        backend: backend,
    }

    dispatch({ type: types.LOG_IN, payload: body, meta: { status: status.BEGIN, params: { backend } } })

    postNormal(`${url}/auth/convert-token/`, body)
    .then((tokenInfo) => {
        getNormal(`${url}/v2/users/me/`, 15000, signal, tokenInfo.access_token, tokenInfo.token_type)
        .then((userInfo) => {
            // If this is a new account => Fill the name and picture fields
            if (userInfo.name === "" && name !== null && name !== undefined && name !== "") {
                //Add only name
                patchNormal(`${url}/v2/users/${userInfo.uid}/`, { name: name }, 15000, signal, tokenInfo.access_token, tokenInfo.token_type)
                .then((_userInfo) => {
                    dispatch({ type: types.LOG_IN, payload: { ..._userInfo, completed_onboarding: userInfo.completed_onboarding, auth_info: tokenInfo }, meta: { status: status.SUCCESS, params: { backend } } })
                })
                .catch((err) => {
                    dispatch({ type: types.LOG_IN, payload: { ...userInfo, auth_info: tokenInfo }, meta: { status: status.SUCCESS, params: { backend } } })
                    console.warn(err);
                })

            } else {
                dispatch({ type: types.LOG_IN, payload: { ...userInfo, auth_info: tokenInfo }, meta: { status: status.SUCCESS, params: { backend } } })
            }

        })
        .catch((err) => {
            console.warn(err);
            dispatch({ type: types.LOG_IN, payload: err, meta: { status: status.ERROR, params: { backend } } })
        })
    })
    .catch((err) => {
        console.warn(err);
        dispatch({ type: types.LOG_IN, payload: err, meta: { status: status.ERROR, params: { backend } } })
    })
};

export const signUp = (name, email, password) => post(types.LOG_IN, `${url}/v2/users/`, {
    email,
    name,
    password
});

export const logOut = () => (dispatch, getState) => dispatch({ type: types.LOG_OUT });

//Handle account
export const forgotPassword = (email) => post(types.FORGOT_PASSWORD, `${url}/v2/users/forgot_password/`, {
    email
});

export const verifyToken = (token) => post(types.LOG_IN, `${url}/v2/users/verify_token/`, {
    token
});

export const passwordToken = (token) => post(types.LOG_IN, `${url}/v2/users/password_token/`, {
    token
});

export const changePassword = (password, confirmedPassword) => put(types.CHANGE_PASSWORD, `${url}/v2/users/change_password/`, {
    password,
    confirmed_password: confirmedPassword
});

//User Exists
export const userExists = (username) => post(types.USER_EXISTS, `${url}/v2/users/exists/`, {
    username
});

//Health
export const getHealthCheck = () => get(types.GET_HEALTH_CHECK, `${url}/v2/health/`);