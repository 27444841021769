import styles from './sharedropdown.module.css'

import React, { useEffect, useRef, useState } from 'react'

import { ogImageUrl } from '../../config'

import { usePopup } from '../../hooks/popup/popup'

import { firstLetterCapital } from '../../utils/captialization'

import Icon from '../Icon'
import Tooltip from '../Tooltip'
import Dropdown from '../Dropdown'

export default function ShareDropdown({ type, review, thread, list, uid, className, style, isPrivate }) {
    //General
    const { openPopup } = usePopup()

    //State
    const [visible, setVisible] = useState(false)

    return (
        <div className={`flex-row margin-left-8 ${className}`}  style={{ position: 'relative', display: 'flex', justifyContent: 'center', ...style }}>
            <div 
            id={isPrivate ? `${type}-item-${uid}-disabled-share-tip` : `${type}-item-${uid}-share-tip`}
            className="link-clickable"
            >
                <div 
                className={isPrivate ? styles.disabledShare : ""}
                onClick={() => {
                    openPopup(thread ? "ShareThread" : list ? "ShareList" : "ShareReview", { list: list, review: review, thread: thread, popupTitle: `Share ${firstLetterCapital(type)}` })
                }} 
                style={{height: 20}}
                >
                    <Icon size={23} icon="reply" className={`${styles.icon}`} style={{ marginTop: -9 }} />
                </div>
            </div>

            <Tooltip
            id={`${type}-item-${uid}-disabled-share-tip`}
            text={`Make this ${type} public to share`}
            offset={10}
            />
        </div>
    )
}
