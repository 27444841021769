import styles from './textinput.module.css'

import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Icon from '../Icon'
import { useInterval } from '../../hooks/interval'

function TextInput({ 
    referrer,

    text="", 
    onTextChange=() => {}, 
    onEnterClicked=() => {}, 
    onKeyDown=() => {},
    style,
    className,
    placeholder="",
    label,
    name="",
    type="text",
    textarea,
    textareaHeight=128,
    skipTextareaHeight,
    autoComplete="on",
    autoCapitalize="on",
    maxLength=524288,
    showMaxLength,
    inputStyle,
    inputClassName,
    autoFocus,
    lighterStyle,
    greyStyle,

    rightIcon,
    iconColor="var(--black)",
    iconSize=20,
    iconStyle,
    onIconClick=()=>{},
    showUpgradePro,

    renderLeftText,

    onFocus=() => {},
    onBlur=() => {},
}) {

    const is_pro = useSelector(state => state.me.is_pro)

    /*
    const [placeholderIndex, setPlaceholderIndex] = useState(Array.isArray(placeholder) ? Math.floor(Math.random()*placeholder.length) : null)

    useInterval(() => {
        if (placeholderIndex !== null) {
            if (text === "") {
                setPlaceholderIndex(
                    (placeholderIndex < placeholder.length-1) ? placeholderIndex+1 : 0
                )   
            }
        }
    }, text !== "" ? null : 6000)
   */

    if (textarea) {
        return (
            <div style={style} className={`${styles.div} ${className}`}>
                {label &&
                    <p className="black">{label}</p>
                }

                <textarea
                ref={referrer}
                value={text}
                onChange={(e) => onTextChange(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        onEnterClicked()
                    }
                }}
                style={{
                    maxHeight: skipTextareaHeight ? undefined : textareaHeight,
                    minHeight: skipTextareaHeight ? undefined : textareaHeight,
                    ...inputStyle
                }}
                className={`${label && "margin-top-4"} ${styles.input} ${lighterStyle ? styles.lighterStyle : ""} ${greyStyle ? styles.greyStyle : ""} ${inputClassName}`}
                placeholder={Array.isArray(placeholder) ? "" : placeholder}
                type={type}
                name={name}
                autoComplete={autoComplete}
                autoCapitalize={autoCapitalize}
                autoFocus={autoFocus}
                onFocus={function(e) {
                    onFocus()
                    var val = e.target.value;
                    e.target.value = '';
                    e.target.value = val;
                }}
                onBlur={onBlur}
                maxLength={maxLength}
                />

                {((showMaxLength && text !== null) || renderLeftText) &&
                    <div className={`flex-row justify-between ${(showUpgradePro && !is_pro) ? "margin-top-8" : "margin-top-4"}`}>
                        {renderLeftText ? renderLeftText : <div />}
                        {(showMaxLength && text !== null) &&
                            <div className="flex-row">
                                {showUpgradePro && !is_pro &&
                                    <Link to="/pro?ref=TextInput" target="_blank">
                                        <h6 className={`${styles.textAreaMaxLength} ${styles.proText} margin-right-8`}>Upgrade to <span className={styles.pro}>Pro</span></h6>
                                    </Link>
                                }
                                <p className={styles.textAreaMaxLength}>{maxLength-text.length}/{maxLength}</p>
                            </div>
                        }
                    </div>
                }

                {rightIcon &&
                    <Icon
                    className={styles.textAreaIcon}
                    icon={rightIcon}
                    color={iconColor}
                    size={iconSize}
                    style={iconStyle}
                    onClick={(e) => onIconClick(e)}
                    />
                }

                {/* Array.isArray(placeholder) && text === "" && placeholderIndex !== null &&
                    <p className={`${styles.placeholderCarousel} ${styles.textArea}`}>{placeholder[placeholderIndex]}</p>
                */}
            </div>
        )
    }

    return (
        <div style={style} className={`${styles.div} ${className}`}>
            {label &&
                <p className="black medium">{label}</p>
            }

            <div className={`${styles.div} ${label && "margin-top-4"}`}>
                <input
                ref={referrer}
                value={text}
                onChange={(e) => onTextChange(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        onEnterClicked()
                    }
                    onKeyDown(e)
                }}
                style={{
                    paddingRight: rightIcon ? 32 : showMaxLength ? 72 : 12,
                    ...inputStyle
                }}
                className={`${styles.input} ${lighterStyle ? styles.lighterStyle : ""} ${greyStyle ? styles.greyStyle : ""} ${inputClassName}`}
                placeholder={Array.isArray(placeholder) ? "" : placeholder}
                type={type}
                name={name}
                autoComplete={autoComplete}
                autoCapitalize={autoCapitalize}
                autoFocus={autoFocus}
                onFocus={function(e) {
                    onFocus()
                    var val = e.target.value;
                    e.target.value = '';
                    e.target.value = val;
                }}
                onBlur={onBlur}
                maxLength={maxLength}
                />

                {((showMaxLength && text !== null) || renderLeftText) &&
                    <div className={`flex-row justify-between ${(showUpgradePro && !is_pro) ? "margin-top-8" : "margin-top-4"}`}>
                        {renderLeftText ? renderLeftText : <div />}
                        {(showMaxLength && text !== null) &&
                            <div className="flex-row">
                                {showUpgradePro && !is_pro &&
                                    <Link to="/pro?ref=TextInput" target="_blank">
                                        <h6 className={`${styles.inputMaxLength} ${styles.proText} margin-right-8`}>Upgrade to <span className={styles.pro}>Pro</span></h6>
                                    </Link>
                                }
                                <p className={styles.inputMaxLength}>{maxLength-text.length}/{maxLength}</p>
                            </div>
                        }
                    </div>
                }
                
                {rightIcon &&
                    <Icon
                    className={styles.icon}
                    icon={rightIcon}
                    color={iconColor}
                    size={iconSize}
                    style={iconStyle}
                    onClick={(e) => onIconClick(e)}
                    />
                }

                {/* Array.isArray(placeholder) && text === "" && placeholderIndex !== null &&
                    <p className={`${styles.placeholderCarousel} one-line`}>{placeholder[placeholderIndex]}</p>
                */}
            </div>
        </div>
    )
}

export default React.forwardRef((props, ref) => <TextInput referrer={ref} {...props} />)