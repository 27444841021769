import styles from './listfilter.module.css'

import React, { useState } from 'react'
import { useTheme } from '../../../../../../hooks/theme'
import { useHref } from '../../../../../../hooks/href'
import { useRouteMatch, useLocation, Link } from 'react-router-dom'
import Icon from '../../../../../../components/Icon'
import { useSelector } from 'react-redux'
import Dropdown from '../../../../../../components/Dropdown'

export default function Filter({ proRequired, title, parameter, filters, className, style, clearFilter, showExactLabel, showInternalLabel, emptyLabel="All" }) {
    const { colors } = useTheme()

    const is_pro = useSelector(state => state.me.is_pro)

    const [visible, setVisible] = useState(false)

    //Query params
    const match = useRouteMatch()
    const location = useLocation()
    const currentValue = new URLSearchParams(location.search).get(parameter)
    const { getHref } = useHref()

    //Prepare data for rendering
    const activeIndex = filters.findIndex(item => item.ordering.findIndex(item => item.value == currentValue) !== -1)
    const activeOrderingIndex = filters[activeIndex] ? filters[activeIndex].ordering.findIndex(item => item.value == currentValue) : null
    const currentTitle = showExactLabel ? currentValue ? currentValue : emptyLabel : filters[activeIndex] ? showInternalLabel && filters[activeIndex].ordering[activeOrderingIndex] ? filters[activeIndex].ordering[activeOrderingIndex].altLabel ? filters[activeIndex].ordering[activeOrderingIndex].altLabel : filters[activeIndex].ordering[activeOrderingIndex].label : filters[activeIndex].title : emptyLabel

    return (
        <div 
        className={`${className}`} 
        style={{ position: 'relative', ...style }}
        >
            <div onClick={() => setVisible(!visible)} className={`padding-around-8 flex-row cursor`}>
                <div className="grid">
                    <p className="one-line highDarkGrey">
                        {title}
                        {": "}
                        <span style={{ fontWeight: 600 }} className={`${currentValue ? "black" : "highDarkGrey"} margin-left-4`}>
                            {currentTitle}
                        </span>
                    </p>
                </div>

                <Icon size={16} icon="expand-more" className={`${styles.arrow} ${visible ? styles.active : ""}`} color={currentValue ? "var(--black)" : "var(--highDarkGrey)"} />
            </div>

            <Dropdown 
            simpleAnimation
            visible={visible}
            zIndex={1090} 
            position="left" 
            appendId={`listfilter-${parameter}`} 
            hideDropdown={() => setVisible(false)} 
            offset={36} 
            style={{ marginBottom: 32 }}
            >
                <div className="padding-around-8" style={{ minWidth: 160, }}>
                    {filters.map((item, index) => (
                        <div key={`${title}-${parameter}-filter-${index}`} className={index !== 0 ? "margin-top-8" : ""}>
                            <div className="flex-row justify-between">
                                <h6 className="black">{item.title}</h6>
                                {index === 0 && clearFilter && currentValue &&
                                    <Link to={`${match.url}${getHref(null, parameter)}`}>
                                        <p>
                                            Clear filter
                                        </p>
                                    </Link>
                                }
                            </div>
                            {item.ordering.map(item => (
                                <Link onClick={() => setVisible(false)} to={item.redirect ? item.redirect : `${match.url}${getHref(item.value, parameter)}`} key={`ordering-${item.value}`}>
                                    <div className={`flex-row ${styles.item} ${item.value == currentValue ? styles.active : ""}`}>
                                        {item.value == currentValue &&
                                            <Icon color={colors.green} size={16} icon="check" />
                                        }

                                        <p className={`one-line margin-right-16 ${styles.itemText}`}>{item.label}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ))}
                </div>
            </Dropdown>
        </div>
    )
}
