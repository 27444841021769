import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { handleReducer } from '../utils/reducer-helpers';

const defaultLists = {
	allArticles: {
        count: null,
        next: null,
        data: [],
        status: status.DEFAULT,
    },
    
	articles: {},
	getArticleStatus: status.DEFAULT,

	signUpResponse: null,
	signUpForNewsletterStatus: status.DEFAULT,

	errorMessage: null,
};

const news = (state = defaultLists, action) => {
	switch (action.type) {
		case types.GET_ALL_ARTICLES:
			return handleReducer(state, action, 'allArticles')

		case types.GET_ARTICLE:
			let uid = action.meta && action.meta.params ? action.meta.params.uid : false;

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						getArticleStatus: status.BEGIN,
						articles: {
							...state.articles,
							[uid]: {
								...state.articles[uid],
								...state.allArticles.data[state.allArticles.data.findIndex((a) => a.uid === uid)]
							}
						}
					}
				case status.SUCCESS:
					return {
						...state,
						getArticleStatus: status.SUCCESS,
						articles: {
							...state.articles,
							[uid]: {
								...state.articles[uid],
								...action.payload
							}
						}
					}
				case status.ERROR:
					return {
						...state,
						getArticleStatus: status.ERROR,
						articles: {
							...state.articles,
							[uid]: null
						},
						errorMessage: errorToString(action.payload)
					}
			}

		case types.SIGN_UP_FOR_NEWSLETTER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						signUpForNewsletterStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						signUpForNewsletterStatus: status.SUCCESS,
						signUpResponse: action.payload.message,
					}
				case status.ERROR:
					return {
						...state,
						signUpForNewsletterStatus: status.ERROR,
						signUpResponse: errorToString(action.payload),
						errorMessage: errorToString(action.payload),
					}
			}

		default:
			return state;
	}
};

export default news;