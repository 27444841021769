import React, { useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google';


import Button from '../../../../components/Button'
import { useDispatch, useSelector } from 'react-redux';
import { status } from '../../../../constants/action-types';
import { logInWithSocial } from '../../../../actions/auth-actions';
import { useAlert } from '../../../../hooks/alert/alert';
import Icon from '../../../../components/Icon';

export default function SignInWithGoogle({ className, setError, isSignIn }) {
    //General
    const dispatch = useDispatch()
    const { openAlert } = useAlert()
    
    //Data
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const logInStatus = useSelector(state => state.auth.logInStatus)

    //State
    const [started, setStarted] = useState(false)

    const login = useGoogleLogin({
        onSuccess: tokenResponse => {
            console.log(tokenResponse)
            setStarted(true)
            dispatch(
                logInWithSocial(
                    tokenResponse.access_token, 
                    "google-oauth2", 
                    null
                )
            )
        },
        onError: (error) => {
            openAlert({
                p: "Log in failed. Try again or contact us if the issue persists. Error: " + error.error,
                style: "red",
                timer: 3500,
            })
        }
    });

    return (
        <div className={`SignInBtn-wrapper ${className}`}>
            <Button
            type="secondary-two"
            text={isSignIn ? "Sign in with Google" : "Sign up with Google"}
            className={`col-1`}
            onClicked={login}
            disabled={logInStatus === status.BEGIN}
            loading={logInStatus === status.BEGIN && started}
            height={41}
            />

            <div className='SignInBtn-icon flex-center' style={{ width: 24, height: 24 }}>
                <Icon icon={"google-login"} size={20} />
            </div>
        </div>
    )
}
