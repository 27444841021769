import styles from './albumhome.module.css'

import React, { Suspense, lazy, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'

import { status, types } from '../../../../constants/action-types'
import { recordTypeEnumsLong } from '../../../../constants/enums'

import { fetchTracksForAlbum, getAlbumBonusTracks, getAlbumLists, getAlbumRatingDistribution, getAlbumRatings, getAlbumReviews, getArtistDiscography } from '../../../../actions/content-actions'
import { createWant, deleteWant } from '../../../../actions/me-actions'

import { createAlbumMusicAlbumSchema, createBreadcrumbSchema } from '../../../../data/structured-data'

import { usePopup } from '../../../../hooks/popup/popup'
import { useSpotify } from '../../../../hooks/spotify'
import { useDeezer } from '../../../../hooks/deezer'
import { useAudio } from '../../../../hooks/audio/audio'

import ReviewItemLoader from '../../../../loaders/ReviewItemLoader'
import ListItemLoader from '../../../../loaders/ListItemLoader'
import TrackItemLoader from '../../../../loaders/TrackItemLoader'
import RatingDistributionLoader from '../../../../loaders/RatingDistributionLoader'

import DefaultHelmet from '../../../../shared/DefaultHelmet'
import StructuredData from '../../../../shared/StructuredData'

import { secondsToFormattedTime } from '../../../../utils/format-time'
import { numberWithCommas } from '../../../../utils/format-text'
import { filterAndFill } from '../../../../utils/format-array'

import Button from '../../../../components/Button'
import Heading from '../../../../components/Heading'
import MiniFooter from '../../../../components/MiniFooter'
import OptionsMenu from '../../../../components/OptionsMenu'
import ReviewItem from '../../../../components/ReviewItem'
import ListItem from '../../../../components/ListItem'
import ShareElement from '../../../../components/ShareElement'
import SmallReleaseItem from '../../../../components/SmallReleaseItem'
import Tooltip from '../../../../components/Tooltip'
import TrackItem from '../../../../components/TrackItem/TrackItem'
import UserRatingItem from '../../../../components/UserRatingItem'
import StickyScrolling from '../../../../components/StickyScrolling'
import RatingsGraph from '../../../../components/RatingsGraph'
import AdComponent from '../../../../components/AdComponent'

import AlbumInformationSection from '../AlbumInformationSection';

export default function AlbumHome({ backend, titleSlug }) {
    //General
    const dispatch = useDispatch()
    const location = useLocation()
    const deezer = useDeezer()
    const spotifyFetch = useSpotify()
    const { openPopup } = usePopup()
    const { playTracks } = useAudio()

    //Responsive
    const isMidScreen = useMediaQuery({ maxWidth: 1100 })
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isTablet = useMediaQuery({ maxWidth: 768 })
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const isPro = useSelector(state => state.me.is_pro)
    const isModerator = useSelector(state => state.me.is_moderator)

    //Params
    const tracksParam = new URLSearchParams(location.search).get("tracks")
    const highlightedTracks = tracksParam ? tracksParam.split(",") : []

    //Data
    const discography = useSelector(state => backend && backend.artist && state.artists.artists[backend.artist.id] ? state.artists.artists[backend.artist.id].discography : null)
    const errorMessage = useSelector(state => state.albums.errorMessage)
    
    //Wants data
    const deleteWantStatus = useSelector(state => state.me.deleteWantStatus)
    const lastDeletedWantContentId = useSelector(state => state.me.lastDeletedWantContentId)
    const myWants = useSelector(state => state.me.wants)
    const cachedWants = useSelector(state => state.me.cachedWants)
    const wants = [ ...myWants, ...cachedWants ]
    const wantIndex = wants.findIndex(want => want.content_id == backend.id && want.content_ct === "album")
    const wantObj = wantIndex !== -1 ? wants[wantIndex] : null

    //State
    const [tracks, setTracks] = useState(null)
    const [spotify, setSpotify] = useState(null)
    const [viewTrackList, setViewTrackList] = useState(false)

    //Get data when we have the correct album
    const hasFetched = useRef(false)
    useEffect(() => {
        if (backend && backend.id && !hasFetched.current) {
            hasFetched.current = true;
            
            //Get album's rating distribution
            dispatch(
                getAlbumRatingDistribution(backend.id)
            )
            
            //Get album's duplicates
            dispatch(
                getAlbumBonusTracks(backend.id)
            )

            //Fetch top lists
            dispatch( 
                getAlbumLists({
                    id: backend.id,
                    order_by: "-like_count",
                    limit: 3,
                    type: types.GET_ALBUMS_HOME_LISTS,
                })
            )

            //Fetch top reviews
            dispatch( 
                getAlbumReviews({
                    id: backend.id,
                    scored: true,
                    order_by: "",
                    limit: 6,
                    type: types.GET_ALBUMS_HOME_REVIEWS,
                })
            )

            //Fetch friends ratings
            if (isLoggedIn) {
                dispatch( 
                    getAlbumRatings({
                        id: backend.id,
                        following: true
                    }) 
                )
            }

            if (Boolean(backend.deezer_id)) {
                //Get album for its tracks from Deezer
                Promise.all([
                    deezer.getAlbum(backend.deezer_id),
                    deezer.getAlbumTracks(backend.deezer_id),
                ]).then(([album, tracks]) => {
                    console.log({tracks,album})
                    if (tracks && tracks.data && tracks.data.length > 0) {
                        setTracks(
                            tracks.data.map((track) => ({ ...track, album: { ...album, tracks: null } }))
                        )
                    } else {
                        setTracks("error")
                    }
                }).catch((err) => {
                    setTracks("error")
                })
            } else {
                //If a track that should be highlighted is further down than 6 => open the tracklist
                if (backend.tracks && backend.tracks.length > 0 && highlightedTracks.length > 0) {
                    highlightedTracks.forEach((track) => {
                        const index = backend.tracks.findIndex((item) => item.id == track);
                        if (index >= 6) {
                            setViewTrackList(true)
                        }
                    })
                }
            }

            //Fetch the artist's discography (if we haven't already)
            if (backend.artist && backend.artist.id) {
                if (!(discography && discography.data && discography.data.length >= 0)) {
                    dispatch(
                        getArtistDiscography(backend.artist.id, backend.artist.deezer_id)
                    )
                }
            }

            //Fetch tracks from API
            dispatch(
                fetchTracksForAlbum(backend.id, Boolean(backend.deezer_id))
            )
            
            if (backend.duplicated_albums && backend.duplicated_albums.length > 0) {
                backend.duplicated_albums.forEach((album) => {
                    dispatch(
                        fetchTracksForAlbum(album.id, Boolean(album.deezer_id))
                    )
                })
            }
            
            //Get spotify link
            spotifyFetch.searchAlbum(backend.title, backend.artist.name).then((res) => {
                setSpotify(res.albums.items[0].external_urls.spotify)
            }).catch((err) => {
                console.warn("Spotify error", { err })
            })

        }
    }, [ backend ])

    //If a track that should be highlighted is further down than 6 => open the tracklist
    useEffect(() => {
        if (tracks && tracks.length > 0 && highlightedTracks.length > 0) {
            try {
                highlightedTracks.forEach((track) => {
                    const index = tracks.findIndex((item) => item.id == track);
                    if (index >= 6) {
                        setViewTrackList(true)
                    }
                })
            } catch (err) {
                console.warn("Error in highlighted track check", { err })
            }
        }
    }, [ tracks ])

    //Helper functions
    const playTracksCallback = useCallback((trackId) => {
        // Render tracks from Deezer
        if (backend.deezer_id && tracks && tracks !== "error") {
            console.info(tracks)
            playTracks(
                tracks
                .filter((track) => track.preview)
                .map((track) => ({
                    id: track.id, 
                    deezer_id: track.deezer_id, 
                    url_slug: track.url_slug, 
                    name: track.title, 
                    singer: track.artist.name, 
                    cover: track.album.cover !== null && track.album.cover !== "" ? track.album.cover_medium || track.album.cover : backend.cover_medium || backend.cover, 
                    musicSrc: track.preview,
                    duration: track.duration,
                    album: {
                        id: backend.id,
                        deezer_id: backend.deezer_id,
                        url_slug: backend.url_slug,
                    },
                    artist: {
                        id: backend.artist.id,
                        deezer_id: backend.artist.deezer_id,
                        url_slug: backend.artist.url_slug,
                    }
                })),
                tracks.findIndex((track) => track.id == trackId)
            )
        }
    }, [tracks])
    
    //Prepare for render
    const showExpandTracklist = [
        ...(tracks ? tracks : []),
        ...(backend.tracks ? backend.tracks : []),
        ...(backend.bonus_tracks ? backend.bonus_tracks : []),
    ].length > 7
    
    return (
        <div className="page-container">
            <DefaultHelmet
            title={`${backend.title} by ${backend.artist.name}${titleSlug?.match(/\-\d+$/) ? ` - ${titleSlug.match(/\-(\d+)$/)[1]}` : ''} - Musicboard`}
            canonical={`https://musicboard.app${backend.url_slug}`}
            description={`${backend.title}, ${recordTypeEnumsLong[backend.record_type]} by ${backend.artist.name}. Released ${backend.release_date ? `on ${moment(backend.release_date).format("DD MMMM YYYY")}` : ""} ${backend.label && backend.label !== "" ? `by ${backend.label}` : ""} ${backend.country && backend.country !== "" ? `in ${backend.country}` : ""}. ${(backend.genres.length > 0 || backend.styles.length > 0) ? `Genres: ${[...backend.genres, ...backend.styles].map(i => i.name).join(", ")}.` : ""} Tracklist: ${backend.tracks.length} songs. Rated ${(backend.average_rating / 2).toFixed(2)} / 5 by ${numberWithCommas(backend.ratings_count || 0)} listeners. Explore reviews, ratings, tracklist, lists, and information on Musicboard.`}
            ogType="music.album"
            ogImage={backend.cover_large || backend.cover}
            ogImageAlt={backend.title}
            ogImageWidth={500}
            ogImageHeight={500}
            twitterImage={backend.cover_large || backend.cover}
            twitterImageWidth={500}
            twitterImageHeight={500}
            labelOne={
                backend.release_date ? 
                    { label: "Release Date", data: moment(backend.release_date).format("DD MMM YYYY") }
                :
                    { label: "Duration", data: secondsToFormattedTime(backend.duration) }
            }
            labelTwo={{ label: "Average Rating", data: `${(backend.average_rating / 2).toFixed(2)} out of 5` }}
            deepLink={`musicboard://album/${backend.id}`}
            headingOne={`${backend.title} by ${backend.artist.name}`}
            headingTwo={`Reviews & Ratings on Musicboard`}
            />

            {(backend.homeReviews && backend.homeReviews.data && backend.homeReviews.data.length > 0) ?
                <StructuredData
                key={`Album-MusicAlbum-Data-${backend.id}`}
                id={`Album-MusicAlbum-Data-${backend.id}`}
                data={createAlbumMusicAlbumSchema(backend, backend.homeReviews.data.filter((review) => review.rating?.rating !== null))}
                />
            : null}

            <StructuredData
            key={`Album-Breadcrumb-Data-${backend.id}`}
            id={`Album-Breadcrumb-Data-${backend.id}`}
            data={createBreadcrumbSchema([
                { "name": "Musicboard", "item": "https://musicboard.app/" },
                { "name": "Albums", "item": "https://musicboard.app/albums/" },
                { "name": backend.artist.name, "item": `https://musicboard.app${backend.artist.url_slug}` },
                { "name": backend.title, "item": `https://musicboard.app${backend.url_slug}` }
            ])}
            />

            <div className="section-handler">
                {/* LEFT SIDE */}
                <div className="section-2 section-margin">
                    <Heading
                    text="Tracklist"
                    onPress={
                        showExpandTracklist ? 
                            () => setViewTrackList(!viewTrackList) 
                        : 
                            null
                    }
                    icon={viewTrackList ? "expand-less" : "expand-more"}
                    className="margin-top-32"
                    animationDirection={viewTrackList ? "up" : "down"}
                    />

                    <div className="section-block padding-around-8" style={{ position: 'relative' }}>
                        {/* Render tracks from Deezer */}
                        {backend.deezer_id && tracks && tracks !== "error" && tracks.filter((_, i) => !showExpandTracklist ? true : viewTrackList ? true : i < 7).map((item, index) => (
                            <TrackItem
                            track={item}
                            album={backend}
                            artist={backend.artist}
                            highlight={highlightedTracks.indexOf(item.id.toString()) !== -1}
                            number={index+1}
                            key={`albumTrackItem-${backend.id}-${item.id}`}
                            playTracks={playTracksCallback}
                            />
                        ))}

                        {/* Render tracks from API */}
                        {!backend.deezer_id && !tracks && backend.tracks && backend.tracks.length > 0 && backend.tracks.filter((_, i) => !showExpandTracklist ? true : viewTrackList ? true : i < 7).map((item, index) => (
                            <TrackItem
                            track={item}
                            album={backend}
                            artist={backend.artist}
                            highlight={highlightedTracks.indexOf(item.id.toString()) !== -1}
                            number={index+1}
                            key={`albumTrackItem-${backend.id}-${item.id}`}
                            />
                        ))}

                        {(!showExpandTracklist || viewTrackList) && backend.bonus_tracks && backend.bonus_tracks.length > 0 && 
                            <div className='flex-row margin-top-16 margin-bottom-16'>
                                <div style={{ height: 1, flex: '1 1', backgroundColor: 'var(--darkLightGrey)' }} />

                                <h6 className="margin-left-16 margin-right-16 highDarkGrey">EXTRA TRACKS</h6>
                                
                                <div style={{ height: 1, flex: '1 1', backgroundColor: 'var(--darkLightGrey)' }} />
                            </div>
                        }

                        {/* Render tracks from API */}
                        {(!showExpandTracklist || viewTrackList) && backend.bonus_tracks && backend.bonus_tracks.length > 0 && backend.bonus_tracks.map((item, index) => (
                            <TrackItem
                            track={item}
                            album={backend}
                            artist={backend.artist}
                            highlight={highlightedTracks.indexOf(item.id.toString()) !== -1}
                            number={(tracks ? tracks.length : backend.tracks.length)+index+1}
                            key={`albumTrackItem-${backend.id}-bonusTrack-${item.id}`}
                            />
                        ))}

                        {/* Render error for Deezer tracks */}
                        {backend.deezer_id && tracks === "error" &&
                            <p className="empty-text">
                                {`Could not load tracks for this album.\nTry again soon and it should be solved.`}
                            </p>
                        }

                        {/* Render loading for Deezer tracks */}
                        {backend.deezer_id && !tracks &&
                            <div className="flex-column">
                                <TrackItemLoader />
                                <TrackItemLoader />
                                <TrackItemLoader />
                                <TrackItemLoader />
                                <TrackItemLoader />
                                <TrackItemLoader />
                                <TrackItemLoader />
                            </div>
                        }

                        <div
                        id={`tracklist-tooltip-${backend.id}`}
                        onClick={() => setViewTrackList(true)}
                        className={`${styles.tracklistOverlay} ${(!viewTrackList && ((tracks && tracks.length > 7) || (backend.tracks && backend.tracks.length > 7))) ? styles.active : ""}`} 
                        />

                        {!viewTrackList &&
                            <Tooltip id={`tracklist-tooltip-${backend.id}`} text="Show full tracklist" offset={-4} />
                        }
                    </div>

                    <AdComponent 
                    className="margin-top-32"
                    type="large"
                    adChannel="Album/LeftFirst"
                    />
                    
                    {backend && backend.ratings && backend.ratings.data && backend.ratings.data.length > 0 &&
                        <Heading
                        text="Friends Ratings"
                        redirect={`${backend.url_slug}ratings?following=true`}
                        className="margin-top-32"
                        />
                    }

                    {backend && backend.ratings && backend.ratings.data && backend.ratings.data.length > 0 &&
                        <div className={`grid-view ${isMobile ? "four" : isTablet ? "six" : isSmallScreen ? "eight" : isMidScreen ? "six" : "eight"} section-block padding-around`}>
                            {filterAndFill(backend.ratings.data, isMobile ? 8 : isTablet ? 6 : isSmallScreen ? 8 : isMidScreen ? 6 : 8).map((item, index) => (
                                <div key={`albumFriendRating-${index}`}>
                                    {item &&
                                        <UserRatingItem rating={item} />
                                    }
                                </div>
                            ))}
                        </div>
                    }

                    <Heading
                    text="Top Reviews"
                    redirect={`${backend.url_slug}reviews?order_by=helpful`}
                    className="margin-top-32"
                    />

                    <div className="section-block">
                        {backend && backend.homeReviews && backend.homeReviews.data && backend.homeReviews.data.length > 0 && backend.homeReviews.data.filter((_, i) => i < 6).map((item, index) => (
                            <ReviewItem
                            review={item}
                            hideUser
                            presentUserReview
                            isFirst={index === 0}
                            isLast={index === backend.homeReviews.data.filter((_, i) => i < 6).length-1}
                            key={`albumReview-${backend.id}-${item.uid}`}
                            />
                        ))}

                        {backend && backend.homeReviews && backend.homeReviews.data && backend.homeReviews.data.length <= 0 && backend.homeReviews.status !== status.BEGIN &&
                            <div className="empty-text">
                                <p className="text-center highDarkGrey">Seems like no one has gotten to this yet! Be the first to write a review for {backend.title}.</p>
                                <div className="flex-center">
                                    <Button
                                    text={isLoggedIn ? "Write a review" : "Sign up to write a review"}
                                    iconName={isLoggedIn ? 'edit' : "lock"}
                                    showIcon
                                    onClicked={() => {
                                        if (isLoggedIn) {
                                            openPopup("CreateReview", { content: backend, cacheRating: true })
                                        } else {
                                            openPopup("SignUp")
                                        }
                                    }}
                                    className="margin-top-16"
                                    style={{
                                        paddingLeft: 48,
                                        paddingRight: 48
                                    }}
                                    />
                                </div>
                            </div>
                        }

                        {backend && backend.homeReviews && backend.homeReviews.data && backend.homeReviews.data.length <= 0 && backend.homeReviews.status === status.BEGIN &&
                            [0,1,2,3].map((i) => (
                                <div key={`album-reviews-loader-${i}`} style={{ width: '100%', borderTop: i !== 0 ? '1px solid var(--lightGrey)' : '' }}>
                                    <ReviewItemLoader roundImage />
                                </div>
                            ))
                        }
                    </div>

                    <AdComponent
                    className="margin-top-32"
                    type="large"
                    adChannel="Album/LeftSecond"
                    />
                    
                    {((backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length > 0) || backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length <= 0 && backend.homeLists.status === status.BEGIN) && 
                        <Heading
                        text={`Lists With ${backend.title}`}
                        redirect={`${backend.url_slug}lists?order_by=-like_count`}
                        className="margin-top-32"
                        />
                    }

                    {((backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length > 0) || backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length <= 0 && backend.homeLists.status === status.BEGIN) && 
                        <div className="section-block">
                            {backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length > 0 && 
                            backend.homeLists.data.filter((_, i) => i < 3).map((item, index) => (
                                <ListItem
                                list={item}
                                isFirst={index === 0}
                                isLast={index === backend.homeLists.data.filter((_, i) => i < 3).length-1}
                                key={`albumList-${backend.id}-${item.uid}`}
                                />
                            ))}

                            {backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length <= 0 && backend.homeLists.status === status.BEGIN &&
                                [0,1,2,3].map((i) => (
                                    <div key={`album-lists-loader-${i}`} style={{ width: '100%', borderTop: i !== 0 ? '1px solid var(--lightGrey)' : '' }}>
                                        <ListItemLoader roundImage />
                                    </div>
                                ))
                            }
                        </div>
                    }

                    <AdComponent 
                    className="margin-top-32"
                    type="large"
                    adChannel="Album/LeftThird"
                    skipProAd
                    />
                </div>
                
                {/* RIGHT SIDE */}
                <div className="section-1">
                    <StickyScrolling enabled={!isSmallScreen}>
                        <div className="padding-top-32" />

                        <OptionsMenu
                        options={[
                            {
                                text: "Write review",
                                onPress: () => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }

                                    openPopup("CreateReview", { content: backend, cacheRating: true })
                                },
                            },
                            {
                                text: wantObj ? "Remove from Listen Later" : "Add to Listen Later",
                                onPress: () => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }

                                    if (wantObj) {
                                        console.log({wantObj})
                                        dispatch(
                                            deleteWant(wantObj.uid)
                                        )
                                    } else {
                                        dispatch(
                                            createWant(backend.id, "album", false)
                                        )
                                    }
                                },
                                icon: (deleteWantStatus === status.BEGIN && lastDeletedWantContentId == backend.id) ? "loading" : null,
                            },
                            {
                                text: "Add album to a list",
                                onPress: () => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }

                                    openPopup("AddToList", { popupTitle: `Add '${backend.title}' to a List`, content: backend })
                                },
                            },
                            {
                                text: "Listen on Spotify",
                                redirect: `${spotify}`,
                                target: "_blank"
                            },
                            {
                                text: "Share on Musicboard",
                                onPress: () => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }

                                    openPopup("SendContent", { type: "album", content: backend })
                                },
                            },
                            {
                                onPress: () => openPopup("ShareAlbum", { album: backend }),
                                element: (
                                    <ShareElement 
                                    type="album" 
                                    title={`${backend.title} by ${backend.artist.name}`} 
                                    image={backend.cover} 
                                    />
                                )
                            },
                        ]}
                        />

                        <Heading
                        text={`Information`}
                        className="margin-top-32"
                        />

                        <div className="section-block">
                            <div className="padding-around">
                                {backend.rating_distribution && backend.rating_distribution.length > 0 &&
                                    <RatingsGraph ratings={backend.rating_distribution} />
                                }
                                
                                {(!backend.rating_distribution || backend.rating_distribution.length <= 0) &&
                                    <RatingDistributionLoader />
                                }
                            </div>
                            
                            <Suspense fallback={<div />}>
                                <AlbumInformationSection album={backend} />
                            </Suspense>
                        </div>
                        
                        <AdComponent 
                        className="margin-top-32"
                        type="small"
                        adChannel="Album/RightSmall"
                        />

                        {discography && discography.data && discography.data.filter((disc) => disc.id !== backend.id).length > 0 &&
                            <Heading
                            text={`More From ${backend.artist.name}`}
                            redirect={`${backend.artist.url_slug}releases`}
                            className="margin-top-32"
                            />
                        }

                        {discography && discography.data && discography.data.filter((disc) => disc.id !== backend.id).length > 0 &&
                            <div className="section-block padding-around-8">
                                {discography.data
                                .filter((disc) => disc.id !== backend.id)
                                .filter((_, i) => i < 10)
                                .map((item, index) => (
                                    <SmallReleaseItem
                                    key={`albumRelated-${backend.id}-${item.id}`}
                                    release={item}
                                    artist={item.artist}
                                    />
                                ))}
                            </div>
                        }

                        {isModerator &&
                        <>
                            <Heading
                            text={`Moderator`}
                            className="margin-top-32"
                            />
                            <div className='section-block padding-around'>
                                <h5 className='black'>ID: {backend.id}</h5>
                                <p className='highDarkGrey margin-top-8'>UID: {backend.uid}</p>
                                <p className='highDarkGrey margin-top-8'>Deezer ID: {backend.deezer_id}</p>

                                <a 
                                href={`https://api.musicboard.app/admin/content/album/${backend.id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                >
                                    <Button
                                    className='margin-top-16'
                                    type="secondary"
                                    text="Open in Admin"
                                    height={41}
                                    textStyle={{ fontSize: 14 }}
                                    />
                                </a>
                            </div>
                        </>
                        }

                        {isLoggedIn &&
                            <div className="flex-row margin-top-16">
                                <Link to={`/album/${backend.id}/edit`} className="col-1">
                                    <Button
                                    showIcon
                                    iconSize={21}
                                    iconName="edit"
                                    iconColor="var(--black)"
                                    type="secondary"
                                    text="Contribute to This Album"
                                    height={41}
                                    textStyle={{ fontSize: 14 }}
                                    />
                                </Link>
                                
                                <Button
                                className={"margin-left-12"}
                                showIcon
                                iconSize={23}
                                iconName="flag"
                                iconColor="var(--black)"
                                type="secondary"
                                squareButton
                                height={41}
                                textStyle={{ fontSize: 14 }}
                                onClicked={() => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }
                                    
                                    openPopup("ReportDuplicate", { contentOne: backend, contentTwo: null })
                                }}
                                />
                            </div>
                        }
                        
                        {backend.duplicated_albums && backend.duplicated_albums.length > 0 &&
                            <Button
                            className={"margin-top-12"}
                            iconColor="var(--black)"
                            type="secondary"
                            text="Duplicated Versions"
                            height={41}
                            textStyle={{ fontSize: 14 }}
                            onClicked={() => {
                                openPopup("DuplicatedAlbums", { duplicatedAlbums: backend.duplicated_albums })
                            }}
                            />
                        }

                        <AdComponent 
                        className="margin-top-32"
                        type="vertical"
                        adChannel="Album/RightVertical"
                        />

                        <MiniFooter className={"margin-top-24"} />
                    </StickyScrolling>
                </div>
            </div>
            
            <div style={{ height: 64 }} />
        </div>
    )
}