export const stickerClassEnums = {
    0: "love-it",
    1: "pure-gold",
    2: "this-or-that",
    3: "steal-this",
    4: "fresh",
    5: "save-for-later",
    6: "vibes",
    7: "cut-it",
    8: "wip",
    9: "take-a-peek",
    10: "thoughts",
    11: "keep-exploring",
    12: "winner",
    13: "dope",
    14: "ok",
    15: "not-sure",
    16: "killed-it",
    17: "idea",
    18: "check-the-deets",
    19: "hot",
}

export const stickerNameEnums = {
    0: "Love It!",
    1: "Pure Gold",
    2: "This or That?",
    3: "Steal This",
    4: "Fresh!",
    5: "Save for Later",
    6: "VIBES",
    7: "Cut It...",
    8: "W.I.P.",
    9: "Take a Peek",
    10: "Thoughts?",
    11: "Keep Exploring",
    12: "Winner!",
    13: "Dope!",
    14: "OK.",
    15: "Not Sure...",
    16: "Killed It!",
    17: "Great Idea",
    18: "Check the Deets",
    19: "HOT!",
}

//Themes
export const themes = [
    { 
        id: null, 
        key: null, 
        label: "None", 
        image: "https://cdn.musicboard.app/musicboard/assets/themes/none-theme-item-3.png" 
    },
    { 
        id: 0, 
        key: "light", 
        label: "Light", 
        image: "https://cdn.musicboard.app/musicboard/assets/themes/light-theme-item.png" 
    },
    { 
        id: 1, 
        key: "dark", 
        label: "Dark", 
        image: "https://cdn.musicboard.app/musicboard/assets/themes/dark-theme-item.png" 
    },
    { 
        id: 2, 
        key: "black", 
        label: "Blackout", 
        image: "https://cdn.musicboard.app/musicboard/assets/themes/black-theme-item.png" 
    },
    { 
        id: 3, 
        key: "red", 
        label: "Blood Red", 
        image: "https://cdn.musicboard.app/musicboard/assets/themes/red-theme-item.png" 
    },
    { 
        id: 4, 
        key: "blue", 
        label: "Midnight Blue", 
        image: "https://cdn.musicboard.app/musicboard/assets/themes/blue-theme-item.png" 
    },
    { 
        id: 5, 
        key: "green", 
        label: "Forest Green", 
        image: "https://cdn.musicboard.app/musicboard/assets/themes/green-theme-item.png" 
    },
    { 
        id: 6, 
        key: "pink", 
        label: "Amethyst", 
        image: "https://cdn.musicboard.app/musicboard/assets/themes/pink-theme-item.png" 
    },
    { 
        id: 7, 
        key: "teal", 
        label: "Emerald", 
        image: "https://cdn.musicboard.app/musicboard/assets/themes/teal-theme-item.png" 
    },
]

export const themeEnums = {
    0: "light",
    1: "dark",
    2: "black",
    3: "red",
    4: "blue",
    5: "green",
    6: "pink",
    7: "teal",
}

export const recordTypeEnums = {
    "album": "album",
    "ep": "EP",
    "single": "single",
    "mixtape": "mixtape",
    "live": "live",
    "acoustic": "acoustic",
    "instrumental": "instrumental",
    "soundtrack": "soundtrack",
    "theme_song": "theme song",
    "anthem": "anthem",
    "demo": "demo",
    "deluxe": "reissue",
    "remix": "remix",
    "cover": "cover",
    "dj_mix": "DJ ix",
    "compile": "compilation",
    "unofficial": "unofficial release",
    "miscellaneous": "miscellaneous",
}

export const recordTypeEnumsLong = {
    "album": "an Album",
    "ep": "an EP",
    "single": "a Single",
    "mixtape": "a Mixtape",
    "live": "a Live",
    "acoustic": "an Acoustic",
    "instrumental": "an Instrumental",
    "soundtrack": "a Soundtrack",
    "theme_song": "a Theme Song",
    "anthem": "an Anthem",
    "demo": "a Demo",
    "deluxe": "a Reissue",
    "remix": "a Remix",
    "cover": "a Cover",
    "dj_mix": "a DJ Mix",
    "compile": "a Compilation",
    "unofficial": "an Unofficial Release",
    "miscellaneous": "Miscellaneous",
}