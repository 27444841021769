import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'

const defaultSuggestions = {
	suggestion: null,
};

const suggestions = (state = defaultSuggestions, action) => {
	switch (action.type) {
        case types.ADD_SUGGESTION:
            return {
                ...state,
				suggestion: action.payload,
            }
        case types.REMOVE_SUGGESTION:
            return {
                ...state,
				suggestion: null,
            }
		default:
			return state;
	}
};

export default suggestions;