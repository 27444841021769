import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { calculateNewRating, calculateUpdatedRating } from '../utils/calculate-ratings';
import moment from 'moment';
import { checkNull, checkNullNumber } from '../utils/format-text';

const defaultGenres = {
    genres: [],
	getGenresStatus: status.DEFAULT,
	lastFetchedGenres: null,

	styles: [],
	getStylesStatus: status.DEFAULT,
	lastFetchedStyles: null,

	favoriteGenres: [],
	getFavoriteGenresStatus: status.DEFAULT,
	createFavoriteGenreStatus: status.DEFAULT,
	deleteFavoriteGenreStatus: status.DEFAULT,
	lastDeletedFavoriteGenre: null,

	errorMessage: null,
};

const genres = (state = defaultGenres, action) => {
	let id = action.meta && action.meta.params ? action.meta.params.id : null
	switch (action.type) {
		case types.GET_GENRES:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
                        getGenresStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						genres: action.payload,
						getGenresStatus: status.SUCCESS,
						lastFetchedGenres: new Date(),
                        errorMessage: null
					}
				case status.ERROR:
					return {
                        ...state,
                        genres: [],
                        getGenresStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}

		case types.GET_STYLES:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						getStylesStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						styles: action.payload,
						getStylesStatus: status.SUCCESS,
						lastFetchedStyles: new Date(),
						errorMessage: null
					}
				case status.ERROR:
					return {
						...state,
						styles: [],
						getStylesStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}
		
		case types.GET_FAVORITE_GENRES:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						getFavoriteGenresStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						favoriteGenres: action.payload,
						getFavoriteGenresStatus: status.SUCCESS,
						errorMessage: null
					}
				case status.ERROR:
					return {
						...state,
						favoriteGenres: [],
						getFavoriteGenresStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}
		
		case types.CREATE_FAVORITE_GENRE:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						favoriteGenres: [
							...state.favoriteGenres,
							{
								uid: null,
								genre: action.meta.params.genre
							}
						],
						createFavoriteGenreStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						favoriteGenres: state.favoriteGenres.map((favorite) => {
							if (favorite.genre.id !== action.payload.genre) {
								return favorite
							}

							return ({
								...favorite,
								uid: action.payload.uid,
								created_at: action.payload.created_at
							})
						}),
						createFavoriteGenreStatus: status.SUCCESS,
						errorMessage: null
					}
				case status.ERROR:
					return {
						...state,
						favoriteGenres: state.favoriteGenres.filter((favorite) => favorite.genre.id !== action.meta.params.genre.id),
						createFavoriteGenreStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}
		
		case types.DELETE_FAVORITE_GENRE:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						deleteFavoriteGenreStatus: status.BEGIN,
						favoriteGenres: state.favoriteGenres.filter((favorite) => favorite.uid !== action.meta.params.favorite.uid),
						lastDeletedFavoriteGenre: action.meta.params.favorite,
					}
				case status.SUCCESS:
					return {
						...state,
						deleteFavoriteGenreStatus: status.SUCCESS,
						errorMessage: null
					}
				case status.ERROR:
					return {
						...state,
						deleteFavoriteGenreStatus: status.ERROR,
						favoriteGenres: [ ...state.favoriteGenres, action.meta.params.favorite ],
						lastDeletedFavoriteGenre: null,
						errorMessage: errorToString(action.payload),
					}
			}
		
		default:
			return state;
	}
};

export default genres;