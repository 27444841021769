import styles from './dropdown.module.css'
import React, { useState, useRef, useEffect } from 'react'

export default function Dropdown({
    appendId="",
    visible=true,
    hideDropdown=()=>{},
    children,
    position="center",
    vertical="top",
    offset=0,
    className,
    style,
    zIndex=2000,
    simpleAnimation,
    ...props
}) {

    //Handle click outside
    const wrapperRef = useRef()
    
    //State
    const [compareVisible, setCompareVisible] = useState(visible)

    //Update state
    useEffect(() => {
        setTimeout(() => {
            setCompareVisible(visible)
        }, 20)
    }, [visible])

    //Handle click outside
    function handleClickOutside(event) {
        const item = document.getElementById(`${appendId}-dropdown-wrapper`)
        if (wrapperRef.current) {
            if (!wrapperRef.current.contains(event.target)) {
                if (compareVisible && item.style.display !== "none") {
                    hideDropdown()
                }
            }
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return(() => {
            document.removeEventListener('click', handleClickOutside);
        })
    }, [compareVisible])

    return (
        <div 
        id={`${appendId}-dropdown-wrapper`} 
        ref={wrapperRef} 
        className={`${styles.dropdown} ${visible ? styles.visible : styles.disabled} ${simpleAnimation ? styles.dropdownSimple : ""} ${position === "right" ? styles.right : position === "left" ? styles.left : ""} ${className}`}
        style={{
            top: vertical === "top" ? offset : undefined,
            bottom: vertical === "bottom" ? offset : undefined,
            zIndex: zIndex,
            ...style
        }}
        {...props}
        >
            {children}
        </div>
    )
}
