import { status, types } from '../constants/action-types'
import { post, put, get, patch, patchRaw, delete_ } from '../utils/fetch'
import { url } from '../config'
import { getNormal, patchNormal, postNormal } from '../utils/fetch-normal';


export const fetchUser = (username) => 
    get(types.FETCH_USER, `${url}/v2/users/from_username/?username=${username}`, { username, reset: true });


export const getUsersRatings = ({
    username,
    uid=null, 
    type=types.GET_USERS_DIARY, 
    order_by="-created_at", 
    limit=24,
    offset=0,
    isPrivate="",
    content_type="",
    genres__id="",
    styles__id="",
    release_date__year="",
    release_date__lte="",
    release_date__gte="",
    record_type="",
}) => (dispatch, getState) =>
    dispatch(
        get(type, `${url}/v2/ratings/${uid === getState().auth.uid ? "mine/" : ""}?content_type=${content_type}&order_by=${order_by}&creator=${uid}&limit=${limit}&offset=${offset}&genres__id=${genres__id}&styles__id=${styles__id}&release_date__year=${release_date__year}&release_date__lte=${release_date__lte}&release_date__gte=${release_date__gte}&record_type=${record_type}&private=${isPrivate}&speedup=true`, { uid, username, reset: true, speedup: true, limit: limit })
    )

export const getWants = ({
    username,
    uid,
    order_by="-created_at",
    content_type="",
    genres__id="",
    styles__id="",
    release_date__year="",
    release_date__lte="",
    release_date__gte="",
    record_type="",
    limit=24,
    offset=0,
}) => 
    get(types.GET_WANTS, `${url}/v2/wants/?limit=${limit}&offset=${offset}&creator=${uid}&order_by=${order_by}&content_type=${content_type}&genres__id=${genres__id}&styles__id=${styles__id}&release_date__year=${release_date__year}&release_date__lte=${release_date__lte}&release_date__gte=${release_date__gte}&record_type=${record_type}`, { uid, username, reset: true });

export const getRandomWant = (uid) => 
    get(types.GET_RANDOM_WANT, `${url}/v2/wants/get_random_want/?uid=${uid}`, { uid });


export const getFavoriteAlbums = (username, uid) => 
    get(types.GET_FAVORITE_ALBUMS, `${url}/v2/favorite_albums/?creator=${uid}`, { uid, username, reset: true });


export const getUsersLists = ({
    username,
    uid=null,
    type=types.GET_USERS_LISTS,
    order_by="-created_at",
    pinned="",
    title="",
    has_reports="",
    isPrivate="",
    limit=16,
    offset=0,
}) => (dispatch, getState) => 
    dispatch(
        get(type, `${url}/v2/lists/${uid === getState().auth.uid ? "mine/" : ""}?limit=${limit}&offset=${offset}&limit_content=10&order_by=${order_by}&creator=${uid}&pinned=${pinned}&title=${title}&has_reports=${has_reports}&private=${isPrivate}&speedup=true`, { uid, username, reset: true, speedup: true, limit: limit })
    )

export const getUsersReviews = ({
    username,
    uid=null,
    content_type="",
    type=types.GET_USERS_REVIEWS,
    order_by="-created_at",
    pinned="",
    title="",
    has_reports="",
    isPrivate="",
    genres__id="",
    styles__id="",
    release_date__year="",
    release_date__lte="",
    release_date__gte="",
    record_type="",
    limit=16,
    offset=0,
}) => (dispatch, getState) => 
    dispatch(
        get(type, `${url}/v2/reviews/${uid === getState().auth.uid ? "mine/" : ""}?limit=${limit}&offset=${offset}&order_by=${order_by}&creator=${uid}&pinned=${pinned}&title=${title}&has_reports=${has_reports}&content_type=${content_type}&genres__id=${genres__id}&styles__id=${styles__id}&release_date__year=${release_date__year}&release_date__lte=${release_date__lte}&release_date__gte=${release_date__gte}&record_type=${record_type}&private=${isPrivate}&speedup=true`, { uid, username, reset: true, speedup: true, limit: limit })
    );

export const getFollowers = ({
    username,
    uid,
    order_by="-created_at",
    offset=0,
}) => 
    get(types.GET_FOLLOWERS, `${url}/v2/users/?order_by=${order_by}&follows=${uid}&offset=${offset}&speedup=true`, { speedup: true, limit: 24, uid, username, reset: true });

export const getFollowing = ({
    username,
    uid,
    order_by="-created_at",
    offset=0,
}) => 
    get(types.GET_FOLLOWING, `${url}/v2/users/?order_by=${order_by}&followed_by=${uid}&offset=${offset}&speedup=true`, { speedup: true, limit: 24, uid, username, reset: true });


export const followUser = (uid) => 
    post(types.FOLLOW_USER, `${url}/v2/follows/`, { followed_user: uid }, { uid });

export const followMultipleUsers = (users) => (dispatch, getState) => {
    dispatch({ type: types.FOLLOW_MULTIPLE_USERS, meta: { status: status.BEGIN, params: { users } } })

    Promise.all(
        users.map(({ uid }) => {
            return postNormal(`${url}/v2/follows/`, {
                followed_user: uid
            })
        })
    )
    .then((res) => {
        dispatch({ type: types.FOLLOW_MULTIPLE_USERS, meta: { status: status.SUCCESS, params: { users } }, payload: res })

    })
    .catch((err) => {
        dispatch({ type: types.FOLLOW_MULTIPLE_USERS, meta: { status: status.ERROR, params: { users } }, payload: err })

    })
};

export const unfollowUser = (followUid, userUid) => 
    delete_(types.UNFOLLOW_USER, `${url}/v2/follows/${followUid}/`, { uid: userUid, followUid, });


export const getAllUsers = ({
    order_by="-created_at",
    username="",
    name="",
    advanced_search=""
}) => 
    get(types.GET_ALL_USERS, `${url}/v2/users/?followed_by=&follows=&name__icontains=${name}&order_by=${order_by}&username__icontains=${username}&advanced_search=${advanced_search}`, { reset: true });

export const getAllBlockedUsers = (orderBy="-created_at") => 
    get(types.GET_ALL_BLOCKED_USERS, `${url}/v2/users/blocked/?order_by=${orderBy}`, { reset: true });

export const getRecommendedUsers = () => 
    get(types.GET_RECOMMENDED_USERS, `${url}/v2/users/suggested_personal/?speedup=true`, { reset: true, speedup: true, limit: 24 });

export const blockUser = (uid) => 
    post(types.BLOCK_USER, `${url}/v2/blocks/`, { blocked_user: uid }, { uid });

export const unblockUser = (blockUid, userUid) => 
    delete_(types.UNBLOCK_USER, `${url}/v2/blocks/${blockUid}/`, { uid: userUid, blockUid, });


export const getUserUid = (username, uniqueRandom) =>
    post(types.GET_USER_UID, `${url}/v2/users/get_uid/`, { username }, { username, uniqueRandom })

export const getUserStatistics = (username, uid) => 
    get(types.GET_USER_STATISTICS, `${url}/v2/users/${uid}/statistics/`, { username, uid });

export const getUserFollowStatus = (username, uid) => 
    get(types.GET_USER_FOLLOW_STATUS, `${url}/v2/users/${uid}/follow_status/`, { username, uid });

export const getUserYearRatings = (username, uid, year, content_type, order_by="-rating", limit=12) => 
    get(types.GET_USER_YEAR_RATINGS, `${url}/v2/ratings/?creator=${uid}&content_type=${content_type}&release_date__year=${year}&order_by=${order_by}&limit=${limit}`, { uid, username, year, content_type, reset: true });


export const createFavoriteAlbum = (id, fromDeezer, priority) => (dispatch, getState) => {
    const params = {
        album: id,
        priority,
    }

    dispatch({ 
        type: types.CREATE_FAVORITE_ALBUM, 
        meta: { 
            status: status.BEGIN,
            params,
        }
    });
    
    postNormal(`${url}/v2/${fromDeezer ? "dz_albums" : "albums"}/`, {
        id
    })
    .then((res) => {
        postNormal(`${url}/v2/favorite_albums/`, {
            album: res.id,
            priority,
        })
        .then((res) => {
            dispatch({ type: types.CREATE_FAVORITE_ALBUM, meta: { status: status.SUCCESS, params }, payload: res });

        })
        .catch((err) => {
            dispatch({ type: types.CREATE_FAVORITE_ALBUM, meta: { status: status.ERROR, params }, payload: err });
    
        });

    })
    .catch((err) => {
        dispatch({ type: types.CREATE_FAVORITE_ALBUM, meta: { status: status.ERROR, params }, payload: err });

    });

}

//favoriteAlbums are objects with atleast { uid, priority }
export const updateFavoriteAlbums = (favoriteAlbums) => (dispatch, getState) => {
    dispatch({ type: types.UPDATE_FAVORITE_ALBUMS, meta: { status: status.BEGIN, }});

    Promise.all(
        favoriteAlbums.map((favoriteAlbum, i) => {
            return patchNormal(`${url}/v2/favorite_albums/${favoriteAlbum.uid}/`, {
                priority: favoriteAlbum.priority,
            });
        })
    )
    .then((results) => {
        dispatch({ type: types.UPDATE_FAVORITE_ALBUMS, meta: { status: status.SUCCESS } });

    })
    .catch((err) => {
        dispatch({ type: types.UPDATE_FAVORITE_ALBUMS, meta: { status: status.ERROR }, payload: err });

    });
}


export const deleteFavoriteAlbum = (uid) =>
    delete_(types.DELETE_FAVORITE_ALBUM, `${url}/v2/favorite_albums/${uid}/`, { uid })


export const getUsersSuggestedUsers = (username, uid, genreIds) => (dispatch, getState) => {
    dispatch({ type: types.GET_USERS_SUGGESTED_USERS, meta: { status: status.BEGIN, params: { username, uid, reset: true } } });

    getNormal(`${url}/v2/users/suggested_personal/?speedup=true&filter_genres=${genreIds.join(",")}&limit=12`)
    .then((response) => {
        //Only get unique users.
        const nonDuplicateResults = []
        const map = new Map();
        for (const user of response.results) {
            if(!map.has(user.uid)){
                map.set(user.uid, true);
                nonDuplicateResults.push(user);
            }
        }

        const randomOrderResults = (
            nonDuplicateResults
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
        )
        
        const payload = {
            next: response.next,
            results: randomOrderResults.filter((_, i) => i < 10),
        }

        dispatch({ type: types.GET_USERS_SUGGESTED_USERS, meta: { status: status.SUCCESS, params: { username, uid, reset: true } }, payload });

    })
    .catch((err) => {
        dispatch({ type: types.GET_USERS_SUGGESTED_USERS, meta: { status: status.ERROR, params: { username, uid, reset: true } }, payload: err });
    });
}