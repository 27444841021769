import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { types } from '../../constants/action-types';

export default function CacheData({ children }) {
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(() => {
        const element = document.getElementById('cdjs-0.1.0-mb');
        if (element && element.textContent) {
            console.log("Has CDJS", element.textContent)
            
            let json;
            try {
                console.log("Parsed CDJS")
                json = JSON.parse(element.textContent)
            } catch (e) {
                json = null;
            }
            
            if (json) {
                console.log("Inside CDJS")
                if (json.type === "album" && json.id &&  json.uid && json.deezer_id && json.title) {
                    console.log("Inside album")
                    dispatch({ type: types.SET_INIT_ALBUM, payload: json })

                } else if (json.type === "track" && json.id && json.uid && json.deezer_id && json.title) {
                    console.log("Inside track")
                    dispatch({ type: types.SET_INIT_TRACK, payload: json })

                } else if (json.type === "artist" && json.id && json.uid && json.deezer_id && json.name) {
                    console.log("Inside artist")
                    dispatch({ type: types.SET_INIT_ARTIST, payload: json })

                } else if (json.type === "review" && json.uid && json.url_slug) {
                    console.log("Inside review")
                    dispatch({ type: types.SET_INIT_REVIEW, payload: json })

                } else if (json.type === "thread" && json.uid && json.url_slug) {
                    console.log("Inside thread")
                    dispatch({ type: types.SET_INIT_THREAD, payload: json })

                } else if (json.type === "list" && json.uid && json.url_slug) {
                    console.log("Inside list")
                    //Normal filters
                    const orderBy = new URLSearchParams(location.search).get("order_by") ? new URLSearchParams(location.search).get("order_by") : null
                    const contentType = new URLSearchParams(location.search).get("content_type") ? new URLSearchParams(location.search).get("content_type") : ""
                    const genre = new URLSearchParams(location.search).get("genre") ? new URLSearchParams(location.search).get("genre") : ""
                    const release_year = new URLSearchParams(location.search).get("release_year") ? new URLSearchParams(location.search).get("release_year") : ""

                    const hasFilters = (genre !== "" || release_year !== "" || contentType !== "" || orderBy !== null)

                    dispatch({ type: types.SET_INIT_LIST, payload: json, meta: { params: { skipTheContent: hasFilters } } })

                } else if (json.type === "rating" && json.uid && json.url_slug) {
                    console.log("Inside rating")
                    dispatch({ type: types.SET_INIT_RATING, payload: json })

                } else if (json.uid && json.username) {
                    console.log("Inside user")
                    dispatch({ type: types.SET_INIT_USER, payload: json })

                }
            }
        }
    }, [])
    
    return children
}
