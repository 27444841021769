import { useSelector } from 'react-redux';

export function useTrack() {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    
    return (eventName, properties) => {
        if (isLoggedIn && window.analytics) {
            window.analytics.track(eventName, properties);
        }
    };
}
