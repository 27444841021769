import styles from './pagecontrols.module.css'

import React, { useMemo } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import { useHref } from '../../hooks/href'

import Button from '../Button'

// Utility function to generate the pagination range
const getPaginationRange = (currentPage, pageAmount) => {
    const delta = 2; // Number of pages to show before and after the current page
    const range = [];
    const rangeWithDots = [];
    let l;

    range.push(1);
    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
        if (i < pageAmount && i > 1) {
            range.push(i);
        }
    }
    range.push(pageAmount);

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

export default function PageControls({
    hasNext,
    hasPrev,
    currentPage=1,
    pageAmount,
    className
}) {
    //General
    const match = useRouteMatch()
    const { getPagination } = useHref()

    const pageAmountArray = useMemo(() => {
        return getPaginationRange(Number(currentPage), pageAmount);
    }, [pageAmount, currentPage])

    if (!hasNext && !hasPrev) {
        return null
    }

    return (
        <div className={className}>
            <div className="bottom-divider" />

            <div className="flex-row justify-between margin-top-16">
                <Link to={`${match.url}${getPagination(Number(currentPage)-1, "page")}`} style={{ opacity: hasPrev ? 1 : 0, pointerEvents: hasPrev ? 'all' : 'none' }}>
                    <Button 
                    text="Previous" 
                    type='secondary'
                    height={38}
                    textStyle={{ fontSize: 14 }}
                    />
                </Link>

                {pageAmount &&
                    <div className="flex-row">
                        {pageAmountArray.map((page, i) => (
                            page === '...' ? 
                                <span key={i} className={styles.pageWrapper}>...</span>
                            : 
                                <Link key={i} to={`${match.url}${getPagination(page, "page")}`} className={styles.pageWrapper}>
                                    <p className={`${styles.page} ${currentPage == page ? styles.active : ""}`}>{page}</p>
                                </Link>
                        ))}
                    </div>
                }

                <Link to={`${match.url}${getPagination(Number(currentPage)+1, "page")}`} style={{ opacity: hasNext ? 1 : 0, pointerEvents: hasNext ? 'all' : 'none' }}>
                    <Button 
                    text="Next" 
                    type='secondary'
                    height={38}
                    textStyle={{ fontSize: 14 }}
                    />
                </Link>
            </div>
        </div>
    )
}
