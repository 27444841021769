import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { version } from '../../config'
import { useLocation } from 'react-router-dom';
import { createABCTest, createOneTwentyTest } from '../../utils/abTesting';
import { useTrack } from '../../hooks/tracking';

export default function Segment({ children }) {
    const location = useLocation()
    const track = useTrack()

    const uid = useSelector(state => state.auth.uid)
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

    const localUid = useSelector(state => state.local.localUid)
    const theme = useSelector(state => state.local.theme)

    const username = useSelector(state => state.me.username)
    const name = useSelector(state => state.me.name)
    const email = useSelector(state => state.me.email)
    const profile_picture = useSelector(state => state.me.profile_picture)
    const is_verified = useSelector(state => state.me.is_verified)
    const is_pro = useSelector(state => state.me.is_pro)
    const tier = useSelector(state => state.me.tier)
    const biography = useSelector(state => state.me.biography)
    const website = useSelector(state => state.me.website)
    const counts = useSelector(state => state.me.counts)
    const background = useSelector(state => state.me.background)

    const homeReviewsIsFriends = useSelector(state => state.local.homeReviewsIsFriends)
    const homeListsIsFriends = useSelector(state => state.local.homeListsIsFriends)
    const homeAlbumsIsPopular = useSelector(state => state.local.homeAlbumsIsPopular)

    const heroSectionTestSalt = "ox6"
    const heroSectionTest = useMemo(() => {
        return createABCTest(localUid, heroSectionTestSalt)
    }, [localUid, heroSectionTestSalt])

    const isBot = useMemo(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return userAgent.includes('bot') || 
               userAgent.includes('crawler') || 
               userAgent.includes('spider') ||
               userAgent.includes('googlebot') ||
               userAgent.includes('bingbot') ||
               userAgent.includes('applebot') ||
               userAgent.includes('yandexbot') ||
               userAgent.includes('baiduspider');
    }, []);

    useEffect(() => {
        if (!isBot) {
            track("Opened site");
    
            if (uid && isLoggedIn) {
                window.analytics.identify(uid, {
                    "isLoggedIn": isLoggedIn,
                    "picture": profile_picture,
                    "email": email,
                    "name": username,
                    "written_name": name,
                    "local_uid": localUid,
                    "heroSectionTest": heroSectionTest,
                    "uid": uid,
                    "is_verified": is_verified,
                    "is_pro": is_pro,
                    "tier": tier,
                    "biography": biography,
                    "website": website,
                    "has_background": (background !== null && background !== undefined),
    
                    "Web Version": version,
                    "Web Build Version": Cookies.get("Build-Version"),
                    
                    "web_home_reviews_is_friends": homeReviewsIsFriends,
                    "web_home_lists_is_friends": homeListsIsFriends,
                    "web_home_albums_is_popular": homeAlbumsIsPopular,
    
                    "web_theme": theme
                });
    
                if (counts) {
                    window.analytics.identify({
                        "follows_count": counts.follows,
                        "followed_by_count": counts.followed_by,
                        "rating_set_count": counts.rating_set,
                        "review_set_count": counts.review_set,
                        "want_list_count": counts.want_list,
                        "list_set_count": counts.list_set,
                        "rated_tracks_count": counts.rated_tracks,
                        "rated_albums_count": counts.rated_albums,
                        "rated_artists_count": counts.rated_artists
                    });
                }
    
            } else {
                //window.analytics.identify({
                //    "isLoggedIn": isLoggedIn,
                //    "local_uid": localUid,
                //    "heroSectionTest": heroSectionTest,
                //    "Web Version": version,
                //    "Web Build Version": Cookies.get("Build-Version"),
                //    "web_theme": theme
                //});
            }
        }
    }, [])

    useEffect(() => {
        if (!isBot) {
            track("Opened location", {
                "pathname": location.pathname,
                "search": location.search,
                "state": location.state,
                "hash": location.hash,
            });
        }
    }, [ location ])

    return children;
}
