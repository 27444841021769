import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import NavBar from '../NavBar';

import Icon from '../../components/Icon';

import UserNavigator from './components/UserNavigator';
import ListNavigator from './components/ListNavigator';
import NewsNavigator from './components/NewsNavigator';
import AlbumNavigator from './components/AlbumNavigator';
import ArtistNavigator from './components/ArtistNavigator';
import SearchNavigator from './components/SearchNavigator';

import Review from '../../routes/Review';
import List from '../../routes/List';
import Home from '../../routes/Home';
import Track from '../../routes/Track';

const Thread = lazy(() => import('../../routes/Thread'));
const Pro = lazy(() => import('../../routes/Pro'));
const Apps = lazy(() => import('../../routes/Apps'));
const Contact = lazy(() => import('../../routes/Contact'));
const FAQ = lazy(() => import('../../routes/FAQ'));
const Privacy = lazy(() => import('../../routes/Privacy'));
const Guidelines = lazy(() => import('../../routes/Guidelines'));
const Terms = lazy(() => import('../../routes/Terms'));
const Welcome = lazy(() => import('../../routes/Welcome'));
const Onboarding = lazy(() => import('../../routes/Onboarding'));
const About = lazy(() => import('../../routes/About'));
const ThankYou = lazy(() => import('../../routes/ThankYou'));
const Settings = lazy(() => import('../../routes/Settings'));
const ResetPassword = lazy(() => import('../../routes/ResetPassword'));
const VerifyEmail = lazy(() => import('../../routes/VerifyEmail'));

const ContributorGuidelines = lazy(() => import('../../routes/ContributorGuidelines'));
const ContributorReleaseGuidelines = lazy(() => import('../../routes/ContributorReleaseGuidelines'));
const ContributorArtistGuidelines = lazy(() => import('../../routes/ContributorArtistGuidelines'));

const Download = lazy(() => import('../../routes/Download'));

const Rating = lazy(() => import('../../routes/Rating'));
const Users = lazy(() => import('../../routes/Users'));
const Reviews = lazy(() => import('../../routes/Reviews'));
const Lists = lazy(() => import('../../routes/Lists'));
const FriendsReviews = lazy(() => import('../../routes/FriendsReviews'));
const FriendsLists = lazy(() => import('../../routes/FriendsLists'));
const FriendsRatings = lazy(() => import('../../routes/FriendsRatings'));
const ContentListing = lazy(() => import('../../routes/ContentListing'));
const Groups = lazy(() => import('../../routes/Groups'));
const PatchNote = lazy(() => import('../../routes/PatchNote'));

const ClearStorageTutorial = lazy(() => import('../../routes/ClearStorageTutorial'));

const MusicLandingPage = lazy(() => import('../../routes/MusicLandingPage'));
const ListLandingPage = lazy(() => import('../../routes/ListLandingPage'));
const MembersLandingPage = lazy(() => import('../../routes/MembersLandingPage'));

export default function Navigator(props) {
    return (
        <div>
            <NavBar />

            <div className='col-1'>

                <div className="heading-div" id="heading-div"></div>

                <Suspense 
                fallback={
                    <div className="full-height">
                        <div className="full-center">
                            <Icon className="margin-auto" icon="loading" size={32} />
                        </div>
                    </div>
                }
                >
                    <Switch>
                        <Route exact path="/" component={Home} />

                        <Route exact path="/welcome" component={Welcome} />
                        <Route path="/onboarding" component={Onboarding} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/apps" component={Apps} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/privacy" component={Privacy} />
                        <Route exact path="/terms" component={Terms} />
                        <Route exact path="/guidelines" component={Guidelines} />

                        <Route exact path="/contributors" component={ContributorGuidelines} />
                        <Route exact path="/contributors/releases" component={ContributorReleaseGuidelines} />
                        <Route exact path="/contributors/artists" component={ContributorArtistGuidelines} />
                        
                        <Route exact path="/download" component={Download} />

                        <Route exact path="/faq" component={FAQ} />
                        <Route exact path="/faq/storage" component={ClearStorageTutorial} />

                        <Route exact path="/pro" component={Pro} />
                        <Route exact path="/pro/complete" component={ThankYou} />

                        <Route path="/settings" component={Settings} />
                        <Route exact path="/reset-password" component={ResetPassword} />
                        <Route exact path="/verify-email" component={VerifyEmail} />

                        <Route path="/search" component={SearchNavigator} />

                        <Route path="/album" component={AlbumNavigator} />
                        <Route path="/artist" component={ArtistNavigator} />
                        
                        <Route path="/track/:titleSlug/:albumSlug/:artistSlug" component={Track} />
                        <Route path="/track/:id" component={Track} />

                        <Route exact path="/users" component={Users} />

                        <Route exact path="/explore/music" component={MusicLandingPage} />
                        <Route exact path="/explore/lists" component={ListLandingPage} />
                        <Route exact path="/explore/members" component={MembersLandingPage} />

                        <Route exact path="/reviews" component={Reviews} />
                        <Route exact path="/reviews/following" component={FriendsReviews} />
                        <Route exact path="/lists" component={Lists} />
                        <Route exact path="/lists/following" component={FriendsLists} />
                        <Route exact path="/ratings/following" component={FriendsRatings} />

                        <Route exact path="/albums" render={() => <ContentListing type="album" />} />
                        <Route exact path="/artists" render={() => <ContentListing type="artist" />} />
                        <Route exact path="/tracks" render={() => <ContentListing type="track" />} />

                        <Route exact path="/patch-notes/:uid" component={PatchNote} />

                        <Route path="/news" component={NewsNavigator} />

                        <Route path="/groups" component={Groups} />

                        {/* List paths navigator */}
                        <Route path="/list" component={ListNavigator} />

                        {/* Review path */}
                        <Route exact path="/review/:uid" component={Review} />
                        
                        {/* Rating paths */}
                        <Route exact path="/rating/:uid" component={Rating} />

                        {/* Thread paths */}
                        <Route exact path="/thread/:uid" component={Thread} />

                        {/* List paths */}
                        <Route exact path="/:usernameSlug/list/:titleSlug" component={List} />

                        {/* Thread paths */}
                        <Route exact path="/:usernameSlug/thread/:titleSlug" component={Thread} />

                        {/* Review paths */}
                        <Route exact path="/:usernameSlug/review/track/:trackSlug/:albumSlug/:artistSlug/:integerSlug" component={Review} />
                        <Route exact path="/:usernameSlug/review/album/:albumSlug/:artistSlug/:integerSlug" component={Review} />
                        <Route exact path="/:usernameSlug/review/artist/:artistSlug/:integerSlug" component={Review} />

                        <Route exact path="/:usernameSlug/review/track/:trackSlug/:albumSlug/:artistSlug" component={Review} />
                        <Route exact path="/:usernameSlug/review/album/:albumSlug/:artistSlug" component={Review} />
                        <Route exact path="/:usernameSlug/review/artist/:artistSlug" component={Review} />
                        
                        {/* Rating paths */}
                        <Route exact path="/:usernameSlug/rating/track/:trackSlug/:albumSlug/:artistSlug/:integerSlug" component={Rating} />
                        <Route exact path="/:usernameSlug/rating/album/:albumSlug/:artistSlug/:integerSlug" component={Rating} />
                        <Route exact path="/:usernameSlug/rating/artist/:artistSlug/:integerSlug" component={Rating} />

                        <Route exact path="/:usernameSlug/rating/track/:trackSlug/:albumSlug/:artistSlug" component={Rating} />
                        <Route exact path="/:usernameSlug/rating/album/:albumSlug/:artistSlug" component={Rating} />
                        <Route exact path="/:usernameSlug/rating/artist/:artistSlug" component={Rating} />

                        <Route path="" component={UserNavigator} />
                    </Switch>
                </Suspense>
            </div>
        </div>
    )
}
