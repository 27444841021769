import styles from './threaditem.module.css'

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

import { status } from '../../constants/action-types';

import { deleteLike, likeContent } from '../../actions/likes-actions';

import { usePopup } from '../../hooks/popup/popup';
import { useTrack } from '../../hooks/tracking';

import { checkNull, checkNullNumber } from '../../utils/format-text';

import Icon from '../Icon';
import Truncate from '../Truncate';
import UserRedirect from '../UserRedirect';
import ShareDropdown from '../ShareDropdown';

import PollOption from './components/PollOption';

function ThreadItem({ 
    thread,

    hideUser,
    hideSocials,
    disableReadMore,

    isLast,
    isFirst,
    isSmall,
    lighterStyle
}) {
    //General
    const { openPopup } = usePopup()
    const dispatch = useDispatch()
    const track = useTrack()

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

    //Responsive
    const isTablet = useMediaQuery({ maxWidth: 768 })

    const smallThread = (isSmall || isTablet)

    //State
    const [extraLikeCount, setExtraLikeCount] = useState(0)

    //likeObj
    const deleteLikeContentUid = useSelector(state => state.likes.deleteLikeContentUid)
    const deleteLikeStatus = useSelector(state => state.likes.deleteLikeStatus)
    const myLikes = useSelector(state => state.me.likes);
    const cachedLikes = useSelector(state => state.me.cachedLikes);
    const likes = [ ...myLikes, ...cachedLikes ];
    const likeIndex = likes.findIndex(like => like.content_ct === "thread" && like.content_uid === thread.uid);
    const likeObj = likeIndex !== -1 ? likes[likeIndex] : null;

    //Blocks
    const myBlocks = useSelector(state => state.me.blocks)
    const cachedBlocks = useSelector(state => state.me.cachedBlocks)
    const blocks = [...myBlocks, ...cachedBlocks]
    const blockObj = blocks.findIndex(block => block.blocked_user === thread.creator.uid) !== -1 ? blocks[blocks.findIndex(block => block.blocked_user === thread.creator.uid)] : null

    //blockedBy
    const blockedByObj = useSelector(state => 
        state.me.blockedBy && state.me.blockedBy.find(block => block.creator === thread.creator.uid)
    )

    //Poll answers
    const myPollAnswers = useSelector(state => state.threads.myPollAnswers)
    const cachedPollAnswers = useSelector(state => state.threads.cachedPollAnswers)
    const pollAnswers = [...myPollAnswers, ...cachedPollAnswers]
    const pollAnswerObj = pollAnswers.findIndex(pollAnswer => pollAnswer.thread === thread?.uid) !== -1 ? pollAnswers[pollAnswers.findIndex(pollAnswer => pollAnswer.thread === thread?.uid)] : null

    if (blockedByObj) {
        return null
    }

    if (blockObj) {
        return null
    }
    
    if (!thread || !thread.uid) {
        return null;
    }

    //Variables
    const totalLikes = checkNullNumber(thread.like_count) + extraLikeCount;

    return (
        <div>
            <div className={`${styles.container} ${isLast ? styles.last : ""} ${isFirst ? styles.first : ""} ${lighterStyle ? styles.lighterStyle : ""} truncate-wrapper link-wrapper`}>
                <Link to={thread.url_slug} className="link-overlay">
                    <span className='link-overlay-title'>{thread.question !== "" ? thread.question : `Thread by ${thread.creator.username}`}</span>
                </Link>

                <div className="link-inner">
                    <div className={`${styles.featuredBox} ${smallThread ? styles.smallThread : ""}`}>
                        <div>
                            <h6>
                                COMMUNITY QUESTION
                            </h6>
                        </div>
                    </div>

                    {thread.question !== "" &&
                        <h4 style={{ marginTop: smallThread ? 12 : 14 }} className="textColor">{thread.question}</h4>
                    }

                    {thread.description !== "" &&
                        <div style={{ marginTop: smallThread ? 8 : 12 }}>
                            <Truncate
                            text={thread.description}
                            textClassName={`textColor`}
                            appendId={thread.uid}
                            disabled={true}
                            showFade={true}
                            height={200}
                            breakHeight={240}
                            />
                        </div>
                    }

                    {thread.image &&
                        <div className="margin-top-16" style={{ marginBottom: thread.poll_options.length > 0 ? 19 : 0 }}>
                            <img src={thread.image_large || thread.image} alt="Thread Image" className={styles.image} />
                        </div>
                    }

                    {thread.poll_options.length > 0 && thread.poll_options.map((option, index) => (
                        <PollOption
                        key={`ThreadItem-${thread.uid}-PollOption-${option.option}-${index}`}
                        option={option}
                        isFirst={index === 0}
                        isSmall={true}
                        threadUid={thread.uid}
                        totalAnswerCount={thread.poll_options.reduce((acc, curr) => acc + curr.answer_count, 0)}
                        pollAnswerObj={pollAnswerObj}
                        />
                    ))}

                    {!hideUser &&
                        <div
                        className="margin-top-16 flex-row"
                        >
                            <UserRedirect pictureSize={32} fontSize={16} user={thread.creator} />
                        </div>
                    }

                    {!hideSocials &&
                        <div className="flex-row justify-between margin-top-16">
                            <div className="flex-row">
                                <div 
                                onClick={() => {
                                    if (isLoggedIn) {
                                        if (likeObj) {
                                            setExtraLikeCount(extraLikeCount-1)

                                            dispatch(
                                                deleteLike("thread", thread.uid, likeObj.uid, false)
                                            )
                                        } else {
                                            setExtraLikeCount(extraLikeCount+1)

                                            track("Liked content", { type: "thread", referrer: "ThreadItem" })

                                            dispatch(
                                                likeContent("thread", thread.uid, false)
                                            )
                                        }
                                    } else {
                                        openPopup("SignUp")
                                    }
                                }} 
                                style={{ height: 20 }}
                                className="link-clickable"
                                >
                                    <Icon size={20} icon={(deleteLikeStatus === status.BEGIN && deleteLikeContentUid === thread.uid) ? "loading" : "favorite"} className={`padding-right-6 ${styles.icon} ${likeObj ? styles.active : ""}`} />
                                </div>

                                <Link style={{ height: 20 }} to={`${thread.url_slug}?comments=true`}>
                                    <Icon size={20} icon="chat" className={`padding-left-6 padding-right-6 ${styles.icon}`} />
                                </Link>
                                
                                <Link to={`${thread.url_slug}?comments=true`}>
                                    <p className={`padding-left-6 padding-right-6 ${styles.icon} ${styles.counts}`}>{totalLikes} {totalLikes === 1 ? "like" : "likes"}, {checkNull(thread.comment_count)} {thread.comment_count === 1 ? "comment" : "comments"}</p>
                                </Link>
                            </div>

                            <div className='flex-row'>
                                <div 
                                style={{ height: 20 }} 
                                className='link-clickable' 
                                onClick={() => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                    } else {
                                        openPopup("SendContent", { type: "thread", content: thread })
                                    }
                                }}
                                >
                                    <Icon size={20} icon="forum" className={`padding-left-6 padding-right-6 ${styles.icon}`} />
                                </div>

                                <ShareDropdown
                                type="thread" 
                                thread={thread}
                                uid={thread.uid}
                                className="link-clickable"
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default React.memo(ThreadItem)