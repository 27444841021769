import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import DefaultHelmet from '../../shared/DefaultHelmet'

import Icon from '../../components/Icon'
import Button from '../../components/Button'

export default function NotFound(props) {

    const isMinimum = useMediaQuery({ maxWidth: 520 })
    
    return (
        <div className="full-height">
            <DefaultHelmet
            title="Page Not Found"
            description="404. The page you are looking for does not exist."
            skipCanonical
            skipDescription
            />

            <div className="full-center flex-column">
                <Icon icon="link" size={128} color="var(--darkLightGrey)" />

                <h1 className="text-md black text-center margin-top-32">404 - Page Not Found</h1>

                <p
                style={{ fontSize: 16, lineHeight: '22px' }}
                className="margin-top-24 margin-left-32 margin-right-32 text-center highDarkGrey"
                >
                    {`The link might be broken, or the page might have been removed.${!isMinimum ? "\n" : " "}Make sure the link you are trying to visit is correct.`}
                </p>

                <Link to={"/"} className="margin-top-40">
                    <Button
                    text="Back to Home"
                    />
                </Link>
            </div>
        </div>
    )
}
