import styles from './managelistcontentpopup.module.css'

import React, { useCallback, useEffect, useRef, useState } from 'react'

import Button from '../../components/Button'
import IconButton from '../../components/IconButton'

import { usePopup } from '../../hooks/popup/popup'
import AlbumCheckItem from './components/AlbumCheckItem/AlbumCheckItem'
import TrackCheckItem from './components/TrackCheckItem/TrackCheckItem'
import ArtistCheckItem from './components/ArtistCheckItem/ArtistCheckItem'
import { useDispatch, useSelector } from 'react-redux'
import { clearSearchAlbums, clearSearchArtists, clearSearchTracks, searchMergedContent } from '../../actions/search-actions'
import TextInput from '../../components/TextInput'
import Heading from '../../components/Heading'
import Icon from '../../components/Icon'
import { status } from '../../constants/action-types'

export default function ManageListContentPopup({ options: { contents: initContents=[], onContentsChange }, requestClose }) {
    //General
    const dispatch = useDispatch()
    const { openPopup } = usePopup()
    
    //Data
    const mergedAlbums = useSelector(state => state.search.mergedAlbums)
    const mergedTracks = useSelector(state => state.search.mergedTracks)
    const mergedArtists = useSelector(state => state.search.mergedArtists)

    //State
    const [hasChangedContent, setHasChangedContent] = useState(false)
    const [contents, setContents] = useState(initContents)
    const [value, setValue] = useState("")
    const [maximizeAlbums, setMaximizeAlbums] = useState(false)
    const [maximizeArtists, setMaximizeArtists] = useState(false)
    const [maximizeTracks, setMaximizeTracks] = useState(false)

    //Refs
    const timer = useRef()

    //Search content
    useEffect(() => {
        if(value.trim() !== "") {
            if (timer.current) {
                clearTimeout(timer.current)
            }

            if (mergedAlbums.controller) {
                mergedAlbums.controller.abort()
            }
            if (mergedTracks.controller) {
                mergedTracks.controller.abort()
            }
            if (mergedArtists.controller) {
                mergedArtists.controller.abort()
            }

            timer.current = setTimeout(() => {
                dispatch( searchMergedContent(value, "album", 18) )
                dispatch( searchMergedContent(value, "track", 18) )
                dispatch( searchMergedContent(value, "artist", 18) )
                
            }, 300)

        } else {
            dispatch( clearSearchAlbums() )
            dispatch( clearSearchTracks() ) 
            dispatch( clearSearchArtists() )
        }
    }, [ value ])

    //If clicking outside of popup
    const firstUpdateForRequestClose = useRef(true)
    useEffect(() => {
        if (firstUpdateForRequestClose.current) {
            firstUpdateForRequestClose.current = false;
            return;
        }

        if (requestClose) {
            openPopup(null)
        }

    }, [ requestClose ])

    //On demounting
    useEffect(() => {
        return(() => {
            if (timer.current) {
                clearTimeout(timer.current)
            }

            dispatch( clearSearchAlbums() )
            dispatch( clearSearchTracks() ) 
            dispatch( clearSearchArtists() )
        })
    }, [])

    //Helper method to remove content
    const removeContent = useCallback((content) => {
        setHasChangedContent(true)
        
        const newContents = contents.filter(compare => 
            !(
                compare.type === content.type &&
                (
                    compare.hasOwnProperty("deezer_id") && !content.hasOwnProperty("deezer_id") ?
                        compare.deezer_id === content.id 
                    : !compare.hasOwnProperty("deezer_id") && content.hasOwnProperty("deezer_id") ?
                        compare.id === content.deezer_id 
                    : 
                        compare.id === content.id
                )
            )
        )

        onContentsChange(newContents)

        setContents(newContents)
    }, [contents])

    const addContent = useCallback((content) => {
        setHasChangedContent(true)

        const newContents = [ 
            ...contents,
            { ...content, number: contents.length }
        ]
        
        onContentsChange(newContents)

        setContents(newContents)
    }, [contents])

    return (
        <div>
            {/* Header */}
            <div className={`flex-row justify-between padding-around-8 ${styles.header}`}>
                <div className="col-1">
                    <IconButton
                    type="transparent-bright"
                    icon="close"
                    onClick={() => {
                        openPopup(null)
                    }}
                    />
                </div>

                <h5 className="col-2 text-center black" style={{ fontWeight: 700 }}>
                    Manage List
                </h5>
                
                <div className="col-1 flex-row justify-end">
                    <Button
                    disabled={false}
                    text="Done"
                    height={38}
                    textStyle={{ fontSize: 14 }}
                    onClicked={() => {
                        openPopup(null)
                    }}
                    />
                </div>
            </div>

            <TextInput
            autoFocus={true}
            lighterStyle={true}
            placeholder={"Add music to this list..."}
            text={value}
            onTextChange={setValue}
            className="padding-around"
            //inputStyle={{}}
            />

            <div className="padding-around-8">
                {mergedAlbums.data.length > 0 &&
                    <div>
                        <Heading text="Albums" className="margin-left-8" style={{ paddingBottom: 0 }} />

                        <div className="margin-top-4">
                            {mergedAlbums.data.filter((_, i) => maximizeAlbums ? i < 15 : i < 5).map((item, index) => (
                                <AlbumCheckItem
                                key={`managelistcontent-album-${item.id}-${item.id}-${item.deezer_id}-${index}`}
                                onAdd={addContent}
                                onRemove={removeContent}
                                album={item}
                                selected={contents.findIndex(compare => 
                                    compare.type === "album" &&
                                    (
                                        compare.hasOwnProperty("deezer_id") && !item.hasOwnProperty("deezer_id") ?
                                            compare.deezer_id === item.id 
                                        : !compare.hasOwnProperty("deezer_id") && item.hasOwnProperty("deezer_id") ?
                                            compare.id === item.deezer_id 
                                        : 
                                            compare.id === item.id
                                    )
                                ) !== -1}
                                />
                            ))}

                            {!maximizeAlbums &&
                                <p onClick={() => setMaximizeAlbums(true)} className={styles.loadMore}>Show more albums...</p>
                            }
                        </div>
                    </div>
                }

                {mergedTracks.data.length > 0 &&
                    <div className="margin-top-16">
                        <Heading text="Tracks" className="margin-left-8" style={{ paddingBottom: 0 }} />

                        <div className="margin-top-4">
                            {mergedTracks.data.filter((_, i) => maximizeTracks ? i < 15 : i < 5).map((item, index) => (
                                <TrackCheckItem
                                key={`managelistcontent-track-${item.id}-${item.id}-${item.deezer_id}-${index}`}
                                onAdd={addContent}
                                onRemove={removeContent}
                                track={item}
                                selected={contents.findIndex(compare => 
                                    compare.type === "track" &&
                                    (
                                        compare.hasOwnProperty("deezer_id") && !item.hasOwnProperty("deezer_id") ?
                                            compare.deezer_id === item.id 
                                        : !compare.hasOwnProperty("deezer_id") && item.hasOwnProperty("deezer_id") ?
                                            compare.id === item.deezer_id 
                                        : 
                                            compare.id === item.id
                                    )
                                ) !== -1}
                                />
                            ))}

                            {!maximizeTracks &&
                                <p onClick={() => setMaximizeTracks(true)} className={styles.loadMore}>Show more tracks...</p>
                            }
                        </div>
                    </div>
                }

                {mergedArtists.data.length > 0 &&
                    <div className="margin-top-16">
                        <Heading text="Artists" className="margin-left-8" style={{ paddingBottom: 0 }} />

                        <div className="margin-top-4">
                            {mergedArtists.data.filter((_, i) => maximizeArtists ? i < 15 : i < 5).map((item, index) => (
                                <ArtistCheckItem
                                key={`managelistcontent-artist-${item.id}-${item.id}-${item.deezer_id}-${index}`}
                                onAdd={addContent}
                                onRemove={removeContent}
                                artist={item}
                                selected={contents.findIndex(compare => 
                                    compare.type === "artist" &&
                                    (
                                        compare.hasOwnProperty("deezer_id") && !item.hasOwnProperty("deezer_id") ?
                                            compare.deezer_id === item.id 
                                        : !compare.hasOwnProperty("deezer_id") && item.hasOwnProperty("deezer_id") ?
                                            compare.id === item.deezer_id 
                                        : 
                                            compare.id === item.id
                                    )
                                ) !== -1}
                                />
                            ))}

                            {!maximizeArtists &&
                                <p onClick={() => setMaximizeArtists(true)} className={styles.loadMore}>Show more artists...</p>
                            }
                        </div>
                    </div>
                }

                {mergedArtists.data.length <= 0 && mergedArtists.data.length <= 0 && mergedTracks.data.length <= 0 &&
                    <div className="empty-text flex-center" style={{ height: 200 }}>
                        {value.trim() === "" &&
                            <div> 
                                <Icon icon="search" size={40} color={"var(--grey)"} />
                                <p className="highDarkGrey margin-top-16" style={{ fontSize: 16 }}>Search albums, tracks, or artists to add.</p>
                            </div>
                        }

                        {(
                            value.trim() !== "" &&
                            !(mergedAlbums.status === status.SUCCESS && mergedArtists.status === status.SUCCESS && mergedTracks.status === status.SUCCESS)
                        ) &&
                            <Icon icon="loading" />
                        }

                        {(
                            value.trim() !== "" &&
                            (mergedAlbums.status === status.SUCCESS && mergedArtists.status === status.SUCCESS && mergedTracks.status === status.SUCCESS)
                        ) &&
                            <div>
                                <Icon icon="warning" size={40} color={"var(--grey)"} />
                                <p className="highDarkGrey margin-top-16" style={{ fontSize: 16 }}>No results on your search...</p>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}
