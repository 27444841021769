import { logOut } from '../actions/auth-actions'
import { errorToString } from '../utils/error';
import { types } from '../constants/action-types';
import { googleLogout } from '@react-oauth/google';

const logoutHandle = store => next => action => {

    if ((action.meta && action.meta.status !== "ERROR")) {
        return next(action);
    }

    if ((action.meta && action.meta.statusCode === 401 && action.type === types.GET_HEALTH_CHECK)) {
        if((store.getState().auth.token || store.getState().auth.accessToken) && store.getState().auth.isLoggedIn) {
            store.dispatch( logOut() )
            googleLogout()
            alert("Your session has expired.")
            setTimeout(() => {
                window.location.reload()
            }, 300)
        }
    }

    return next(action);
};

export default logoutHandle;