import styles from './checkitem.module.css'

import React from 'react'
import moment from 'moment'
import Icon from '../../../../components/Icon'
import ProfilePicture from '../../../../components/ProfilePicture'
import AlbumCover from '../../../../components/AlbumCover'
import Checkbox from '../../../../components/Checkbox'

export default function ArtistCheckItem({ artist, selected, onAdd, onRemove, className }) {

    return (
        <div 
        onClick={() => {
            if (selected) {
                onRemove(artist)
            } else {
                onAdd(artist)
            }
        }}
        className={`${styles.container} cursor ${className}`}
        >
            <div className="flex-row">
                <ProfilePicture clickable size={60} profilePicture={artist.picture_small || artist.picture} altText={artist.name} />

                <div className={`col-1 margin-left-16 margin-right-16`}>
                    <div className="grid">
                        <h6 className={`${styles.title} one-line`}>
                            {artist.name}
                        </h6>
                    </div>
                    
                    <div className="grid margin-top-4">
                        <p className={`${styles.artist} one-line`}>
                            Artist
                        </p>
                    </div>
                </div>

                <Checkbox
                checked={selected}
                onChange={() => {
                    if (selected) {
                        onRemove(artist)
                    } else {
                        onAdd(artist)
                    }
                }}
                />
            </div>
        </div>
    )
}
