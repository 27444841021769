import styles from './reviewitem.module.css'

import React, { Component, useState, useEffect } from 'react'
import moment from 'moment'
import ClampLines from 'react-clamp-lines';
import LinesEllipsis from 'react-lines-ellipsis'
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { themeEnums } from '../../constants/enums';
import { status } from '../../constants/action-types';

import { deleteLike, likeContent } from '../../actions/likes-actions';

import { usePopup } from '../../hooks/popup/popup';

import ColorOverwrite from '../../shared/ColorOverwrite';

import { firstLetterCapital } from '../../utils/captialization';
import { checkNull, checkNullNumber } from '../../utils/format-text';
import { displayRecordType } from '../../utils/record-type-utils';

import { useTrack } from '../../hooks/tracking';

import Icon from '../Icon';
import Stars from '../Stars';
import ProIcon from '../ProIcon';
import Truncate from '../Truncate';
import AlbumCover from '../AlbumCover';
import VerifiedIcon from '../VerifiedIcon';
import UserRedirect from '../UserRedirect';
import ShareDropdown from '../ShareDropdown'
import ProfilePicture from '../ProfilePicture';
import PlayContentButton from '../PlayContentButton';
import UserProfilePicture from '../UserProfilePicture';

import FeaturedProfileSection from './components/FeaturedProfileSection';

function ReviewItem({ 
    review,

    hideUser,
    hideSocials,
    disableReadMore,
    presentUserReview,

    isLast,
    isFirst,
    isSmall,
    lighterStyle
}) {
    //General
    const { openPopup } = usePopup()
    const dispatch = useDispatch()
    const track = useTrack()

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

    //Responsive
    const isTablet = useMediaQuery({ maxWidth: 768 })

    const smallReview = (isSmall || isTablet)

    //State
    const [extraLikeCount, setExtraLikeCount] = useState(0)

    //likeObj
    const deleteLikeContentUid = useSelector(state => state.likes.deleteLikeContentUid)
    const deleteLikeStatus = useSelector(state => state.likes.deleteLikeStatus)
    const myLikes = useSelector(state => state.me.likes);
    const cachedLikes = useSelector(state => state.me.cachedLikes);
    const likes = [ ...myLikes, ...cachedLikes ];
    const likeIndex = likes.findIndex(like => like.content_ct === "review" && like.content_uid === review.uid);
    const likeObj = likeIndex !== -1 ? likes[likeIndex] : null;

    //Blocks
    const myBlocks = useSelector(state => state.me.blocks)
    const cachedBlocks = useSelector(state => state.me.cachedBlocks)
    const blocks = [...myBlocks, ...cachedBlocks]
    const blockObj = blocks.findIndex(block => block.blocked_user === review.creator.uid) !== -1 ? blocks[blocks.findIndex(block => block.blocked_user === review.creator.uid)] : null

    //blockedBy
    const blockedByObj = useSelector(state => 
        state.me.blockedBy && state.me.blockedBy.find(block => block.creator === review.creator.uid)
    )

    if (blockedByObj) {
        return null
    }

    if (blockObj) {
        return null
    }
    
    if (!review || !review.rating) {
        return null;
    }

    //Variables
    const totalLikes = checkNullNumber(review.like_count) + extraLikeCount;

    const contentType = review && review.rating && review.rating.content.type
    const isSuggested = (contentType === "suggested_album" || contentType === "suggested_artist" || contentType === "suggested_track")
    const formattedContentType = isSuggested ? contentType.substr(10, 100) : contentType
    const contentTitle = review && review.rating && (
        (review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") ? 
            review.rating.content.name 
        : 
            review.rating.content.title
    )
    const contentImage = review && review.rating && (
        review.rating.content.type === "artist" ? 
            review.rating.content.picture_small || review.rating.content.picture
        : review.rating.content.type === "album" ? 
            review.rating.content.cover_small || review.rating.content.cover
        : review.rating.content.type === "track" ? 
            review.rating.content.album.cover_small || review.rating.content.album.cover
        : review.rating.content.type === "suggested_artist" ?
            review.rating.content.picture ?
                review.rating.content.picture
            :
                review.rating.content.picture_url
        : review.rating.content.type === "suggested_album" ?
            review.rating.content.cover ?
                review.rating.content.cover
            :
                review.rating.content.cover_url
                : review.rating.content.type === "suggested_track" ?
                review.rating.content.album ? 
                    review.rating.content.album.cover ?
                        review.rating.content.album.cover
                    :
                        review.rating.content.album.cover_url
                :
                    review.rating.content.suggested_album.cover ?
                        review.rating.content.suggested_album.cover
                    :
                        review.rating.content.suggested_album.cover_url
        : null
    )

    let href = ""
    if (review.rating.content.type === "album") {
        href = review.rating.content.url_slug
    } else if (review.rating.content.type === "track") {
        href = `${review.rating.content.album.url_slug}?tracks=${review.rating.content.id}`
    } else if (review.rating.content.type === "artist") {
        href = review.rating.content.url_slug
    }
    
    return (
        <div>
            {review.featuredProfile && review.creator.is_pro && review.creator.tier === 1 && review.creator.color_theme !== null && review.creator.color_theme !== undefined &&
                <ColorOverwrite
                colorTheme={themeEnums[review.creator.color_theme]}
                styleName={`review-item-${review.uid}-${review.featuredProfile}-color-overwrite`}
                />
            }
            
            <div className={`review-item-${review.uid}-${review.featuredProfile}-color-overwrite`}>
                <div className={`${styles.container} ${isLast ? styles.last : ""} ${isFirst ? styles.first : ""} ${lighterStyle ? styles.lighterStyle : ""} truncate-wrapper link-wrapper ${review.featuredProfile ? styles.featuredProfile : ""}`}>
                    <Link to={review.url_slug} className="link-overlay">
                        <span className='link-overlay-title'>{review.title !== "" ? review.title : `Review of ${contentTitle} by ${review.creator.username}`}</span>
                    </Link>

                    <div className="link-inner">
                        {review.featuredProfile &&
                            <FeaturedProfileSection user={review.creator} />
                        }

                        <div className="flex-row">
                            <div className={`${styles.center}`}>
                                <Link to={presentUserReview ? `/${review.creator.username}` : href} className={(!presentUserReview && isSuggested) ? styles.disabledLink : ""}>
                                    {!presentUserReview && (review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") &&
                                        <ProfilePicture clickable size={smallReview ? 60 : 88} profilePicture={contentImage} altText={contentTitle} />
                                    }

                                    {!presentUserReview && !(review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") &&
                                        <AlbumCover clickable size={smallReview ? 60 : 88} albumCover={contentImage} altText={contentTitle} />
                                    }

                                    {presentUserReview &&
                                        <UserProfilePicture 
                                        clickable 
                                        size={smallReview ? 60 : 88} 
                                        profilePicture={review.creator.profile_picture?.includes(".gif") ? review.creator.profile_picture : review.creator.profile_picture_small || review.creator.profile_picture} 
                                        profileFrame={review.creator.is_pro && review.creator.tier === 1 ? review.creator.profile_frame : null} 
                                        altText={review.creator.name !== "" ? review.creator.name : review.creator.username}
                                        />
                                    }
                                </Link>
                            </div>
                            
                            {!presentUserReview &&
                                <div className="margin-left-16">
                                    <div className="flex-row">
                                        <Link to={href} className={`grid`} style={{ pointerEvents: isSuggested ? "none" : "all" }}>
                                            <h5 className={`${styles.a} one-line`} style={{ marginRight: 6 }}>{contentTitle}</h5>
                                        </Link>

                                        {review.rating.content.explicit_lyrics &&
                                            <Icon icon="explicit" className="margin-right-8" color="var(--grey)" size={14} />
                                        }
                                    </div>

                                    <p className="highDarkGrey margin-top-4">
                                        {review.rating.content.artist &&
                                            <Link className={styles.artistLink} to={review.rating.content.artist.url_slug}>
                                                {review.rating.content.artist.name}
                                            </Link>
                                        }
                                        
                                        {review.rating.content.suggested_artist && review.rating.content.suggested_artist.name}

                                        {(review.rating.content.artist || review.rating.content.suggested_artist) && " • "}

                                        {(review.rating.content.type === "album" || review.rating.content.type === "suggested_album") && review.rating.content.record_type ?
                                            displayRecordType(review.rating.content.record_type)
                                        :
                                            firstLetterCapital(formattedContentType)
                                        }
                                    </p>
                                </div>
                            }
                            
                            {presentUserReview &&
                                <div className="margin-left-16">
                                    <div className="flex-row">
                                        <div className="grid">
                                            <h5 className={`one-line grey`}>
                                                <span style={{ fontWeight: 'normal' }}>Review by </span> 

                                                <Link to={`/${review.creator.username}`} className={`${styles.a} black`}>
                                                    {review.creator.username}
                                                </Link>
                                            </h5>
                                        </div>

                                        {(review.creator.is_pro || review.creator.is_moderator || review.creator.contributor_tier) &&
                                            <ProIcon 
                                            size={"small"} 
                                            className="margin-left-8" 
                                            is_pro={review.creator.is_pro}
                                            is_moderator={review.creator.is_moderator}
                                            contributor_tier={review.creator.contributor_tier}
                                            />
                                        }

                                        {review.creator.is_verified &&
                                            <VerifiedIcon size={12} className="margin-left-8" />
                                        }
                                    </div>

                                    <p className="highDarkGrey margin-top-8">{(!review.rating.hasOwnProperty("show_listened_at") || review.rating.show_listened_at) ? "Listened" : "Reviewed"} on {moment((!review.rating.hasOwnProperty("show_listened_at") || review.rating.show_listened_at) ? review.rating.listened_at : review.rating.created_at).format("DD MMM YYYY")}</p>
                                </div>
                            }

                            <div className='col-1' />

                            {!presentUserReview && !isSmall &&
                                <PlayContentButton content={review.rating.content} appendKey={`ReviewItem-${review.uid}`} style={{ marginLeft: 16 }} /> 
                            }
                        </div>

                        {(review.featured || review.featuredProfile) && (
                            <div className={`${styles.featuredBox} ${smallReview ? styles.smallReview : ""}`}>
                                <div>
                                    <h6>
                                        FEATURED
                                    </h6>
                                </div>
                            </div>
                        )}

                        {review.title !== "" &&
                            <h5 style={{ marginTop: smallReview ? 12 : 14 }} className="textColor">{review.title}</h5>
                        }

                        {(Boolean(review.rating.rating) || review.rating.first_listen === false) &&
                            <div className="flex-row" style={{ marginTop: review.title !== "" ? smallReview ? 8 : 10 : smallReview ? 12 : 14 }}>
                                {Boolean(review.rating.rating) &&
                                    <Stars iconSize={smallReview ? 18 : 20} rating={review.rating.rating} style={{ marginRight: 6 }} />
                                }

                                {review.rating.first_listen === false &&
                                    <Icon icon="sync" size={smallReview ? 18 : 20} style={{ transform: "rotate(45deg)", marginRight: 6 }} color={"var(--grey)"} />
                                }

                                {(review.private || review.private_locked) &&
                                    <Icon icon="lock" size={smallReview ? 15 : 16} color={"var(--grey)"} />
                                }
                            </div>
                        }

                        {review.description !== "" &&
                            <div style={{ marginTop: smallReview ? 8 : 12 }}>
                                <Truncate
                                text={review.description}
                                textClassName={`textColor`}
                                appendId={review.uid}
                                disabled={disableReadMore}
                                height={disableReadMore ? 100 : 200}
                                breakHeight={disableReadMore ? 120 : 240}
                                />
                            </div>
                        }

                        {!hideUser &&
                            <div
                            className="margin-top-12 flex-row"
                            >
                                <UserRedirect pictureSize={32} fontSize={16} user={review.creator} />
                            </div>
                        }

                        {!hideSocials &&
                            <div className="flex-row justify-between margin-top-16">
                                <div className="flex-row">
                                    <div 
                                    onClick={() => {
                                        if (isLoggedIn) {
                                            if (likeObj) {
                                                setExtraLikeCount(extraLikeCount-1)

                                                dispatch(
                                                    deleteLike("review", review.uid, likeObj.uid, false)
                                                )
                                            } else {
                                                setExtraLikeCount(extraLikeCount+1)

                                                track("Liked content", { type: "review", referrer: "ReviewItem" })

                                                dispatch(
                                                    likeContent("review", review.uid, false)
                                                )
                                            }
                                        } else {
                                            openPopup("SignUp")
                                        }
                                    }} 
                                    style={{ height: 20 }}
                                    className="link-clickable"
                                    >
                                        <Icon size={20} icon={(deleteLikeStatus === status.BEGIN && deleteLikeContentUid === review.uid) ? "loading" : "favorite"} className={`padding-right-6 ${styles.icon} ${likeObj ? styles.active : ""}`} />
                                    </div>

                                    <Link style={{ height: 20 }} to={`${review.url_slug}?comments=true`}>
                                        <Icon size={20} icon="chat" className={`padding-left-6 padding-right-6 ${styles.icon}`} />
                                    </Link>
                                    
                                    <Link to={`${review.url_slug}?comments=true`}>
                                        <p className={`padding-left-6 padding-right-6 ${styles.icon} ${styles.counts}`}>{totalLikes} {totalLikes === 1 ? "like" : "likes"}, {checkNull(review.comment_count)} {review.comment_count === 1 ? "comment" : "comments"}</p>
                                    </Link>
                                </div>

                                <div className='flex-row'>
                                    <div 
                                    style={{ height: 20 }} 
                                    className='link-clickable' 
                                    onClick={() => {
                                        if (!isLoggedIn) {
                                            openPopup("SignUp")
                                        } else {
                                            openPopup("SendContent", { type: "review", content: review })
                                        }
                                    }}
                                    >
                                        <Icon size={20} icon="forum" className={`padding-left-6 padding-right-6 ${styles.icon}`} />
                                    </div>

                                    <ShareDropdown
                                    type="review" 
                                    review={review}
                                    uid={review.uid}
                                    className="link-clickable"
                                    isPrivate={(review.private || review.private_locked)}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ReviewItem)