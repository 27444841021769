import React, { useRef, useEffect } from 'react'
import Button from '../../components/Button'
import { usePopup } from '../../hooks/popup/popup'
import { useDispatch, useSelector } from 'react-redux'
import { status } from '../../constants/action-types'
import { deleteReview } from '../../actions/reviews-actions'
import { deleteRating } from '../../actions/ratings-actions'
import { useAlert } from '../../hooks/alert/alert'
import { useHistory } from 'react-router-dom'
import { createDraft, deleteDraft, updateDraft } from '../../actions/drafts-actions'
import { useStatusHandler } from '../../hooks/status-handler'
import Icon from '../../components/Icon'
import moment from 'moment'

export default function SaveDraftPopup({ options: { draftUid=null, data, type, onDone=()=>{} }, requestClose }) {
    //General
    const { openPopup } = usePopup()
    const { openAlert } = useAlert()
    const dispatch = useDispatch()
    const history = useHistory()

    //Data
    const createDraftStatus = useSelector(state => state.drafts.createDraftStatus)
    const updateDraftStatus = useSelector(state => state.drafts.updateDraftStatus)
    const deleteDraftStatus = useSelector(state => state.drafts.deleteDraftStatus)
    const errorMessage = useSelector(state => state.drafts.errorMessage)

    //Helper function for done
    function finishWithDraft() {
        openPopup(null)
        onDone()
    }

    //Handle create draft status
    useStatusHandler({
        effectStatus: createDraftStatus,
        successCallback: () => {
            finishWithDraft()
            openAlert({
                p: `Saved ${type} as a draft.`,
                style: "green",
                timer: 3700
            })
        },
        errorCallback: () => {
            openAlert({
                p: "Could not create draft. Error: " + errorMessage,
                style: "red",
                timer: 5500
            })
        }
    })

    //Handle update draft status
    useStatusHandler({
        effectStatus: updateDraftStatus,
        successCallback: () => {
            finishWithDraft()
            openAlert({
                p: `Successfully updated draft.`,
                style: "green",
                timer: 3700
            })
        },
        errorCallback: () => {
            openAlert({
                p: "Could not update draft. Error: " + errorMessage,
                style: "red",
                timer: 5500
            })
        }
    })

    //Handle delete draft status
    useStatusHandler({
        effectStatus: deleteDraftStatus,
        successCallback: () => {
            finishWithDraft()
        },
        errorCallback: () => {
            openAlert({
                p: "Could not delete draft. Error: " + errorMessage,
                style: "red",
                timer: 5500
            })
        }
    })
    
    //Handle request close
    const firstUpdateForRequestClose = useRef(true)
    useEffect(() => {
        if (firstUpdateForRequestClose.current) {
            firstUpdateForRequestClose.current = false;
            return;
        }

        if (requestClose) {
            if (type === "review") {
                if (data.toScreen === "UpdateReview") {
                    openPopup("UpdateReview", {
                        review: data,
                        draftUid: draftUid,
                        draftTitle: data.draftTitle ? data.draftTitle : data.title,
                        draftDescription: data.draftDescription ? data.draftDescription : data.description,
                    })
                } else if (data.toScreen === "UpdateRating") {
                    openPopup("UpdateRating", {
                        rating: data,
                        draftUid: draftUid,
                    })
                } else {
                    openPopup("CreateReview", {
                        content: data.content,
                        initRating: data.rating,
                        initTitle: data.title,
                        initDescription: data.description,
                        initIsPrivate: data.private,
                        initPinned: data.pinned,
                        initFirstListen: data.firstListen,
                        initDate: moment(data.date),
                        draftUid: draftUid,
                    })
                }
            } else {
                openPopup(null)
            }
        }

    }, [ requestClose ])

    function onSaveDraft() {
        if (draftUid) {
            dispatch(
                updateDraft(
                    draftUid,
                    {
                        data: data,
                        is_created: false,
                        type: type
                    }
                )
            )
        } else {
            dispatch(
                createDraft(
                    data,
                    type,
                    false
                )
            )
        }
    }

    function onDeleteDraft() {
        if (draftUid) {
            /*
            dispatch(
                deleteDraft(draftUid, type)
            )
            */
            finishWithDraft()
        } else {
            finishWithDraft()
        }
    }

    return (
        <div>
            <p className="highDarkGrey" style={{ fontSize: 16, lineHeight: '1.5' }}>You can save this as a draft and publish it later. Do you want to save it or delete your progress? Click the <Icon icon="add-circle" className="small-inline-icon" color={"var(--highDarkGrey)"} size={18} /> in the top-right to find your drafts.</p>


            <div className="flex-row justify-end margin-top-16">
                <Button
                height={40}
                type="secondary-two"
                text="Ignore"
                className="margin-right-12"
                onClicked={onDeleteDraft}
                loading={deleteDraftStatus === status.BEGIN}
                disabled={createDraftStatus === status.BEGIN || updateDraftStatus === status.BEGIN || deleteDraftStatus === status.BEGIN}
                />

                <Button
                height={40}
                type="primary"
                text="Save as Draft"
                loading={createDraftStatus === status.BEGIN || updateDraftStatus === status.BEGIN}
                disabled={createDraftStatus === status.BEGIN || updateDraftStatus === status.BEGIN || deleteDraftStatus === status.BEGIN}
                onClicked={onSaveDraft}
                />
            </div>
        </div>
    )
}
