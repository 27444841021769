import styles from './clickablestars.module.css'

import React, { useState } from 'react'
import Icon from '../Icon';
import { useTheme } from '../../hooks/theme';

export default function ClickableStars({
    iconSize = 24, // 32 and 24 as options
    style={},
    rating=1,
    setRating=() => {},
    appendKey="",
    className="",
}) {
    const { colors, mode } = useTheme()

    const [selecting, setSelecting] = useState(false)
    const [selectedRating, setSelectedRating] = useState(null)

    const renderRating = selectedRating ? selectedRating : rating
    
    return (
        <div 
        onMouseEnter={() => setSelecting(true)}
        onMouseLeave={() => {
            setSelecting(false)
            setSelectedRating(null)
        }}
        onClick={() => {
            setRating(selectedRating)
            setSelecting(false)
        }}
        className={`${styles.container} ${iconSize === 32 ? styles.i32 : styles.i24} ${className}`}
        style={style} 
        >
            <div className={`${styles.bg} flex-row`}>
                <Icon color={mode === "light" ? colors.darkLightGrey : colors.grey} size={iconSize} icon="star" />
                <Icon color={mode === "light" ? colors.darkLightGrey : colors.grey} size={iconSize} icon="star" />
                <Icon color={mode === "light" ? colors.darkLightGrey : colors.grey} size={iconSize} icon="star" />
                <Icon color={mode === "light" ? colors.darkLightGrey : colors.grey} size={iconSize} icon="star" />
                <Icon color={mode === "light" ? colors.darkLightGrey : colors.grey} size={iconSize} icon="star" />
            </div>

            <div className="flex-row">
                {[1,2,3,4,5,6,7,8,9,10].map((_, i) => (
                    <div 
                    onMouseEnter={() => {
                        setSelectedRating(i+1)
                        setSelecting(true)
                    }}
                    key={`starsStar-${appendKey}-${i}`} 
                    className={`${styles.star} ${i < renderRating ? styles.show : ""}`}
                    >
                        <Icon
                        className={`${styles.starIcon} ${i%2 === 0 ? styles.first : ""}`}
                        color={selecting ? colors.blue : colors.yellow} 
                        size={iconSize} 
                        icon="star" 
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}