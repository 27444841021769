import styles from './fullbackground.module.css'
import React, { useState, useEffect } from 'react'
import { useTheme } from '../../hooks/theme'
import { useSize } from '../../hooks/size'
import interpolation from '../../utils/interpolation'
import { useScrollbar } from '../../hooks/scrollbar'

export default function FullBackground({
    image,
    placeholder,
    altText,
    forceOffset,
    height=434,
    marginHeight=0,
    zIndex=-10,
    whiteGradient,
    noSides,
    imageStyle,
    hideLine,
}) {
    //General
    const { width } = useSize()
    const { scrollDisabled, hasScrollbar } = useScrollbar()

    //Responsive
    const onMobile = /Mobi/.test(navigator.userAgent)

    //State
    const [loaded, setLoaded] = useState(false)

    //Calculate
    const calculatedHeight = interpolation(0, 1200, 160, height, width, true);
    const calculatedMarginHeight = interpolation(0, 1200, 80, marginHeight, width, true);
    let calculatedOffset = null;
    if (forceOffset) {
        calculatedOffset = interpolation(1200, 400, forceOffset, 0, width, true);
    }

    return (
        <div className={styles.outerContainer} style={{ zIndex: zIndex }}>
            <div
            className={styles.container}
            style={{
                maxWidth: scrollDisabled && hasScrollbar ? "calc(100% - 10px)" : "100%",
                height: calculatedHeight,
            }}
            >
                <img 
                className={`${styles.image} ${styles.blur} ${styles.visible}`}
                style={{ objectPosition: `center ${calculatedOffset ? `${calculatedOffset}px` : "top"}`, ...imageStyle }} 
                src={placeholder} 
                alt={`${altText} placeholder`}
                />

                <img
                className={`${styles.image} ${loaded ? styles.visible : ""}`}
                style={{ objectPosition: `center ${calculatedOffset ? `${calculatedOffset}px` : "top"}`, ...imageStyle }}
                src={image}
                onLoadCapture={() => {
                    setLoaded(true)
                }}
                alt={altText}
                />

                {!hideLine &&
                    <div 
                    className={`${styles.topLine}`}
                    />
                }

                <div 
                className={`${styles.overlay} ${whiteGradient ? styles.whiteGradient : ""} ${noSides ? styles.noSides : ""}`}
                style={{
                    height: calculatedHeight
                }}
                />
            </div>

            <div style={{height: calculatedMarginHeight}} />
        </div>
    )
}
