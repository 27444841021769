import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { calculateUpdatedRating, calculateNewRating } from '../utils/calculate-ratings';
import moment from 'moment';
import { checkNull, checkNullNumber } from '../utils/format-text';
import { handleReducer } from '../utils/reducer-helpers';

const defaultContributions = {
	createAlbumSuggestionStatus: status.DEFAULT,
	createArtistSuggestionStatus: status.DEFAULT,

	reportDuplicatedContentStatus: status.DEFAULT,
    lastDuplicatedContentReport: null,

	createSuggestedAlbumStatus: status.DEFAULT,
	lastCreatedSuggestedAlbum: null,
	
	createSuggestedArtistStatus: status.DEFAULT,
	lastCreatedSuggestedArtist: null,

	suggestedArtists: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},
	suggestedChanges: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},

	deleteSuggestionStatus: status.DEFAULT,
	lastDeletedSuggestionType: null,
	lastDeletedSuggestionUid: null,

	errorMessage: null,
};

const contributions = (state = defaultContributions, action) => {
	let id = action.meta && action.meta.params ? action.meta.params.id : null
	let reset = action.meta && action.meta.params ? action.meta.params.reset : false

	switch (action.type) {
		//Suggest Changes
		case types.CREATE_ALBUM_SUGGESTION:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createAlbumSuggestionStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						createAlbumSuggestionStatus: status.SUCCESS
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createAlbumSuggestionStatus: status.ERROR
					}
			}
		
		case types.CREATE_ARTIST_SUGGESTION:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createArtistSuggestionStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						createArtistSuggestionStatus: status.SUCCESS
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createArtistSuggestionStatus: status.ERROR
					}
			}
		
		//Report Duplicated Content
		case types.REPORT_DUPLICATED_CONTENT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						reportDuplicatedContentStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						reportDuplicatedContentStatus: status.SUCCESS,
                        lastDuplicatedContentReport: action.payload
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						reportDuplicatedContentStatus: status.ERROR,
                        lastDuplicatedContentReport: null
					}
			}
		
		//Suggested Content
		case types.CREATE_SUGGESTED_ALBUM:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createSuggestedAlbumStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						createSuggestedAlbumStatus: status.SUCCESS,
                        lastCreatedSuggestedAlbum: action.payload
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createSuggestedAlbumStatus: status.ERROR,
                        lastCreatedSuggestedAlbum: null
					}
			}
        
		case types.CREATE_SUGGESTED_ARTIST:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createSuggestedArtistStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						createSuggestedArtistStatus: status.SUCCESS,
                        lastCreatedSuggestedArtist: action.payload
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createSuggestedArtistStatus: status.ERROR,
                        lastCreatedSuggestedArtist: null
					}
			}
        
		//Get Suggested Artists
		case types.GET_SUGGESTED_ARTISTS:
			return handleReducer(state, action, 'suggestedArtists')
        
		//Get Suggested Changes
		case types.GET_SUGGESTED_CHANGES:
			return handleReducer(state, action, 'suggestedChanges')

		case types.DELETE_SUGGESTION:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						deleteSuggestionStatus: status.BEGIN,
						lastDeletedSuggestionType: action.meta.params.type,
						lastDeletedSuggestionUid: action.meta.params.uid,
					}
				case status.SUCCESS:
					return {
						...state,
						deleteSuggestionStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						deleteSuggestionStatus: status.ERROR,
						lastDeletedSuggestionType: null,
						lastDeletedSuggestionUid: null,
					}
			}

		default:
			return state;
	}
};

export default contributions;