export function mapDecades(decade) {
    const map = [
        {
            input: "2020s",
            output: {
                release_date__lte: "2029-12-31",
                release_date__gte: "2020-01-01",
            }
        },
        {
            input: "2010s",
            output: {
                release_date__lte: "2019-12-31",
                release_date__gte: "2010-01-01",
            }
        },
        {
            input: "2000s",
            output: {
                release_date__lte: "2009-12-31",
                release_date__gte: "2000-01-01",
            }
        },
        {
            input: "1990s",
            output: {
                release_date__lte: "1999-12-31",
                release_date__gte: "1990-01-01",
            }
        },
        {
            input: "1980s",
            output: {
                release_date__lte: "1989-12-31",
                release_date__gte: "1980-01-01",
            }
        },
        {
            input: "1970s",
            output: {
                release_date__lte: "1979-12-31",
                release_date__gte: "1970-01-01",
            }
        },
        {
            input: "1960s",
            output: {
                release_date__lte: "1969-12-31",
                release_date__gte: "1960-01-01",
            }
        },
        {
            input: "1950s",
            output: {
                release_date__lte: "1959-12-31",
                release_date__gte: "1950-01-01",
            }
        },
        {
            input: "1940s",
            output: {
                release_date__lte: "1949-12-31",
                release_date__gte: "1940-01-01",
            }
        },
        {
            input: "1930s",
            output: {
                release_date__lte: "1939-12-31",
                release_date__gte: "1930-01-01",
            }
        },
        {
            input: "1920s",
            output: {
                release_date__lte: "1929-12-31",
                release_date__gte: "1920-01-01",
            }
        },
        {
            input: "1910s",
            output: {
                release_date__lte: "1919-12-31",
                release_date__gte: "1910-01-01",
            }
        },
        {
            input: "1900s",
            output: {
                release_date__lte: "1909-12-31",
                release_date__gte: "1900-01-01",
            }
        },
    ]

    const index = map.findIndex((item) => item.input === decade);
    if (index !== -1) {
        return map[index].output
    } else {
        return null
    }
}