import React from 'react'
import axios from 'axios'

import { corsUrl } from '../config'

const hostUrl = `${corsUrl}/https://api.deezer.com`; 

const getReq = (url, source = null) => {
    console.log("Request start", {url, source})

    return axios.get(url, {
        cancelToken: source ? source : axios.CancelToken.source().token
    })
    .then(function (response) {
        return response.data
    })
    .catch(function (error) {
        if (axios.isCancel(error)) {
            console.warn('Request canceled', error);
        } else {
            console.warn('Error with axios request', error);
        }
    })
}

export const useDeezer = () => {
    const deezer = {
        searchSongs: (query, cancelSource) => getReq(`${hostUrl}/search/track/?q=${query}`, cancelSource),
        searchAlbum: (query, cancelSource) => getReq(`${hostUrl}/search/album/?q=${query}`, cancelSource),
        searchArtist: (query, cancelSource) => getReq(`${hostUrl}/search/artist/?q=${query}`, cancelSource),
        getAlbum: (id) => getReq(`${hostUrl}/album/${id}/`),
        getAlbumTracks: (id) => getReq(`${hostUrl}/album/${id}/tracks/?limit=200`),
        getArtist: (id) => getReq(`${hostUrl}/artist/${id}/`),
        getArtistReleases: (id, limit = 5) => getReq(`${hostUrl}/artist/${id}/albums/?limit=${limit}`),
        getArtistTopSongs: (id, limit = 5) => getReq(`${hostUrl}/artist/${id}/top/?limit=${limit}`),
        getArtistRelated: (id) => getReq(`${hostUrl}/artist/${id}/related/?limit=10`),
        getTrack: (id) => getReq(`${hostUrl}/track/${id}/`),
        getChartAlbums: () => getReq(`${hostUrl}/chart/0/albums/`),
        getChartArtists: () => getReq(`${hostUrl}/chart/0/artists/`),

        next: (url, cancelSource) => getReq(`${corsUrl}/${url}`, cancelSource),

    }

    return deezer
}