import styles from './menubar.module.css'

import React, { useEffect, useState } from 'react'
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

export default function MenuBar({ 
    children, 
    tabs=[], 
    className,
    style,
    tabStyle,
    tabWrapperStyle,
}) {
    //General
    const location = useLocation()

    //Responsive
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Helper function
    function getActiveTab(tab) {
        if(location.pathname == tab.redirect.split("?")[0] || `${location.pathname}/` == tab.redirect.split("?")[0]) {
            return true;
        } else if(tab.extraActive && (location.pathname == tab.extraActive.split("?")[0] || `${location.pathname}/` == tab.extraActive.split("?")[0])) {
            return true;
        }

        return false;
    }

    
    return (
        <div className={`${styles.container} ${className}`} style={style}>
            {children  && children}

            {tabs.length > 0 &&
                <div className={`${styles.sticky}`} id="sticky-menu">
                    <div className={`flex-row align-top page-container ${isSmallScreen && "flex-center"}`} style={tabWrapperStyle}>
                        {tabs.filter(i => i).map((tab, index) => (
                            <div key={`menubar-item-${tab.text}`}>
                                <Link to={tab.redirect} className={`${`${styles.tab}`} ${getActiveTab(tab) ? styles.active : ""}`} style={{ ...tabStyle }}>
                                    {!isMobile && <h5 className={`${styles.tabText} one-line`}>{tab.text}</h5>}
                                    {isMobile && <h6 className={`${styles.tabText} one-line`}>{tab.text}</h6>}
                                </Link>

                                {getActiveTab(tab) &&
                                    <div className={styles.activeIndicator} />
                                }
                                {!getActiveTab(tab) &&
                                    <div className={styles.disabledIndicator} />
                                }
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    )
}
