import styles from './activitydropdown.module.css'

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller'

import Dropdown from '../../../../components/Dropdown'
import { getActivity, clearNewActivity } from '../../../../actions/activity-actions'
import { useMediaQuery } from 'react-responsive'
import ActivityItem from './components/ActivityItem'
import { paginationNext } from '../../../../actions/core-actions'
import { types, status } from '../../../../constants/action-types'
import Icon from '../../../../components/Icon'
import IconButton from '../../../../components/IconButton'

export default function ActivityDropdown({ className, style }) {
    //General
    const dispatch = useDispatch()

    //Responsive
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Data
    const hasNewActivity = useSelector(state => state.local.hasNewActivity)
    const activity = useSelector(state => state.activity.activity)

    //State
    const [visible, setVisible] = useState(false)

    //Get data
    useEffect(() => {
        dispatch(
            getActivity()
        )
    }, [])

    //Clear new activity
    useEffect(() => {
        if (visible) {
            dispatch(
                clearNewActivity()
            )

            dispatch(
                getActivity()
            )
        }
    }, [ visible ])

    //Update scroll behavior
    useEffect(() => {
        if (visible && isMobile) {
            document.documentElement.style.overflow = "hidden"
        }

        return(() => {
            document.documentElement.style.overflow = "initial"
        })
    }, [visible, isMobile])

    //Scroll Ref
    const scrollRef = useRef()

    //Prepare data for render
    const timedActivity = activity.data.map(item => ({
        ...item,
        isToday: moment(item.created_at).isSame(moment().utc(), "day"),
        isThisWeek: moment(item.created_at).isSame(moment().utc(), "isoWeek"),
    }))

    const filteredActivity = [
        timedActivity.filter((item) => item.isToday).length > 0 
            ? {
                text: "Today",
                type: "title"
            } : null,
        ...timedActivity.filter((item) => item.isToday),

        timedActivity.filter((item) => !item.isToday && item.isThisWeek).length > 0 
            ? {
                text: "Earlier This Week",
                type: "title"
            } : null,
        ...timedActivity.filter((item) => !item.isToday && item.isThisWeek),

        timedActivity.filter((item) => !item.isToday && !item.isThisWeek).length > 0 
            ? {
                text: "Earlier",
                type: "title"
            } : null,
        ...timedActivity.filter((item) => !item.isToday && !item.isThisWeek),

    ].filter(item => item)

    const closeVisible = useCallback(() => {
        setVisible(false)
    }, [])

    return (
        <div className={className} style={{ position: 'relative', ...style }}>

            <div style={{ position: 'relative' }}>
                <IconButton
                icon="notifications"
                onClick={() => setVisible(!visible)}
                btnSize={40}
                size={22}
                />

                {hasNewActivity &&
                    <div className={styles.dot} />
                }
            </div>

            <Dropdown 
            simpleAnimation
            visible={visible}
            offset={44} 
            vertical="top" 
            position="right" 
            appendId={"activity"} 
            hideDropdown={() => setVisible(false)} 
            className={styles.container}
            >
                {isMobile &&
                    <div className={`${styles.fixedNav}`}>
                        <h4 className="black">Activity</h4>

                        <IconButton onClick={() => setVisible(false)} icon={"close"} />
                    </div>
                }

                <div ref={scrollRef} className={`${styles.scroll}`}>
                    {isMobile &&
                        <div style={{ height: 56 }} />
                    }
                    
                    {activity && activity.data && activity.data.length > 0 &&
                        <InfiniteScroll
                        getScrollParent={() => scrollRef && scrollRef.current}
                        useWindow={false}
                        initialLoad={false}
                        threshold={200}
                        loadMore={() => {
                            if (activity && activity.next) {
                                dispatch( paginationNext(activity.next, types.GET_ACTIVITY, { speedup: true, limit: 24 }) )
                            }
                        }}
                        hasMore={Boolean(activity.next || (activity.status === status.BEGIN && !activity.reset))}
                        loader={
                            <div key={`activity-load-more`} className="flex-center margin-top-16">
                                <Icon icon="loading" disableLoadingAnimation />
                            </div>
                        }
                        >
                            {filteredActivity.map((item, index) => (
                                item.type && item.type === "title"
                                    ?
                                        <div key={`activitytitleitem-${item.text}`}>
                                            <h4 className={`${index !== 0 ? "margin-top-16 margin-bottom-8" : "margin-top-4 margin-bottom-8"} black margin-left-8`}>{item.text}</h4>
                                        </div>
                                    : 
                                        <ActivityItem key={`activityitem-${item.uid}`} notification={item} onClick={closeVisible} />
                            ))}
                        </InfiniteScroll>
                    }

                    {activity && activity.data && activity.data.length <= 0 &&
                        <p className="empty-text">You have no activity yet. Follow some users to get started!</p>
                    }
                    
                    {isMobile && 
                        <div style={{ height: 60 }} />
                    }
                </div>
            </Dropdown>

        </div>
    )
}
