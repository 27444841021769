import styles from './playcontentbutton.module.css'

import React, { useCallback } from 'react'

import playingGifAnimation from '../../assets/animations/playing-gif-white-resize.gif'
import playingDarkGifAnimation from '../../assets/animations/playing-gif-dark-resize.gif'

import { useTheme } from '../../hooks/theme'
import { useDeezer } from '../../hooks/deezer'
import { useAudio } from '../../hooks/audio/audio'
import { useAlert } from '../../hooks/alert/alert'

import Icon from '../Icon'
import Tooltip from '../Tooltip'

function PlayContentButton({ content, startIndex=0, appendKey, size=32, style }) {
    const { mode } = useTheme()
    const { openAlert } = useAlert()
    const { getArtistTopSongs, getAlbum, getTrack } = useDeezer()
    const { playTracks, pause, resume, contentId, playing } = useAudio()

    //Helper function
    const playContent = useCallback((tracks=[], contentId) => {
        // Render tracks from Deezer
        playTracks(
            tracks
            .filter((track) => track.preview)
            .map((track) => ({
                id: track.id, 
                deezer_id: track.deezer_id, 
                url_slug: track.url_slug, 
                name: track.title, 
                singer: track.artist.name, 
                cover: track.album.cover !== null && track.album.cover !== "" ? track.album.cover_medium || track.album.cover : content.type === "album" ? content.cover_medium || content.cover : content.type === "track" ? content.album.cover_medium || content.album.cover : content.picture_medium || content.picture, 
                musicSrc: track.preview,
                duration: track.duration,
                album: {
                    id: track.album.id,
                },
                artist: {
                    id: track.artist.id,
                }
            })),
            startIndex,
            contentId
        )
    }, [])

    const fetchContent = useCallback(() => {
        if (content.id === contentId) {
            if (!playing) {
                resume()
                return
            }
        }

        //Fetch data from Deezer and then play the music
        if (content.type === "album") {
            //Get album for its tracks from Deezer
            getAlbum(content.deezer_id)
            .then((res) => {
                if (res && res.tracks && res.tracks.data) {
                    const tracks = res.tracks.data.map((track) => ({ ...track, album: { ...res, tracks: null } }))

                    playContent(tracks, content.id)
                } else {
                    openAlert({ p: `Can't preview music from this album.`, style: "green", timer: 3000 })
                }
            }).catch((err) => {
                openAlert({ p: `Can't preview music from this album.`, style: "green", timer: 3000 })
            })
            
        } else if (content.type === "artist") {
            //Get artist top songs from Deezer
            getArtistTopSongs(content.deezer_id)
            .then((res) => {
                if (res && res.data) {
                    const tracks = res.data

                    playContent(tracks, content.id)

                } else {
                    openAlert({ p: `Can't preview music from this artist.`, style: "green", timer: 3000 })
                }

            }).catch((err) => {
                openAlert({ p: `Can't preview music from this artist.`, style: "green", timer: 3000 })
            })

        } else if (content.type === "track") {
            //Get the track from Deezer
            getTrack(content.deezer_id)
            .then((res) => {
                if (res && res.preview) {
                    const track = res

                    playContent([track], content.id)

                } else {
                    openAlert({ p: `Can't preview music from this track.`, style: "green", timer: 3000 })
                }

            }).catch((err) => {
                openAlert({ p: `Can't preview music from this track.`, style: "green", timer: 3000 })
            })

        } else {
            openAlert({ p: `Can't preview music from this ${content.type}.`, style: "green", timer: 3000 })
        }

    }, [content, contentId, playing])

    if (!content.deezer_id) {
        return null
    }

    return (
        <div style={style}>
            {!(playing && contentId === content.id) &&
                <div 
                style={{ width: size, height: size }}
                id={`${appendKey}-play-button`}
                className='link-clickable cursor'
                onClick={fetchContent}
                >
                    <Icon icon="play-circle" size={size} className={styles.icon} />
                </div>
            }
            
            {!(playing && contentId === content.id) &&
                <Tooltip id={`${appendKey}-play-button`} text={`Preview ${content.type}`} offset={6} />
            }
            
            {playing && contentId === content.id &&
                <div 
                id={`${appendKey}-control-button`}
                style={{ width: size, height: size }} 
                className={`${styles.lottie} link-clickable cursor flex-center`}
                onClick={() => {
                    pause()
                }}
                >
                    <Icon icon="play-circle" size={size} className={styles.icon} />
                    
                    <div className={styles.overlay} style={{ width: size*0.7, height: size*0.7 }} />
                    
                    <img
                    className={`${styles.playingGif} ${size === 52 ? styles.large : ""}`}
                    src={mode === "light" ? playingGifAnimation : playingDarkGifAnimation}
                    />
                </div>
            }

            {playing && contentId === content.id &&
                <Tooltip id={`${appendKey}-control-button`} text={"Pause"} offset={6} />
            }
        </div>
    )
}

export default React.memo(PlayContentButton)