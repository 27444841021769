import styles from './signupad.module.css'

import React from 'react'
import { appStoreUrl, googlePlayUrl } from '../../config';
import { firstLetterCapital } from '../../utils/captialization'
import { usePopup } from '../../hooks/popup/popup';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import { useTrack } from '../../hooks/tracking';

export default function SignUpAd({ type, username, className }) {
    const { openPopup } = usePopup()
    const track = useTrack()

    const isMobile = /Mobi/.test(navigator.userAgent);
    const onIOS = window.navigator.userAgent.includes("iPhone") || window.navigator.userAgent.includes("iPad");
    const onANDROID = window.navigator.userAgent.includes("Android");

    const appLink = (
        onIOS ?
            appStoreUrl
        : onANDROID ?
            googlePlayUrl
        :
            "/apps"
    )

    return (
        <div className={`section-block ${styles.container} ${className}`}>
            <p style={{ fontSize: 16, lineHeight: '24px' }} className="darkGrey">
                <Link className={styles.linkSpan} to={`/${username}`}>{firstLetterCapital(username)}</Link> is 
                using Musicboard to keep track of all the music they listen to and to share their passion for music with friends.{" "}
                <br />
                <br />
                {isMobile && 
                    <a 
                    className={styles.linkSpan} 
                    target="_blank" 
                    href={appLink} 
                    onClick={() => track("Download App Ad Click")}
                    >
                        Download the app
                    </a>
                } 
                {!isMobile && 
                    <span 
                    className={styles.linkSpan} 
                    onClick={() => {
                        openPopup("SignUp")
                        track("Sign Up Ad Click")
                    }}
                    >
                        Create an account
                    </span>
                } 
                {" "}to {type === "review" ? "write and share your own reviews" : type === "list" ? "curate lists of your own" : "rate and share your favorite albums"}.
            </p>
        </div>
    )
}
