import styles from './loginpopup.module.css'

import React, { useState, useEffect, useRef } from 'react'

import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import { useTheme } from '../../hooks/theme'
import { useDispatch, useSelector } from 'react-redux'
import { logIn } from '../../actions/auth-actions'
import { usePopup } from '../../hooks/popup/popup'
import { status } from '../../constants/action-types'
import Carousel from '../../components/Carousel'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router'
import { isValidEmail } from '../../utils/validators'
import Title from '../../shared/Title'
import IconButton from '../../components/IconButton'
import SignInWithApple from '../SignUpPopup/components/SignInWithApple'
import SignInWithGoogle from '../SignUpPopup/components/SignInWithGoogle'
import { useTrack } from '../../hooks/tracking'

export default function LoginPopup({ options }) {
    //General
    const { colors } = useTheme()
    const { openPopup } = usePopup()
    const history = useHistory()
    const track = useTrack()
    
    //Responsive
    const isMinimum = useMediaQuery({ maxWidth: 490 })
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Data
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(null)

    const dispatch = useDispatch()
    const errorMessage = useSelector(state => state.auth.errorMessage)
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const logInStatus = useSelector(state => state.auth.logInStatus)
    const token = useSelector(state => state.auth.token)
    const accessToken = useSelector(state => state.auth.accessToken)

    const completed_onboarding = useSelector(state => state.me.completed_onboarding)

    //Error
    const firstUpdateError = useRef(true)
    useEffect(() => {
        if(firstUpdateError.current) {
            firstUpdateError.current = false;
            return;
        }

        if (errorMessage && logInStatus === status.ERROR) {
            setError(errorMessage)
        }
    }, [ errorMessage, logInStatus ])

    //Close popup on done
    const firstUpdate = useRef(true)
    useEffect(() => {
        if(firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if(logInStatus === status.SUCCESS && isLoggedIn && (token || accessToken)) {
            if (!completed_onboarding) {
                history.push("/onboarding")
            } else {
                openPopup(null)
            }
        }

    }, [ logInStatus, isLoggedIn ])

    function sendRequest() {
        track("Log in");
        
        setError(null)

        if (username.length === 0 || password.length === 0) {
            setError("Both fields have to be filled.")
        } else {
            if (isValidEmail(username)) {
                dispatch(
                    logIn(null, username, password)
                )
            } else {
                dispatch(
                    logIn(username, null, password)
                )
            }
        }
    }

    return (
        <div className={styles.wrapper}>
            <IconButton
            className={styles.closeButton}
            type="transparent-bright"
            icon="close"
            onClick={() => {
                openPopup(null)
            }}
            />
            
            <h4 className={`black text-2xl ${styles.title}`}>Log in to{isMinimum ? <br /> : " "}Musicboard</h4>

            <SignInWithApple className="margin-top-48" isSignIn />

            <SignInWithGoogle className="margin-top-16" isSignIn />

            <div className='flex-row margin-top-32'>
                <div style={{ height: 1, flex: '1 1', backgroundColor: 'var(--darkLightGrey)' }} />

                <h6 className="margin-left-16 margin-right-16 highDarkGrey">OR</h6>
                
                <div style={{ height: 1, flex: '1 1', backgroundColor: 'var(--darkLightGrey)' }} />
            </div>

            <TextInput
            lighterStyle
            label="Username or Email"
            placeholder="@YeOfficial"
            className="margin-top-32"
            name="username"
            type="text"
            text={username}
            autoCapitalize={"off"}
            onTextChange={(value) => {
                setUsername(value)
                setError(null)
            }}
            onEnterClicked={sendRequest}
            />
            
            <TextInput
            lighterStyle
            className="margin-top-16"
            placeholder="********"
            label="Password"
            name="password"
            type="password"
            autoCapitalize={"off"}
            text={password}
            onTextChange={(value) => {
                setPassword(value)
                setError(null)
            }}
            onEnterClicked={sendRequest}
            />

            {error &&
                <div className={`flex-row margin-top-16 ${styles.errorWrapper}`}>
                    <Icon icon="warning" size={18} color={"var(--red)"} />
                    <p className="margin-left-8 medium red">{error}</p>
                </div>
            }

            <Button
            className="margin-top-32"
            text="Log In"
            loading={logInStatus === status.BEGIN}
            onClicked={sendRequest}
            />

            <div className={`flex-row flex-center margin-top-32 ${isMobile && "margin-top-32 margin-bottom-16"}`}>
                <p 
                className={`cursor ${styles.forgot}`}
                onClick={() => openPopup("ForgotPassword")}
                >
                    Forgot password
                </p>

                <p className={`margin-left-8 grey`}>•</p>
                
                <p 
                className={`margin-left-8 cursor ${styles.forgot}`}
                onClick={() => openPopup("SignUp")}
                >
                    Create an account
                </p>
            </div>
        </div>
    )
}
