import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'

const defaultLikes = {
	ratings: {},
	reviews: {},
	lists: {},
	comments: {},
	threads: {},

	lastLikeContentUid: null,
    likeContentStatus: status.DEFAULT,

	deleteLikeContentUid: null,
    deleteLikeStatus: status.DEFAULT,

	errorMessage: null,
};

const likes = (state = defaultLikes, action) => {
	let uid = action.meta && action.meta.params ? action.meta.params.uid : null
	let reset = action.meta && action.meta.params ? action.meta.params.reset : null
	let content_type = action.meta && action.meta.params ? action.meta.params.content_type : null
	let content_uid = action.meta && action.meta.params ? action.meta.params.content_uid : null

	let speedup = action.meta && action.meta.params ? action.meta.params.speedup : false
	let limit = action.meta && action.meta.params ? action.meta.params.limit : false

	switch (action.type) {
		case types.LIKE_CONTENT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        lastLikeContentUid: uid,
						likeContentStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
						likeContentStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						lastLikeContentUid: null,
						likeContentStatus: status.ERROR,
					}
            }
            
		case types.DELETE_LIKE:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        deleteLikeContentUid: uid,
						deleteLikeStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
						deleteLikeStatus: status.SUCCESS,
					}
				case status.ERROR:
					switch (action.meta.statusCode) {
						case 404:
							return {
								...state,
								errorMessage: null,
								deleteLikeStatus: status.SUCCESS,
							}
						default:
							return {
								...state,
								errorMessage: errorToString(action.payload),
								deleteLikeContentUid: null,
								deleteLikeStatus: status.ERROR,
							}
					}
			}
            
		case types.GET_LIKES:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						[`${content_type}s`]: {
							...state[`${content_type}s`],
							[content_uid]: {
								data: state[`${content_type}s`][content_uid] && state[`${content_type}s`][content_uid].data
								? state[`${content_type}s`][content_uid].data
								: [],
								
								count: state[`${content_type}s`][content_uid] && state[`${content_type}s`][content_uid].count
								? state[`${content_type}s`][content_uid].count
								: 0,

								next: null,
								reset: action.meta && action.meta.params ? action.meta.params.reset : false,
								status: status.BEGIN,
							}
						}
					}
				case status.SUCCESS:
					return {
                        ...state,
						[`${content_type}s`]: {
							...state[`${content_type}s`],
							[content_uid]: {
								data: reset
								? action.payload.results
								: [ ...state[`${content_type}s`][content_uid].data, ...action.payload.results ],
								
								count: action.payload.count,
								next: speedup ? (action.payload.results.length < limit) ? null : action.payload.next : action.payload.next,
								status: status.SUCCESS,
							}
						}
					}
				case status.ERROR:
					return {
						...state,
                        errorMessage: errorToString(action.payload),
						[`${content_type}s`]: {
							...state[`${content_type}s`],
							[content_uid]: {
								data: [],
								count: 0,
								next: null,
								status: status.ERROR,
							}
						}
					}
			}
		
		default:
			return state;
	}
};

export default likes;