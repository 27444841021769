import { types, status } from '../constants/action-types';
import { handleNoPaginationReducer } from '../utils/reducer-helpers';


const defaultAdministration = {
	allPatchNotes: {
        data: [],
        status: status.DEFAULT,
        next: null,
        count: 0,
    },

	errorMessage: null
};

const administration = (state = defaultAdministration, action) => {
	switch (action.type) {

		case types.GET_ALL_PATCH_NOTES:
			return handleNoPaginationReducer(state, action, "allPatchNotes")

		default:
			return state;
	}
};

export default administration;