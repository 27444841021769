import styles from './moredropdown.module.css'

import React, { useState, useEffect, useRef } from 'react'

import IconButton from '../../../../components/IconButton'
import { useMediaQuery } from 'react-responsive'
import Dropdown from '../../../../components/Dropdown'
import { Link } from 'react-router-dom'
import { useTheme } from '../../../../hooks/theme'
import { useSelector } from 'react-redux'

export default function MoreDropdown({ className, style }) {
    const { mode } = useTheme()

    //Responsive
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isSmallScreen2 = useMediaQuery({ maxWidth: 1050 })
    const isTablet = useMediaQuery({ maxWidth: 768 })
    const isTablet2 = useMediaQuery({ maxWidth: 700 })
    const isMobile = useMediaQuery({ maxWidth: 630 })
    const isMinimum = useMediaQuery({ maxWidth: 400 })
    const isMinimum2 = useMediaQuery({ maxWidth: 374 })

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

    //Options
    const options = [/*
        isTablet2 && {
            text: 'News',
            icon: "news",
            redirect: `/news`
        },
    */]
    const endOptions = [
        !(!isTablet2 && (!isTablet || isLoggedIn) && (!isSmallScreen2 || isLoggedIn || isSmallScreen)) && {
            text: 'Pro',
            icon: "star",
            redirect: `/pro?ref=MoreDropdown`,
            color: mode === "light" ? "black" : "yellow"
        }, 
        {
            text: 'About',
            icon: 'contact-page',
            redirect: "/about"
        },
        {
            text: 'Apps',
            icon: 'smartphone',
            redirect: "/apps"
        },
        {
            text: 'Welcome',
            icon: 'support',
            redirect: "/welcome"
        },
        {
            text: 'Contact',
            icon: 'mail',
            redirect: "/contact"
        },
        {
            text: 'FAQ',
            icon: 'help',
            redirect: "/faq"
        },
        {
            text: 'Contribute',
            icon: '',
            redirect: "/contributors"
        },
        {
            text: 'Guidelines',
            icon: '',
            redirect: "/guidelines"
        },
        {
            text: 'Terms of Use',
            icon: 'description',
            redirect: "/terms"
        },
        {
            text: 'Privacy Policy',
            icon: 'verified-user',
            redirect: "/privacy"
        },
    ]

    //Visible
    const [visible, setVisible] = useState(false)

    return (
        <div className={`flex-row ${className}`}  style={{ position: 'relative', display: 'flex', justifyContent: 'center', ...style }}>
            <div onClick={() => setVisible(!visible)} className="cursor">
                <IconButton
                icon="more-horiz"
                type="transparent"
                btnSize={40}
                style={{ borderRadius: isSmallScreen ? 100 : "var(--border-radius-small)" }}
                />
            </div>

            <Dropdown 
            simpleAnimation
            visible={visible}
            appendId="more" 
            hideDropdown={() => setVisible(false)} 
            offset={44}
            >

                {options.filter(i => i).map(item => (
                    <Link
                    to={item.redirect}
                    onClick={() => setVisible(false)}
                    key={`mobile-dropdown-option-${item.text}`}
                    className={`flex-row cursor ${styles.menuItem}`}
                    >
                        <h6 className={`${styles.menuItemText} ${item.color ? item.color : "black"} margin-right-16 `}>{item.text}</h6>
                    </Link>
                ))}

                {options.filter(i => i).length > 0 &&
                    <div className="bottom-divider margin-top-8 margin-bottom-8" />
                }
                
                {endOptions.filter(i => i).map(item => (
                    <Link
                    to={item.redirect}
                    onClick={() => setVisible(false)}
                    key={`mobile-dropdown-endoption-${item.text}`}
                    className={`flex-row cursor ${styles.menuItem}`}
                    >
                        <h6 className={`${styles.menuItemText} ${item.color ? item.color : "black"} margin-right-16`}>{item.text}</h6>
                    </Link>
                ))}
            </Dropdown>
            
        </div>
    )
}
