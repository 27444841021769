import styles from './homebox.module.css'

import React from 'react'
import Icon from '../../../../components/Icon'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

export default function HomeBox({ text="", icon, iconColor, className, style, redirect }) {

    const isMinimum = useMediaQuery({ maxWidth: 490 })

    return (
        <Link to={redirect} className={`${styles.container} ${className} mobile-block`} style={style}>
            <div className={`${styles.iconContainer}`}>
                <Icon icon={icon} color={iconColor} size={isMinimum ? 28 : 28} />
            </div>
            <p className={`${styles.p} margin-top-16 margin-right-64`}>{text}</p>
        </Link>
    )
}
