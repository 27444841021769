import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { handleReducer } from '../utils/reducer-helpers';

const defaultLists = {
	lists: {},
	fetchListStatus: status.DEFAULT,
	lastFetchedListUid: null,

	allLists: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},

    lastUpdatedList: null,
    lastUpdatedListUid: null,
    updateListStatus: status.DEFAULT,

    lastCreatedList: null,
    createListStatus: status.DEFAULT,

    lastDeletedListUid: null,
	deleteListStatus: status.DEFAULT,

	createListConnectionsForContentStatus: status.DEFAULT,
	createListConnectionsForListStatus: status.DEFAULT,
	lastCreatedListContents: null,
	lastCreatedListConnections: null,
	
	updateListConnectionsStatus: status.DEFAULT,

	deleteListConnectionsStatus: status.DEFAULT,
	lastDeletedListConnectionContents: null,
	
	errorMessage: null,

	translatedLists: {},
	translateListStatus: status.DEFAULT,

	detectListLanguageStatus: status.DEFAULT,

	getRandomListItemUid: null,
	getRandomListItemResult: null,
	getRandomListItemStatus: status.DEFAULT,

};

const lists = (state = defaultLists, action) => {
	let uid = action.meta && action.meta.params ? action.meta.params.uid : null
	let skipTheContent = action.meta && action.meta.params ? action.meta.params.skipTheContent : null

	switch (action.type) {
		case types.SET_INIT_LIST:
			return {
				...state,
				lists: {
					...state.lists,
					[action.payload.uid]: {
						...state.lists[action.payload.uid],
						...action.payload,
						list_connections: 
							skipTheContent ? 
								state.lists[action.payload.uid] ? state.lists[action.payload.uid].list_connections : null
							: 
								{
									...action.payload.list_connections,
									status: status.DEFAULT,
								}
					}
				},
				fetchListStatus: status.SUCCESS,
				lastFetchedListUid: action.payload.uid,
				errorMessage: null,
			}

		case types.FETCH_LIST:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        fetchListStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
                        lists: {
							...state.lists,
							[action.payload.uid]: {
								...action.payload,
								list_connections: 
									skipTheContent ? 
										state.lists[action.payload.uid] ? state.lists[action.payload.uid].list_connections : null
									: 
										{
											...action.payload.list_connections,
											status: status.DEFAULT,
										}
							}
						},
						fetchListStatus: status.SUCCESS,
						lastFetchedListUid: action.payload.uid,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
                        fetchListStatus: status.ERROR,
						lastFetchedListUid: null,
                        errorMessage: errorToString(action.payload),
					}
			}
		
		case types.FETCH_LIST_CONTENT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        lists: {
							...state.lists,
							[uid]: {
								...state.lists[uid],
								list_connections: {
									...state.lists[uid] ? state.lists[uid].list_connections : {},
									status: status.BEGIN,
									results: [],
									count: null,
									next: null,
									reset: action.meta && action.meta.params ? action.meta.params.reset : false,
								}
							}
						},
					}
				case status.SUCCESS:
					return {
                        ...state,
                        lists: {
							...state.lists,
							[uid]: {
								...state.lists[uid],
								list_connections: {
									...action.payload,
									status: status.SUCCESS,
								}
							}
						},
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
						lists: {
							...state.lists,
							[uid]: {
								...state.lists[uid],
								list_connections: {
									results: [],
									count: null,
									next: null,
									status: status.ERROR,
								}
							}
						},
                        errorMessage: errorToString(action.payload),
					}
			}

		case types.GET_MORE_LIST_CONTENT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        lists: {
							...state.lists,
							[uid]: {
								...state.lists[uid],
								list_connections: {
									...state.lists[uid].list_connections,
									status: status.BEGIN,
									next: null,
									reset: action.meta && action.meta.params ? action.meta.params.reset : false,
								}
							}
						}
					}
				case status.SUCCESS:
					return {
						...state,
                        lists: {
							...state.lists,
							[uid]: {
								...state.lists[uid],
								list_connections: {
									...state.lists[uid].list_connections,
									results: [ 
										...state.lists[uid].list_connections.results, 
										...action.payload.list_connections ? action.payload.list_connections.results : action.payload.results
									],
									status: status.SUCCESS,
									next: action.payload.list_connections ? action.payload.list_connections.next : action.payload.next,
									count: action.payload.list_connections ? action.payload.list_connections.count : action.payload.count,
								}
							}
						}
					}
				case status.ERROR:
					return {
						...state,
						lists: {
							...state.lists,
							[uid]: {
								...state.lists[uid],
								list_connections: {
									results: [],
									count: null,
									next: null,
									status: status.ERROR,
									next: null,
								}
							}
						},
						errorMessage: errorToString(action.payload),
					}
			}

		case types.UPDATE_LIST:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
						updateListStatus: status.BEGIN,
						lastUpdatedListUid: uid,
					}
				case status.SUCCESS:
					return {
                        ...state,
                        lastUpdatedList: action.payload,
						updateListStatus: status.SUCCESS,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
                        updateListStatus: status.ERROR,
                        errorMessage: errorToString(action.payload),
					}
			}
		
		case types.CREATE_LIST:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createListStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						lastCreatedList: action.payload,
						createListStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createListStatus: status.ERROR,
					}
			}
		
		case types.DELETE_LIST:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						deleteListStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						lastDeletedListUid: uid,
						deleteListStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						deleteListStatus: status.ERROR,
					}
			}

		case types.CREATE_LIST_CONNECTIONS_FOR_CONTENT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createListConnectionsForContentStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						createListConnectionsForContentStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						createListConnectionsForContentStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}

		case types.CREATE_LIST_CONNECTIONS_FOR_LIST:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createListConnectionsForListStatus: status.BEGIN,
						lastCreatedListContents: action.meta.params.contents,
					}
				case status.SUCCESS:
					return {
						...state,
						createListConnectionsForListStatus: status.SUCCESS,
						lastCreatedListConnections: action.payload
					}
				case status.ERROR:
					return {
						...state,
						createListConnectionsForListStatus: status.ERROR,
						lastCreatedListConnections: null,
						errorMessage: errorToString(action.payload),
					}
			}
		
		case types.UPDATE_LIST_CONNECTIONS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						updateListConnectionsStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						updateListConnectionsStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						updateListConnectionsStatus: status.ERROR,
					}
			}
		
		case types.DELETE_LIST_CONNECTIONS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						deleteListConnectionsStatus: status.BEGIN,
						lastDeletedListConnectionContents: action.meta.params.contents
					}
				case status.SUCCESS:
					return {
						...state,
						deleteListConnectionsStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						deleteListConnectionsStatus: status.ERROR,
					}
			}

		case types.GET_ALL_LISTS:
			return handleReducer(state, action, 'allLists')

		case types.LIKE_CONTENT:
			if (action.meta.params.contentType !== "list") return state
			if (!action.meta.params.detailed) return state

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						lists: {
							...state.lists,
							[action.meta.params.uid]: {
								...state.lists[action.meta.params.uid],
								like_count: state.lists[action.meta.params.uid].like_count + 1
							}
						},
					}
				case status.SUCCESS:
                    return state
				case status.ERROR:
					return {
						...state,
						lists: {
							...state.lists,
							[action.meta.params.uid]: {
								...state.lists[action.meta.params.uid],
								like_count: state.lists[action.meta.params.uid].like_count - 1
							}
						},
					}
			}

		case types.DELETE_LIKE:
			if (action.meta.params.contentType !== "list") return state
			if (!action.meta.params.detailed) return state

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						lists: {
							...state.lists,
							[action.meta.params.uid]: {
								...state.lists[action.meta.params.uid],
								like_count: state.lists[action.meta.params.uid].like_count - 1
							}
						},
					}
				case status.SUCCESS:
                    return state
				case status.ERROR:
					return {
						...state,
						lists: {
							...state.lists,
							[action.meta.params.uid]: {
								...state.lists[action.meta.params.uid],
								like_count: state.lists[action.meta.params.uid].like_count + 1
							}
						},
					}
			}

		case types.TRANSLATE_LIST:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        translateListStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
                        translatedLists: {
							...state.translatedLists,
							[uid]: action.payload.translated_text
						},
						translateListStatus: status.SUCCESS,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
						translatedLists: {
							...state.translatedLists,
							[uid]: null
						},
                        translateListStatus: status.ERROR,
                        errorMessage: errorToString(action.payload),
					}
			}

		case types.DETECT_LIST_LANGUAGE:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						detectListLanguageStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						lists: {
							...state.lists,
							[uid]: {
								...state.lists[uid],
								detected_language: action.payload.detected_language
							}
						},
						detectListLanguageStatus: status.SUCCESS,
						errorMessage: null,
					}
				case status.ERROR:
					return {
						...state,
						lists: {
							...state.lists,
							[uid]: {
								...state.lists[uid],
								detected_language: null
							}
						},
						detectListLanguageStatus: status.ERROR,
						errorMessage: errorToString(action.payload),
					}
			}

		//When getting a random item for a list
		case types.GET_RANDOM_LIST_ITEM:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						getRandomListItemStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						getRandomListItemUid: uid,
						getRandomListItemResult: action.payload,
						getRandomListItemStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						getRandomListItemUid: null,
						getRandomListItemResult: null,
						getRandomListItemStatus: status.ERROR,
					}
			}
	
		default:
			return state;
	}
};

export default lists;