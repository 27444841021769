import styles from './listcheckitem.module.css'

import React from 'react'
import moment from 'moment'
import Icon from '../Icon'
import ProfilePicture from '../ProfilePicture'
import AlbumCover from '../AlbumCover'
import Checkbox from '../Checkbox'

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function ListCheckItem({ list, selected, checked, onAdd, onRemove, thumbIcon, className }) {

    return (
        <div 
        onClick={() => {
            if (selected) {
                onRemove(list)
            } else {
                onAdd(list)
            }
        }}
        className={`${styles.container} cursor ${className}`}
        >
            <div className="flex-row">
                <AlbumCover 
                clickable 
                size={60} 
                albumCover={
                    list.list_connections.results.filter((_, i) => i < 4)
                    .flatMap((item) => (
                        item.content.picture ? item.content.picture_small || item.content.picture : item.content.cover ? item.content.cover_small || item.content.cover : item.content.album.cover_small || item.content.album.cover
                    ))
                    .filter(onlyUnique)
                }
                altText={
                    list.list_connections.results.filter((_, i) => i < 4)
                    .flatMap((item) => (
                        item.content.picture ? item.content.name : item.content.cover ? item.content.title : item.content.title
                    ))
                    .filter(onlyUnique)
                }
                />

                <div className={`col-1 margin-left-16 margin-right-16`}>
                    <div className="grid">
                        <h6 className={`${styles.title} one-line`}>
                            {list.title}
                        </h6>
                    </div>
                    
                    <div className="flex-row margin-top-4">
                        {(list.private || list.private_locked) &&
                            <Icon icon="lock" size={14} style={{ marginRight: 4, marginTop: 1 }} color={"var(--grey)"} />
                        }

                        <div className="grid">
                            <p className={`${styles.artist} one-line`}>
                                {(list.private || list.private_locked) ? " • " : ""}
                                {list.list_connections.count} {list.list_connections.count === 1 ? "entry" : "entries"}
                            </p>
                        </div>
                    </div>
                </div>

                <Checkbox
                checked={checked}
                onChange={() => {
                    if (selected) {
                        onRemove(list)
                    } else {
                        onAdd(list)
                    }
                }}
                thumbIcon={thumbIcon}
                />
            </div>
        </div>
    )
}

export default React.memo(ListCheckItem)
