import styles from './track.module.css'

import React, { Suspense, lazy, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { status } from '../../constants/action-types'

import { fetchAlbum, fetchTrack, fetchTrackFromSlug, getAlbumBonusTracks, getMyContentRating } from '../../actions/content-actions'

import { useTheme } from '../../hooks/theme'
import { useDeezer } from '../../hooks/deezer'
import { usePopup } from '../../hooks/popup/popup'
import { useStatusHandler } from '../../hooks/status-handler'

import { checkNull, oneDecimal } from '../../utils/format-text'

import Icon from '../../components/Icon'
import Stats from '../../components/Stats'
import Button from '../../components/Button'
import MenuBar from '../../components/MenuBar'
import AlbumCover from '../../components/AlbumCover'
import ProfilePicture from '../../components/ProfilePicture'
import ChangeContentRating from '../../components/ChangeContentRating'

import NotFound from '../NotFound'

const TrackHome = lazy(() => import('./TrackGroup').then((module) => ({default: module.TrackHome})));

const ContentLists = lazy(() => import('../ContentLists'))
const ContentReviews = lazy(() => import('../ContentReviews'))
const ContentRatings = lazy(() => import('../ContentRatings'))

export default function Track() {
    //general
    const dispatch = useDispatch()
    const match = useRouteMatch()
    const history = useHistory()
    const location = useLocation()
    const deezer = useDeezer()
    const { colors } = useTheme()
    const { openPopup } = usePopup()
    const { id, titleSlug, albumSlug, artistSlug } = useParams()

    //Params
    const fromDeezer = new URLSearchParams(location.search).get("dz") !== null
    const fromRedirect = new URLSearchParams(location.search).get("rd") !== null

    //Responsive
    const isBigScreen = useMediaQuery({ maxWidth: 1250 })
    const isMidScreen = useMediaQuery({ maxWidth: 1100 })
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isTablet = useMediaQuery({ maxWidth: 768 })
    const isMobile = useMediaQuery({ maxWidth: 630 })
    const isMinimum = useMediaQuery({ maxWidth: 490 })

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

    //Prefetched artist id
    const urlSlugConnectionId = useSelector(state => titleSlug && albumSlug && artistSlug ? state.local.trackUrlSlugResult[`/track/${titleSlug}/${albumSlug}/${artistSlug}/`] : null)
    const deezerIdConnectionId = useSelector(state => id && fromDeezer ? state.local.trackDeezerIdResult[id] : null)
    const idConnectionId = useSelector(state => id && !fromDeezer ? state.local.trackIdResult[id] : null)

    //Support to update the track id
    const [trackId, setTrackId] = useState(urlSlugConnectionId || deezerIdConnectionId || idConnectionId || id)

    //Backend data
    const backend = useSelector(state => state.tracks.tracks[trackId])
    
    //Variables
    const albumId = backend && backend.album ? backend.album.duplicate_of_id ? backend.album.duplicate_of_id : backend.album.id : null
    const albumDeezerId = backend && backend.album ? backend.album.duplicate_of_deezer_id ? backend.album.duplicate_of_deezer_id : backend.album.deezer_id : null

    //More data
    const albumBackend = useSelector(state => state.albums.albums[albumId])
    const lastFetchedTrackId = useSelector(state => state.tracks.lastFetchedTrackId)
    const fetchTrackStatus = useSelector(state => state.tracks.fetchTrackStatus)

    //State
    const [tracklist, setTracklist] = useState(null)
    const [initWait, setInitWait] = useState(true)

    //Refs
    const hasFetched = useRef(false)

    //Get track from API and refresh trackId variable
    useEffect(() => {
        //Set init wait to hide error
        setInitWait(true)
        setTimeout(() => {
            setInitWait(false)
        }, 75)

        if (titleSlug && albumSlug && artistSlug) {
            if (urlSlugConnectionId) { setTrackId(urlSlugConnectionId) } else { setTrackId(null) }
        } else if (fromDeezer) {
            if (deezerIdConnectionId) { setTrackId(deezerIdConnectionId) } else { setTrackId(id) }
        } else {
            if (idConnectionId) { setTrackId(idConnectionId) } else { setTrackId(id) }
        }
        
        if (titleSlug && albumSlug && artistSlug) {
            if (!fromRedirect || !(backend && backend.id)) {
                dispatch( 
                    fetchTrackFromSlug(titleSlug, albumSlug, artistSlug) 
                )
            }
        } else {
            dispatch( 
                fetchTrack(id, fromDeezer) 
            )
        }
    }, [ id, titleSlug, albumSlug, artistSlug, fromDeezer ])

    //Potentially update the trackId if our backend return a different
    useStatusHandler({
        effectStatus: fetchTrackStatus,
        successCallback: () => {
            if (lastFetchedTrackId && lastFetchedTrackId !== trackId) {
                setTrackId(lastFetchedTrackId)
            }
        }
    })

    //Reset the hasFetched ref when we change the album
    useEffect(() => {
        hasFetched.current = false
    }, [ id, titleSlug, albumSlug, artistSlug ])
    
    //Get data when we have the correct track
    useEffect(() => {
        if (backend && backend.id && !hasFetched.current) {
            hasFetched.current = true;

            if (backend && !titleSlug && !albumSlug && !artistSlug) {
                const locationSearchList = location.search ? location.search.replace("?", "").split("&") : []
                locationSearchList.splice(locationSearchList.indexOf("dz"), 1)
                if (locationSearchList.indexOf("rd") === -1) {
                    locationSearchList.push("rd")
                }
                history.replace(`${backend.url_slug}${location.pathname.replace(`/track/${id}`, "").replace("/", "")}${locationSearchList.length > 0 ? "?" : ""}${locationSearchList.join("&")}`)
            }
            
            //Fetch the user's rating
            if (isLoggedIn) {
                dispatch( 
                    getMyContentRating(backend.id, 'track', false) 
                )
            }

            //Get tracklist if deezer
            if(albumDeezerId) {
                deezer.getAlbum(albumDeezerId).then((album) => {
                    if (album && album.tracks && album.tracks.data) {
                        setTracklist(album.tracks.data)
                    }
                }).catch((err) => {
                    console.warn(err)
                })
            } else {
                dispatch(
                    fetchAlbum(albumId, false)
                )
            }

            dispatch(
                getAlbumBonusTracks(albumId, false)
            )

        }

    }, [ backend ])

    //Variables
    const activeTracks = [
        ...(albumDeezerId && tracklist ? tracklist : albumBackend && albumBackend.tracks ? albumBackend.tracks : []),
        ...(albumBackend && albumBackend.bonus_tracks ? albumBackend.bonus_tracks : [])
    ]

    if(!backend || !backend.id) {
        if(!initWait && (fetchTrackStatus === status.SUCCESS ||fetchTrackStatus === status.ERROR)) {
            return (
                <NotFound />
            )
        } else {
            return (
                <div className="full-height">
                    <div className="full-center">
                        <Icon className="margin-auto" icon="loading" color={colors.darkLightGrey} size={32} />
                    </div>
                </div>
            )
        }
    }
    
    return (
        <div>
            <MenuBar
            tabs={[
                {
                    text: "Home",
                    redirect: backend.url_slug,
                    extraActive: `/track/${id}`
                },
                {
                    text: "Reviews",
                    redirect: `${backend.url_slug}reviews?order_by=helpful`,
                    extraActive: `/track/${id}/reviews?order_by=helpful`
                },
                {
                    text: "Lists",
                    redirect: `${backend.url_slug}lists?order_by=-like_count`,
                    extraActive: `/track/${id}/lists?order_by=-like_count`
                },
                !isMinimum && {
                    text: "Ratings",
                    redirect: `${backend.url_slug}ratings`,
                    extraActive: `/track/${id}/ratings`
                },
            ]}
            >
                <div className="padding-bottom-32" style={{ position: 'relative', zIndex: 1 }}>
                    <div className="page-container padding-top-48">
                            
                        <div className="">
                            <Link to={backend.album.url_slug} className={styles.backButton}>
                                <h6 className={styles.backButtonText}>Go Back to Album</h6>
                            </Link>
                        </div>

                        <div className="section-handler-end padding-top-32">
                            <div className="section-3 section-margin">
                                <div className={isSmallScreen ? "flex-column flex-center" : "flex-row align-end"}>
                                    <Link to={backend.album.url_slug}>
                                        <AlbumCover
                                        clickable
                                        size={isMobile ? 200 : isMidScreen ? 240 : isBigScreen ? 270 : 300}
                                        albumCover={backend.album.cover_large || backend.album.cover}
                                        altText={backend.title}
                                        />
                                    </Link>

                                    <div className={isSmallScreen ? "flex-column flex-center margin-top-24" : "margin-left-24 margin-bottom-16"}>
                                        <h4 className={`black text-2xl ${isSmallScreen && "text-center"}`} style={isMobile ? { fontSize: 28, lineHeight: '38px' } : isMidScreen ? { fontSize: 32, lineHeight: '42px' } : isBigScreen ? { fontSize: 36, lineHeight: '46px' } : {}}>{backend.title}</h4>

                                        <p style={{ fontSize: 16, marginTop: 14 }} className={`medium highDarkGrey ${isSmallScreen && "text-center"}`}>Track {backend.track_position || activeTracks.findIndex(track => track.id === backend.id || track.id === backend.deezer_id) + 1} on <Link className="medium" to={backend.album.url_slug}><span>{backend.album.title}</span></Link></p>

                                        <div className="flex-row" style={{ marginTop: 20 }}>
                                            <Link to={backend.artist.url_slug}>
                                                <ProfilePicture size={32} clickable profilePicture={backend.artist.picture_small || backend.artist.picture} altText={backend.artist.name} />
                                            </Link>

                                            <Link to={backend.artist.url_slug}>
                                                <p className="medium margin-left-12" style={{ fontSize: 16 }}>{backend.artist.name}</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`section-2 ${isSmallScreen ? "margin-top-32" : "margin-bottom-16"}`}>
                                <Stats
                                hideIcons={(isMidScreen && !isSmallScreen) || isMobile}
                                statistics={[
                                    {
                                        number: backend && backend.ratings_count ? checkNull(backend.ratings_count) : '0',
                                        description: "Total ratings"
                                    },
                                    {
                                        number: backend && backend.average_rating ? oneDecimal(backend.average_rating/2) : 0,
                                        subText: " / 5",
                                        description: "Average rating",
                                        star: true,
                                        active: backend && backend.average_rating
                                    },
                                    {
                                        number: (backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0]) ? (backend.myRatings.data[0].rating/2) : 0,
                                        subText: " / 5",
                                        description: "Your rating",
                                        star: true,
                                        active: (backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0] && backend.myRatings.data[0].rating)
                                    },
                                ]}
                                />

                                {(backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0]) &&
                                    <div className={`${isMobile ? "margin-top-32" : "margin-top-16"} ${styles.myRatingContainer}`}>
                                        <div className={styles.myRating}>
                                            <ChangeContentRating contentId={backend.id} contentType="track" fromDeezer={false} iconSize={24} />
                                        </div>

                                        <div className={styles.myRatingOverlayBtn}>
                                            <Button
                                            text={!backend.myRatings.data[0].rating ? "Logged track" : "Edit rating"}
                                            iconName="edit"
                                            showIcon
                                            style={{ cursor: "default" }}
                                            type={!backend.myRatings.data[0].rating ? "green" : "yellow"}
                                            />
                                        </div>
                                    </div>
                                }

                                {!(backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0]) &&
                                    <Button
                                    className="margin-top-16"
                                    text={isLoggedIn ? "Rate Track" : "Sign up to rate this track"}
                                    iconName={isLoggedIn ? "star" : "lock"}
                                    showIcon
                                    onClicked={() => {
                                        if (isLoggedIn) {
                                            openPopup("CreateReview", { content: backend, cacheRating: true })
                                        } else {
                                            openPopup("SignUp")
                                        }
                                    }}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </MenuBar>
            
            <Suspense
            fallback={
                <div className='flex-center empty-text margin-top-16 margin-bottom-16'>
                    <Icon icon="loading" />
                </div>
            }
            >
                <Switch>
                    <Route 
                    exact 
                    path={`${match.path}`} 
                    render={() => <TrackHome backend={backend} titleSlug={titleSlug} tracklist={activeTracks} activeTrackId={backend.id} activeTrackDeezerId={backend.deezer_id} />} 
                    />

                    <Route 
                    exact 
                    path={`${match.path}/reviews`} 
                    render={() => <ContentReviews backend={backend} titleSlug={titleSlug} tracklist={activeTracks} activeTrackId={backend.id} activeTrackDeezerId={backend.deezer_id} />} 
                    />

                    <Route
                    exact 
                    path={`${match.path}/lists`} 
                    render={() => (
                        <ContentLists backend={backend} titleSlug={titleSlug} tracklist={activeTracks} activeTrackId={backend.id} activeTrackDeezerId={backend.deezer_id} />
                    )}
                    />

                    <Route 
                    exact 
                    path={`${match.path}/ratings`} 
                    render={() => <ContentRatings backend={backend} titleSlug={titleSlug} tracklist={activeTracks} activeTrackId={backend.id} activeTrackDeezerId={backend.deezer_id} />} 
                    />
                </Switch>
            </Suspense>
        </div>
    )
}
