import React, { useState, useEffect, useRef } from 'react'


import UserRedirect from '../../components/UserRedirect'
import { useSelector, useDispatch } from 'react-redux'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import { createComment } from '../../actions/comments-actions'
import { status } from '../../constants/action-types'
import ProfilePicture from '../ProfilePicture'
import { usePopup } from '../../hooks/popup/popup'
import UserProfilePicture from '../UserProfilePicture'
import { useTrack } from '../../hooks/tracking'

export default function WriteCommentPopup({ text="", contentType, contentUid, commentUid, style }) {
    //General
    const dispatch = useDispatch()
    const {openPopup} = usePopup()
    const track = useTrack()

    //Data
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const me = useSelector(state => state.me)
    const createCommentStatus = useSelector(state => state.comments.createCommentStatus)

    //State
    const [comment, setComment] = useState(text)

    //Close popup when done
    const firstUpdate = useRef(true)
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (createCommentStatus === status.SUCCESS) {
            setComment("")
        }
    }, [ createCommentStatus ])

    return (
        <div style={style}>
            <div className="flex-row align-top">
                <a href={`/${me.username}`} target="_blank">
                    <UserProfilePicture 
                    clickable 
                    profilePicture={me.profile_picture?.includes(".gif") ? me.profile_picture : me.profile_picture_small || me.profile_picture} 
                    profileFrame={me.is_pro && me.tier === 1 ? me.profile_frame : null} 
                    size={32} 
                    altText={me.name !== "" ? me.name : me.username}
                    />
                </a>
                
                <div className="col-1 margin-left-16">
                    <TextInput
                    textarea
                    placeholder="Write a comment..."
                    text={comment}
                    onTextChange={(t) => setComment(t)}
                    maxLength={10000000}
                    textareaHeight={90}
                    />

                    <Button
                    text="Send"
                    disabled={comment === ""}
                    loading={createCommentStatus === status.BEGIN}
                    height={40}
                    className="margin-top-16"
                    onClicked={() => {
                        if (!isLoggedIn) {
                            openPopup("SignUp")
                            return
                        }

                        track(
                            "Created comment", 
                            { 
                                "comment": comment,
                                "content_type": contentType,
                            }
                        );

                        dispatch(
                            createComment(contentUid, contentType, comment, commentUid ? commentUid : null)
                        )
                    }}
                    />
                </div>
            </div>
        </div>
    )
}
