import styles from './button.module.css'

import React from 'react'

import Icon from '../Icon'

import { useTheme } from '../../hooks/theme'

export default function Button({
    text, 
    iconName,
    iconSize,
    iconColor,
    showIcon,
    textStyle, 
    style,
    className,
    textClassName,
    marginClassName,
    marginStyle,
    loading,
    disabled,
    onClicked=() => {},
    subText,
    subTextStyle,
    subTextClass,
    noShadow,
    height=45,
    type="primary", // "primary" || "secondary" || "secondary-two" || "transparent" || "transparent-bright" || "destructive" || "yellow" || "green" || "black"
    squareButton=false,
}) {
    const { mode } = useTheme()

    return (
        <div
        className={`grid ${styles.btn} ${noShadow ? styles.noShadow : ""} ${disabled ? styles.disabled : ""} ${
            type === "transparent" ?
                styles.transparent
            : type === "transparent-bright" ?
                styles.transparentBright
            : type === "secondary" ?
                styles.secondary
            :  type === "secondary-two" ?
                styles.secondaryTwo
            : type === "destructive" ?
                styles.destructive
            : type === "yellow" ?
                styles.yellow
            : type === "green" ?
                styles.green
            : type === "black" ?
                styles.black
            : ""
        } ${className}`}
        style={{
            ...style,
            height: (style && style.height) ? style.height+Math.round(styles.height*0.1333333333) : height+Math.round(height*0.1333333333),
        }}
        onClick={(e) => {
            if(!disabled) {
                onClicked(e)
            }
        }}
        >
            <div
            className={`${styles.margin} ${marginClassName} AnimatedArrow-hover`}
            style={{
                ...marginStyle,
                margin: squareButton ? 0 : undefined,
                height: squareButton ? (style && style.height) ? style.height+Math.round(styles.height*0.1333333333) : height+Math.round(height*0.1333333333) : undefined,
                width: squareButton ? (style && style.height) ? style.height+Math.round(styles.height*0.1333333333) : height+Math.round(height*0.1333333333) : undefined,
            }}
            >
                {showIcon && !loading &&
                    <Icon color={iconColor ? iconColor : type === "transparent" ? "var(--black)" : type === "black" ? "var(--white)" : mode === "teal" ? (type === "secondary" || type === "secondary-two") ? "var(--black)" : "var(--lightestGrey)" : "white"} icon={iconName} size={iconSize ? iconSize : 20} />
                }
                
                {loading &&
                    <Icon color={iconColor ? iconColor : type === "transparent" ? "var(--black)" : type === "black" ? "var(--white)" : mode === "teal" ? (type === "secondary" || type === "secondary-two") ? "var(--black)" : "var(--lightestGrey)" : "white"} icon={"loading"} size={iconSize ? iconSize : 20} />
                }

                {text &&
                    <div className="grid">
                        <h5 className={`${styles.text} ${textClassName} ${(showIcon || loading) ? "margin-left-8" : ""} one-line`} style={textStyle}>{text} {subText && <span className={subTextClass} style={{fontWeight: 'normal', ...subTextStyle}}>{subText}</span>}</h5>
                    </div>
                }
            </div>
        </div>
    )
}
