import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'

const defaultCore = {
	lastReportUserContentUid: null,
    reportUserContentStatus: status.DEFAULT,

	errorMessage: null,
};

const core = (state = defaultCore, action) => {
	let uid = action.meta && action.meta.params ? action.meta.params.uid : null

	switch (action.type) {
		case types.REPORT_USER_CONTENT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        lastReportUserContentUid: uid,
						reportUserContentStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
						reportUserContentStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
                        errorMessage: errorToString(action.payload),
						reportUserContentStatus: status.ERROR,
					}
			}
		
		default:
			return state;
	}
};

export default core;