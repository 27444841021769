import styles from './mobilesearchmenu.module.css'

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useTheme } from '../../../../hooks/theme'
import { addToListsHistory, addToMusicHistory, addToUsersHistory, clearListsHistory, removeFromMusicHistory, removeFromUsersHistory } from '../../../../actions/local-actions'
import { clearSearchAlbums, clearSearchArtists, clearSearchLists, clearSearchTracks, clearSearchUsers, getSearchLists, getSearchUsers, searchMergedContent } from '../../../../actions/search-actions'
import { checkDeezerId } from '../../../../utils/deezer-utils'

import Icon from '../../../../components/Icon'
import Heading from '../../../../components/Heading'
import UserItem from '../../../../components/UserItem'
import SearchAlbum from '../../../../routes/Search/components/SearchAlbum'
import SearchArtist from '../../../../routes/Search/components/SearchArtist'
import SearchTrack from '../../../../routes/Search/components/SearchTrack'
import IconButton from '../../../../components/IconButton'
import { status } from '../../../../constants/action-types'
import ListItem from '../../../../components/ListItem'
import Tooltip from '../../../../components/Tooltip'

export default function MobileSearchMenu({ showingMenu, setShowingMenu }) {
    const { mode } = useTheme()
    const dispatch = useDispatch()
    const routerHistory = useHistory()
    const location = useLocation()
    
    //Data
    const type = useSelector(state => state.local.searchType)

    const musicHistory = useSelector(state => state.local.musicHistory)
    const usersHistory = useSelector(state => state.local.usersHistory)
    const listsHistory = useSelector(state => state.local.listsHistory)

    const mergedAlbums = useSelector(state => state.search.mergedAlbums)
    const mergedTracks = useSelector(state => state.search.mergedTracks)
    const mergedArtists = useSelector(state => state.search.mergedArtists)
    const searchUsers = useSelector(state => state.search.users)
    const searchLists = useSelector(state => state.search.lists)

    const [inputFocused, setInputFocused] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [cursorIndex, setCursorIndex] = useState(0)
    const [query, setQuery] = useState("")

    //Refs
    const timer = useRef()
    const inputRef = useRef()

    //Get data
    useEffect(() => {
        if(query.trim() !== "") {
            if (timer.current) {
                clearTimeout(timer.current)
            }

            if (mergedAlbums.controller) {
                mergedAlbums.controller.abort()
            }
            if (mergedTracks.controller) {
                mergedTracks.controller.abort()
            }
            if (mergedArtists.controller) {
                mergedArtists.controller.abort()
            }
            if (searchUsers.controller) {
                searchUsers.controller.abort()
            }

            timer.current = setTimeout(() => {
                dispatch( searchMergedContent(query, "album", 8) )
                dispatch( searchMergedContent(query, "track", 8) )
                dispatch( searchMergedContent(query, "artist", 8) )

                dispatch( 
                    getSearchUsers({
                        search: query,
                        limit: 20
                    })
                )

                dispatch( 
                    getSearchLists({
                        search: query,
                        limit: 12
                    })
                )
            }, 200)

        } else {
            dispatch( clearSearchAlbums() )
            dispatch( clearSearchTracks() ) 
            dispatch( clearSearchArtists() )
            dispatch( clearSearchLists() )

            dispatch( clearSearchUsers() )

        }
    }, [ query ])

    //Reset cursor on new search value
    useEffect(() => {
        setCursorIndex(0)
    }, [query])

    //Update scroll behavior
    useEffect(() => {
        if (showingMenu) {
            document.documentElement.style.overflow = "hidden"
        }

        return(() => {
            document.documentElement.style.overflow = "initial"
        })
    }, [showingMenu])

    //Cursor index help variables and functions
    const musicIndexArray = [
        ...mergedAlbums.data.filter((_, i) => i < 5),
        "search-albums",
        ...mergedTracks.data.filter((_, i) => i < 5),
        "search-tracks",
        ...mergedArtists.data.filter((_, i) => i < 5),
        "search-artists",
    ]
    
    const userIndexArray = [
        ...searchUsers.data,
        "search-users",
    ]
    
    const listIndexArray = [
        ...searchLists.data,
        "search-lists",
    ]

    const indexArray = (
        query.trim() === "" ?
            activeTab === 0 ?
                musicHistory
            : activeTab === 1 ?
                usersHistory
            :
                listsHistory
        :
            activeTab === 0 ?
                musicIndexArray
            : activeTab === 1 ?
                userIndexArray
            :
                listIndexArray
    )

    function getIndex(content) {
        if (content === "search-albums") {
            return indexArray.indexOf("search-albums") + 1
        } else if (content === "search-tracks") {
            return indexArray.indexOf("search-tracks") + 1
        } else if (content === "search-artists") {
            return indexArray.indexOf("search-artists") + 1
        } else if (content === "search-users") {
            return indexArray.indexOf("search-users") + 1
        } else if (content === "search-reviews") {
            return indexArray.indexOf("search-reviews") + 1
        } else if (content === "search-lists") {
            return indexArray.indexOf("search-lists") + 1
        } else if (content.username) {
            return indexArray.findIndex((compare) => compare.username === content.username) + 1
        } else if (content.type === "list") {
            return indexArray.findIndex((compare) => compare.uid === content.uid) + 1
        } else {
            return indexArray.findIndex((compare) => `${compare.type}-${compare.id}` === `${content.type}-${content.id}`) + 1
        }
    }

    //Helper functions
    function onKeyDown(e) {
        if (e.key === "Enter") {
            setShowingMenu(false)

            if (inputRef.current) {
                inputRef.current.blur()
            }

            if (cursorIndex !== 0) {
                const option = indexArray[cursorIndex-1]
                
                //Add to history
                if (option.type === "album" || (option.type === "track" && option.album) || option.type === "artist") {
                    dispatch(
                        addToMusicHistory(option)
                    )
                } else if (option.type === "list") {
                    dispatch(
                        addToListsHistory(option)
                    )
                } else if (option.type === "user") {
                    dispatch(
                        addToUsersHistory(option)
                    )
                }

                //Route to result
                if (option.type === "album") {
                    routerHistory.push(checkDeezerId(option) ? `/album/${option.id}?dz` : option.url_slug)
                } else if (option.type === "track" && option.album) {
                    routerHistory.push(
                        checkDeezerId(option.album) ? 
                            `/album/${option.album.id}?tracks=${option.id}&dz`
                        : 
                            `${option.album.url_slug}?tracks=${option.id}`
                    )
                } else if (option.type === "artist") {
                    routerHistory.push(checkDeezerId(option) ? `/artist/${option.id}?dz` : option.url_slug)
                } else if (option.username) {
                    routerHistory.push(`/${option.username}`)
                } else if (option.type === "list") {
                    routerHistory.push(option.url_slug)
                } 
                else if (option === "search-albums") {
                    routerHistory.push(`/search/${encodeURIComponent(query)}/album`)
                } else if (option === "search-tracks") {
                    routerHistory.push(`/search/${encodeURIComponent(query)}/track`)
                } else if (option === "search-artists") {
                    routerHistory.push(`/search/${encodeURIComponent(query)}/artist`)
                } else if (option === "search-users") {
                    routerHistory.push(`/search/${encodeURIComponent(query)}/user`)
                } else if (option === "search-reviews") {
                    routerHistory.push(`/search/${encodeURIComponent(query)}/review`)
                } else if (option === "search-lists") {
                    routerHistory.push(`/search/${encodeURIComponent(query)}/list`)
                }
            }

        } else if(e.key === "Escape") {
            setShowingMenu(false)

            if (inputRef.current) {
                inputRef.current.blur()
            }

        } else if (e.key === "ArrowDown") {
            e.preventDefault()

            if (cursorIndex <= indexArray.length-1) {
                setCursorIndex(cursorIndex+1)
            } else {
                setCursorIndex(0)
            }

        } else if (e.key === "ArrowUp") {
            e.preventDefault()

            if (cursorIndex > 0) {
                setCursorIndex(cursorIndex-1)
            } else {
                setCursorIndex(indexArray.length)
            }
        }
    }

    return (
        <div className={`${styles.wrapper}`}>
            <div onClick={() => setShowingMenu(false)} className={styles.background} />

            <div className={styles.menu}>

                <div className={`${styles.inputContainer} ${inputFocused ? styles.active : ""}`}>
                    <input
                    ref={inputRef}
                    autoFocus={true}
                    placeholder={"Search Musicboard..."} 
                    className={styles.input}
                    value={query}
                    onKeyDown={onKeyDown}
                    onChange={(e) => {
                        setQuery(e.target.value)
                        setCursorIndex(0)
                    }}
                    onFocus={() => {
                        setInputFocused(true)
                        setCursorIndex(0)
                    }}
                    onBlur={() => {
                        setInputFocused(false)
                    }}
                    />

                    <Icon color={"var(--highDarkGrey)"} size={20} icon="search" className={styles.icon} />
                </div>

                <div className='flex-row bottom-divider' style={{ position: 'relative' }}>
                    <div onClick={() => setActiveTab(0)} className={`padding-around col-1 flex-center ${styles.tabPickerItem} ${activeTab === 0 ? styles.active : ""}`}>
                        <h5 className='text-center'>Music</h5>
                    </div>
                    
                    <div onClick={() => setActiveTab(1)} className={`padding-around col-1 flex-center ${styles.tabPickerItem} ${activeTab === 1 ? styles.active : ""}`}>
                        <h5 className='text-center'>Members</h5>
                    </div>

                    <div onClick={() => setActiveTab(2)} className={`padding-around col-1 flex-center ${styles.tabPickerItem} ${activeTab === 2 ? styles.active : ""}`}>
                        <h5 className='text-center'>Lists</h5>
                    </div>

                    <div className={styles.tabPickerLine} style={{ left: activeTab === 0 ? "0%" : activeTab === 1 ? "33.33333%" : "66.66667%" }} />
                </div>

                <div className={styles.menuScroller}>
                    {activeTab === 0 && 
                        <div className='padding-around-8'>
                            {query.trim() !== "" &&
                            <>
                                {mergedAlbums.data.length > 0 &&
                                    <div className="margin-top-8">
                                        <Heading text="Albums" className="margin-left-8" style={{ paddingBottom: 0 }} />

                                        <div className="margin-top-4">
                                            {mergedAlbums.data.filter((_, i) => i < 5).map((item, index) => (
                                                <div 
                                                onMouseOver={() => setCursorIndex(getIndex(item))}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        //Add to history
                                                        dispatch(
                                                            addToMusicHistory(item)
                                                        )
                                                    }, 300)
                                                }}
                                                >
                                                    <SearchAlbum
                                                    key={`searchbar-album-${item.id}`}
                                                    size={48}
                                                    album={item}
                                                    active={getIndex(item) === cursorIndex}
                                                    />
                                                </div>
                                            ))}
                                            
                                            <Link 
                                            to={`/search/${encodeURIComponent(query)}/album`}
                                            onMouseOver={() => setCursorIndex(getIndex("search-albums"))}
                                            className={`${styles.searchMore} ${getIndex("search-albums") === cursorIndex ? styles.active : ""}`}
                                            >
                                                <p>Search all albums...</p>
                                            </Link>
                                        </div>
                                    </div>
                                }

                                {mergedTracks.data.length > 0 &&
                                    <div className={"margin-top-8"}>
                                        <Heading text="Tracks" className="margin-left-8" style={{ paddingBottom: 0 }} />

                                        <div className="margin-top-4">
                                            {mergedTracks.data.filter((_, i) => i < 5).map((item, index) => (
                                                <div 
                                                onMouseOver={() => setCursorIndex(getIndex(item))}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        //Add to history
                                                        dispatch(
                                                            addToMusicHistory(item)
                                                        )
                                                    }, 300)
                                                }}
                                                >
                                                    <SearchTrack
                                                    key={`searchbar-track-${item.id}`}
                                                    size={48}
                                                    track={item}
                                                    active={getIndex(item) === cursorIndex}
                                                    />
                                                </div>
                                            ))}

                                            <Link 
                                            to={`/search/${encodeURIComponent(query)}/track`}
                                            onMouseOver={() => setCursorIndex(getIndex("search-tracks"))}
                                            className={`${styles.searchMore} ${getIndex("search-tracks") === cursorIndex ? styles.active : ""}`}
                                            >
                                                <p>Search all tracks...</p>
                                            </Link>
                                        </div>
                                    </div>
                                }

                                {mergedArtists.data.length > 0 &&
                                    <div className={"margin-top-8"}>
                                        <Heading text="Artists" className="margin-left-8" style={{ paddingBottom: 0 }} />

                                        <div className="margin-top-4">
                                            {mergedArtists.data.filter((_, i) => i < 5).map((item, index) => (
                                                <div 
                                                onMouseOver={() => setCursorIndex(getIndex(item))}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        //Add to history
                                                        dispatch(
                                                            addToMusicHistory(item)
                                                        )
                                                    }, 300)
                                                }}
                                                >
                                                    <SearchArtist
                                                    key={`searchbar-artist-${item.id}`}
                                                    size={48}
                                                    artist={item}
                                                    active={getIndex(item) === cursorIndex}
                                                    />
                                                </div>
                                            ))}

                                            <Link 
                                            to={`/search/${encodeURIComponent(query)}/artist`}
                                            onMouseOver={() => setCursorIndex(getIndex("search-artists"))}
                                            className={`${styles.searchMore} ${getIndex("search-artists") === cursorIndex ? styles.active : ""}`}
                                            >
                                                <p>Search all artists...</p>
                                            </Link>
                                        </div>
                                    </div>
                                }
                            </>
                            }

                            {( 
                                query.trim() !== "" &&
                                (mergedAlbums.data.length <= 0 && mergedArtists.data.length <= 0 && mergedTracks.data.length <= 0) &&
                                (
                                    (mergedAlbums.status === status.BEGIN || mergedArtists.status === status.BEGIN || mergedTracks.status === status.BEGIN) ||
                                    (mergedAlbums.status === status.DEFAULT || mergedArtists.status === status.DEFAULT || mergedTracks.status === status.DEFAULT)
                                )
                            ) &&
                                <div style={{ height: '60vh', width: '100%' }} className="flex-center flex-column">
                                    <Icon icon="loading" />
                                </div>
                            }
                            
                            {(
                                query.trim() !== "" &&
                                (mergedAlbums.data.length <= 0 && mergedArtists.data.length <= 0 && mergedTracks.data.length <= 0) &&
                                (mergedAlbums.status === status.SUCCESS && mergedArtists.status === status.SUCCESS && mergedTracks.status === status.SUCCESS)
                            ) &&
                                <div style={{ height: '60vh', width: '100%' }} className="flex-center flex-column">
                                    <Icon icon="search" color="var(--grey)" size={40} />

                                    <p className="highDarkGrey margin-top-16">No results on search...</p>
                                </div>
                            }

                            {query.trim() === "" && musicHistory && musicHistory.length > 0 && musicHistory.map((item, index) => {
                                const clearBtn = (
                                    <IconButton
                                    icon={"close"}
                                    btnSize={32}
                                    type="transparent-bright"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        dispatch(
                                            removeFromMusicHistory(item.id)
                                        )
                                    }}
                                    />
                                )
                                return (
                                    item.type === "album" ?
                                        <SearchAlbum
                                        key={`searchbar-artist-${item.id}`}
                                        size={48}
                                        album={item}
                                        active={getIndex(item) === cursorIndex}
                                        overwriteButton={clearBtn}
                                        />
                                    : item.type === "artist" ?
                                        <SearchArtist
                                        key={`searchbar-artist-${item.id}`}
                                        size={48}
                                        artist={item}
                                        active={getIndex(item) === cursorIndex}
                                        overwriteButton={clearBtn}
                                        />
                                    : item.type === "track" ?
                                        <SearchTrack
                                        key={`searchbar-artist-${item.id}`}
                                        size={48}
                                        track={item}
                                        active={getIndex(item) === cursorIndex}
                                        overwriteButton={clearBtn}
                                        />
                                    : 
                                        null
                                )
                            })}
                        </div>
                    }
                    
                    {activeTab === 1 && 
                        <div className='padding-around-8'>
                            {searchUsers.data.length > 0 &&
                                <div className={"margin-top-8"}>
                                    <Heading text="Users" className="margin-left-8" style={{ paddingBottom: 0 }} />

                                    <div className="margin-top-4">
                                        {searchUsers.data.map((item, index) => (
                                            <div
                                            onMouseOver={() => setCursorIndex(getIndex(item))}
                                            onClick={() => {
                                                setTimeout(() => {
                                                    //Add to history
                                                    dispatch(
                                                        addToUsersHistory(item)
                                                    )
                                                }, 300)
                                            }}
                                            >
                                                <UserItem
                                                key={`searchbar-user-${item.uid}`}
                                                size={48}
                                                user={item} 
                                                active={getIndex(item) === cursorIndex}
                                                />
                                            </div>
                                        ))}

                                        <Link 
                                        to={`/search/${encodeURIComponent(query)}/user`}
                                        onMouseOver={() => setCursorIndex(getIndex("search-users"))}
                                        className={`${styles.searchMore} ${getIndex("search-users") === cursorIndex ? styles.active : ""}`}
                                        >
                                            <p>Search all users...</p>
                                        </Link>
                                    </div>
                                </div>
                            }

                            {(
                                query.trim() !== "" &&
                                searchUsers.data.length <= 0 &&
                                (
                                    searchUsers.status === status.BEGIN ||
                                    searchUsers.status === status.DEFAULT
                                )
                            ) &&
                                <div style={{ height: '60vh', width: '100%' }} className="flex-center flex-column">
                                    <Icon icon="loading" />
                                </div>
                            }
                            
                            {(
                                query.trim() !== "" &&
                                searchUsers.data.length <= 0 &&
                                searchUsers.status === status.SUCCESS
                            ) &&
                                <div style={{ height: '60vh', width: '100%' }} className="flex-center flex-column">
                                    <Icon icon="search" color="var(--grey)" size={40} />

                                    <p className="highDarkGrey margin-top-16">No results on search...</p>
                                </div>
                            }

                            {query.trim() === "" && usersHistory && usersHistory.length > 0 && usersHistory.map((item, index) => (
                                <UserItem
                                key={`searchbar-user-${item.uid}`}
                                size={48}
                                user={item}
                                active={getIndex(item) === cursorIndex}
                                overwriteButton={
                                    <IconButton
                                    icon={"close"}
                                    btnSize={32}
                                    type="transparent-bright"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        dispatch(
                                            removeFromUsersHistory(item.uid)
                                        )
                                    }}
                                    />
                                }
                                />
                            ))}
                        </div>
                    }
                    
                    {activeTab === 2 && 
                        <div>
                            {searchLists.data.length > 0 &&
                                <div>
                                    <div>
                                        {searchLists.data.map((item, index) => (
                                            <div
                                            onMouseOver={() => setCursorIndex(getIndex(item))}
                                            onClick={() => {
                                                setTimeout(() => {
                                                    //Add to history
                                                    dispatch(
                                                        addToListsHistory(item)
                                                    )
                                                }, 300)
                                            }}
                                            >
                                                <ListItem
                                                key={`searchbar-list-${item.uid}`}
                                                size={48}
                                                list={item} 
                                                active={getIndex(item) === cursorIndex}
                                                isSmall
                                                />
                                            </div>
                                        ))}

                                        <div className='padding-around-8'>
                                            <Link 
                                            to={`/search/${encodeURIComponent(query)}/list`}
                                            onMouseOver={() => setCursorIndex(getIndex("search-lists"))}
                                            className={`${styles.searchMore} ${getIndex("search-lists") === cursorIndex ? styles.active : ""}`}
                                            >
                                                <p>Search all lists...</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            }

                            {(
                                query.trim() !== "" &&
                                searchUsers.data.length <= 0 &&
                                (
                                    searchUsers.status === status.BEGIN ||
                                    searchUsers.status === status.DEFAULT
                                )
                            ) &&
                                <div style={{ height: '60vh', width: '100%' }} className="flex-center flex-column">
                                    <Icon icon="loading" />
                                </div>
                            }
                            
                            {(
                                query.trim() !== "" &&
                                searchUsers.data.length <= 0 &&
                                searchUsers.status === status.SUCCESS
                            ) &&
                                <div style={{ height: '60vh', width: '100%' }} className="flex-center flex-column">
                                    <Icon icon="search" color="var(--grey)" size={40} />

                                    <p className="highDarkGrey margin-top-16">No results on search...</p>
                                </div>
                            }

                            {query.trim() === "" && listsHistory && listsHistory.length > 0 &&
                                <div>
                                    <div className="flex-row padding-around-8 padding-left-16 bottom-divider">
                                        <Heading text="Recent lists" className={"col-1"} style={{ paddingBottom: 0 }} />
                                        
                                        <div className='flex-center' id="clear-lists-history-tip">
                                            <IconButton
                                            icon={"close"}
                                            btnSize={32}
                                            type="transparent-bright"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                dispatch(
                                                    clearListsHistory()
                                                )
                                            }}
                                            />
                                            
                                            <Tooltip className={styles.clearTooltip} text="Clear recent lists" id="clear-lists-history-tip" place="bottom" offset={8} />
                                        </div>

                                    </div>

                                    {listsHistory.map((item, index) => (
                                        <div>
                                            <ListItem
                                            key={`searchbar-user-${item.uid}`}
                                            size={48}
                                            list={item}
                                            active={getIndex(item) === cursorIndex}
                                            isSmall
                                            />
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
