import { get, patch, post, delete_ } from "../utils/fetch";
import { types } from "../constants/action-types";
import { url } from '../config'

export const createSticker = (sticker, reviewUid, ratingUid, threadUid, listUid) => 
    post(types.CREATE_STICKER, `${url}/v2/stickers/`, {
        sticker,
        review: reviewUid,
        rating: ratingUid,
        thread: threadUid,
        list: listUid,
    }, {
        sticker,
        reviewUid,
        ratingUid,
        threadUid,
        listUid,
    })

export const deleteSticker = (stickerUid, reviewUid, ratingUid, threadUid, listUid) => 
    delete_(types.DELETE_STICKER, `${url}/v2/stickers/${stickerUid}/`, {
        stickerUid,
        reviewUid,
        ratingUid,
        threadUid,
        listUid,
    })

export const getStickerCounts = (reviewUid, ratingUid, threadUid, listUid) => 
    get(types.GET_STICKER_COUNTS, `${url}/v2/stickers/sticker_counts/?review=${reviewUid || ""}&rating=${ratingUid || ""}&thread=${threadUid || ""}&list=${listUid || ""}`, {
        reviewUid,
        ratingUid,
        threadUid,
        listUid,
    })

export const getMySticker = (reviewUid, ratingUid, threadUid, listUid) => 
    get(types.GET_MY_STICKER, `${url}/v2/stickers/my_sticker/?review=${reviewUid || ""}&rating=${ratingUid || ""}&thread=${threadUid || ""}&list=${listUid || ""}`, {
        reviewUid,
        ratingUid,
        threadUid,
        listUid,
    })

export const getStickers = (sticker, reviewUid, ratingUid, threadUid, listUid) => 
    get(types.GET_STICKERS, `${url}/v2/stickers/?review=${reviewUid || ""}&rating=${ratingUid || ""}&thread=${threadUid || ""}&list=${listUid || ""}&sticker=${sticker}&limit=24&speedup=true`, {
        sticker,
        reviewUid,
        ratingUid,
        threadUid,
        listUid,
        speedup: true,
        limit: 24,
        reset: true
    })
