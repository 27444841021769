import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { status } from '../../../../constants/action-types'
import { getGroupWithUsers, createGroup } from '../../../../actions/chat-actions'

import { useAlert } from '../../../../hooks/alert/alert'
import { useTheme } from '../../../../hooks/theme'

import Button from '../../../../components/Button'
import { usePopup } from '../../../../hooks/popup/popup'
import { useTrack } from '../../../../hooks/tracking'

export default function SendMessageBtn({ user }) {
    //General
    const dispatch = useDispatch()
    const history = useHistory()
    const { colors } = useTheme()
    const { openAlert } = useAlert()
    const { openPopup } = usePopup()
    const track = useTrack()
    
    //Auth
    const myUid = useSelector(state => state.auth.uid)
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

    //Data
    const lastCreatedGroup = useSelector(state => state.chat.lastCreatedGroup)
    const createGroupStatus = useSelector(state => state.chat.createGroupStatus)
    const lastGetGroupWithUsersGroup = useSelector(state => state.chat.lastGetGroupWithUsersGroup)
    const getGroupWithUsersStatus = useSelector(state => state.chat.getGroupWithUsersStatus)
    const errorMessageChat = useSelector(state => state.chat.errorMessage)

    //Handle create group status
    const firstUpdateCreateGroup = useRef(true)
    useEffect(() => {
        if (firstUpdateCreateGroup.current) {
            firstUpdateCreateGroup.current = false;
            return;
        }

        if (createGroupStatus === status.SUCCESS) {
            history.push(`/groups/${lastCreatedGroup.uid}`)

        } else if (createGroupStatus === status.ERROR && errorMessageChat) {
            openAlert({
                p: "Could not open chat. Error: " + errorMessageChat,
                style: "red",
                timer: 4000
            })
        }
    }, [ createGroupStatus ])
    
    //Handle get group status
    const firstUpdateGetGroup = useRef(true)
    useEffect(() => {
        if (firstUpdateGetGroup.current) {
            firstUpdateGetGroup.current = false;
            return;
        }

        if (getGroupWithUsersStatus === status.SUCCESS && lastGetGroupWithUsersGroup) {
            history.push(`/groups/${lastGetGroupWithUsersGroup.uid}`)

        } else if (getGroupWithUsersStatus === status.ERROR && errorMessageChat) {
            if (errorMessageChat.includes("No group found with those users")) {
                track("Create chat group", { isPersonalChat: true, usersLength: 2, fromRef: "SendMessageBtn" })

                dispatch(
                    createGroup(
                        null, 
                        null, 
                        [
                            myUid,
                            user.uid
                        ], 
                        true
                    )
                )
            } else {
                openAlert({
                    p: "Could not open chat. Error: " + errorMessageChat,
                    style: "red",
                    timer: 4000
                })
            }
        }
    }, [ getGroupWithUsersStatus ])

    return (
        <Button
        squareButton
        showIcon={true}
        iconName={"forum"}
        iconSize={22}
        iconColor={"var(--black)"}
        type={"secondary"}
        loading={(createGroupStatus === status.BEGIN || getGroupWithUsersStatus === status.BEGIN)}
        onClicked={() => {
            if (!isLoggedIn) {
                openPopup("SignUp")
                return;
            }

            dispatch(
                getGroupWithUsers([user.uid], true)
            )
        }}
        />
    )
}
