import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { handleNested } from '../utils/reducer-helpers';

const defaultStickers = {
	reviews: {},
	ratings: {},
	lists: {},
    threads: {},

	lastCreatedStickerContentUid: null,
    createStickerStatus: status.DEFAULT,

	deleteStickerContentUid: null,
    deleteStickerStatus: status.DEFAULT,

	errorMessage: null,
};

const stickers = (state = defaultStickers, action) => {
	let sticker = action.meta && action.meta.params ? action.meta.params.sticker : null
	let stickerUid = action.meta && action.meta.params ? action.meta.params.stickerUid : null
	let reviewUid = action.meta && action.meta.params ? action.meta.params.reviewUid : null
	let ratingUid = action.meta && action.meta.params ? action.meta.params.ratingUid : null
    let threadUid = action.meta && action.meta.params ? action.meta.params.threadUid : null
	let listUid = action.meta && action.meta.params ? action.meta.params.listUid : null
    
	let reset = action.meta && action.meta.params ? action.meta.params.reset : false
	let speedup = action.meta && action.meta.params ? action.meta.params.speedup : false
	let limit = action.meta && action.meta.params ? action.meta.params.limit : false

    const objectName = reviewUid ? 'reviews' : ratingUid ? 'ratings' : threadUid ? 'threads' : 'lists'
    const objectUid = reviewUid || ratingUid || threadUid || listUid

	switch (action.type) {
		case types.CREATE_STICKER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        lastCreatedStickerContentUid: reviewUid || ratingUid || threadUid || listUid,
						createStickerStatus: status.BEGIN,
					}
				case status.SUCCESS:
                    return {
                        ...state,
                        createStickerStatus: status.SUCCESS,
                        [objectName]: {
                            ...state[objectName],
                            [objectUid]: {
                                ...state[objectName][objectUid],
                                hasFetchedBefore: true,
                                mySticker: action.payload.sticker,
                                myStickerUid: action.payload.uid,
                                stickerCounts: state[objectName][objectUid]?.stickerCounts ?
                                    [
                                        ...state[objectName][objectUid]?.stickerCounts.filter((stickerCount) => stickerCount.sticker === action.payload.sticker).length <= 0 ?
                                            [{ sticker: action.payload.sticker, count: 1 }]
                                        : [],
                                        ...state[objectName][objectUid]?.stickerCounts.map((stickerCount) => {
                                            if (stickerCount.sticker === action.payload.sticker) {
                                                return {
                                                    ...stickerCount,
                                                    count: stickerCount.count + 1,
                                                }
                                            }
                                            if (stickerCount.sticker === state[objectName][objectUid]?.mySticker) {
                                                return {
                                                    ...stickerCount,
                                                    count: stickerCount.count - 1,
                                                }
                                            }
                                            return stickerCount
                                        }),
                                    ]
                                : 
                                    [{ sticker: action.payload.sticker, count: 1 }],
                            }
                        }
                    }
				case status.ERROR:
					return {
						...state,
                        errorMessage: errorToString(action.payload),
						createStickerStatus: status.ERROR,
					}
            }
            
		case types.DELETE_STICKER:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        deleteStickerContentUid: reviewUid || ratingUid || threadUid || listUid,
						deleteStickerStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
						deleteStickerStatus: status.SUCCESS,
                        [objectName]: {
                            ...state[objectName],
                            [objectUid]: {
                                ...state[objectName][objectUid],
                                hasFetchedBefore: true,
                                mySticker: null,
                                myStickerUid: null,
                                stickerCounts: state[objectName][objectUid]?.stickerCounts ?
                                    [
                                        ...state[objectName][objectUid]?.stickerCounts.filter((stickerCount) => stickerCount.sticker === action.payload.sticker).length <= 0 ?
                                            [{ sticker: action.payload.sticker, count: 0 }]
                                        : [],
                                        ...state[objectName][objectUid]?.stickerCounts.map((stickerCount) => {
                                            if (stickerCount.sticker === state[objectName][objectUid]?.mySticker) {
                                                return {
                                                    ...stickerCount,
                                                    count: stickerCount.count - 1,
                                                }
                                            }
                                            return stickerCount
                                        }),
                                    ]
                                : 
                                    [{ sticker: action.payload.sticker, count: 0 }],
                            }
                        }
					}
				case status.ERROR:
					switch (action.meta.statusCode) {
						case 404:
							return {
								...state,
								errorMessage: null,
								deleteStickerStatus: status.SUCCESS,
                                [objectName]: {
                                    ...state[objectName],
                                    [objectUid]: {
                                        ...state[objectName][objectUid],
                                        hasFetchedBefore: true,
                                        mySticker: null,
                                        myStickerUid: null,
                                        stickerCounts: state[objectName][objectUid]?.stickerCounts ?
                                            [
                                                ...state[objectName][objectUid]?.stickerCounts.filter((stickerCount) => stickerCount.sticker === action.payload.sticker).length <= 0 ?
                                                    [{ sticker: action.payload.sticker, count: 0 }]
                                                : [],
                                                ...state[objectName][objectUid]?.stickerCounts.map((stickerCount) => {
                                                    if (stickerCount.sticker === state[objectName][objectUid]?.mySticker) {
                                                        return {
                                                            ...stickerCount,
                                                            count: stickerCount.count - 1,
                                                        }
                                                    }
                                                    return stickerCount
                                                }),
                                            ]
                                        : 
                                            [{ sticker: action.payload.sticker, count: 0 }],
                                    }
                                }
							}
						default:
							return {
								...state,
								errorMessage: errorToString(action.payload),
								deleteStickerStatus: status.ERROR,
							}
					}
			}

		case types.GET_STICKER_COUNTS:
            switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						[objectName]: {
							...state[objectName],
							[objectUid]: {
                                ...state[objectName][objectUid],
                                fetchStickerCountsStatus: status.BEGIN,
							}
						}
					}
				case status.SUCCESS:
					return {
                        ...state,
						[objectName]: {
							...state[objectName],
							[objectUid]: {
								...state[objectName][objectUid],
                                stickerCounts: action.payload,
                                fetchStickerCountsStatus: status.SUCCESS,
							}
						}
					}
				case status.ERROR:
					return {
						...state,
                        errorMessage: errorToString(action.payload),
						[objectName]: {
							...state[objectName],
							[objectUid]: {
                                ...state[objectName][objectUid],
                                stickerCounts: null,
                                fetchStickerCountsStatus: status.ERROR,
							}
						}
					}
			}
        
		case types.GET_MY_STICKER:
            switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						[objectName]: {
							...state[objectName],
							[objectUid]: {
                                ...state[objectName][objectUid],
                                fetchMyStickerStatus: status.BEGIN,
							}
						}
					}
				case status.SUCCESS:
					return {
                        ...state,
						[objectName]: {
							...state[objectName],
							[objectUid]: {
								...state[objectName][objectUid],
                                hasFetchedBefore: true,
                                mySticker: action.payload?.sticker,
                                myStickerUid: action.payload?.uid,
                                fetchMyStickerStatus: status.SUCCESS,
							}
						}
					}
				case status.ERROR:
					return {
						...state,
                        errorMessage: errorToString(action.payload),
						[objectName]: {
							...state[objectName],
							[objectUid]: {
                                ...state[objectName][objectUid],
                                mySticker: null,
                                myStickerUid: null,
                                fetchMyStickerStatus: status.ERROR,
							}
						}
					}
			}
            
		case types.GET_STICKERS:
			let reset = action.meta && action.meta.params ? action.meta.params.reset : false
            let speedup = action.meta && action.meta.params ? action.meta.params.speedup : false
            let limit = action.meta && action.meta.params ? action.meta.params.limit : false

            switch (action.meta.status) {
                case status.BEGIN:
                    return {
                        ...state,
                        [objectName]: {
                            ...state[objectName],
                            [objectUid]: {
                                ...state[objectName][objectUid],
                                [sticker]: {
                                    ...state[objectName][objectUid] && state[objectName][objectUid][sticker],
                                    stickers: {
                                        data: (
                                            state[objectName][objectUid] && state[objectName][objectUid][sticker] && state[objectName][objectUid][sticker].stickers ? 
                                                state[objectName][objectUid][sticker].stickers.data
                                            : 
                                                []
                                        ),
                                        count: (
                                            state[objectName][objectUid] && state[objectName][objectUid][sticker] && state[objectName][objectUid][sticker].stickers ? 
                                                state[objectName][objectUid][sticker].stickers.count
                                            : 
                                                null
                                        ),
                                        next: null,
                                        reset: action.meta && action.meta.params ? action.meta.params.reset : false,
                                        status: status.BEGIN,
                                    }
                                }
                            }
                        }
                    }
                case status.SUCCESS:
                    return {
                        ...state,
                        [objectName]: {
                            ...state[objectName],
                            [objectUid]: {
                                ...state[objectName][objectUid],
                                [sticker]: {
                                    ...state[objectName][objectUid] && state[objectName][objectUid][sticker],
                                    stickers: {
                                        data: (
                                            reset ? 
                                                action.payload.results 
                                            : 
                                                [ ...state[objectName][objectUid][sticker].stickers.data, ...action.payload.results ]
                                        ),
                                        count: action.payload.count,
                                        next: speedup ? (action.payload.results.length < limit) ? null : action.payload.next : action.payload.next,
                                        status: status.SUCCESS,
                                    }
                                }
                            }
                        }
                    }
                case status.ERROR:
                    return {
                        ...state,
                        [objectName]: {
                            ...state[objectName],
                            [objectUid]: {
                                ...state[objectName][objectUid],
                                [sticker]: {
                                    ...state[objectName][objectUid] && state[objectName][objectUid][sticker],
                                    stickers: {
                                        ...state[objectName][objectUid][sticker].stickers,
                                        data: [],
                                        next: null,
                                        count: null,
                                        status: status.ERROR,
                                    }
                                }
                            }
                        },
                        errorMessage: errorToString(action.payload),
                    }
            }
		
		default:
			return state;
	}
};

export default stickers;