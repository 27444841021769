import styles from './searchcontentinput.module.css'

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TextInput from '../TextInput'
import Heading from '../Heading'

import SearchAlbum from '../../routes/Search/components/SearchAlbum'
import SearchTrack from '../../routes/Search/components/SearchTrack'
import SearchArtist from '../../routes/Search/components/SearchArtist'

import Dropdown from '../Dropdown'

import { clearSearchAlbums, clearSearchArtists, clearSearchTracks, searchMergedContent } from '../../actions/search-actions'

export default function SearchContentInput({
    onContentPressed=()=>{},
    placeholder="Search music...",
    autoFocus,
    showResultsOnFocus,
    initMaximizeAlbums,
    initMaximizeTracks,
    initMaximizeArtists,
    hideAlbums,
    hideTracks,
    hideArtists,
    inputClassName,
    inputStyle,
    className,
    style,
    offset=50,
    lighterStyle
}) {
    //General
    const dispatch = useDispatch()
    
    //Data
    const mergedAlbums = useSelector(state => state.search.mergedAlbums)
    const mergedTracks = useSelector(state => state.search.mergedTracks)
    const mergedArtists = useSelector(state => state.search.mergedArtists)

    //State
    const [cursorIndex, setCursorIndex] = useState(0)
    const [value, setValue] = useState("")
    const [maximizeAlbums, setMaximizeAlbums] = useState(initMaximizeAlbums)
    const [maximizeArtists, setMaximizeArtists] = useState(initMaximizeArtists)
    const [maximizeTracks, setMaximizeTracks] = useState(initMaximizeTracks)

    //Refs
    const timer = useRef()

    useEffect(() => {
        if(value.trim() !== "") {
            if (timer.current) {
                clearTimeout(timer.current)
            }

            if (mergedAlbums.controller) {
                mergedAlbums.controller.abort()
            }
            if (mergedTracks.controller) {
                mergedTracks.controller.abort()
            }
            if (mergedArtists.controller) {
                mergedArtists.controller.abort()
            }

            timer.current = setTimeout(() => {
                dispatch( searchMergedContent(value, "album", 18) )
                dispatch( searchMergedContent(value, "track", 18) )
                dispatch( searchMergedContent(value, "artist", 18) )
                
            }, 200)

        } else {
            dispatch( clearSearchAlbums() )
            dispatch( clearSearchTracks() ) 
            dispatch( clearSearchArtists() )
        }
    }, [ value ])

    useEffect(() => {
        return(() => {
            if (timer.current) {
                clearTimeout(timer.current)
            }

            dispatch( clearSearchAlbums() )
            dispatch( clearSearchTracks() ) 
            dispatch( clearSearchArtists() )
        })
    }, [])

    //Focused handling
    const containerRef = useRef()
    const [focused, setFocused] = useState(false)
    const focusedRef = useRef(false)
    useEffect(() => {
        focusedRef.current = focused;
    }, [ focused ])

    function handleClickOutside(event) {
        if (containerRef.current) {
            if (!containerRef.current.contains(event.target) && focusedRef.current) {
                setFocused(false)
            }
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return(() => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        })
        
    }, [])

    //Helper functions
    function onClick(content) {
        if (showResultsOnFocus) {
            setFocused(false)
        }

        onContentPressed(content)
    }

    const indexArray = [
        ...!hideAlbums ? mergedAlbums.data.filter((_, i) => maximizeAlbums ? i < 15 : i < 5) : [],
        ...(!hideAlbums && !maximizeAlbums) ? ["maximize-albums"] : [],
        ...!hideTracks ? mergedTracks.data.filter((_, i) => maximizeTracks ? i < 15 : i < 5) : [],
        ...(!hideTracks && !maximizeTracks) ? ["maximize-tracks"] : [],
        ...!hideArtists ? mergedArtists.data.filter((_, i) => maximizeArtists ? i < 15 : i < 5) : [],
        ...(!hideArtists && !maximizeArtists) ? ["maximize-artists"] : [],
    ]

    function getIndex(content) {

        if (content === "maximize-albums") {
            return indexArray.indexOf("maximize-albums")
        } else if (content === "maximize-tracks") {
            return indexArray.indexOf("maximize-tracks")
        } else if (content === "maximize-artists") {
            return indexArray.indexOf("maximize-artists")
        } else {
            return indexArray.findIndex((compare) => `${compare.type}-${compare.id}` === `${content.type}-${content.id}`)
        }
    }

    return (
        <div ref={containerRef} className={`col-1 flex-column ${className}`} style={{ position: 'relative', zIndex: 100, ...style }}>
            <TextInput
            autoFocus={autoFocus}
            lighterStyle={lighterStyle}
            placeholder={placeholder}
            text={value}
            onTextChange={(t) => {
                setValue(t)
                setCursorIndex(0)
            }}
            onFocus={() => setFocused(true)}
            inputClassName={inputClassName}
            inputStyle={inputStyle}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    setFocused(false)

                    const option = indexArray[cursorIndex]
                    if (option === "maximize-albums") {
                        setMaximizeAlbums(true)
                    } else if (option === "maximize-tracks") {
                        setMaximizeTracks(true)
                    } else if (option === "maximize-artists") {
                        setMaximizeArtists(true)
                    } else {
                        onClick(option)
                        
                    }

                } else if(e.key === "Escape") {
                    setFocused(false)

                } else if (e.key === "ArrowDown") {
                    e.preventDefault()
                    if (cursorIndex <= indexArray.length-2) {
                        setCursorIndex(cursorIndex+1)
                    } else {
                        setCursorIndex(0)
                    }

                } else if (e.key === "ArrowUp") {
                    e.preventDefault()
                    if (cursorIndex > 0) {
                        setCursorIndex(cursorIndex-1)
                    } else {
                        setCursorIndex(indexArray.length-1)
                    }
                }
            }}
            />

            <Dropdown
            simpleAnimation
            visible={value !== "" && (mergedAlbums.data.length > 0 || mergedTracks.data.length > 0 || mergedArtists.data.length > 0) && ((showResultsOnFocus && focused) || !showResultsOnFocus)}
            className={`${styles.results}`}
            offset={offset}
            position="center"
            appendId={`search-content`}
            >
                <div className="margin-top-8">
                    {!hideAlbums && mergedAlbums.data.length > 0 &&
                        <div>
                            <Heading text="Albums" className="margin-left-8" style={{ paddingBottom: 0 }} />

                            <div className="margin-top-4">
                                {mergedAlbums.data.filter((_, i) => maximizeAlbums ? i < 15 : i < 5).map((item, index) => (
                                    <div onMouseOver={() => setCursorIndex(getIndex(item))}>
                                        <SearchAlbum
                                        key={`searchcontentinput-album-${item.id}`}
                                        overrideClick={() => onClick(item)}
                                        size={56}
                                        album={item}
                                        active={getIndex(item) === cursorIndex}
                                        />
                                    </div>
                                ))}

                                {!maximizeAlbums &&
                                    <p 
                                    onMouseOver={() => setCursorIndex(getIndex("maximize-albums"))}
                                    onClick={() => setMaximizeAlbums(true)} 
                                    className={`${styles.loadMore} ${getIndex("maximize-albums") === cursorIndex ? styles.active : ""}`}
                                    >
                                        Show more albums...
                                    </p>
                                }
                            </div>
                        </div>
                    }

                    {!hideTracks && mergedTracks.data.length > 0 &&
                        <div className={hideAlbums ? "" : "margin-top-16"}>
                            <Heading text="Tracks" className="margin-left-8" style={{ paddingBottom: 0 }} />

                            <div className="margin-top-4">
                                {mergedTracks.data.filter((_, i) => maximizeTracks ? i < 15 : i < 5).map((item, index) => (
                                    <div onMouseOver={() => setCursorIndex(getIndex(item))}>
                                        <SearchTrack
                                        key={`searchcontentinput-track-${item.id}`}
                                        overrideClick={() => onClick(item)}
                                        size={56} 
                                        track={item}
                                        active={getIndex(item) === cursorIndex}
                                        />
                                    </div>
                                ))}

                                {!maximizeTracks &&
                                    <p
                                    onMouseOver={() => setCursorIndex(getIndex("maximize-tracks"))}
                                    onClick={() => setMaximizeTracks(true)}
                                    className={`${styles.loadMore} ${getIndex("maximize-tracks") === cursorIndex ? styles.active : ""}`}
                                    >
                                        Show more tracks...
                                    </p>
                                }
                            </div>
                        </div>
                    }

                    {!hideArtists && mergedArtists.data.length > 0 &&
                        <div className={hideAlbums && hideTracks ? "" : "margin-top-16"}>
                            <Heading text="Artists" className="margin-left-8" style={{ paddingBottom: 0 }} />

                            <div className="margin-top-4">
                                {mergedArtists.data.filter((_, i) => maximizeArtists ? i < 15 : i < 5).map((item, index) => (
                                    <div onMouseOver={() => setCursorIndex(getIndex(item))}>
                                        <SearchArtist
                                        key={`searchcontentinput-artist-${item.id}`}
                                        overrideClick={() => onClick(item)}
                                        size={56} 
                                        artist={item}
                                        active={getIndex(item) === cursorIndex}
                                        />
                                    </div>
                                ))}

                                {!maximizeArtists &&
                                    <p 
                                    onMouseOver={() => setCursorIndex(getIndex("maximize-artists"))}
                                    onClick={() => setMaximizeArtists(true)} 
                                    className={`${styles.loadMore} ${getIndex("maximize-artists") === cursorIndex ? styles.active : ""}`}
                                    >
                                        Show more artists...
                                    </p>
                                }
                            </div>
                        </div>
                    }
                </div>
            </Dropdown>
        </div>
    )
}
