import styles from './albumcover.module.css'

import React from 'react'
import Icon from '../Icon'
import { useTheme } from '../../hooks/theme'

export default function AlbumCover({
    albumCover=null,
    altText,
    size=60,
    className="",
    noShadow=false,
    clickable,
    style,
    paddingTop,
    skipFrameTitle,
    frameText,
    skipBorder
}) {
    const { mode } = useTheme()

    return (
        <div
        className={`${styles.container} ${typeof size === "number" ? "" : styles.sized} ${className} ${clickable ? styles.clickable : ""}`}
        style={typeof size === "number" ? {
            height: size,
            width: size,
            minWidth: size,
            paddingTop: paddingTop ? paddingTop : undefined,
            boxShadow: (noShadow || (mode === "light" && !albumCover)) ? 'none' : undefined,
            ...style
        } : { 
            ...size !== "100%" ? {
                height: size,
                width: size,
                minWidth: size,
            } : {},
            paddingTop: paddingTop ? paddingTop : undefined, 
            boxShadow: (noShadow || (mode === "light" && !albumCover)) ? 'none' : undefined,
            ...style 
        }}
        >
            <div className={styles.iconContainer}>
                <Icon 
                className={styles.icon} 
                icon="album"
                size={typeof size === "number" ? size/2 : 48}
                color={"var(--darkLightGrey)"}
                />
            </div>

            {(typeof albumCover === "string" || (typeof albumCover !== "string" && albumCover && albumCover.length === 1)) &&
                <img
                className={styles.cover}
                src={albumCover}
                alt={altText}
                />
            }

            {typeof albumCover !== "string" && albumCover && albumCover.length > 1 &&
                <div
                className={styles.cover}
                style={{
                    overflow: 'hidden'
                }}
                >
                    {(albumCover.length === 3 || albumCover.length === 2) &&
                        <div className={styles.twoCover}>
                            <img
                            className={styles.splitCover}
                            src={albumCover[0]}
                            alt={altText[0]}
                            />
                            
                            <img
                            className={styles.splitCover}
                            src={albumCover[1]}
                            alt={altText[1]}
                            />
                        </div>
                    }

                    {albumCover && albumCover.length === 4 &&
                        <div className={styles.twoCover}>
                            <div className={styles.fourCover}>
                                <img
                                className={styles.splitCover}
                                src={albumCover[0]}
                                alt={altText[0]}
                                />

                                <img
                                className={styles.splitCover}
                                src={albumCover[1]}
                                alt={altText[1]}
                                />
                            </div>

                            <div className={styles.fourCover}>
                                <img
                                className={styles.splitCover}
                                src={albumCover[2]}
                                alt={altText[2]}
                                />

                                <img
                                className={styles.splitCover}
                                src={albumCover[3]}
                                alt={altText[3]}
                                />
                            </div>
                        </div>
                    }
                </div>
            }

            {!skipBorder &&
                <div className={`${styles.border} ${!Boolean(albumCover) ? styles.empty : ""}`}>
                    {clickable && !skipFrameTitle &&
                        <span className={styles.borderTitle}>{frameText ? frameText : altText}</span>
                    }
                </div>
            }
        </div>
    )
}
