import { types, status } from '../constants/action-types'
import { post, put, get, patch, patchRaw, delete_, postRaw } from '../utils/fetch'
import { patchNormal, postNormal, getNormal } from '../utils/fetch-normal'
import { url } from '../config'


export const fetchMe = () => get(types.FETCH_ME, `${url}/v2/users/me/`);
export const validatePayment = () => post(types.FETCH_ME, `${url}/v2/users/validate_payment/`);

export const updateMe = (body) => (dispatch, getState) => 
    dispatch(patch(types.FETCH_ME, `${url}/v2/users/${getState().auth.uid}/`, body, { email: body.email }))

export const updateMeRaw = (formData, email=null) => (dispatch, getState) => 
    dispatch(patchRaw(types.FETCH_ME, `${url}/v2/users/${getState().auth.uid}/`, formData, { email: email }))

export const deleteAccount = () => (dispatch, getState) => 
    dispatch(delete_(types.DELETE_ACCOUNT, `${url}/v2/users/${getState().auth.uid}/`, {}))

// Follow
export const fetchMyFollows = () => get(types.FETCH_MY_FOLLOWS, `${url}/v2/follows/`)


// Want
export const fetchMyWants = () => get(types.FETCH_MY_WANTS, `${url}/v2/my_wants/`)

export const createWant = (content_id, content_type, fromDeezer) => (dispatch, getState) => {
    dispatch({ 
        type: types.CREATE_WANT, 
        meta: { 
            params: { id: content_id, contentType: content_type, fromDeezer: fromDeezer }, 
            status: status.BEGIN 
        } 
    })
    
    postNormal(`${url}/v2/${fromDeezer ? "dz_" : ""}${content_type}s/`, { id: content_id }).then((res) => {
        postNormal(`${url}/v2/wants/`, { content_id: res.id, content_type }).then((done) => {
            dispatch({ 
                type: types.CREATE_WANT, 
                payload: done, 
                meta: { 
                    params: { id: content_id, contentType: content_type },
                    status: status.SUCCESS 
                } 
            })

        }).catch((err) => {
            dispatch({ 
                type: types.CREATE_WANT, 
                payload: err, 
                meta: { 
                    params: { id: content_id, contentType: content_type, fromDeezer: fromDeezer }, 
                    status: status.ERROR 
                } 
            })

        })
    }).catch((err) => {
        dispatch({ 
            type: types.CREATE_WANT, 
            payload: err, 
            meta: {
                params: { id: content_id, contentType: content_type, fromDeezer: fromDeezer }, 
                status: status.ERROR 
            } 
        })

    })

}

export const deleteWant = (uid) => 
    delete_(
        types.DELETE_WANT, 
        `${url}/v2/wants/${uid}/`,
        { uid, reset: true },
    );


// Block
export const fetchMyBlocks = () => get(types.FETCH_MY_BLOCKS, `${url}/v2/blocks/`)
export const fetchBlockedBy = () => get(types.FETCH_BLOCKED_BY, `${url}/v2/blocks/blocked_by/`)

// Likes
export const fetchMyLikes = () => get(types.FETCH_MY_LIKES, `${url}/v2/my_likes/`)


// Push Notifications
export const createApnsDevice = (token) => post(types.CREATE_APNS_DEVICE, `${url}/v2/push_notifications/device/apns/`, {
    registration_id: token
})

export const createFcmDevice = (token) => post(types.CREATE_FCM_DEVICE, `${url}/v2/push_notifications/device/gcm/`, {
    registration_id: token,
    cloud_message_type: "FCM",
})

//Banners
export const getBanners = ({ search="" }) => get(types.GET_BANNERS, `${url}/v2/backgrounds/?search=${search}`, { reset: true })

export const deleteBanner = (uid) => delete_(types.DELETE_BANNER, `${url}/v2/backgrounds/${uid}/`, { uid })

export const createBanner = (formData) => postRaw(types.CREATE_BANNER, `${url}/v2/backgrounds/`, formData)

export const updateBanner = (uid, data) => patch(types.UPDATE_BANNER, `${url}/v2/backgrounds/${uid}/`, data, { bannerUid: uid })

//Update pinned
export const updatePinnedContent = (reviews, lists) => (dispatch, getState) => {
    // Send BEGIN to reducers
    dispatch({ type: types.UPDATE_PINNED_CONTENT, meta: { status: status.BEGIN } });

    // Update all reviews and lists
    Promise.all(
        [
            ...reviews.map((review) => {
                return patchNormal(`${url}/v2/reviews/${review.uid}/`, {
                    pinned: !review.pinned
                })
            }),
            ...lists.map((list) => {
                return patchNormal(`${url}/v2/lists/${list.uid}/`, {
                    pinned: !list.pinned
                })
            })
        ]
    )
    .then((res) => {
        // Send SUCCESS to reducers
        dispatch({ type: types.UPDATE_PINNED_CONTENT, meta: { status: status.SUCCESS }, payload: res });
        
    })
    .catch((err) => {
        // Send ERROR to reducers
        dispatch({ type: types.UPDATE_PINNED_CONTENT, meta: { status: status.ERROR }, payload: err });

    });
}

export const setMyPrimaryLanguage = () => (dispatch, getState) => {
    
    dispatch({ type: types.FETCH_ME, meta: { status: status.BEGIN } })
    
    const supportedLanguages = getState().auth.healthCheck.supported_languages
    
    getNormal("https://ipapi.co/json/")
    .then((ipData) => {
        
        let language;
        const languageArr = ipData.languages.split(",")
        for (let i = 0; i < languageArr.length; i++) {
            let languageStr = languageArr[i]

            const _lang = supportedLanguages.find((language) => 
                language.code === languageStr.toLowerCase() ||
                language.code === languageStr.split("-")[0]
            )

            if (_lang) {
                language = _lang;
                break;
            }
        }
        
        patchNormal(`${url}/v2/users/${getState().auth.uid}/`, {
            primary_language: language ? language.code : null,
        }).then((res) => {
            dispatch({ type: types.FETCH_ME, payload: res, meta: { status: status.SUCCESS } })
        })
        .catch(err => {
            dispatch({ type: types.FETCH_ME, payload: err, meta: { status: status.ERROR } })
        })
    })
    .catch((err) => {
        console.warn(err)
    })
}
