import axios from 'axios'
import { url } from '../config';

const getReq = (url) => {
    return axios.get(url, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        }
    })
    .then(function (response) {
        return response.data
    })
    .catch(function (error) {
        console.warn('Error with axios request', error);
    })
}

export const useSpotify = () => {
    const spotify = {
        searchTrack: (track, album, artist) => getReq(`${url}/v2/spotify/?q=track:"${track.replace(" ", "+")}"+album:"${album.replace(" ", "+")}"+artist:"${artist.replace(" ", "+")}"&type=track&limit=1`),
        searchAlbum: (album, artist) => getReq(`${url}/v2/spotify/?q=album:"${album.replace(" ", "+")}"+artist:"${artist.replace(" ", "+")}"&type=album&limit=1`),
        searchArtist: (artist) => getReq(`${url}/v2/spotify/?q=artist:"${artist.replace(" ", "+")}"&type=artist&limit=1`),
    }

    return spotify
}