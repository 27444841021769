import React, { Suspense, lazy } from 'react'

import { BrowserRouter as Router, Route, Switch, Redirect, useRouteMatch } from "react-router-dom";

import NotFound from '../../../../routes/NotFound';

import User from '../../../../routes/User';

const Stats = lazy(() => import('../../../../routes/Stats'));
const UserReviews = lazy(() => import('../../../../routes/UserReviews'));
const UserLists = lazy(() => import('../../../../routes/UserLists'));
const UserRatings = lazy(() => import('../../../../routes/UserRatings'));
const UserNetwork = lazy(() => import('../../../../routes/UserNetwork'));
const UserWantlist = lazy(() => import('../../../../routes/UserWantlist'));
const UserHistory = lazy(() => import('../../../../routes/UserHistory'));
const UserPinned = lazy(() => import('../../../../routes/UserPinned'));
const UserContributions = lazy(() => import('../../../../routes/UserContributions'));

export default function UserNavigator(props) {
    const match = useRouteMatch()
    return (
        <Switch>
            <Route exact path={`/:username`} component={User} />

            <Route exact path={`/:username/stats`} component={Stats} />

            <Route exact path={`/:username/reviews`} component={UserReviews} />
            <Route exact path={`/:username/lists`} component={UserLists} />
            <Route exact path={`/:username/wantlist`} component={UserWantlist} />
            <Route exact path={`/:username/history`} component={UserHistory} />
            <Route exact path={`/:username/pinned`} component={UserPinned} />

            <Route exact path={`/:username/albums`} render={() => <UserRatings type={"album"} />} />
            <Route exact path={`/:username/artists`} render={() => <UserRatings type={"artist"} />} />
            <Route exact path={`/:username/tracks`} render={() => <UserRatings type={"track"} />} />

            <Route exact path={`/:username/contributions`} component={UserContributions} />

            <Route exact path={`/:username/following`} render={() => <UserNetwork type={"following"} />} />
            <Route exact path={`/:username/followers`} render={() => <UserNetwork type={"followers"} />} />

            <Route path="*" component={NotFound} />
        </Switch>
    )
}
