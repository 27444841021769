import styles from './reportdropdown.module.css'

import React, { useEffect, useRef, useState } from 'react'


import Icon from '../Icon'
import Dropdown from '../Dropdown'
import IconButton from '../IconButton'
import { usePopup } from '../../hooks/popup/popup'
import { firstLetterCapital } from '../../utils/captialization'
import { useSelector } from 'react-redux'
import { useTheme } from '../../hooks/theme'

export default function ReportDropdown({ content, type, className, style }) {
    //General
    const { openPopup } = usePopup()
    const { mode } = useTheme()

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

    //State
    const [visible, setVisible] = useState(false)

    return (
        <div className={`${className}`}  style={{ position: 'relative', ...style }}>
            <IconButton icon="more-horiz" onClick={() => setVisible(true)} type={mode === "light" ? "transparent-bright" : "transparent"} />

            <Dropdown
            simpleAnimation
            visible={visible}
            offset={42} 
            vertical="top" 
            position="right" 
            appendId={"report"} 
            hideDropdown={() => setVisible(false)}
            zIndex={1090}
            >
                <div 
                className={`flex-row ${styles.item} cursor`} 
                onClick={() => {
                    if (!isLoggedIn) {
                        openPopup("Login")
                        return;
                    }

                    setVisible(false)
                    openPopup("ReportContent", { content, type: type })
                }}
                >
                    <Icon className="margin-right-12" size={20} icon="flag" />
                    <h6 className="black">Report {firstLetterCapital(content.type)}</h6>
                </div>
            </Dropdown>
            
        </div>
    )
}
