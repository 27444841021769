export default function interpolation(fromLow, fromHigh, toLow, toHigh, progress, clamp=false) {
    const val = (toLow+(toHigh-toLow)*(progress-fromLow)/(fromHigh-fromLow))

    if (val && val !== Infinity)  {
        if(clamp) {
            if (val >= toHigh) {
                return toHigh
                
            } else if (val <= toLow) {
                return toLow
    
            }
        }

        return val

    } else {
        return toLow

    }
    
}