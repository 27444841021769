import { status, types } from '../constants/action-types'
import { post, put, get, patch, patchRaw } from '../utils/fetch'
import { url, deezerHostDomain, deezerHostUrl } from '../config'
import moment from 'moment';
import { getNormal } from '../utils/fetch-normal';

// Artist
export const fetchArtist = (id, fromDeezer) => 
    get(types.FETCH_ARTIST, `${url}/v2/${fromDeezer ? "dz_artists" : "artists"}/${id}/`, { id, fromDeezer });

export const fetchArtistFromSlug = (nameSlug) => 
    get(types.FETCH_ARTIST, `${url}/v2/artists/from_url_slug/?url_slug=/artist/${nameSlug}/`, { urlSlug: `/artist/${nameSlug}/` });

export const getArtistRatingDistribution = (id) =>
    get(types.GET_ARTIST_RATING_DISTRIBUTION, `${url}/v2/artists/${id}/rating_distribution/`, { id });

export const getArtistLatestContributions = (id) => 
    get(types.GET_ARTIST_LATEST_CONTRIBUTIONS, `${url}/v2/artists/${id}/latest_contributions/`, { id });

export const getArtistReviews = ({
    id=null,
    order_by="-created_at",
    pinned="",
    title="",
    has_reports="",
    most_liked=false,
    scored=false,
    limit=16,
    offset=0,
    fromDeezer=false,
    type=types.GET_ARTISTS_REVIEWS,
}) => 
    get(type, `${url}/v2/${scored ? "most_liked_reviews/scored" : most_liked ? "most_liked_reviews" : "reviews"}/?content_type=artist&${fromDeezer ? "deezer_id" : "content_id"}=${id}&order_by=${order_by}&pinned=${pinned}&title=${title}&has_reports=${has_reports}&limit=${limit}&offset=${offset}&speedup=true`, { id, reset: true, speedup: true, limit: limit });

export const getArtistLists = ({
    id=null,
    order_by="-created_at",
    limit=12,
    offset=0,
    type=types.GET_ARTISTS_LISTS,
}) => 
    get(type, `${url}/v2/lists/content_lists/?content_ct=artist&content_id=${id}&order_by=${order_by}&limit=${limit}&offset=${offset}&speedup=true&limit_content=10`, { id, reset: true, speedup: true, limit: limit });

export const getArtistRatings = ({
    id=null,
    order_by="-created_at",
    following=false,
    limit=24,
    offset=0,
    fromDeezer=false,
}) => 
    get(types.GET_ARTISTS_RATINGS, `${url}/v2/ratings/${following ? "following/" : ""}?content_type=artist&${fromDeezer ? "deezer_id" : "content_id"}=${id}&order_by=${order_by}&limit=${limit}&offset=${offset}&speedup=true`, { id, reset: true, speedup: true, limit: limit });

export const getArtistTopTracks = (artistId) => 
    get(types.GET_ARTIST_TOP_TRACKS, `${url}/v2/tracks/?artist__id=${artistId}&order_by=-ratings_count&speedup=true&limit=5`, { id: artistId, reset: true });
    
// Album
export const fetchAlbum = (id, fromDeezer) => 
    get(types.FETCH_ALBUM, `${url}/v2/${fromDeezer ? "dz_albums" : "albums"}/${id}/`, { id, fromDeezer });

export const fetchAlbumFromSlug = (nameSlug, artistSlug) => 
    get(types.FETCH_ALBUM, `${url}/v2/albums/from_url_slug/?url_slug=/album/${nameSlug}/${artistSlug}/`, { urlSlug: `/album/${nameSlug}/${artistSlug}/` });

export const getAlbumRatingDistribution = (id) =>
    get(types.GET_ALBUM_RATING_DISTRIBUTION, `${url}/v2/albums/${id}/rating_distribution/`, { id });

export const getAlbumLatestContributions = (id) => 
    get(types.GET_ALBUM_LATEST_CONTRIBUTIONS, `${url}/v2/albums/${id}/latest_contributions/`, { id });

export const getAlbumBonusTracks = (id) => 
    get(types.GET_ALBUM_BONUS_TRACKS, `${url}/v2/albums/${id}/bonus_tracks/`, { id });

export const getAlbumReviews = ({
    id=null,
    order_by="-created_at",
    pinned="",
    title="",
    has_reports="",
    most_liked=false,
    scored=false,
    limit=16,
    offset=0,
    fromDeezer=false,
    type=types.GET_ALBUMS_REVIEWS,
}) => 
    get(type, `${url}/v2/${scored ? "most_liked_reviews/scored" : most_liked ? "most_liked_reviews" : "reviews"}/?content_type=album&${fromDeezer ? "deezer_id" : "content_id"}=${id}&order_by=${order_by}&pinned=${pinned}&title=${title}&has_reports=${has_reports}&limit=${limit}&offset=${offset}&speedup=true`, { id, reset: true, speedup: true, limit: limit });

export const getAlbumLists = ({
    id=null,
    order_by="-like_count",
    limit=12,
    offset=0,
    type=types.GET_ALBUMS_LISTS,
}) => 
    get(type, `${url}/v2/lists/content_lists/?content_ct=album&content_id=${id}&order_by=${order_by}&limit=${limit}&offset=${offset}&speedup=true&limit_content=10`, { id, reset: true, speedup: true, limit: limit });

export const getAlbumRatings = ({
    id=null,
    order_by="-created_at",
    following=false,
    limit=24,
    offset=0,
    fromDeezer=false,
}) => 
    get(types.GET_ALBUMS_RATINGS, `${url}/v2/ratings/${following ? "following/" : ""}?content_type=album&${fromDeezer ? "deezer_id" : "content_id"}=${id}&order_by=${order_by}&limit=${limit}&offset=${offset}&speedup=true`, { id, reset: true, speedup: true, limit: limit });

// Track
export const fetchTrack = (id, fromDeezer) => 
    get(types.FETCH_TRACK, `${url}/v2/${fromDeezer ? "dz_tracks" : "tracks"}/${id}/`, { id, fromDeezer });

export const fetchTrackFromSlug = (titleSlug, albumSlug, artistSlug) => 
    get(types.FETCH_TRACK, `${url}/v2/tracks/from_url_slug/?url_slug=/track/${titleSlug}/${albumSlug}/${artistSlug}/`, { urlSlug: `/track/${titleSlug}/${albumSlug}/${artistSlug}/` });

export const getTrackRatingDistribution = (id) =>
    get(types.GET_TRACK_RATING_DISTRIBUTION, `${url}/v2/tracks/${id}/rating_distribution/`, { id });

export const fetchTracksForAlbum = (albumId, isDeezerIds) => 
    get(types.FETCH_MULTIPLE_TRACKS, `${url}/v2/tracks/?album__id=${albumId}&limit=200&speedup=true`, { albumId: albumId, isDeezerIds, speedup: true, limit: 200 });

export const fetchTracksForArtist = (ids="", isDeezerIds) => 
    get(types.FETCH_MULTIPLE_TRACKS, `${url}/v2/tracks/?${isDeezerIds ? "deezer_ids" : "ids"}=${ids}&limit=200&speedup=true`, { ids: ids, isDeezerIds, speedup: true, limit: 200 });

export const getTrackReviews = ({
    id=null,
    order_by="-created_at",
    pinned="",
    title="",
    has_reports="",
    most_liked=false,
    scored=false,
    limit=16,
    offset=0,
    fromDeezer=false,
    type=types.GET_TRACKS_REVIEWS,
}) => 
    get(type, `${url}/v2/${scored ? "most_liked_reviews/scored" : most_liked ? "most_liked_reviews" : "reviews"}/?content_type=track&${fromDeezer ? "deezer_id" : "content_id"}=${id}&order_by=${order_by}&pinned=${pinned}&title=${title}&has_reports=${has_reports}&limit=${limit}&offset=${offset}&speedup=true`, { id, reset: true, speedup: true, limit: limit });

export const getTrackRatings = ({
    id=null,
    order_by="-created_at",
    following=false,
    limit=24,
    offset=0,
    fromDeezer=false,
}) => 
    get(types.GET_TRACKS_RATINGS, `${url}/v2/ratings/${following ? "following/" : ""}?content_type=track&${fromDeezer ? "deezer_id" : "content_id"}=${id}&order_by=${order_by}&limit=${limit}&offset=${offset}&speedup=true`, { id, reset: true, speedup: true, limit: limit });

export const getTrackLists = ({
    id=null,
    order_by="-like_count",
    limit=12,
    offset=0,
    type=types.GET_TRACKS_LISTS,
}) => 
    get(type, `${url}/v2/lists/content_lists/?content_ct=track&content_id=${id}&order_by=${order_by}&limit=${limit}&offset=${offset}&speedup=true&limit_content=10`, { id, reset: true, speedup: true, limit: limit });
    
// Create content
export const createArtist = (id, fromDeezer) => post(types.CREATE_ARTIST, `${url}/v2/${fromDeezer ? "dz_artists" : "artists"}/`, { id }, { id, reset: true });

export const createAlbum = (id, fromDeezer) => post(types.CREATE_ALBUM, `${url}/v2/${fromDeezer ? "dz_albums" : "albums"}/`, { id }, { id, reset: true });

export const createTrack = (id, fromDeezer) => post(types.CREATE_TRACK, `${url}/v2/${fromDeezer ? "dz_tracks" : "tracks"}/`, { id }, { id, reset: true });

// User rating
export const getMyContentRating = (content_id=null, content_type=null, fromDeezer) => (dispatch, getState) => 
    dispatch(
        get(
            content_type === "artist" ? types.GET_MY_ARTIST_RATINGS : content_type === "album" ? types.GET_MY_ALBUM_RATINGS : types.GET_MY_TRACK_RATINGS, 
            `${url}/v2/ratings/mine/?creator=${getState().auth.uid}&${fromDeezer ? "deezer_id" :"content_id"}=${content_id}&content_type=${content_type}&order_by=-created_at&speedup=true`,
            { id: content_id, type: content_type, speedup: true, limit: 24, reset: true }
        )
    )

// Listing content
export const getTopContent = ({
    contentType=null,
    label="",
    genres__id="",
    album__genres__id="",
    styles__id="",
    album__styles__id="",
    release_date__year="",
    release_date__lte="",
    release_date__gte="",
    release_date="",
    record_type="",
    explicit_lyrics="",
    limit=24,
    offset=0,
}) => 
    get(
        contentType === "artist" ? types.GET_ALL_ARTISTS : contentType === "album" ? types.GET_ALL_ALBUMS : types.GET_ALL_TRACKS, 
        `${url}/v2/${contentType}s/top/?limit=${limit}&offset=${offset}&label=${label}&genres__id=${genres__id}&album__genres__id=${album__genres__id}&styles__id=${styles__id}&album__styles__id=${album__styles__id}&release_date__year=${release_date__year}&release_date__lte=${release_date__lte}&release_date__gte=${release_date__gte}&release_date=${release_date}&record_type=${record_type}&explicit_lyrics=${explicit_lyrics}&speedup=true`,
        { reset: true, speedup: true, limit: limit }
    )

export const getPopularContent = ({
    contentType=null,
    label="",
    genreIds=[],
    styleIds=[],
    release_date__year="",
    release_date__lte="",
    release_date__gte="",
    release_date="",
    record_type="",
    explicit_lyrics="",
    limit=24,
    offset=0,
    weekly=false,
    newReleases=false,
    type,
}) => 
    get(
        type ? type : contentType === "artist" ? types.GET_ALL_ARTISTS : contentType === "album" ? types.GET_ALL_ALBUMS : types.GET_ALL_TRACKS, 
        `${url}/v2/${contentType}s/${newReleases ? "new_releases" : weekly ? "popular_weekly" : "popular"}/?limit=${limit}&offset=${offset}&label=${label}${genreIds.map((id) => `&multi_genres=${id}`).join("")}${styleIds.map((id) => `&multi_styles=${id}`).join("")}&release_date__year=${release_date__year}&release_date__lte=${release_date__lte}&release_date__gte=${release_date__gte}&release_date=${release_date}&record_type=${record_type}&explicit_lyrics=${explicit_lyrics}&speedup=true`,
        { reset: true, speedup: true, limit: limit }
    )

// Custom fetching for an artist's discography
export const getArtistDiscography = (artistId, deezerId) => (dispatch, getState) => {
    // Send BEGIN to reducers
    dispatch({ type: types.GET_ARTIST_DISCOGRAPHY, meta: { status: status.BEGIN, params: { reset: true, id: artistId } } });

    // Make requests both to Deezer and our API
    Promise.all([
        getNormal(`${url}/v2/albums/?limit=1000&artist__id=${artistId}&speedup=true`), 
        //getNormal(`${url}/v2/albums/contributed/?limit=1000&artist__id=${artistId}&speedup=true`), 
        ...deezerId ? [getNormal(`${deezerHostUrl}/artist/${deezerId}/albums/?limit=1000`, 15000, null, null, null, true)] : []
    ])
    .then((responses) => {

        // Get individual responses
        const apiResponse = responses[0]
        const deezerResponse = deezerId ? responses[1] : {data:[]}

        // Only take albums that have more than one rating on our API
        const rankedApiResults = [ ...apiResponse.results ];
        
        //Only get releases from Deezer that have unique title.
        const nonDuplicateDeezerResults = []
        const map = new Map();
        for (const release of deezerResponse.data) {
            if (!map.has(`${release.title}-${release.record_type}`)) {
                map.set(`${release.title}-${release.record_type}`, true);
                nonDuplicateDeezerResults.push(release);
            }
        }

        // Filter the results from Deezer
        const filteredDeezerResults = [
            ...nonDuplicateDeezerResults
            // Only take albums that does not exist in our API
            .filter(content =>
                rankedApiResults.findIndex((compare) => (
                    (compare.hasOwnProperty("deezer_id") ? compare.deezer_id === content.id : compare.id === content.id)
                    ||
                    compare.duplicate_of_deezer_id === content.id
                )) === -1
            )
        ];

        // Filter the results from our API
        const filteredApiResults = [
            ...rankedApiResults
            // Only take albums that are not duplicates
            .filter(content => 
                !content.duplicate_of_id || 
                rankedApiResults.findIndex((compare) => compare.id === content.duplicate_of_id) === -1
            )
        ]

        const sortedAlbums = [
            ...filteredDeezerResults.filter(album => !(album.record_type === "single" || album.record_type === "ep")),
            ...filteredApiResults.filter(album => !(album.record_type === "single" || album.record_type === "ep")),
        ].sort((a, b) => a.release_date && b.release_date && moment(a.release_date).isAfter(moment(b.release_date), "seconds") ? -1 : 1)

        const sortedSingles = [
            ...filteredDeezerResults.filter(album => (album.record_type === "single" || album.record_type === "ep")),
            ...filteredApiResults.filter(album => (album.record_type === "single" || album.record_type === "ep")),
        ].sort((a, b) => a.release_date && b.release_date && moment(a.release_date).isAfter(moment(b.release_date), "seconds") ? -1 : 1)
        
        const combinedResults = [
            ...sortedAlbums,
            ...sortedSingles,
        ]

        // Finished payload
        const payload = {
            results: combinedResults,
            next: null,
        }

        // Send SUCCESS to reducers
        dispatch({ type: types.GET_ARTIST_DISCOGRAPHY, meta: { status: status.SUCCESS, params: { reset: true, id: artistId } }, payload });
        
    })
    .catch((err) => {
        // Send ERROR to reducers
        dispatch({ type: types.GET_ARTIST_DISCOGRAPHY, meta: { status: status.ERROR, params: { reset: true, id: artistId } }, payload: err });
        console.warn(err)

    });
}