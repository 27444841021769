export const types = {
    _: '_',
    INITIAL_LOAD: 'INITIAL_LOAD',

    //core
    REPORT_USER_CONTENT: 'REPORT_USER_CONTENT',
    
    //init
    SET_INIT_ALBUM: 'SET_INIT_ALBUM',
    SET_INIT_TRACK: 'SET_INIT_TRACK',
    SET_INIT_ARTIST: 'SET_INIT_ARTIST',
    SET_INIT_USER: 'SET_INIT_USER',
    SET_INIT_REVIEW: 'SET_INIT_REVIEW',
    SET_INIT_THREAD: 'SET_INIT_THREAD',
    SET_INIT_LIST: 'SET_INIT_LIST',
    SET_INIT_RATING: 'SET_INIT_RATING',

    //auth
    LOG_IN: 'LOG_IN',
    LOG_OUT: 'LOG_OUT',
    SIGN_UP: 'SIGN_UP',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    USER_EXISTS: 'USER_EXISTS',
    GET_HEALTH_CHECK: 'GET_HEALTH_CHECK',

    //administation
    GET_ALL_PATCH_NOTES: 'GET_ALL_PATCH_NOTES',
    
    //me
    FETCH_ME: 'FETCH_ME',
    DELETE_ACCOUNT: 'DELETE_ACCOUNT',
    CREATE_WANT: 'CREATE_WANT',
    DELETE_WANT: 'DELETE_WANT',
    CREATE_APNS_DEVICE: 'CREATE_APNS_DEVICE',
    CREATE_FCM_DEVICE: 'CREATE_FCM_DEVICE',
    RESET_PROFILE_FLATLISTS: 'RESET_PROFILE_FLATLISTS',
    GET_BANNERS: 'GET_BANNERS',
    DELETE_BANNER: 'DELETE_BANNER',
    CREATE_BANNER: 'CREATE_BANNER',
    UPDATE_BANNER: 'UPDATE_BANNER',
    SET_MY_PRIMARY_LANGUAGE: 'SET_MY_PRIMARY_LANGUAGE',
    
    FETCH_MY_WANTS: 'FETCH_MY_WANTS',
    FETCH_MY_FOLLOWS: 'FETCH_MY_FOLLOWS',
    FETCH_MY_BLOCKS: 'FETCH_MY_BLOCKS',
    FETCH_BLOCKED_BY: 'FETCH_BLOCKED_BY',
    FETCH_MY_LIKES: 'FETCH_MY_LIKES',

    UPDATE_PINNED_CONTENT: 'UPDATE_PINNED_CONTENT',

    //local
    SET_SEARCH_TYPE: 'SET_SEARCH_TYPE',
    SET_THEME: 'SET_THEME',
    SET_TEMP_THEME: 'SET_TEMP_THEME',
    SET_DARK_MODE: 'SET_DARK_MODE',
    SET_LIGHT_MODE: 'SET_LIGHT_MODE',
    SET_SHOW_FILTERS: 'SET_SHOW_FILTERS',
    SET_HOME_REVIEWS_IS_FRIENDS: 'SET_HOME_REVIEWS_IS_FRIENDS',
    SET_HOME_LISTS_IS_FRIENDS: 'SET_HOME_LISTS_IS_FRIENDS',
    SET_HOME_ALBUMS_IS_POPULAR: 'SET_HOME_ALBUMS_IS_POPULAR',
    GET_RATINGS_COUNT: 'GET_RATINGS_COUNT',
    SET_LAST_MOBILE_POPUP: 'SET_LAST_MOBILE_POPUP',
    SET_ACCEPTED_COOKIES: 'SET_ACCEPTED_COOKIES',
    SET_BOTTOM_AD_VISIBLE: 'SET_BOTTOM_AD_VISIBLE',

    ADD_TO_MUSIC_HISTORY: 'ADD_TO_MUSIC_HISTORY',
    REMOVE_FROM_MUSIC_HISTORY: 'REMOVE_FROM_MUSIC_HISTORY',
    ADD_TO_USERS_HISTORY: 'ADD_TO_USERS_HISTORY',
    REMOVE_FROM_USERS_HISTORY: 'REMOVE_FROM_USERS_HISTORY',
    ADD_TO_LISTS_HISTORY: 'ADD_TO_LISTS_HISTORY',
    REMOVE_FROM_LISTS_HISTORY: 'REMOVE_FROM_LISTS_HISTORY',
    CLEAR_LISTS_HISTORY: 'CLEAR_LISTS_HISTORY',

    STORE_LOCAL_ALBUM: 'STORE_LOCAL_ALBUM',
    SAVE_LOCAL_SUGGESTED_ALBUM: 'SAVE_LOCAL_SUGGESTED_ALBUM',
    STORE_LOCAL_ARTIST: 'STORE_LOCAL_ARTIST',
    SAVE_LOCAL_SUGGESTED_ARTIST: 'SAVE_LOCAL_SUGGESTED_ARTIST',
    STORE_RESUBMIT_DATA: 'STORE_RESUBMIT_DATA',

    SET_VOLUME: 'SET_VOLUME',

    //users
    FETCH_USER: 'FETCH_USER',
    GET_FOLLOWERS: 'GET_FOLLOWERS',
    GET_FOLLOWING: 'GET_FOLLOWING',
    GET_USERS_TRACK_RATINGS: 'GET_USERS_TRACK_RATINGS',
    GET_USERS_ALBUM_RATINGS: 'GET_USERS_ALBUM_RATINGS',
    GET_USERS_ARTIST_RATINGS: 'GET_USERS_ARTIST_RATINGS',
    GET_USERS_DIARY: 'GET_USERS_DIARY',
    GET_WANTS: 'GET_WANTS',
    GET_RANDOM_WANT: 'GET_RANDOM_WANT',
    GET_USERS_LISTS: 'GET_USERS_LISTS',
    GET_USERS_REVIEWS: 'GET_USERS_REVIEWS',
    GET_USERS_PINNED_LISTS: 'GET_USERS_PINNED_LISTS',
    GET_USERS_PINNED_REVIEWS: 'GET_USERS_PINNED_REVIEWS',
    GET_USERS_RECENT_ACTIVITY: 'GET_USERS_RECENT_ACTIVITY',
    GET_USERS_SUGGESTED_CHANGES: 'GET_USERS_SUGGESTED_CHANGES',
    FOLLOW_USER: 'FOLLOW_USER',
    FOLLOW_MULTIPLE_USERS: 'FOLLOW_MULTIPLE_USERS',
    UNFOLLOW_USER: 'UNFOLLOW_USER',
    GET_ALL_USERS: 'GET_ALL_USERS',
    BLOCK_USER: 'BLOCK_USER',
    UNBLOCK_USER: 'UNBLOCK_USER',
    GET_ALL_BLOCKED_USERS: 'GET_ALL_BLOCKED_USERS',
    GET_RECOMMENDED_USERS: 'GET_RECOMMENDED_USERS',
    GET_USER_UID: 'GET_USER_UID',
    GET_USER_STATISTICS: 'GET_USER_STATISTICS',
    GET_USER_FOLLOW_STATUS: 'GET_USER_FOLLOW_STATUS',
    GET_USER_YEAR_RATINGS: 'GET_USER_YEAR_RATINGS',
    GET_FAVORITE_ALBUMS: 'GET_FAVORITE_ALBUMS',
    GET_USERS_SUGGESTED_USERS: 'GET_USERS_SUGGESTED_USERS',
    CREATE_FAVORITE_ALBUM: 'CREATE_FAVORITE_ALBUM',
    UPDATE_FAVORITE_ALBUM: 'UPDATE_FAVORITE_ALBUM',
    UPDATE_FAVORITE_ALBUMS: 'UPDATE_FAVORITE_ALBUMS',
    DELETE_FAVORITE_ALBUM: 'DELETE_FAVORITE_ALBUM',

    //lists
    FETCH_LIST: 'FETCH_LIST',
    UPDATE_LIST: 'UPDATE_LIST',
    GET_MORE_LIST_CONTENT: 'GET_MORE_LIST_CONTENT',
    CREATE_LIST: 'CREATE_LIST',
    DELETE_LIST: 'DELETE_LIST',
    CREATE_LIST_CONNECTION: 'CREATE_LIST_CONNECTION',
    DELETE_LIST_CONNECTION: 'DELETE_LIST_CONNECTION',
    UPDATE_LIST_CONNECTIONS: 'UPDATE_LIST_CONNECTIONS',
    GET_ALL_LISTS: 'GET_ALL_LISTS',
    FETCH_LIST_CONTENT: 'FETCH_LIST_CONTENT',
    CREATE_LIST_CONNECTIONS_FOR_CONTENT: 'CREATE_LIST_CONNECTIONS_FOR_CONTENT',
    CREATE_LIST_CONNECTIONS_FOR_LIST: 'CREATE_LIST_CONNECTIONS_FOR_LIST',
    DELETE_LIST_CONNECTIONS: 'DELETE_LIST_CONNECTIONS',
    TRANSLATE_LIST: 'TRANSLATE_LIST',
    DETECT_LIST_LANGUAGE: 'DETECT_LIST_LANGUAGE',
    GET_RANDOM_LIST_ITEM: 'GET_RANDOM_LIST_ITEM',

    //reviews
    FETCH_REVIEW: 'FETCH_REVIEW',
    UPDATE_REVIEW: 'UPDATE_REVIEW',
    CREATE_REVIEW: 'CREATE_REVIEW',
    DELETE_REVIEW: 'DELETE_REVIEW',
    GET_ALL_REVIEWS: 'GET_ALL_REVIEWS',
    TRANSLATE_REVIEW: 'TRANSLATE_REVIEW',
    DETECT_REVIEW_LANGUAGE: 'DETECT_REVIEW_LANGUAGE',

    //threads
    FETCH_THREAD: 'FETCH_THREAD',
    GET_THREADS: 'GET_THREADS',
    FETCH_MY_POLL_ANSWERS: 'FETCH_MY_POLL_ANSWERS',
    ANSWER_POLL: 'ANSWER_POLL',
    DELETE_POLL_ANSWER: 'DELETE_POLL_ANSWER',

    //ratings
    GET_LATEST_RATINGS: 'GET_LATEST_RATINGS',
    FETCH_RATING: 'FETCH_RATING',
    UPDATE_RATING: 'UPDATE_RATING',
    CREATE_RATING: 'CREATE_RATING',
    DELETE_RATING: 'DELETE_RATING',

    //artists
    FETCH_ARTIST: 'FETCH_ARTIST',
    GET_ARTIST_RATING_DISTRIBUTION: 'GET_ARTIST_RATING_DISTRIBUTION',
    GET_ARTIST_LATEST_CONTRIBUTIONS: 'GET_ARTIST_LATEST_CONTRIBUTIONS',
    GET_ARTISTS_REVIEWS: 'GET_ARTISTS_REVIEWS',
    GET_ARTISTS_HOME_REVIEWS: 'GET_ARTISTS_HOME_REVIEWS',
    GET_ARTISTS_CONTENT_REVIEWS: 'GET_ARTISTS_CONTENT_REVIEWS',
    GET_ARTISTS_LISTS: 'GET_ARTISTS_LISTS',
    GET_ARTISTS_HOME_LISTS: 'GET_ARTISTS_HOME_LISTS',
    CREATE_ARTIST: 'CREATE_ARTIST',
    GET_MY_ARTIST_RATINGS: 'GET_MY_ARTIST_RATINGS',
    GET_ALL_ARTISTS: 'GET_ALL_ARTISTS',
    GET_ARTISTS_RATINGS: 'GET_ARTISTS_RATINGS',
    GET_ARTIST_DISCOGRAPHY: 'GET_ARTIST_DISCOGRAPHY',
    GET_ARTIST_TOP_TRACKS: 'GET_ARTIST_TOP_TRACKS',

    //albums
    FETCH_ALBUM: 'FETCH_ALBUM',
    GET_ALBUM_RATING_DISTRIBUTION: 'GET_ALBUM_RATING_DISTRIBUTION',
    GET_ALBUM_LATEST_CONTRIBUTIONS: 'GET_ALBUM_LATEST_CONTRIBUTIONS',
    GET_ALBUM_BONUS_TRACKS: 'GET_ALBUM_BONUS_TRACKS',
    GET_ALBUMS_REVIEWS: 'GET_ALBUMS_REVIEWS',
    GET_ALBUMS_HOME_REVIEWS: 'GET_ALBUMS_HOME_REVIEWS',
    GET_ALBUMS_CONTENT_REVIEWS: 'GET_ALBUMS_CONTENT_REVIEWS',
    GET_ALBUMS_LISTS: 'GET_ALBUMS_LISTS',
    GET_ALBUMS_HOME_LISTS: 'GET_ALBUMS_HOME_LISTS',
    CREATE_ALBUM: 'CREATE_ALBUM',
    GET_MY_ALBUM_RATINGS: 'GET_MY_ALBUM_RATINGS',
    GET_ALL_ALBUMS: 'GET_ALL_ALBUMS',
    GET_POPULAR_ALBUMS: 'GET_POPULAR_ALBUMS',
    GET_ALBUMS_RATINGS: 'GET_ALBUMS_RATINGS',

    //tracks
    FETCH_TRACK: 'FETCH_TRACK',
    GET_TRACK_RATING_DISTRIBUTION: 'GET_TRACK_RATING_DISTRIBUTION',
    FETCH_MULTIPLE_TRACKS: 'FETCH_MULTIPLE_TRACKS',
    GET_TRACKS_REVIEWS: 'GET_TRACKS_REVIEWS',
    GET_TRACKS_HOME_REVIEWS: 'GET_TRACKS_HOME_REVIEWS',
    GET_TRACKS_CONTENT_REVIEWS: 'GET_TRACKS_CONTENT_REVIEWS',
    CREATE_TRACK: 'CREATE_TRACK',
    GET_MY_TRACK_RATINGS: 'GET_MY_TRACK_RATINGS',
    GET_ALL_TRACKS: 'GET_ALL_TRACKS',
    GET_TRACKS_RATINGS: 'GET_TRACKS_RATINGS',
    GET_TRACKS_LISTS: 'GET_TRACKS_LISTS',
    GET_TRACKS_HOME_LISTS: 'GET_TRACKS_HOME_LISTS',

    //suggestions
    REMOVE_SUGGESTION: 'REMOVE_SUGGESTION',
    ADD_SUGGESTION: 'ADD_SUGGESTION',

    //search
    GET_SEARCH_REVIEWS: 'GET_SEARCH_REVIEWS',
    GET_SEARCH_LISTS: 'GET_SEARCH_LISTS',
    GET_SEARCH_USERS: 'GET_SEARCH_USERS',

    GET_STATIC_SEARCH_REVIEWS: 'GET_STATIC_SEARCH_REVIEWS',
    GET_STATIC_SEARCH_LISTS: 'GET_STATIC_SEARCH_LISTS',
    GET_STATIC_SEARCH_USERS: 'GET_STATIC_SEARCH_USERS',

    CLEAR_SEARCH_REVIEWS: 'CLEAR_SEARCH_REVIEWS',
    CLEAR_SEARCH_LISTS: 'CLEAR_SEARCH_LISTS',
    CLEAR_SEARCH_USERS: 'CLEAR_SEARCH_USERS',

    SEARCH_MERGED_ALBUMS: 'SEARCH_MERGED_ALBUMS',
    SEARCH_MERGED_TRACKS: 'SEARCH_MERGED_TRACKS',
    SEARCH_MERGED_ARTISTS: 'SEARCH_MERGED_ARTISTS',

    SEARCH_STATIC_MERGED_ALBUMS: 'SEARCH_STATIC_MERGED_ALBUMS',
    SEARCH_STATIC_MERGED_TRACKS: 'SEARCH_STATIC_MERGED_TRACKS',
    SEARCH_STATIC_MERGED_ARTISTS: 'SEARCH_STATIC_MERGED_ARTISTS',
    
    CLEAR_SEARCH_ALBUMS: 'CLEAR_SEARCH_ALBUMS',
    CLEAR_SEARCH_TRACKS: 'CLEAR_SEARCH_TRACKS',
    CLEAR_SEARCH_ARTISTS: 'CLEAR_SEARCH_ARTISTS',

    //home
    GET_FEATURED_REVIEWS: 'GET_FEATURED_REVIEWS',
    GET_FEATURED_LISTS: 'GET_FEATURED_LISTS',
    GET_DISCOVER: 'GET_DISCOVER',
    GET_POPULAR_USERS: 'GET_POPULAR_USERS',
    GET_SUGGESTED_USERS: 'GET_SUGGESTED_USERS',
    GET_FRIENDS_RATINGS: 'GET_FRIENDS_RATINGS',
    GET_FRIENDS_REVIEWS: 'GET_FRIENDS_REVIEWS',
    GET_FRIENDS_LISTS: 'GET_FRIENDS_LISTS',
    GET_FEATURED_SECTIONS: 'GET_FEATURED_SECTIONS',
    GET_ACTIVE_USERS: 'GET_ACTIVE_USERS',
    GET_NEW_RELEASES: 'GET_NEW_RELEASES',
    GET_TIMELINE_REVIEWS: 'GET_TIMELINE_REVIEWS',
    GET_TIMELINE_LISTS: 'GET_TIMELINE_LISTS',
    RESET_HOME_FLATLISTS: 'RESET_HOME_FLATLISTS',

    //comments
    GET_COMMENTS: 'GET_COMMENTS',
    GET_MORE_REPLIES: 'GET_MORE_REPLIES',
    CREATE_COMMENT: 'CREATE_COMMENT',
    UPDATE_COMMENT: 'UPDATE_COMMENT',
    DELETE_COMMENT: 'DELETE_COMMENT',

    //stickers
    CREATE_STICKER: 'CREATE_STICKER',
    DELETE_STICKER: 'DELETE_STICKER',
    GET_STICKER_COUNTS: 'GET_STICKER_COUNTS',
    GET_MY_STICKER: 'GET_MY_STICKER',
    GET_STICKERS: 'GET_STICKERS',

    //likes
    LIKE_CONTENT: 'LIKE_CONTENT',
    DELETE_LIKE: 'DELETE_LIKE',
    GET_LIKES: 'GET_LIKES',

    //activity
    GET_ACTIVITY: 'GET_ACTIVITY',
    CLEAR_NEW_ACTIVITY: 'CLEAR_NEW_ACTIVITY',
    RESET_ACTIVITY_FLATLISTS: 'RESET_ACTIVITY_FLATLISTS',

    //payments
    GET_PRODUCTS: 'GET_PRODUCTS',
    GET_SUBSCRIPTION: 'GET_SUBSCRIPTION',
    CREATE_PORTAL_SESSION: 'CREATE_PORTAL_SESSION',
    CREATE_PRO_GIFT: 'CREATE_PRO_GIFT',
    UPDATE_PRO_GIFT: 'UPDATE_PRO_GIFT',
    GET_PRO_GIFTS: 'GET_PRO_GIFTS',

    //genres
    GET_GENRES: 'GET_GENRES',
    GET_STYLES: 'GET_STYLES',
    GET_FAVORITE_GENRES: 'GET_FAVORITE_GENRES',
    CREATE_FAVORITE_GENRE: 'CREATE_FAVORITE_GENRE',
    DELETE_FAVORITE_GENRE: 'DELETE_FAVORITE_GENRE',

    //news
    GET_ALL_ARTICLES: 'GET_ALL_ARTICLES',
    GET_ARTICLE: 'GET_ARTICLE',
    SIGN_UP_FOR_NEWSLETTER: 'SIGN_UP_FOR_NEWSLETTER',

    //contributions
    REPORT_DUPLICATED_CONTENT: 'REPORT_DUPLICATED_CONTENT',
    GET_SUGGESTED_CHANGES: 'GET_SUGGESTED_CHANGES',
    GET_SUGGESTED_ARTISTS: 'GET_SUGGESTED_ARTISTS',
    
    CREATE_SUGGESTED_ALBUM: 'CREATE_SUGGESTED_ALBUM',
    CREATE_SUGGESTED_ARTIST: 'CREATE_SUGGESTED_ARTIST',

    CREATE_ARTIST_SUGGESTION: 'CREATE_ARTIST_SUGGESTION',
    CREATE_ALBUM_SUGGESTION: 'CREATE_ALBUM_SUGGESTION',

    GET_MY_SUGGESTED_ALBUM_RATINGS: 'GET_MY_SUGGESTED_ALBUM_RATINGS',
    GET_MY_SUGGESTED_ARTIST_RATINGS: 'GET_MY_SUGGESTED_ARTIST_RATINGS',
    GET_MY_SUGGESTED_TRACK_RATINGS: 'GET_MY_SUGGESTED_TRACK_RATINGS',

    DELETE_SUGGESTION: 'DELETE_SUGGESTION',

    //chat
    GET_GROUPS: 'GET_GROUPS',
    FETCH_GROUP: 'FETCH_GROUP',
    GET_MESSAGES: 'GET_MESSAGES',
    REFETCH_MESSAGES: 'REFETCH_MESSAGES',
    CLEAR_NEW_MESSAGES: 'CLEAR_NEW_MESSAGES',
    CREATE_MESSAGE: 'CREATE_MESSAGE',
    DELETE_MESSAGE: 'DELETE_MESSAGE',
    UPDATE_MESSAGE: 'UPDATE_MESSAGE',
    ADD_GROUP_MEMBER: 'ADD_GROUP_MEMBER',
    KICK_GROUP_MEMBER: 'KICK_GROUP_MEMBER',
    UPDATE_GROUP: 'UPDATE_GROUP',
    UPDATE_GROUP_INSTANT: 'UPDATE_GROUP_INSTANT',
    LEAVE_GROUP: 'LEAVE_GROUP',
    GET_GROUP_WITH_USERS: 'GET_GROUP_WITH_USERS',
    CREATE_GROUP: 'CREATE_GROUP',
    FETCH_MY_GROUP_SETTINGS: 'FETCH_MY_GROUP_SETTINGS',
    UPDATE_GROUP_SETTINGS: 'UPDATE_GROUP_SETTINGS',
    CREATE_GROUP_SETTINGS: 'CREATE_GROUP_SETTINGS',

    //drafts
    GET_ALL_REVIEW_DRAFTS: 'GET_ALL_REVIEW_DRAFTS',
    GET_ALL_LIST_DRAFTS: 'GET_ALL_LIST_DRAFTS',
    UPDATE_DRAFT: 'UPDATE_DRAFT',
    CREATE_DRAFT: 'CREATE_DRAFT',
    DELETE_DRAFT: 'DELETE_DRAFT',

    //gifs
    GET_GIF_RESULTS: 'GET_GIF_RESULTS',
    CLEAR_GIF_RESULTS: 'CLEAR_GIF_RESULTS',
    GET_TRENDING_GIFS: 'GET_TRENDING_GIFS',
    GET_GIF_CATEGORIES: 'GET_GIF_CATEGORIES',
    GET_GIF_TRENDING_TERMS: 'GET_GIF_TRENDING_TERMS',
    GET_GIF_AUTOCOMPLETE: 'GET_GIF_AUTOCOMPLETE',
    GET_GIF_SUGGESTED_SEARCH: 'GET_GIF_SUGGESTED_SEARCH',
    CLEAR_GIF_SEARCHES: 'CLEAR_GIF_SEARCHES',
    CROP_GIF: 'CROP_GIF',
    
}
export const status = {
    DEFAULT: 'DEFAULT',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    BEGIN: 'BEGIN'
}