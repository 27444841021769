import styles from './proicon.module.css'
import React from 'react'

export default function ProIcon({ className="", size="medium", style, is_pro, is_moderator, contributor_tier }) {
    let fontSize = 12;
    let padding = '0px 6px';
    let borderRadius = 5;
    let lineHeight = '12px';

    if (size === "small") {
        fontSize = 10;
        padding = '0px 4px';
        borderRadius = 3;
        lineHeight = '16px';
    
    } else if (size === "medium") {
        fontSize = 12;
        padding = '0px 6px';
        borderRadius = 5;
        lineHeight = '18px';

    } else if (size === "large") {
        fontSize = 14;
        padding = '1px 8px';
        borderRadius = 6;
        lineHeight = '21px';
    }

    return (
        <div 
        className={`${styles.img} ${contributor_tier === 1 ? styles.contributorOne : ""} ${contributor_tier === 2 ? styles.contributorTwo : ""} ${contributor_tier === 3 ? styles.contributorThree : ""} ${contributor_tier === 4 ? styles.contributorFour : ""} ${is_pro ? styles.pro : ""} ${is_moderator ? styles.moderator : ""} ${size === "small" ? styles.small : ""} ${size === "medium" ? styles.medium : ""} ${size === "large" ? styles.large : ""} ${className}`}
        style={style}
        />
    )

    return (
        <div
        className={` ${styles.txt} ${styles.pro} ${contributor_tier === 1 ? styles.contributorOne : ""} ${contributor_tier === 2 ? styles.contributorTwo : ""} ${contributor_tier === 3 ? styles.contributorThree : ""} ${contributor_tier === 4 ? styles.contributorFour : ""} ${is_moderator ? styles.moderator : ""} ${className}`} 
        style={{ padding, borderRadius, ...style, }}
        >
            <h6 className={styles.text} style={{ fontSize, lineHeight }}>
                {is_moderator ? "CREW" : contributor_tier ? "MOD" : "PRO"}
            </h6>
        </div>
    )
}
