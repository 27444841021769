import React, { Component } from 'react'

import DefaultHelmet from '../DefaultHelmet'

import Icon from '../../components/Icon'
import Button from '../../components/Button'


class ErrorPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    if (this.state.error) {
      return (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <DefaultHelmet
          title="Something went wrong"
          description="500. An error occurred when visiting this page."
          skipCanonical
          skipDescription
          />
          
          <div style={{ maxWidth: 460 }}>
            <div className='full-center flex-column'>
              <Icon icon="error" size={128} color="var(--darkLightGrey)" />

              <h1 className="text-md black text-center margin-top-32">500 - Something Went Wrong</h1>

              <p className="highDarkGrey text-center margin-top-24 margin-right-32 margin-left-32">We track these errors automatically, but if the issue persists contact us at <a href="mailto:info@musicboard.app" style={{textDecoration: 'underline', color : 'var(--highDarkGrey)'}}>info@musicboard.app</a>.</p>
              
              <Button 
              className="margin-top-32"
              onClicked={() => document.location = '/'}
              text="Back to home"
              />

              <div style={{ display: 'block', position: 'absolute', bottom: 16, padding: '8px 0', fontSize: '12px', color: 'var(--grey)'}}>
                {this.state.error && `Error: ${this.state.error.toString()}`}
              </div>
            </div>
          </div>
        </div>
      )
    }
    
    return this.props.children;

  }
}

export default ErrorPage;
