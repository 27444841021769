import styles from './checkbox.module.css'
import React from 'react'
import Icon from '../Icon'

export default function Checkbox({ 
    checked, 
    onChange=()=>{},
    className,
    style,
    text,
    allowed=true,
    lighterStyle,
    thumbIcon
}) {
    return (
        <div
        onClick={() => {
            if (allowed) {
                onChange(checked ? false : true)
            }
        }} 
        className={`flex-row ${className} ${styles.container} ${allowed ? styles.allowed : ""}`} 
        style={style}
        >
            <div 
            className={`${styles.checkBox} ${allowed ? styles.allowed : ""} ${lighterStyle ? styles.lighterStyle : ""} ${checked ? styles.active : ""}`}
            >
                <Icon size={thumbIcon ? 14 : 20} icon={thumbIcon ? "thumb" : "check"} color="white" className={styles.checkMark} />
            </div>

            {text &&
                <div className="">
                    <p className={`margin-left-8 ${styles.checkText} ${checked ? styles.active : ""}`}>{text}</p>
                </div>
            }
        </div>
    )
}
