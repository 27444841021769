import styles from './changecontentrating.module.css'

import React, { useState, useEffect, useRef } from 'react'
import ClickableStars from '../ClickableStars'
import { createRatingWithContent, updateRating } from '../../actions/ratings-actions'
import { useDispatch, useSelector } from 'react-redux'
import { status } from '../../constants/action-types'
import { getMyContentRating } from '../../actions/content-actions'
import { useAlert } from '../../hooks/alert/alert'
import Icon from '../Icon'

export default function ChangeContentRating({ iconSize=32, contentId, contentType, fromDeezer }) {
    //General
    const { openAlert } = useAlert()
    const dispatch = useDispatch()

    const contentTypePlural = `${contentType}s`

    //Data
    const backend = useSelector(state => state[contentTypePlural][contentTypePlural][contentId])
    const updateRatingStatus = useSelector(state => state.ratings.updateRatingStatus)
    const createRatingStatus = useSelector(state => state.ratings.createRatingStatus)
    const lastUpdatedRating = useSelector(state => state.ratings.lastUpdatedRating)
    const lastCreatedRating = useSelector(state => state.ratings.lastCreatedRating)
    const errorMessage = useSelector(state => state.ratings.errorMessage)

    const ratingObj = (backend && backend.myRatings && backend.myRatings.data && backend.myRatings.data[0]) ? backend.myRatings.data[0] : null

    //State
    const [rating, setRating] = useState(ratingObj ? ratingObj.rating : 0)
    const [clicked, setClicked] = useState(false)

    //Handle updateRatingStatus
    const firstUpdate = useRef(true)
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        
        if(clicked && updateRatingStatus === status.SUCCESS && lastUpdatedRating && lastUpdatedRating.content.id === contentId) {
            setClicked(false)

            openAlert({
                style: "green",
                p: `Your rating was changed.`,
                a: "View it here.",
                href: lastUpdatedRating.review_url_slug ? lastUpdatedRating.review_url_slug : lastUpdatedRating.review_uid ? `/review/${lastUpdatedRating.review_uid}` : `/rating/${lastUpdatedRating.uid}`,
                timer: 4000,
            })
        } else if (clicked && updateRatingStatus === status.ERROR && lastUpdatedRating && lastUpdatedRating.content.id === contentId) {
            setClicked(false)
            
            setRating(ratingObj ? ratingObj.rating : 0)
            
            openAlert({
                style: "red",
                p: `Could not update rating. Error: ${errorMessage}`,
                timer: 6000,
            })

        }
    }, [ updateRatingStatus, lastUpdatedRating ])
    
    //Handle createRatingStatus
    const firstUpdateCreate = useRef(true)
    useEffect(() => {
        if (firstUpdateCreate.current) {
            firstUpdateCreate.current = false;
            return;
        }

        if(clicked && createRatingStatus === status.SUCCESS && lastCreatedRating && lastCreatedRating.content.id === contentId) {
            setClicked(false)

            openAlert({
                style: "green",
                p: `Thanks for rating`,
                a: `${lastCreatedRating.content.type === "artist" ? lastCreatedRating.content.name : lastCreatedRating.content.title}!`,
                href: lastCreatedRating.content.type === "track" ? `${lastCreatedRating.content.album.url_slug}?tracks=${lastCreatedRating.content.id}` : lastCreatedRating.content.url_slug,
                timer: 4000,
            })

        } else if (clicked && createRatingStatus === status.ERROR && lastCreatedRating && lastCreatedRating.content.id === contentId) {
            setClicked(false)
            
            setRating(0)
            
            openAlert({
                style: "red",
                p: `Could not create rating. Error: ${errorMessage}`,
                timer: 6000,
            })

        }
    }, [ createRatingStatus, lastCreatedRating ])

    //Handle fetching of 
    const firstUpdateRating = useRef(true)
    useEffect(() => {
        if (firstUpdateRating.current) {
            firstUpdateRating.current = false;
            return;
        }
        
        if(ratingObj && ratingObj.rating !== rating) {
            setRating(ratingObj.rating)
        }
    }, [ ratingObj ])

    return (
        <div className={`flex-row ${styles.ratingContainer}`}>
            <div 
            onClick={() => {
                setClicked(true)
                setRating(null)
                
                dispatch(
                    updateRating(ratingObj.uid, { rating: null }, true, rating)
                )
            }} 
            className={`${styles.clearRatingBtn} ${rating && ratingObj ? styles.active : styles.disabled}`}
            >
                <Icon icon='close' size={20} className={styles.clearRatingIcon} />
            </div>
            
            <ClickableStars 
            iconSize={iconSize} 
            rating={rating} 
            setRating={(newRating) => {
                setClicked(true)
                setRating(newRating)
                if (ratingObj) {
                    dispatch(
                        updateRating(ratingObj.uid, { rating: newRating }, true, rating)
                    )
                } else {
                    dispatch(
                        createRatingWithContent(contentId, contentType, fromDeezer, newRating, false, true)
                    )
                }
            }} 
            />
        </div>
    )
}
