import styles from './searchartist.module.css'

import React from 'react'
import ProfilePicture from '../../../../components/ProfilePicture'
import Icon from '../../../../components/Icon'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import ContentOptionsTooltip from '../../../../components/ContentOptionsTooltip'
import { checkDeezerId } from '../../../../utils/deezer-utils'

export default function SearchArtist({ artist, style, size=88, className, overrideClick, active, useContentOptions, overwriteButton }) {

    //Responsive
    const isTablet = useMediaQuery({ maxWidth: 768 })

    return (
        <div className={`${styles.container} link-wrapper content-options-wrapper cursor ${className} ${active ? styles.active : ""}`} style={style}>
            <Link
            className="link-overlay"
            to={checkDeezerId(artist) ? `/artist/${artist.id}?dz` : artist.url_slug}
            onClick={(e) => {
                if (overrideClick) {
                    e.preventDefault()
                    overrideClick(artist)
                }
            }}
            >
                <span className='link-overlay-title'>{artist.name}</span>
            </Link>

            <div className="flex-row link-inner">
                <Link
                className="link-clickable"
                to={checkDeezerId(artist) ? `/artist/${artist.id}?dz` : artist.url_slug}
                onClick={(e) => {
                    if (overrideClick) {
                        e.preventDefault()
                        overrideClick(artist)
                    }
                }}
                >
                    <ProfilePicture size={isTablet ? 60 : size} clickable profilePicture={artist.picture_small || artist.picture} altText={artist.name} />
                </Link>
                
                <div className="col-1 margin-left-16">
                    <div className="grid">
                        <h5 className={`${styles.text} black one-line`}>{artist.name}</h5>
                    </div>
                </div>

                {overwriteButton &&
                    <div className='link-clickable margin-left-8'>
                        {overwriteButton}
                    </div>
                }

                {useContentOptions && !isTablet &&
                    <div className="link-clickable margin-right-8 margin-left-8">
                        <ContentOptionsTooltip content={artist} appendId={`searchartist-`} absoluteDesign={false} />
                    </div>
                }
            </div>
        </div>
    )
}
