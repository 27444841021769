import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function AnnouncementAd({ className }) {
    const announcement_text = useSelector(state => state.auth.healthCheck.announcement_text)

    return (
        <div className={`section-block margin-bottom-32 ${className}`}>
            <div className='flex-row padding-around-24'>
                <p className='black'>
                    {announcement_text}
                </p>
            </div>
        </div>
    )
}
