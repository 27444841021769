import styles from './mobilenavigationmenu.module.css'

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { feedbackUrl, bugReportUrl, appStoreUrl, googlePlayUrl } from '../../../../config'

import appStoreBadge from '../../../../assets/images/app-store-badge.svg'
import googlePlayBadge from '../../../../assets/images/google-play-badge.svg'

import { usePopup } from '../../../../hooks/popup/popup'

import Icon from '../../../../components/Icon'
import Button from '../../../../components/Button'

export default function MobileNavigationMenu({ showingMenu, setShowingMenu }) {
    const { openPopup } = usePopup()

    //Responsive
    var onIOS = window.navigator.userAgent.includes("iPhone") || window.navigator.userAgent.includes("iPad");
    var onANDROID = window.navigator.userAgent.includes("Android");
    
    //Data
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    //State
    const [openItem, setOpenItem] = useState(null)

    //Update scroll behavior
    useEffect(() => {
        if (showingMenu) {
            document.documentElement.style.overflow = "hidden"
        }

        return(() => {
            document.documentElement.style.overflow = "initial"
        })
    }, [showingMenu])

    //Prepare for render
    const list = [
        {
            label: "Music",
            icon: "music-note",
            link: "/explore/music"
        },
        {
            label: "Members",
            icon: "groups",
            link: "/explore/members"
        },
        {
            label: "Lists",
            icon: "queue-music",
            link: "/explore/lists"
        },
        {
            label: "Pro",
            icon: "star",
            link: "/pro"
        },
        {
            label: "Top Lists",
            icon: "emoji-events",
            list: [
                {
                    label: "Top 250 Albums",
                    link: "/albums?view=detailed&order_by=top&record_type=album",
                    element: (
                        <div className={`${styles.listItemIcon} ${styles.crown}`} />
                    )
                },
                {
                    label: "Hot New Releases",
                    link: "/albums?order_by=new",
                    element: (
                        <div className={`${styles.listItemIcon} ${styles.fire}`} />
                    )
                },
                {
                    label: "Popular This Week",
                    link: "/albums?order_by=week",
                    element: (
                        <div className={`${styles.listItemIcon} ${styles.trend}`} />
                    )
                },
                {
                    label: "Top 250 Artists",
                    link: "/artists?order_by=top&view=detailed",
                    element: (
                        <div className={`${styles.listItemIcon}`} />
                    )
                },
                {
                    label: "Top 250 Tracks",
                    link: "/tracks?order_by=top&view=detailed",
                    element: (
                        <div className={`${styles.listItemIcon}`} />
                    )
                },
                {
                    label: "Most Popular Albums",
                    link: "/albums",
                    element: (
                        <div className={`${styles.listItemIcon}`} />
                    )
                },
                {
                    label: "Most Popular Artists",
                    link: "/artists",
                    element: (
                        <div className={`${styles.listItemIcon}`} />
                    )
                },
                {
                    label: "Most Popular Tracks",
                    link: "/tracks",
                    element: (
                        <div className={`${styles.listItemIcon}`} />
                    )
                },
            ]
        },
        {
            label: "Resources",
            icon: "folder-open",
            list: [
                {
                    label: "About",
                    link: "/about",
                    icon: "info"
                },
                {
                    label: "Apps",
                    link: "/apps",
                    icon: "smartphone"
                },
                {
                    label: "FAQ",
                    link: "/faq",
                    icon: "forum"
                },
                {
                    label: "Welcome",
                    link: "/welcome",
                    icon: "support"
                },
                {
                    label: "Contact",
                    link: "/contact",
                    icon: "mail"
                },
                {
                    label: "Send feedback",
                    link: feedbackUrl,
                    icon: "thumb-up"
                },
                {
                    label: "Bug report",
                    link: bugReportUrl,
                    icon: "bug-report"
                },
                {
                    label: "Community Guidelines",
                    link: "/guidelines",
                    icon: "shield"
                },
            ]
        },
    ]

    return (
        <div className={styles.wrapper}>
            <div onClick={() => setShowingMenu(false)} className={styles.background} />

            <div className={styles.menu}>
                <div className={styles.menuScroller}>
                    {list.filter(i => i).map((item, index) => (
                        <div className={`${styles.menuItemWrapper} ${openItem === item.label ? styles.active : ""}`}>
                            <Link
                            to={item.link}
                            target={item.target}
                            onClick={(e) => {
                                if (item.list) {
                                    e.preventDefault()

                                    if (openItem !== item.label) {
                                        setOpenItem(item.label)
                                    } else {
                                        setOpenItem(null)
                                    }
                                }
                                
                                if (item.onClick) {
                                    e.preventDefault()

                                    item.onClick()
                                }
                            }}
                            >
                                <div className={`flex-row justify-between ${styles.itemContainer}`}>
                                    <div className="flex-row" style={{ color: item.color ? item.color : undefined }}>
                                        <Icon icon={item.icon} size={24} />

                                        <h5 className="margin-left-12">{item.label}</h5>
                                    </div>

                                    {item.list &&
                                        <div style={{ width: 30 }} className="flex-center">
                                            <Icon 
                                            icon={"keyboard-arrow-down"} 
                                            className={`${styles.menuItemArrow}`} 
                                            style={24}
                                            />
                                        </div>
                                    }
                                </div>
                            </Link>

                            {item.list &&
                                <div className={styles.menuItemList}>
                                    {item.list.map((listItem, index2) => (
                                        (listItem.link && (listItem.link.includes("http") || listItem.link.includes("mailto"))) ?
                                            <a href={listItem.link} target="_blank" rel="noopener noreferrer">
                                                <div className={`flex-row padding-around padding-left-32 ${styles.innerItemContainer}`} style={{ color: listItem.color ? listItem.color : undefined }}>
                                                    {listItem.element && listItem.element}
                                                    
                                                    {!listItem.element && 
                                                        <div className='flex-center' style={{ width: 25 }}>
                                                            <Icon icon={listItem.icon} size={20} />
                                                        </div>
                                                    }

                                                    <h5 className="margin-left-12">{listItem.label}</h5>
                                                </div>
                                            </a>
                                        :
                                            <Link
                                            to={listItem.link}
                                            onClick={(e) => {
                                                if (listItem.onClick) {
                                                    e.preventDefault()
                
                                                    listItem.onClick()
                                                }
                                            }}
                                            >
                                                <div className={`flex-row padding-around padding-left-32 ${styles.innerItemContainer}`} style={{ color: listItem.color ? listItem.color : undefined }}>
                                                    {listItem.element && listItem.element}
                                                    
                                                    {!listItem.element && 
                                                        <div className='flex-center' style={{ width: 25 }}>
                                                            <Icon icon={listItem.icon} size={20} />
                                                        </div>
                                                    }

                                                    <h5 className="margin-left-12">{listItem.label}</h5>
                                                </div>
                                            </Link>
                                    ))}
                                </div>
                            }
                        </div>
                    ))}

                    {!isLoggedIn && !onIOS && !onANDROID &&
                    <>
                        <Button
                        size="large"
                        text="Sign up - completely free"
                        className="margin-top-24 margin-left-24 margin-right-24"
                        onClicked={() => {
                            openPopup("SignUp")
                        }}
                        />

                        <Button
                        type='secondary'
                        size="large"
                        text="Log in"
                        className="margin-top-8 margin-left-24 margin-right-24"
                        onClicked={() => {
                            openPopup("Login")
                        }}
                        />
                    </>
                    }
                    
                    {!isLoggedIn && onIOS &&
                        <div className="flex-center margin-top-24 margin-left-24 margin-right-24">
                            <a target={"_blank"} href={appStoreUrl} className={styles.badgeWrapper}>
                                <img src={appStoreBadge} height={54} alt="App Store badge" />
                                <span className={styles.badgeTitle}>Download Musicboard on App Store</span>
                            </a>
                        </div>
                    }
                    
                    {!isLoggedIn && onANDROID &&
                        <div className="flex-center margin-top-24 margin-left-24 margin-right-24">
                            <a target={"_blank"} href={googlePlayUrl} className={styles.badgeWrapper}>
                                <img src={googlePlayBadge} height={54} alt="Google Play badge" />
                                <span className={styles.badgeTitle}>Download Musicboard on Google Play</span>
                            </a>
                        </div>
                    }

                    <div className={`${styles.menuSocialWrapper}`}>
                        <a href={"https://x.com/musicboardapp"} target="_blank" rel="noreferrer noopener" className={`${styles.menuSocialA}`}>
                            <div className={`${styles.menuSocial} ${styles.twitter}`} />
                        </a>
                        
                        <a href={"https://instagram.com/musicboard.app"} target="_blank" rel="noreferrer noopener" className={`${styles.menuSocialA}`}>
                            <div className={`${styles.menuSocial} ${styles.instagram}`} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
