import styles from './healthcheck.module.css'

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { status } from '../../constants/action-types'
import { getHealthCheck } from '../../actions/auth-actions'
import NavBar from '../../shared/NavBar'
import Button from '../../components/Button'
import { useMediaQuery } from 'react-responsive'
import DefaultHelmet from '../DefaultHelmet'

export default function HealthCheck({ children }) {
    const dispatch = useDispatch()

    const healthCheck = useSelector(state => state.auth.healthCheck)

    useEffect(() => {
        dispatch(
            getHealthCheck()
        )
    }, [])

    const isMinimum = useMediaQuery({ maxWidth: 490 })

    if (healthCheck.status === status.ERROR) {
        return (
            <div>
                <DefaultHelmet />
                
                <div style={{ opacity: 0.5, pointerEvents: 'none' }}>
                    <NavBar />
                </div>
                
                <div className="full-height">
                    <div className="full-center flex-column">
                        <div className={`${styles.tools}`} />

                        <p
                        style={{ fontSize: 16, lineHeight: '22px' }}
                        className="margin-top-40 margin-left-32 margin-right-32 text-center highDarkGrey"
                        >
                            {/* `Could not connect to the Musicboard servers.${!isMinimum ? "\n" : " "}We should be up and running again very soon.` */}
                            {`We're currently doing scheduled maintenance on Musicboard.${!isMinimum ? "\n" : " "}Server will be back online later today.${!isMinimum ? "\n" : " "}Check back soon!`}
                        </p>

                        <Button
                        className="margin-top-40"
                        //text="Try Again"
                        text="Refresh"
                        onClicked={() => {
                            window.location.reload()
                        }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        children
    )
}