import styles from './searchtrack.module.css'

import React from 'react'
import AlbumCover from '../../../../components/AlbumCover'
import Icon from '../../../../components/Icon'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import ContentOptionsTooltip from '../../../../components/ContentOptionsTooltip'
import { checkDeezerId } from '../../../../utils/deezer-utils'

export default function SearchTrack({ track, style, size=88, className, overrideClick, active, useContentOptions, overwriteButton }) {

    //Responsive
    const isTablet = useMediaQuery({ maxWidth: 768 })

    return (
        <div className={`${styles.container} link-wrapper content-options-wrapper cursor ${className} ${active ? styles.active : ""}`} style={style}>
            <Link
            className="link-overlay"
            onClick={(e) => {
                if (overrideClick) {
                    e.preventDefault()
                    overrideClick()
                }
            }}
            to={checkDeezerId(track.album) ? `/album/${track.album.id}?tracks=${track.id}&dz` : `${track.album.url_slug}?tracks=${track.id}`}
            >
                <span className='link-overlay-title'>{track.title}</span>
            </Link>

            <div className="flex-row link-inner">
                <Link
                className="link-clickable"
                onClick={(e) => {
                    if (overrideClick) {
                        e.preventDefault()
                        overrideClick()
                    }
                }}
                to={checkDeezerId(track.album) ? `/album/${track.album.id}?tracks=${track.id}&dz` : `${track.album.url_slug}?tracks=${track.id}`}
                >
                    <AlbumCover size={isTablet ? 56 : size} clickable albumCover={track.album.cover_medium || track.album.cover} altText={track.title} />
                </Link>
                
                <div className="col-1 margin-left-16">
                    <div className="flex-row">
                        <div className="grid">
                            <h5 className={`${styles.text} black one-line`} style={{marginRight: 6}}>{track.title}</h5>
                        </div>
                        {track.explicit_lyrics &&
                            <Icon icon="explicit" className="margin-right-8" style={{ marginTop: 0 }} color="var(--grey)" size={14} />
                        }
                    </div>
                    <div className="grid margin-top-4">
                        <p className="highDarkGrey one-line">
                            <Link className={styles.artistLink} to={checkDeezerId(track.album) ? `/album/${track.album.id}?dz` : track.album.url_slug}>
                                {track.album.title}
                            </Link>

                            {" • "}

                            <Link className={styles.artistLink} to={checkDeezerId(track.artist) ? `/artist/${track.artist.id}?dz` : track.artist.url_slug}>
                                {track.artist.name}
                            </Link>
                        </p>
                    </div>
                </div>

                {overwriteButton &&
                    <div className='link-clickable margin-left-8'>
                        {overwriteButton}
                    </div>
                }
                
                {useContentOptions && !isTablet &&
                    <div className="link-clickable margin-right-8 margin-left-8">
                        <ContentOptionsTooltip content={track} appendId={`searchartist-`} absoluteDesign={false} />
                    </div>
                }
            </div>
        </div>
    )
}
