import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'

export function handleNested(state, action, nestedName, objectName, unique) {
	//This function is a generalized solution for paginated nested content inside a user.

	let reset = action.meta && action.meta.params ? action.meta.params.reset : false
	let speedup = action.meta && action.meta.params ? action.meta.params.speedup : false
	let limit = action.meta && action.meta.params ? action.meta.params.limit : false

	switch (action.meta.status) {
		case status.BEGIN:
			return {
				...state,
				[objectName]: {
					...state[objectName],
					[unique]: {
						...state[objectName][unique],
						[nestedName]: {
							data: (
								state[objectName][unique] && state[objectName][unique][nestedName] ? 
									state[objectName][unique][nestedName].data
								: 
									[]
							),
							
							count: (
								state[objectName][unique] && state[objectName][unique][nestedName] ? 
									state[objectName][unique][nestedName].count
								: 
									null
							),

							next: null,
							reset: action.meta && action.meta.params ? action.meta.params.reset : false,
							status: status.BEGIN,
						}
					}
				}
			}
		case status.SUCCESS:
			return {
				...state,
				[objectName]: {
					...state[objectName],
					[unique]: {
						...state[objectName][unique],
						[nestedName]: {
							data: (
								reset ? 
									action.payload.results 
								: 
									[ ...state[objectName][unique][nestedName].data, ...action.payload.results ]
							),
							count: action.payload.count,
							next: speedup ? (action.payload.results.length < limit) ? null : action.payload.next : action.payload.next,
							status: status.SUCCESS,
						}
					}
				}
			}
		case status.ERROR:
			return {
				...state,
				[objectName]: {
					...state[objectName],
					[unique]: {
						...state[objectName][unique],
						[nestedName]: {
							...state[objectName][unique][nestedName],
							data: [],
							next: null,
							count: null,
							status: status.ERROR,
						}
					}
				},
				errorMessage: errorToString(action.payload),
			}
	}
}


export function handleReducer(state, action, nestedName) {
	//This function is a generalized solution for handling the status and more for list requests.
	let reset = action.meta && action.meta.params ? action.meta.params.reset : false
	let speedup = action.meta && action.meta.params ? action.meta.params.speedup : false
	let limit = action.meta && action.meta.params ? action.meta.params.limit : false

	switch (action.meta.status) {
		case status.BEGIN:
			return {
				...state,
				[nestedName]: {
					...state[nestedName],
					next: null,
					reset: reset,
					status: status.BEGIN,
					controller: action.meta ? action.meta.controller : null
				},
			}
		case status.SUCCESS:
			return {
				...state,
				[nestedName]: {
					data: reset ? action.payload.results : [ ...state[nestedName].data, ...action.payload.results ],
					count: action.payload.count,
					next: speedup ? (action.payload.results.length < limit) ? null : action.payload.next : action.payload.next,
					status: status.SUCCESS,
					controller: null
				},
			}
		case status.ERROR:
			return {
				...state,
				[nestedName]: {
					...state[nestedName],
					data: [],
					count: null,
					next: null,
					status: status.ERROR,
					controller: null
				},
				errorMessage: errorToString(action.payload)
			}
	}
}

export function handleReducerWithFiltering(state, action, nestedName, compareObjectsFunction=(_old, _new)=>false) {
	//This function is a generalized solution for handling the status and more for list requests.
	let reset = action.meta && action.meta.params ? action.meta.params.reset : false
	let speedup = action.meta && action.meta.params ? action.meta.params.speedup : false
	let limit = action.meta && action.meta.params ? action.meta.params.limit : false

	switch (action.meta.status) {
		case status.BEGIN:
			return {
				...state,
				[nestedName]: {
					...state[nestedName],
					next: null,
					reset: reset,
					status: status.BEGIN,
					controller: action.meta ? action.meta.controller : null
				},
			}
		case status.SUCCESS:
			return {
				...state,
				[nestedName]: {
					data: 
						reset ? 
							action.payload.results 
						: 
							[ 
								...state[nestedName].data, 
								...action.payload.results.filter((_new) => state[nestedName].data.findIndex((_old) => compareObjectsFunction(_old, _new)) === -1) 
							],
					count: action.payload.count,
					next: speedup ? (action.payload.results.length < limit) ? null : action.payload.next : action.payload.next,
					status: status.SUCCESS,
					controller: null
				},
			}
		case status.ERROR:
			return {
				...state,
				[nestedName]: {
					...state[nestedName],
					data: [],
					count: null,
					next: null,
					status: status.ERROR,
					controller: null
				},
				errorMessage: errorToString(action.payload)
			}
	}
}

export function handleNoPaginationReducer(state, action, nestedName) {
	//This function is a generalized solution for handling the status and data for list requests where there is no pagination.

	switch (action.meta.status) {
		case status.BEGIN:
			return {
				...state,
				[nestedName]: {
					...state[nestedName],
					status: status.BEGIN,
					controller: action.meta ? action.meta.controller : null
				},
			}
		case status.SUCCESS:
			return {
				...state,
				[nestedName]: {
					data: action.payload,
					count: action.payload.length,
					status: status.SUCCESS,
					controller: null
				},
			}
		case status.ERROR:
			return {
				...state,
				[nestedName]: {
					...state[nestedName],
					data: [],
					count: null,
					status: status.ERROR,
					controller: null
				},
				errorMessage: errorToString(action.payload)
			}
	}
}