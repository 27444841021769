import { types } from '../constants/action-types'
import { post, put, get, patch, patchRaw, delete_ } from '../utils/fetch'
import { url } from '../config'

export const fetchReview = (uid) => 
    get(types.FETCH_REVIEW, `${url}/v2/reviews/${uid}/`, {});

export const fetchReviewFromSlug = (urlSlug) => 
    get(types.FETCH_REVIEW, `${url}/v2/reviews/from_url_slug/?url_slug=${urlSlug}`, { urlSlug });

export const updateReview = (uid, body) => 
    patch(types.UPDATE_REVIEW, `${url}/v2/reviews/${uid}/`, body, { uid, reset: true });
 
export const deleteReview = (uid) => 
    delete_(types.DELETE_REVIEW, `${url}/v2/reviews/${uid}/`, { uid, reset: true });

export const createReview = (title, description, ratingUid, pinned=false) => 
    post(types.CREATE_REVIEW, `${url}/v2/reviews/`, {
        title,
        description,
        rating_uid: ratingUid,
        pinned
    });


export const getAllReviews = ({
    content_type="",
    content_id="",
    order_by="-created_at",
    pinned="",
    title="",
    has_reports="",
    most_liked=false,
    weekly=false,
    genres__id="",
    styles__id="",
    release_date__year="",
    release_date__lte="",
    release_date__gte="",
    record_type="",
    offset=0,
}) => 
    get(types.GET_ALL_REVIEWS, `${url}/v2/${weekly ? "most_liked_reviews/weekly" : most_liked ? "most_liked_reviews" : "reviews"}/?content_type=${content_type}&content_id=${content_id}&order_by=${order_by}&pinned=${pinned}&title=${title}&has_reports=${has_reports}&genres__id=${genres__id}&styles__id=${styles__id}&release_date__year=${release_date__year}&release_date__lte=${release_date__lte}&release_date__gte=${release_date__gte}&record_type=${record_type}&speedup=true&limit=16&offset=${offset}`, { reset: true, speedup: true, limit: 16 });

//Translations
export const translateReview = (uid, text, from, to) =>
    post(types.TRANSLATE_REVIEW, `${url}/v2/translate/`, { text, from, to }, { uid })

export const detectReviewLanguage = (uid) =>
    get(types.DETECT_REVIEW_LANGUAGE, `${url}/v2/reviews/${uid}/lang_detect/`, { uid })
