import axios from 'axios'
import { corsUrl } from '../config'

const headers = {
    "Content-Type": "application/json",
    "Accept": "application/json",
}

const getBiography = (artistName) => {
    return new Promise((resolve, reject) => {
        const searchUrl = `${corsUrl}/https://musicbrainz.org/ws/2/artist/?query=${artistName}`

        axios.get(searchUrl, { headers: headers })
        .then(function (response) {
            const artist = (response.data && response.data.artists) ?  response.data.artists[0] : null;

            if (artist && artist.name.toLowerCase() === artistName.toLowerCase()) {

                const bioUrl = `${corsUrl}/https://musicbrainz.org/artist/${artist.id}/wikipedia-extract`

                axios.get(bioUrl, { headers: headers })
                .then(function (response) {
                    const bio = (response.data && response.data.wikipediaExtract) ? response.data.wikipediaExtract.content : null;
                    const link = (response.data && response.data.wikipediaExtract) ? response.data.wikipediaExtract.url : null;

                    if (bio) {
                        resolve({ bio, link })
                    } else {
                        reject("No bio found")
                    }
                })
                .catch(function (error) {
                    console.warn('Error with axios request', error);
                    reject(error)
                })

            } else {
                reject("No artist found")

            }

        })
        .catch(function (error) {
            console.warn('Error with axios request', error);
            reject(error)
        })

    })
}

export const useMusicbrainz = () => {
    return {
        getBiography: (artist) => getBiography(artist),
    }
}