import styles from './checkitem.module.css'

import React from 'react'
import moment from 'moment'
import Icon from '../../../../components/Icon'
import ProfilePicture from '../../../../components/ProfilePicture'
import AlbumCover from '../../../../components/AlbumCover'
import Checkbox from '../../../../components/Checkbox'

export default function AlbumCheckItem({ album, selected, onAdd, onRemove, className }) {

    return (
        <div 
        onClick={() => {
            if (selected) {
                onRemove(album)
            } else {
                onAdd(album)
            }
        }}
        className={`${styles.container} cursor ${className}`}
        >
            <div className="flex-row">
                <AlbumCover clickable size={60} albumCover={album.cover_small || album.cover} altText={album.title} />

                <div className={`col-1 margin-left-16 margin-right-16`}>
                    <div className="grid">
                        <h6 className={`${styles.title} one-line`}>
                            {album.title}
                        </h6>
                    </div>
                    
                    <div className="grid margin-top-4">
                        <p className={`${styles.artist} one-line`}>
                            {album.artist.name}
                        </p>
                    </div>
                </div>

                <Checkbox
                checked={selected}
                onChange={() => {
                    if (selected) {
                        onRemove(album)
                    } else {
                        onAdd(album)
                    }
                }}
                />
            </div>
        </div>
    )
}
