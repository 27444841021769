import React from "react"
import ContentLoader from "react-content-loader"

export default function BiographyLoader(props) {
    return(
        <ContentLoader 
        speed={2}
        width={280}
        height={100}
        backgroundColor="var(--lightGrey)"
        foregroundColor="var(--darkLightGrey)"
        {...props}
        >
            <rect x="0" y="7" rx="2" ry="2" width="280" height="6" /> 
            <rect x="0" y="27" rx="2" ry="2" width="280" height="6" /> 
            <rect x="0" y="47" rx="2" ry="2" width="280" height="6" />
            <rect x="0" y="67" rx="2" ry="2" width="280" height="6" />
            <rect x="0" y="87" rx="2" ry="2" width="120" height="6" />
        </ContentLoader>
    )
}