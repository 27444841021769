import styles from './smallbackenditem.module.css'

import React from 'react'
import AlbumCover from '../AlbumCover'
import ProfilePicture from '../ProfilePicture'
import Icon from '../Icon'
import Stars from '../Stars'
import { oneDecimal } from '../../utils/format-text'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import ContentOptionsTooltip from '../ContentOptionsTooltip'
import Tooltip from '../Tooltip'
import { usePopup } from '../../hooks/popup/popup'
import { useSelector } from 'react-redux'
import moment from 'moment'

function SmallBackendItem({
    className,
    textClassName,
    style={},
    textStyle={},
    width='100%',
    hideText,
    size=60,
    content,
    useNumbers, 
    number, 
    rating, 
    ratingObj,
    showArtistName,
    showReleaseDate,
    showAverageRating,
    hideArtistOnMobile,
    skipContentTooltip,
    newTab
}) {
    //General
    const { openPopup } = usePopup()

    //Responsive
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    
    let href = ""
    if(ratingObj) {
        if (ratingObj.review_url_slug) {
            href = ratingObj.review_url_slug
        } else if (ratingObj.review_uid) {
            href = `/review/${ratingObj.review_uid}`
        } else {
            href = `/rating/${ratingObj.uid}`
        }
    } else {
        if(content.type === "artist") {
            href = content.url_slug
        }
        if(content.type === "track") {
            href = `${content.album.url_slug}?tracks=${content.id}`
        }
        if(content.type === "album") {
            href = content.url_slug
        }
    }

    //Blocks
    const myBlocks = useSelector(state => state.me.blocks)
    const cachedBlocks = useSelector(state => state.me.cachedBlocks)
    const blocks = [...myBlocks, ...cachedBlocks]
    const blockObj = blocks.findIndex(block => ratingObj && block.blocked_user === ratingObj.creator.uid) !== -1 ? blocks[blocks.findIndex(block => ratingObj && block.blocked_user === ratingObj.creator.uid)] : null

    //blockedBy
    const blockedByObj = useSelector(state => 
        state.me.blockedBy && state.me.blockedBy.find(block => ratingObj && block.creator === ratingObj.creator.uid)
    )

    if (blockedByObj) {
        return null
    }

    if (blockObj) {
        return null
    }

    return (
        <div className={`${styles.container} link-wrapper content-options-wrapper cursor ${className}`} style={style}>
            <Link to={href} target={newTab && "_blank"} className="link-overlay">
                <span className='link-overlay-title'>{content.title}</span>
            </Link>

            <div className="flex-row link-inner">
                <div className={styles.center}>
                    <Link to={href} target={newTab && "_blank"} className="link-clickable">
                        {content.type === "track" &&
                            <AlbumCover clickable size={size} albumCover={content.album.cover_small || content.album.cover} altText={content.title} />
                        }
                        
                        {content.type === "album" &&
                            <AlbumCover clickable size={size} albumCover={content.cover_small || content.cover} altText={content.title} />
                        }

                        {content.type === "artist" &&
                            <ProfilePicture clickable size={size} profilePicture={content.picture_small || content.picture} altText={content.name} />
                        }
                    </Link>
                </div>

                {!hideText &&
                    <div className={`${styles.alignCenter} margin-left-16`} style={{ width: width }}>
                        <div className="flex-row">
                            <div className="grid">
                                <h6 className={`${styles.title} one-line ${textClassName}`} style={{ ...textStyle, marginRight: 6 }}>
                                    {useNumbers && <span>{number}. </span>}
                                    {content.type === "artist" ? content.name : content.title}
                                </h6>
                            </div>

                            {content.explicit_lyrics &&
                                <Icon icon="explicit" className="margin-right-8" style={{ marginTop: 1 }} color="var(--grey)" size={14} />
                            }
                        </div>
                        
                        {!rating && (!hideArtistOnMobile || !isMobile) && content.type !== "artist" && showArtistName &&
                            <div className="grid">
                                <p className={`${styles.artist} margin-top-4 one-line`}>
                                    <Link className={styles.artistLink} to={content.artist.url_slug}>
                                        {content.artist.name}
                                    </Link>
                                    {content.release_date && showReleaseDate && ` • ${moment(content.release_date).format("MMMM D")}`}
                                </p>
                            </div>
                        }

                        {(rating || (ratingObj && ratingObj.first_listen === false)) &&
                            <div className="flex-row margin-top-4">
                                <Stars rating={rating} iconSize={16} className="margin-right-4" />

                                {ratingObj.first_listen === false &&
                                    <Icon icon="sync" size={15} className="margin-right-4" style={{ transform: "rotate(45deg)" }} color={"var(--grey)"} />
                                }

                                {(ratingObj.private || ratingObj.private_locked) &&
                                    <Icon icon="lock" size={15} className="margin-right-4" color={"var(--grey)"} />
                                }

                                {(ratingObj.review_url_slug || ratingObj.review_uid) &&
                                    <Icon icon="subject" size={16} color={"var(--grey)"} />
                                }
                            </div>
                        }

                        {showAverageRating && isMobile &&
                            <div className="flex-row cursor margin-top-4" style={{ marginBottom: -1 }}>
                                <Icon icon="star" size={16} color={"var(--yellow)"} className={"margin-right-8"} />

                                <p className="black">{oneDecimal(content.average_rating/2)} <span className="grey">/ 5</span></p>
                            </div>
                        }
                    </div>
                }
                
                {!isMobile && !skipContentTooltip &&
                    <div className="link-clickable margin-right-8 margin-left-8">
                        <ContentOptionsTooltip content={content} appendId={`smallbackenditem-`} absoluteDesign={false} />
                    </div>
                }
                
                {showAverageRating && !isMobile &&
                    <div
                    onClick={() => {
                        if (!isLoggedIn) {
                            openPopup("SignUp")
                            return;
                        }
        
                        openPopup("CreateReview", { content: content, cacheRating: false })
                    }} 
                    id={`smallbackenditem-${content.type}-${content.id}-stars`} 
                    className="flex-row cursor margin-left-16 link-clickable"
                    >
                        <Icon icon="star" size={16} color={"var(--yellow)"} className={"margin-right-8"} />
        
                        <div className={styles.ratingContainer}>
                            <p className="black">{oneDecimal(content.average_rating/2)} <span className="grey">/ 5</span></p>
                        </div>
                    </div>
                }

                <Tooltip id={`smallbackenditem-${content.type}-${content.id}-stars`} text={`Rate this ${content.type}`} offset={-6} />
            </div>
        </div>
    )
}

export default React.memo(SmallBackendItem)