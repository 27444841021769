import React, { useEffect, useState, useContext, useReducer } from 'react'
import { usePopup } from './popup/popup';

const ScrollbarContext = React.createContext();

const rules = [
    `
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    `,
    `
    ::-webkit-scrollbar-track {
        background-color: var(--lightGrey);
        border-radius: var(--border-radius-small);
    }
    `,
    `
    ::-webkit-scrollbar-thumb {
        background-color: var(--darkLightGrey);
        border-radius: var(--border-radius-small);
    }
    `,
    `
    ::-webkit-scrollbar-thumb:hover {
        background-color: var(--highDarkGrey);
    }
    `,
]

export const useScrollbar = () => {
    const { state, dispatch } = useContext(ScrollbarContext)

    return {
        ...state,
        setScrollDisabled: (value) => dispatch({
            type: "SET_SCROLL_DISABLED",
            payload: value
        })
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_SCROLL_DISABLED":
            return {
                ...state,
                scrollDisabled: action.payload,
            }
        case "SET_HAS_SCROLLBAR":
            return {
                ...state,
                hasScrollbar: action.payload,
            }
        default:
            throw new Error()
    }
}

const defaultScrollbar = { 
    scrollDisabled: false,
    hasScrollbar: false
}
  
const ScrollbarProvider = ({ init, children }) => {
    const [ state, dispatch ] = useReducer(reducer, defaultScrollbar);

    const { hasScrollbar, scrollDisabled } = state;

    useEffect(() => {
        const hasScrollbar = window.innerWidth - document.documentElement.clientWidth > 0;

        if (hasScrollbar) {
            dispatch({
                type: "SET_HAS_SCROLLBAR",
                payload: true
            })

            try {
                rules.forEach((rule, index) => {
                    document.styleSheets[2].insertRule(rule, 0);
                })
            }
            catch(error) {
                console.warn("error")
            }
        }
    }, [])

    return (
        <ScrollbarContext.Provider value={{ state, dispatch }}>

            <div className="flex-row" style={{ minHeight: "inherit" }}>
                <div className="col-1 flex-column" style={{ minHeight: "inherit" }}>
                    {children}
                </div>

                {scrollDisabled && hasScrollbar &&
                    <div style={{ width: 10 }} />
                }
                {scrollDisabled && hasScrollbar &&
                    <div style={{ position: 'absolute', top: 0, background: 'var(--lightGrey)', width: 10, height: '100vh', right: 0 }} />
                }
            </div>

        </ScrollbarContext.Provider>
    )
}
export default ScrollbarProvider;
