import styles from './useritem.module.css'

import React, { useState } from 'react'


import ProfilePicture from '../ProfilePicture'
import VerifiedIcon from '../VerifiedIcon'
import Button from '../Button'
import { useDispatch, useSelector } from 'react-redux'
import { usePopup } from '../../hooks/popup/popup'
import { followUser, unfollowUser, unblockUser } from '../../actions/users-actions'
import { status } from '../../constants/action-types'
import ProIcon from '../ProIcon'
import { Link } from 'react-router-dom'
import UserProfilePicture from '../UserProfilePicture'
import { useTrack } from '../../hooks/tracking'

function UserItem({ 
    user,
    lighterStyle,
    newTab,
    active,
    size=48,
    overwriteButton,
    onClick,
    nameComponent
}) {
    //General
    const dispatch = useDispatch()
    const { openPopup } = usePopup()
    const track = useTrack()

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const myUid = useSelector(state => state.auth.uid);
    const isMe = user.uid === myUid;

    //followObj
    const lastUnfollowUserUid = useSelector(state => state.users.lastUnfollowUserUid)
    const unfollowUserStatus = useSelector(state => state.users.unfollowUserStatus)
    const myFollows = useSelector(state => state.me.follows)
    const cachedFollows = useSelector(state => state.me.cachedFollows)
    const follows = [ ...myFollows, ...cachedFollows ]
    const followIndex = follows.findIndex(follow => follow.followed_user === user.uid)
    const followObj = followIndex !== -1 ? follows[followIndex] : null

    //blockObj
    const lastUnblockUserUid = useSelector(state => state.users.lastUnblockUserUid)
    const unblockUserStatus = useSelector(state => state.users.unblockUserStatus)
    const myBlocks = useSelector(state => state.me.blocks)
    const cachedBlocks = useSelector(state => state.me.cachedBlocks)
    const blocks = [ ...myBlocks, ...cachedBlocks ]
    const blockIndex = blocks.findIndex(block => block.blocked_user === user.uid)
    const blockObj = blockIndex !== -1 ? blocks[blockIndex] : null

    //blockedBy
    const blockedByObj = useSelector(state => 
        state.me.blockedBy && state.me.blockedBy.find(block => block.creator === user.uid)
    )

    if (blockedByObj) {
        return null
    }
    
    return (
        <div className={`link-wrapper ${styles.container} ${lighterStyle ? styles.lighterStyle : ""} ${active ? styles.active : ""}`}>
            <Link 
            to={`/${user.username}`} 
            className="link-overlay" 
            target={newTab ? "_blank" : null} 
            onClick={(e) => {
                if (onClick) {
                    e.preventDefault()
                    onClick(user)
                }
            }}
            >
                <span className='link-overlay-title'>{user.name !== "" ? user.name : user.username}</span>
            </Link>

            <div className={`flex-row justify-between cursor link-inner`}>
                <div className="flex-row margin-right-32">
                    <Link 
                    to={`/${user.username}`} 
                    target={newTab ? "_blank" : null} 
                    onClick={(e) => {
                        if (onClick) {
                            e.preventDefault()
                            onClick(user)
                        }
                    }}
                    >
                        <UserProfilePicture
                        clickable 
                        size={size} 
                        profilePicture={user.profile_picture?.includes(".gif") ? user.profile_picture : user.profile_picture_small || user.profile_picture} 
                        profileFrame={user.is_pro && user.tier === 1 ? user.profile_frame : null} 
                        className="link-clickable" 
                        altText={user.name !== "" ? user.name : user.username}
                        />
                    </Link>

                    <div className="margin-left-16">
                        <div className="flex-row" style={{ display: 'inline-flex' }}>
                                <div className="grid">
                                    <h5 className={`${styles.text} black one-line`} style={{ }}>{user.username}</h5>
                                </div>
                                
                                {(user.is_pro || user.is_moderator || user.contributor_tier) &&
                                    <ProIcon 
                                    size={"small"} 
                                    className="margin-left-8" 
                                    is_pro={user.is_pro}
                                    is_moderator={user.is_moderator}
                                    contributor_tier={user.contributor_tier}
                                    />
                                }

                                {user.is_verified &&
                                    <VerifiedIcon size={12} className="margin-left-8" />
                                }
                        </div>

                        {user.name !== "" && !nameComponent && 
                            <div className="grid">
                                <p className="highDarkGrey margin-top-2 one-line">{user.name}</p>
                            </div>
                        }

                        {nameComponent && nameComponent}
                    </div>
                </div>

                <div className="link-clickable">
                    {!isMe && !blockObj && !overwriteButton &&
                        <Button
                        text={followObj ? "Following" : "Follow"}
                        type={followObj ? "secondary-two" : "primary"}
                        height={34}
                        style={
                            followObj ? {
                                width: 110,
                            } : 
                            {
                                width: 112 
                            }
                        }
                        loading={unfollowUserStatus === status.BEGIN && lastUnfollowUserUid === user.uid}
                        textStyle={followObj ? { color: "var(--black)", fontSize: 13 } : { fontSize: 14 }}
                        onClicked={() => {
                            if (!isLoggedIn) {
                                openPopup("SignUp")
                                return;
                            }

                            if (followObj) {
                                dispatch(
                                    unfollowUser(followObj.uid, user.uid)
                                )
                            } else {
                                track(
                                    "Follow user", 
                                    { 
                                        "user_uid": user.uid,
                                        "referrer": "UserItem",
                                    }
                                );

                                dispatch(
                                    followUser(user.uid)
                                )
                            }

                        }}
                        />
                    }

                    {!isMe && isLoggedIn && blockObj && !overwriteButton &&
                        <Button
                        text="Unblock"
                        height={34}
                        style={{ width: 110 }}
                        textStyle={{ fontSize: 13 }}
                        loading={unblockUserStatus === status.BEGIN && lastUnblockUserUid === user.uid}
                        onClicked={() => {
                            dispatch(
                                unblockUser(blockObj.uid, user.uid)
                            )
                        }}
                        />
                    }

                    {overwriteButton && overwriteButton}
                </div>
            </div>
        </div>
    )
}

export default React.memo(UserItem)