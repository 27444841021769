import styles from './featuredprofilesection.module.css';

import React from 'react'
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

import { status } from '../../../../constants/action-types';

import { followUser, unblockUser, unfollowUser } from '../../../../actions/users-actions';

import { useTrack } from '../../../../hooks/tracking';
import { usePopup } from '../../../../hooks/popup/popup';

import { checkNull } from '../../../../utils/format-text';

import Button from '../../../Button';
import ProIcon from '../../../ProIcon';
import VerifiedIcon from '../../../VerifiedIcon';
import UserProfilePicture from '../../../UserProfilePicture';

export default function FeaturedProfileSection({ user }) {
    //General
    const dispatch = useDispatch()
    const { openPopup } = usePopup()
    const track = useTrack()

    //Responsive
    const isBigScreen = useMediaQuery({ maxWidth: 1150 })

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const myUid = useSelector(state => state.auth.uid);
    const isMe = user.uid === myUid;

    //followObj
    const lastUnfollowUserUid = useSelector(state => state.users.lastUnfollowUserUid)
    const unfollowUserStatus = useSelector(state => state.users.unfollowUserStatus)
    const myFollows = useSelector(state => state.me.follows)
    const cachedFollows = useSelector(state => state.me.cachedFollows)
    const follows = [ ...myFollows, ...cachedFollows ]
    const followIndex = follows.findIndex(follow => follow.followed_user === user.uid)
    const followObj = followIndex !== -1 ? follows[followIndex] : null

    //blockObj
    const lastUnblockUserUid = useSelector(state => state.users.lastUnblockUserUid)
    const unblockUserStatus = useSelector(state => state.users.unblockUserStatus)
    const myBlocks = useSelector(state => state.me.blocks)
    const cachedBlocks = useSelector(state => state.me.cachedBlocks)
    const blocks = [ ...myBlocks, ...cachedBlocks ]
    const blockIndex = blocks.findIndex(block => block.blocked_user === user.uid)
    const blockObj = blockIndex !== -1 ? blocks[blockIndex] : null

    //blockedBy
    const blockedByObj = useSelector(state => 
        state.me.blockedBy && state.me.blockedBy.find(block => block.creator === user.uid)
    )

    if (blockedByObj) {
        return null
    }

    return (
        <div className={`bottom-divider-bright margin-bottom-16 padding-bottom-16 flex-row`}>
            <Link to={`/${user.username}`} >
                <UserProfilePicture
                clickable 
                size={48} 
                profilePicture={user.profile_picture?.includes(".gif") ? user.profile_picture : user.profile_picture_small || user.profile_picture} 
                profileFrame={user.is_pro && user.tier === 1 ? user.profile_frame : null} 
                altText={user.name !== "" ? user.name : user.username} 
                />
            </Link>

            <div className="col-1 margin-left-16">
                <Link to={`/${user.username}`} className={`${styles.text}`}>
                    <div className="flex-row" style={{ display: 'inline-flex' }}>
                        <div className="grid">
                            <h5 className={`one-line`} style={{ }}>{user.username}</h5>
                        </div>
                        {(user.is_pro || user.is_moderator || user.contributor_tier) &&
                            <ProIcon
                            size={"small"} 
                            className="margin-left-8" 
                            is_pro={user.is_pro}
                            is_moderator={user.is_moderator}
                            contributor_tier={user.contributor_tier}
                            />
                        }

                        {user.is_verified &&
                            <VerifiedIcon size={12} className="margin-left-8" />
                        }
                    </div>
                </Link>
                        

                {user.name !== "" &&
                    <div className="grid">
                        <p className="highDarkGrey margin-top-4 one-line">{user.name}</p>
                    </div>
                }
            </div>

            {!isBigScreen &&
                <div className='flex-row'>
                    <div className="grid margin-right-24">
                        <h6 className="black text-center one-line">{checkNull(user.counts.review_set)}</h6>

                        <p className="highDarkGrey margin-top-4 text-center one-line">Reviews</p>
                    </div>

                    <div className='border-right-bright margin-right-24' style={{ height: 20 }} />

                    <div className="grid margin-right-24">
                        <h6 className="black text-center one-line">{checkNull(user.counts.rating_set)}</h6>

                        <p className="highDarkGrey margin-top-4 text-center one-line">Listened</p>
                    </div>

                    <div className='border-right-bright margin-right-24' style={{ height: 20 }} />

                    <div className="grid margin-right-24">
                        <h6 className="black text-center one-line">{checkNull(user.counts.followed_by)}</h6>

                        <p className="highDarkGrey margin-top-4 text-center one-line">Followers</p>
                    </div>
                    
                    <div className='border-right-bright margin-right-24' style={{ height: 20 }} />
                </div>
            }

            <div className="link-clickable">
                {!isMe && !blockObj &&
                    <Button
                    text={followObj ? "Following" : "Follow"}
                    type={followObj ? "secondary-two" : "primary"}
                    height={34}
                    style={
                        followObj ? {
                            width: 110,
                        } : 
                        {
                            width: 112 
                        }
                    }
                    loading={unfollowUserStatus === status.BEGIN && lastUnfollowUserUid === user.uid}
                    textStyle={followObj ? { color: "var(--black)", fontSize: 13 } : { fontSize: 14 }}
                    onClicked={() => {
                        if (!isLoggedIn) {
                            openPopup("SignUp")
                            return;
                        }

                        if (followObj) {
                            dispatch(
                                unfollowUser(followObj.uid, user.uid)
                            )
                        } else {
                            track(
                                "Follow user", 
                                { 
                                    "user_uid": user.uid,
                                    "referrer": "UserItem",
                                }
                            );

                            dispatch(
                                followUser(user.uid)
                            )
                        }

                    }}
                    />
                }

                {!isMe && isLoggedIn && blockObj &&
                    <Button
                    text="Unblock"
                    height={34}
                    style={{ width: 110 }}
                    textStyle={{ fontSize: 13 }}
                    loading={unblockUserStatus === status.BEGIN && lastUnblockUserUid === user.uid}
                    onClicked={() => {
                        dispatch(
                            unblockUser(blockObj.uid, user.uid)
                        )
                    }}
                    />
                }
            </div>
        </div>
    )
}
