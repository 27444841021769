import styles from './albuminformationsection.module.css'
import moment from 'moment'
import React from 'react'

import { secondsToFormattedTime } from '../../../../utils/format-time'
import { displayRecordType } from '../../../../utils/record-type-utils'
import { Link } from 'react-router-dom'
import { usePopup } from '../../../../hooks/popup/popup'

export default function AlbumInformationSection({ album }) {
    const { openPopup } = usePopup()
    
    const hasAnyData = (
        album.release_date ||
        Boolean(album.duration) ||
        Boolean(album.country) ||
        Boolean(album.label) ||
        album.explicit_lyrics ||
        //album.contributors.length > 0 ||
        album.genres.length > 0 ||
        album.styles.length > 0
    )

    return (
        <div style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8, overflow: 'hidden' }}>
            {hasAnyData &&
                <div>
                    {/* Release Date */}
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Release Date</h5>
                        </div>
                        
                        <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                            {Boolean(album.release_date) ? 
                                moment(album.release_date).format("MMMM DD, YYYY")
                            : 
                                <Link 
                                className={styles.link}
                                to={`/album/${album.id}/edit`}
                                >
                                    {`Add a release date to this album...`}
                                </Link>
                            }
                        </p>
                    </div>
                    
                    {Boolean(album.duration) && !album.duration.includes(":") && // Duration
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Duration</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {Boolean(album.duration) && !album.duration.includes(":") ? 
                                    secondsToFormattedTime(album.duration)
                                : 
                                    <Link 
                                    className={styles.link}
                                    to={`/album/${album.id}/edit`}
                                    >
                                        {`How long is this release?`}
                                    </Link>
                                }
                            </p>
                        </div>
                    }

                    {/* Country */}
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Country</h5>
                        </div>
                        
                        <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                            {Boolean(album.country) ? 
                                album.country
                            : 
                                <Link 
                                className={styles.link}
                                to={`/album/${album.id}/edit`}
                                >
                                    {`Contribute by adding a country...`}
                                </Link>
                            }
                        </p>
                    </div>

                    {Boolean(album.label) && // Label
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Label</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {album.label}
                            </p>
                        </div>
                    }

                    {album.explicit_lyrics !== null && // Explicit Lyrics
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Explicit Lyrics</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {album.explicit_lyrics ? "Yes" : "No"}
                            </p>
                        </div>
                    }

                    {/* Genres */}
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Genres</h5>
                        </div>
                        
                        <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                            {album.genres && album.genres.length > 0 ? 
                                album.genres.map((genre, index) => (
                                    <span>
                                        <Link 
                                        to={`/albums?genre=${genre.id}`}
                                        className={styles.link}
                                        >
                                            {genre.name}
                                        </Link>
                                        {index !== album.genres.length-1 && ", "}
                                    </span>
                                ))
                            : 
                                <Link 
                                className={styles.link}
                                to={`/album/${album.id}/edit`}
                                >
                                    {`Add genres to this ${displayRecordType(album.record_type, true)}...`}
                                </Link>
                            }
                        </p>
                    </div>

                    {/* Styles */}
                    <div className={`padding-around ${styles.container}`}>
                        <div className="grid">
                            <h5 className="black one-line" style={{ fontSize: 15 }}>Styles</h5>
                        </div>
                        
                        <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                            {album.styles && album.styles.length > 0 ? 
                                album.styles.map((style, index) => (
                                    <span>
                                        <Link 
                                        to={`/albums?style=${style.id}`}
                                        className={styles.link}
                                        >
                                            {style.name}
                                        </Link>
                                        {index !== album.styles.length-1 && ", "}
                                    </span>
                                ))
                            : 
                                <Link 
                                className={styles.link}
                                to={`/album/${album.id}/edit`}
                                >
                                    {`Add styles to this ${displayRecordType(album.record_type, true)}...`}
                                </Link>
                            }
                        </p>
                    </div>
                </div>
            }
        </div>
    )
}
