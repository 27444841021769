import { get, post } from "../utils/fetch";
import { status, types } from "../constants/action-types";
import { url, corsUrl } from "../config";
import { getNormal } from "../utils/fetch-normal";
import moment from "moment";

export const paginationNext = (url, type, params = {}) => 
    get(
        type,
        /* 
        The url is handled below.
        Since the production server requires HTTPS, but the server can return HTTP with its pagination
        we check if it is local or production, then add HTTPS to all calls to production.
        */
        `${process.env.REACT_APP_STAGE === "development" ? `${corsUrl}/` : ""}${url.includes(":8000") ? url : url.replace("http://", "https://")}`,
        params
    )

export const reportUserContent = (contentType, contentUid, reason, description) => 
    post(types.REPORT_USER_CONTENT, `${url}/v2/reports/`, {
        content_type: contentType,
        content_uid: contentUid,
        reason,
        description
    }, {
        uid: contentUid
    })

//Multi Url Fetching
export const fetchMultipleUrls = (urls, type, sortField, params) => (dispatch, getState) => {
    //Send BEGIN
    dispatch({ type: type, meta: { status: status.BEGIN, params: { reset: true, ...params } } });

    Promise.all(urls.map((url) => getNormal(url)))
    .then((responses) => {
        //Get content from results
        const payload = {
            next: responses.flatMap((item) => item.next).filter(i => i).length > 0 ? responses.flatMap((item) => item.next) : null,
            results: responses.flatMap((item) => item.results).sort((a, b) => moment(a[sortField]).isAfter(b[sortField]) ? -1 : 1),
        }
        
        //Send SUCCESS
        dispatch({ type: type, meta: { status: status.SUCCESS, params: { reset: true, ...params } }, payload: payload });

    })
    .catch((err) => {
        //Send ERROR
        dispatch({ type: type, meta: { status: status.ERROR, params: { reset: true, ...params } }, payload: err });
    });
}

export const paginationForMultipleUrls = (urls, type, sortField, params) => (dispatch, getState) => {
    console.log("BEGINBEGIN")
    //Send BEGIN
    dispatch({ type: type, meta: { status: status.BEGIN, params: { ...params } } });

    Promise.all(
        urls.map(url => 
            url ? 
            `${process.env.REACT_APP_STAGE === "development" ? `${corsUrl}/` : ""}${url.includes(":8000") ? url : url.replace("http://", "https://")}`
            : 
                null
        ).filter(f => f)
        .map((url) => getNormal(url))
    )
    .then((responses) => {
        console.log({responses})
        //Get content from results
        const payload = {
            next: responses.flatMap((item) => item.next).filter(i => i).length > 0 ? responses.flatMap((item) => item.next) : null,
            results: responses.flatMap((item) => item.results).sort((a, b) => moment(a[sortField]).isAfter(b[sortField]) ? -1 : 1),
        }

        console.log({payload})

        //Send SUCCESS
        dispatch({ type: type, meta: { status: status.SUCCESS, params: { ...params } }, payload: payload });

    })
    .catch((err) => {
        console.log({err})
        //Send ERROR
        dispatch({ type: type, meta: { status: status.ERROR, params: { ...params } }, payload: err });
    });
}