import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { status } from '../../constants/action-types'

import { getAllListDrafts, getAllReviewDrafts } from '../../actions/drafts-actions'

import { useAlert } from '../../hooks/alert/alert'
import { usePopup } from '../../hooks/popup/popup'
import { useStatusHandler } from '../../hooks/status-handler'

import { firstLetterCapital } from '../../utils/captialization'
import { logOut } from '../../actions/auth-actions'

export default function StatusAlertHandler(props) {
    //General
    const dispatch = useDispatch()
    const location = useLocation()
    const { openAlert } = useAlert()
    const { openPopup } = usePopup()

    //Auth
    const myUid = useSelector(state => state.auth.uid)
    const username = useSelector(state => state.me.username)

    //Me data
    const deleteAccountStatus = useSelector(state => state.me.deleteAccountStatus)

    //Handle delete account status
    useStatusHandler({
        effectStatus: deleteAccountStatus,
        successCallback: () => {
            dispatch(
                logOut()
            )

            setTimeout(() => {
                document.location.href = "/?deleted=true"
            }, 100)
        },
        errorCallback: () => {
            openAlert({
                p: "Could not delete your account. Try again or contact us for assistance.",
                style: "red",
                timer: 5000
            })
        }
    })
    
    //Payments
    const portalSessionUrl = useSelector(state => state.payments.portalSessionUrl)
    const createPortalSessionStatus = useSelector(state => state.payments.createPortalSessionStatus)
    const proGifts = useSelector(state => state.payments.proGifts)

    //Status for portal
    useStatusHandler({
        effectStatus: createPortalSessionStatus,
        successCallback: () => {
            document.location.href = portalSessionUrl;
        },
        errorCallback: () => {
            openAlert({ p: "Could not open Stripe portal. Please contact us.", style: "red", timer: 4000 })
        }
    })

    //Status for gifts
    const giftParam = new URLSearchParams(location.search).get("gift")

    useStatusHandler({
        effectStatus: proGifts?.status,
        successCallback: () => {
            const proGift = proGifts?.data.find((item) => item.user_read.uid === myUid && item.is_valid && !item.seen)
            if (proGift) {
                openPopup("AcceptGift", { gift: proGift })
            } else {
                if (giftParam === "true") {
                    const latestGift = 
                        proGifts?.data
                        .filter((item) => item.creator.uid === myUid)
                        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                        [0]

                    if (latestGift) {
                        setTimeout(() => {
                            openPopup("SentGiftInfo", { gift: latestGift })
                        }, 300)
                    }
                }
            }
        },
    })
    
    //Comments Data
    const createCommentStatus = useSelector(state => state.comments.createCommentStatus)
    const updateCommentStatus = useSelector(state => state.comments.updateCommentStatus)
    const deleteCommentStatus = useSelector(state => state.comments.deleteCommentStatus)
    const commentsErrorMessage = useSelector(state => state.comments.errorMessage)
    
    //Create Comment
    useStatusHandler({
        effectStatus: createCommentStatus,
        errorCallback: () => {
            openAlert({
                p: `Error creating comment. Error: ${commentsErrorMessage}`,
                style: "red",
                timer: 6000,
            })
        },
    })

    //Update Comment
    useStatusHandler({
        effectStatus: updateCommentStatus,
        successCallback: () => {
            openAlert({
                p: "Comment updated.",
                style: "green",
            })
        },
        errorCallback: () => {
            openAlert({
                p: `Error updating comment. Error: ${commentsErrorMessage}`,
                style: "red",
                timer: 6000,
            })
        },
    })
    
    //Delete Comment
    useStatusHandler({
        effectStatus: deleteCommentStatus,
        successCallback: () => {
            openAlert({
                p: "Comment was successfully deleted.",
                style: "green",
            })
        },
        errorCallback: () => {
            openAlert({
                p: `Error deleting comment. Error: ${commentsErrorMessage}`,
                style: "red",
                timer: 6000,
            })
        },
    })
    
    //Wants Data
    const createWantStatus = useSelector(state => state.me.createWantStatus)
    const deleteWantStatus = useSelector(state => state.me.deleteWantStatus)
    const wantErrorMessage = useSelector(state => state.me.errorMessage)
    
    //Create Want
    useStatusHandler({
        effectStatus: createWantStatus,
        beginCallback: () => {
            openAlert({
                p: "Added to your",
                a: "Listen Later.",
                href: `/${username}/wantlist`,
                style: "green",
                timer: 6000,
            })
        },
        errorCallback: () => {
            openAlert({
                p: `Could not add to Listen Later. Error: ${wantErrorMessage}`,
                style: "red",
                timer: 6000,
            })
        },
    })

    //Delete Want
    useStatusHandler({
        effectStatus: deleteWantStatus,
        successCallback: () => {
            openAlert({
                p: `Removed from your`,
                a: "Listen Later.",
                href: `/${username}/wantlist`,
                style: "green",
                timer: 6000,
            })
        },
        errorCallback: () => {
            openAlert({
                p: `Could not remove from Listen Later. Error: ${wantErrorMessage}`,
                style: "red",
                timer: 6000,
            })
        },
    })

    //Likes Data
    const likeContentStatus = useSelector(state => state.likes.likeContentStatus)
    const deleteLikeStatus = useSelector(state => state.likes.deleteLikeStatus)
    const likesErrorMessage = useSelector(state => state.likes.errorMessage)

    //Like Content
    useStatusHandler({
        effectStatus: likeContentStatus,
        errorCallback: () => {
            openAlert({
                p: `Could not like. Error: ${likesErrorMessage}`,
                style: "red",
                timer: 6000,
            })
        },
    })

    //Delete Like
    useStatusHandler({
        effectStatus: deleteLikeStatus,
        errorCallback: () => {
            openAlert({
                p: `Could not delete like. Error: ${likesErrorMessage}`,
                style: "red",
                timer: 6000,
            })
        },
    })

    //Drafts data
    const createDraftStatus = useSelector(state => state.drafts.createDraftStatus)
    const lastCreatedDraft = useSelector(state => state.drafts.lastCreatedDraft)
    const deleteDraftStatus = useSelector(state => state.drafts.deleteDraftStatus)
    const lastDeletedDraftType = useSelector(state => state.drafts.lastDeletedDraftType)
    const updateDraftStatus = useSelector(state => state.drafts.updateDraftStatus)
    const lastUpdatedDraft = useSelector(state => state.drafts.lastUpdatedDraft)

    //Handle create draft status
    useStatusHandler({
        effectStatus: createDraftStatus,
        successCallback: () => {
            if (lastCreatedDraft) {
                if (lastCreatedDraft.type === "review") {
                    dispatch(
                        getAllReviewDrafts()
                    )
                } else {
                    dispatch(
                        getAllListDrafts()
                    )
                }
            }
        },
        errorCallback: () => {
            openAlert({
                p: `Could not save ${firstLetterCapital(lastCreatedDraft.type)} to your drafts.`,
                style: "red",
                timer: 4500
            })
        }
    })

    //Handle delete draft status
    useStatusHandler({
        effectStatus: deleteDraftStatus,
        successCallback: () => {
            if (lastDeletedDraftType === "review") {
                dispatch(
                    getAllReviewDrafts()
                )
            } else {
                dispatch(
                    getAllListDrafts()
                )
            }
        },
    })

    //Handle update draft status
    useStatusHandler({
        effectStatus: updateDraftStatus,
        successCallback: () => {
            if (lastUpdatedDraft) {
                if (lastUpdatedDraft.type === "review") {
                    dispatch(
                        getAllReviewDrafts()
                    )
                } else {
                    dispatch(
                        getAllListDrafts()
                    )
                }
            }
        },
    })
    
    //Chat data
    const updateGroupStatus = useSelector(state => state.chat.updateGroupStatus)
    const errorMessageChat = useSelector(state => state.chat.errorMessage)

    //Handle update group state
    useStatusHandler({
        effectStatus: updateGroupStatus,
        successCallback: () => {
            openAlert({
                p: "Successfully updated group!",
                style: 'green',
            })
        },
        errorCallback: () => {
            openAlert({
                p: "Could not update group. Error: " + errorMessageChat,
                style: 'red',
                timer: 4500
            })
        }
    })

    return props.children
}
