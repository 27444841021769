import styles from './sharereviewpopup.module.css'

import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'

import { ogImageUrl } from '../../config'
import { recordTypeEnums } from '../../constants/enums'

import xImage from '../../assets/images/socials/x.png'
import smsImage from '../../assets/images/socials/sms.png'
import copyImage from '../../assets/images/socials/link.png'
import emailImage from '../../assets/images/socials/email.png'
import redditImage from '../../assets/images/socials/reddit.png'
import facebookImage from '../../assets/images/socials/facebook.png'
import whatsappImage from '../../assets/images/socials/whatsapp.png'
import pinterestImage from '../../assets/images/socials/pinterest.png'
import messengerImage from '../../assets/images/socials/messenger.png'

import { usePopup } from '../../hooks/popup/popup'
import { useScrollbar } from '../../hooks/scrollbar'

import { displayRecordType } from '../../utils/record-type-utils'
import { firstLetterCapital } from '../../utils/captialization'

import Icon from '../../components/Icon'
import Stars from '../../components/Stars'
import Button from '../../components/Button'
import Heading from '../../components/Heading'
import Tooltip from '../../components/Tooltip'
import AlbumCover from '../../components/AlbumCover'
import IconButton from '../../components/IconButton'
import ReviewItem from '../../components/ReviewItem'
import UserRedirect from '../../components/UserRedirect'
import ProfilePicture from '../../components/ProfilePicture'
import PlayContentButton from '../../components/PlayContentButton'
import { useTrack } from '../../hooks/tracking'

const REDIRECT_URI = "https://musicboard.app"
const FACEBOOK_APP_ID = "990256672716610"
const DESCRIPTION = "Musicboard is the social network for music-lovers. Keep track of all the music you listen to and grow your passion for music with friends. Write reviews, rate albums, compile lists, and more."
const DEFAULT_IMAGE = ogImageUrl

export default function ShareReviewPopup({ options: { review } }) {
    //General
    const { openPopup } = usePopup()
    const track = useTrack()
    const { hasScrollbar } = useScrollbar()

    //Variables
    const contentType = review && review.rating && review.rating.content.type
    const isSuggested = (contentType === "suggested_album" || contentType === "suggested_artist" || contentType === "suggested_track")
    const formattedContentType = isSuggested ? contentType.substr(10, 100) : contentType
    const contentTitle = review && review.rating && (
        (review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") ? 
            review.rating.content.name 
        : 
            review.rating.content.title
    )
    const contentImage = review && review.rating && (
        review.rating.content.type === "artist" ? 
            review.rating.content.picture_large || review.rating.content.picture
        : review.rating.content.type === "album" ? 
            review.rating.content.cover_large || review.rating.content.cover
        : review.rating.content.type === "track" ? 
            review.rating.content.album.cover_large || review.rating.content.album.cover
        : review.rating.content.type === "suggested_artist" ?
            review.rating.content.picture ?
                review.rating.content.picture
            :
                review.rating.content.picture_url
        : review.rating.content.type === "suggested_album" ?
            review.rating.content.cover ?
                review.rating.content.cover
            :
                review.rating.content.cover_url
        : review.rating.content.type === "suggested_track" ?
            review.rating.content.album ? 
                review.rating.content.album.cover ?
                    review.rating.content.album.cover
                :
                    review.rating.content.album.cover_url
            :
                review.rating.content.suggested_album.cover ?
                    review.rating.content.suggested_album.cover
                :
                    review.rating.content.suggested_album.cover_url
        : null
    )

    //State
    const [copiedLink, setCopiedLink] = useState(false)

    //Reset copied link
    const firstUpdate = useRef(true)
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (copiedLink) {
            setTimeout(() => {
                setCopiedLink(false)
            }, 3000)
        }
    }, [ copiedLink ])

    function copyText() {
        track(
            "Share", 
            { 
                "ref": "popup",
                "type": "copy",
                "content_type": "review",
            }
        );

        setCopiedLink(true)

        const element = document.getElementById(`share-content-copy-input-${review.uid}`)

        /* Select the text field */
        element.select();
        element.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");
    }

    const title = `Review of ${contentType === "artist" ? contentTitle : `${contentTitle} by ${review.rating.content.artist.name}`}`
    const url = `https://musicboard.app${review.url_slug}`
    const image = contentImage;
    const description = 
        review.description !== "" ?
            `Review of ${contentType === "artist" ? contentTitle : `${contentTitle} by ${review.rating.content.artist.name}`}. ${review.creator.name !== "" ? review.creator.name : review.creator.username} rated the ${review.rating.content.type === "album" ? recordTypeEnums[review.rating.content.record_type] : review.rating.content.type} ${(review.rating.rating / 2).toFixed(1)} / 5 stars. ${review.description.substr(0, 300)}${review.description.length >= 300 ? '...' : '.'}`
        :
            `${review.creator.name !== "" ? review.creator.name : review.creator.username} gave ${contentTitle} a rating of ${(review.rating.rating / 2).toFixed(1)} / 5 stars on Musicboard. Read the entire review of ${contentTitle} on Musicboard.`

    const socials = [
        { 
            name: "X", 
            image: xImage,
            href: `https://x.com/intent/tweet?text=${title}%20on%20%40musicboardapp%3A%20${url}`,
        },
        { 
            name: "WhatsApp", 
            image: whatsappImage,
            href: `https://wa.me/?text=${title}%3A%20${url}`,
        },
        { 
            name: "Reddit", 
            image: redditImage,
            href: `https://www.reddit.com/submit?url=${url}&title=${title}`,
        },
        { 
            name: "Facebook", 
            image: facebookImage,
            href: `https://www.facebook.com/dialog/feed?display=popup&app_id=${FACEBOOK_APP_ID}&link=${url}&picture=${image ? image : DEFAULT_IMAGE}&name=${title}&description=${description ? description : DESCRIPTION}&redirect_uri=${REDIRECT_URI}&href=${url}`,
        },
        { 
            name: "SMS", 
            image: smsImage,
            href: `sms:?&body=${title}%3A%20${url}`,
        },
        { 
            name: "Pinterest",
            image: pinterestImage,
            href: `http://pinterest.com/pin/create/button/?url=${url}&media=${image}&description=${title}`
        },
        { 
            name: "Email", 
            image: emailImage,
            href: `mailto:?subject=${title}&body=${url}`,
        },
        { 
            name: "Messenger", 
            image: messengerImage,
            href: `fb-messenger://share/?link=${url}&app_id=${FACEBOOK_APP_ID}`,
        },
        { 
            name: copiedLink ? "Copied!" : "Copy link", 
            image: copyImage,
            onClick: copyText,
        },
    ]

    return (
        <div className='padding-around-8'>
            <div className='padding-around border-radius-mid flex-row' style={{ backgroundColor: 'var(--white)' }}>
                {(review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") &&
                    <ProfilePicture size={88} profilePicture={contentImage} altText={contentTitle} />
                }

                {!(review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") &&
                    <AlbumCover size={88} albumCover={contentImage} altText={contentTitle} />
                }

                <div className='col-1 grid margin-left-16'>
                    <h5 className='black one-line' style={{ marginTop: 1 }}>
                        {review.title !== "" ? review.title : `Review of ${contentTitle}`}
                    </h5>

                    {review.rating.rating &&
                        <Stars rating={review.rating.rating} iconSize={20} style={{ marginTop: 10 }} />
                    }

                    <div className="flex-row" style={{ marginTop: 10 }}>
                        {(review.private || review.private_locked) &&
                            <Icon icon="lock" size={14} style={{ marginRight: 4, marginTop: 1 }} color={"var(--grey)"} />
                        }

                        <div className="grid">
                            <p 
                            className={`highDarkGrey ${review.rating.rating ? "one-line" : ""}`} 
                            style={{ lineHeight: review.rating.rating ? '17px' : undefined, maxHeight: review.rating.rating ? undefined : 40, overflow: 'hidden' }}
                            >
                                {(review.private || review.private_locked) ? " • " : ""}
                                {review.description !== "" ? review.description : moment(review.rating.show_listened_at ? review.rating.listened_at : review.rating.created_at).format("DD MMMM YYYY")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <h4 style={{ marginTop: 32 }}>Share a link</h4>

            <div className={`${styles.socialsWrapper} ${hasScrollbar ? styles.scrollbar : ""}`}>
                <div className={`${styles.socialsScroll}`}>
                    {socials.map((item, index) => (
                        <a
                        key={`SharePopupSocial-${index}`}
                        href={item.href}
                        target="_blank"
                        onClick={(e) => {
                            if (item.onClick) {
                                e.preventDefault()
                                item.onClick()
                            }
                        }}
                        >
                            <div className={`flex-column flex-center ${styles.socialItem}`}>
                                <img src={item.image} className={styles.socialImg} alt={`${item.name} icon`} />

                                <div className='grid margin-top-8'>
                                    <h6 className={`one-line black ${styles.socialName}`}>{item.name}</h6>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>

                <div className={`${styles.socialsGradient} ${styles.left}`} />
                <div className={`${styles.socialsGradient} ${styles.right}`} />
            </div>

            <h4 style={{ marginTop: 16 }}>Review link</h4>

            <div className="padding-around border-radius-mid flex-row margin-top-16" style={{ backgroundColor: 'var(--white)' }}>
                <div className="col-1 margin-right-16">
                    <div className='grid'>
                        <p className="one-line highDarkGrey">
                            Copy the link below
                        </p> 
                    </div>

                    <div className='flex-row'>
                        {!copiedLink &&
                            <div className='grid margin-top-8'>
                                <p 
                                id={"ShareReviewPopup-tip"} 
                                className={`one-line ${styles.link}`} 
                                onClick={copyText}
                                >
                                    {`https://musicboard.app${review.url_slug}`}
                                </p>
                            </div>
                        }
                        
                        {copiedLink &&
                            <div className='grid margin-top-8'>
                                <p 
                                id={"ShareReviewPopup-tipped"} 
                                className={`one-line ${styles.link}`} 
                                onClick={copyText}
                                >
                                    {`https://musicboard.app${review.url_slug}`}
                                </p>
                            </div>
                        }
                        
                        {!copiedLink &&
                            <Tooltip
                            id="ShareReviewPopup-tip"
                            text="Click to copy"
                            offset={12}
                            />
                        }
                        
                        {copiedLink &&
                            <Tooltip
                            id="ShareReviewPopup-tipped"
                            text="Copied link"
                            offset={12}
                            />
                        }
                    </div>
                </div>

                <IconButton 
                size={24}
                btnSize={46}
                icon={copiedLink ? "check" : "copy-all"}
                iconColor={copiedLink ? "var(--green)" : "var(--black)"}
                type="transparent" 
                onClick={copyText}
                />
            </div>

            <input 
            id={`share-content-copy-input-${review.uid}`}
            className={styles.input}
            value={url}
            type="text"
            readOnly
            spellCheck="false"
            />
        </div>
    )
}
