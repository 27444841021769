export function newLinePresent (text) {
    return text.replace("\n", "...\n")
}

export function getIndicesOf(searchStr, str, caseSensitive) {
    var searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
        return [];
    }
    var startIndex = 0, index, indices = [];
    if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }
    return indices;
}

export function oneDecimal (num) {
    if (typeof num !== "number") {
        return 0
    }

    return Math.floor(num*10)/10
}

export function checkNull(num) {
    if(num) {
        return num
    } else {
        return '0';
    }
}

export function checkNullNumber(num) {
    if(num) {
        return num
    } else {
        return 0;
    }
}

export function addEndingS (name) {
    if (!name) {
        return null
    }
    
    return name + (name.charAt(name.length-1) === "s" ? "'" : "'s")
}

export function isLastS(name) {
    return name.charAt(name.length-1) === "s"
}

export const formatPrice = (price, currency) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 2
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      
      return formatter.format(price)
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export function secondsToDuration(seconds) {
	const SECONDS_PER_SECOND = 1;
	const SECONDS_PER_MINUTE = 60;
	const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;
	const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;

	const designations = [
		['D', SECONDS_PER_DAY],
		['H', SECONDS_PER_HOUR],
		['M', SECONDS_PER_MINUTE],
		['S', SECONDS_PER_SECOND],
	];

	let duration = 'P';
	let remainder = seconds;

	designations.forEach(([sign, seconds]) => {
		const value = Math.floor(remainder / seconds);

		remainder = remainder % seconds;

		if (value) {
			duration += `${value}${sign}`;
		}
	});

	if (duration == 'P') {
		duration = 'P0S';
	}

	return duration;
}
