import React from 'react'
import styles from './iconbutton.module.css'
import Icon from '../Icon/Icon'

export default function IconButton({
    icon,
    iconClassName,
    iconStyle,
    iconColor,
    size=20, 
    btnSize=38, 
    className, 
    style, 
    onClick=()=>{}, 
    type="secondary", // secondary || transparent || primary || transparent-bright || transparent-super-bright || destructive || destructive-transparent
    ...props
}) {

    return (
        <div
        className={`${styles.iconContainer} ${className} ${
            type === "transparent" ?
                styles.transparent
            : type === "primary" ?
                styles.primary
            : type === "destructive" ?
                styles.destructive
            : type === "yellow" ?
                styles.yellow
            : type === "transparent-bright" ?
                styles.transparentBright
            : type === "transparent-super-bright" ?
                styles.transparentSuperBright
            : type === "destructive-transparent" ?
                styles.destructiveTransparent
            : type === "destructive-transparent-super-bright" ?
                styles.destructiveTransparentSuperBright
            : type === "secondary-two" ?
                styles.secondaryTwo
            : ""
        }`}
        style={{
            width: btnSize,
            height: btnSize,
            ...style
        }}
        onClick={(e) => onClick(e)}
        {...props}
        >
            <Icon icon={icon} size={size} className={`${styles.icon} ${iconClassName}`} style={iconStyle} color={iconColor} />
        </div>
    )
}
