import styles from './filter.module.css'

import React, { useState } from 'react'
import Tooltip from '../../../Tooltip'
import Icon from '../../../Icon'
import { useRouteMatch, useLocation, Link } from 'react-router-dom'
import { useTheme } from '../../../../hooks/theme'

export default function Filter({
    filter
}) {
    const { colors } = useTheme()

    const [visible, setVisible] = useState(false)

    //Query params
    const match = useRouteMatch()
    const location = useLocation()
    const orderBy = new URLSearchParams(location.search).get(filter.parameter)

    //Prepare data for rendering
    const activeIndex = filter.ordering.findIndex(item => item.ordering.findIndex(item => item.value === orderBy) !== -1)
    const title = filter.ordering[activeIndex] ? filter.ordering[activeIndex].title : "None"

    return (
        <div className="flex-row margin-left-16">
            <p className="grey" style={{ fontSize: 14 }}>{filter.title}</p>
            <div id={`filter-${filter.parameter}`} className="margin-left-4 flex-row cursor">
                <h6 className="black">
                    {title}
                </h6>
                <Icon size={20} className={`${styles.arrow} ${visible ? styles.active : ""}`} icon="expand-more" />
            </div>

            <Tooltip
            afterHide={() => setVisible(false)}
            afterShow={() => setVisible(true)}
            clickable={true}
            globalEventOff
            id={`filter-${filter.parameter}`}
            events={{ click: true, focus: true }}
            place="bottom"
            offset={{}}
            element={
                <div className="margin-bottom-8">
                    {filter.ordering.map(item => (
                        <div className="margin-top-8">
                            <h6 className="highDarkGrey">{item.title}</h6>
                            {item.ordering.map(item => (
                                <Link to={item.value ? `${match.url}?${filter.parameter}=${item.value}` : match.url} key={`ordering-${item.value}`}>
                                    <div className={`flex-row ${styles.item} ${item.value === orderBy ? styles.active : ""}`}>
                                        <Icon color={colors.green} size={16} icon="check" style={{ opacity: item.value === orderBy ? 1 : 0 }} />
                                        <p className={`margin-left-4 ${styles.itemText}`}>{item.label}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ))}
                </div>
            }
            />
        </div>
    )
}
