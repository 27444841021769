import styles from './biguseritem.module.css'

import React, { useState } from 'react'

import ProfilePicture from '../ProfilePicture'
import Stars from '../Stars'
import Icon from '../Icon'
import { useTheme } from '../../hooks/theme'
import Button from '../Button'
import { useDispatch, useSelector } from 'react-redux'
import { usePopup } from '../../hooks/popup/popup'
import { followUser, unblockUser, unfollowUser } from '../../actions/users-actions'
import { status } from '../../constants/action-types'
import VerifiedIcon from '../VerifiedIcon'
import { Link } from 'react-router-dom'
import UserProfilePicture from '../UserProfilePicture'
import { useTrack } from '../../hooks/tracking'

function BigUserItem({user, size='100%', target, largeImage}) {
    //General
    const { colors } = useTheme()
    const dispatch = useDispatch()
    const { openPopup } = usePopup()
    const track = useTrack()
    
    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const myUid = useSelector(state => state.auth.uid);
    const isMe = user.uid === myUid;

    //followObj
    const lastUnfollowUserUid = useSelector(state => state.users.lastUnfollowUserUid)
    const unfollowUserStatus = useSelector(state => state.users.unfollowUserStatus)
    const myFollows = useSelector(state => state.me.follows)
    const cachedFollows = useSelector(state => state.me.cachedFollows)
    const follows = [ ...myFollows, ...cachedFollows ]
    const followIndex = follows.findIndex(follow => follow.followed_user === user.uid)
    const followObj = followIndex !== -1 ? follows[followIndex] : null

    //blockObj
    const lastUnblockUserUid = useSelector(state => state.users.lastUnblockUserUid)
    const unblockUserStatus = useSelector(state => state.users.unblockUserStatus)
    const myBlocks = useSelector(state => state.me.blocks)
    const cachedBlocks = useSelector(state => state.me.cachedBlocks)
    const blocks = [ ...myBlocks, ...cachedBlocks ]
    const blockIndex = blocks.findIndex(block => block.blocked_user === user.uid)
    const blockObj = blockIndex !== -1 ? blocks[blockIndex] : null

    //blockedBy
    const blockedByObj = useSelector(state => 
        state.me.blockedBy && state.me.blockedBy.find(block => block.creator === user.uid)
    )

    if (blockedByObj) {
        return null
    }

    return (
        <div className={`${styles.container} col-1 align-center cursor`}>
                <div style={{position: 'relative', width: size}}>
                    <Link target={target} to={`/${user.username}`}>
                        <UserProfilePicture 
                        size={size} 
                        clickable 
                        profilePicture={user.profile_picture?.includes(".gif") ? user.profile_picture : (largeImage ? user.profile_picture_large : user.profile_picture_medium) || user.profile_picture} 
                        profileFrame={user.is_pro && user.tier === 1 ? user.profile_frame : null}
                        altText={user.name !== "" ? user.name : user.username}
                        />

                        {user.is_verified &&
                            <VerifiedIcon whiteBg className={styles.verified} size={24} />
                        }
                    </Link>
                </div>

            <Link target={target} to={`/${user.username}`}>
                <div className={`grid ${styles.textContent}`}>
                    <h5 className="margin-top-12 one-line text-center">{user.username}</h5>

                    <p className="highDarkGrey margin-top-4 one-line text-center" style={{height: 20}}>{user.name !== "" ? user.name : " "}</p>
                </div>
            </Link>

            <div 
            className="margin-top-8"
            style={{width: '100%'}}
            >
                {!isMe && !blockObj &&
                    <Button
                    text={followObj ? "Following" : "Follow"}
                    type={followObj ? "secondary" : "primary"}
                    height={34}
                    loading={unfollowUserStatus === status.BEGIN && lastUnfollowUserUid === user.uid}
                    textStyle={followObj ? { color: colors.black, fontSize: 13 } : { fontSize: 14 }}
                    onClicked={() => {
                        if (!isLoggedIn) {
                            openPopup("SignUp")
                            return;
                        }

                        if (followObj) {
                            dispatch(
                                unfollowUser(followObj.uid, user.uid)
                            )
                        } else {
                            track(
                                "Follow user", 
                                { 
                                    "user_uid": user.uid,
                                    "referrer": "BigUserItem",
                                }
                            );

                            dispatch(
                                followUser(user.uid)
                            )
                        }

                    }}
                    />
                }

                {!isMe && isLoggedIn && blockObj &&
                    <Button
                    text="Unblock"
                    height={34}
                    textStyle={{ fontSize: 13 }}
                    loading={unblockUserStatus === status.BEGIN && lastUnblockUserUid === user.uid}
                    onClicked={() => {
                        dispatch(
                            unblockUser(blockObj.uid, user.uid)
                        )
                    }}
                    />
                }
            </div>
        </div>
    )
}

export default React.memo(
    BigUserItem,
    (prevProps, nextProps) => {
        if (prevProps.user.uid !== nextProps.user.uid) {
            return true;
        }
        if (prevProps.user.username !== nextProps.user.username) {
            return true;
        }
        if (prevProps.user.name !== nextProps.user.name) {
            return true;
        }
        if (prevProps.user.is_verified !== nextProps.user.is_verified) {
            return true;
        }
        if (prevProps.user.profile_picture !== nextProps.user.profile_picture) {
            return true;
        }

        if (prevProps.target !== nextProps.target) {
            return true;
        }
        if (prevProps.size !== nextProps.size) {
            return true;
        }
        return false;
    }
)