import styles from './composereview.module.css'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProfilePicture from '../../../../components/ProfilePicture'
import { useTheme } from '../../../../hooks/theme'
import SearchContentPopup from '../../../../popups/SearchContentPopup'
import Icon from '../../../../components/Icon'
import Button from '../../../../components/Button'
import { usePopup } from '../../../../hooks/popup/popup'
import { Link } from 'react-router-dom'
import SearchContentInput from '../../../../components/SearchContentInput'
import { useMediaQuery } from 'react-responsive'
import UserProfilePicture from '../../../../components/UserProfilePicture'

export default function ComposeReview({ className, style }) {
    //General
    const { color, mode } = useTheme()
    const dispatch = useDispatch()
    const { openPopup } = usePopup()
   
    //Responsive
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Data
    const me = useSelector(state => state.me)

    return (
        <div 
        className={`section-block padding-left-16 padding-right-16 padding-bottom-16 padding-top-16 ${className}`} 
        style={style}
        >
            <div className="flex-row">
                <Link to={`/${me.username}`}>
                    <UserProfilePicture 
                    clickable 
                    profilePicture={me.profile_picture?.includes(".gif") ? me.profile_picture : me.profile_picture_small || me.profile_picture} 
                    profileFrame={me.is_pro && me.tier === 1 ? me.profile_frame : null} 
                    size={40} 
                    altText={me.name !== "" ? me.name : me.username}
                    />
                </Link>

                <SearchContentInput
                className="margin-left-12"
                showResultsOnFocus
                onContentPressed={(content) => {
                    openPopup("CreateReview", { content: content })
                }}
                placeholder="What have you been listening to?"
                inputStyle={{
                    borderRadius: 100,
                    backgroundColor: "var(--lightestGrey)",
                    border: "none",
                    boxShadow: mode === "black" ? undefined : "none",
                    padding: "12px 16px"
                }}
                offset={44}
                />
            </div>

            <div className={`margin-top-16 ${styles.line}`} />

            <div className="margin-top-16 flex-row">
                {!isMobile &&
                    <Button
                    showIcon
                    iconName="star"
                    iconColor="var(--yellow)"
                    type="transparent"
                    text="Rate an album"
                    className="col-1 margin-right-12"
                    onClicked={() => openPopup("SearchContent")}
                    />
                }

                <Link to="/list/new" className="col-1">
                    <Button
                    showIcon
                    iconName="playlist-add"
                    iconColor="var(--blue)"
                    type="transparent"
                    text="Start a new list"
                    />
                </Link>
            </div>
        </div>
    )
}
