import styles from './artistinformationsection.module.css'
import moment from 'moment'
import React from 'react'

import { Link } from 'react-router-dom'
import Button from '../../../../components/Button'
import { addEndingS } from '../../../../utils/format-text'
import { firstLetterCapital } from '../../../../utils/captialization'
import Icon from '../../../../components/Icon'
import { useTheme } from '../../../../hooks/theme'
import { usePopup } from '../../../../hooks/popup/popup'

export default function ArtistInformationSection({ artist }) {
    const {mode} = useTheme()
    const {openPopup} = usePopup()

    const unknownData = [
        !artist.birth_year && "birth date", 
        !Boolean(artist.country) && "location of origin", 
        !Boolean(artist.gender) && "gender identity", 
        !Boolean(artist.label) && "label", 
        !Boolean(artist.website) && "website", 
        !Boolean(artist.twitter || artist.instagram || artist.facebook) && "social media handles"
    ].filter(i => i)

    const hasAnyData = (
        artist.birth_year ||
        artist.passing_year ||
        Boolean(artist.country) ||
        Boolean(artist.gender) ||
        Boolean(artist.label) ||
        Boolean(artist.website) ||
        Boolean(artist.twitter || artist.instagram || artist.facebook)
    )

    const socials = [
        Boolean(artist.twitter) && {
            label: "X",
            link: (artist.twitter.includes("http://") || artist.twitter.includes("https://")) ? artist.twitter.replace("twitter.com", "x.com") : `https://${artist.twitter.replace("twitter.com", "x.com")}`
        }, 
        Boolean(artist.instagram) && {
            label: "Instagram",
            link: (artist.instagram.includes("http://") || artist.instagram.includes("https://")) ? artist.instagram : `https://${artist.instagram}`
        }, 
        Boolean(artist.facebook) && {
            label: "Facebook",
            link: (artist.facebook.includes("http://") || artist.facebook.includes("https://")) ? artist.facebook : `https://${artist.facebook}`
        },
    ].filter(i => i)

    return (
        <div style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8, overflow: 'hidden' }}>
            {hasAnyData &&
                <div>
                    {artist.birth_year && // Born
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>{artist.is_group ? "Formed" : "Born"}</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {artist.birth_year.substr(5, 5) === "01-01" ? moment(artist.birth_year).format("YYYY") : moment(artist.birth_year).format("MMMM DD, YYYY")}
                            </p>
                        </div>
                    }
                    
                    {artist.passing_year && // Passed Away
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>{artist.is_group ? "Disbanded" : "Passed Away"}</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {artist.passing_year.substr(5, 5) === "01-01" ? moment(artist.passing_year).format("YYYY") : moment(artist.passing_year).format("MMMM DD, YYYY")}
                            </p>
                        </div>
                    }

                    {!artist.is_group && Boolean(artist.gender) && // Gender
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Gender</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {artist.gender}
                            </p>
                        </div>
                    }

                    {artist.is_group && Boolean(artist.amount_of_members) && Boolean(artist.gender) &&  // Members
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Members</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {`${artist.amount_of_members} ${artist.amount_of_members === 1 ? artist.gender : `${artist.gender}s`}`}
                            </p>
                        </div>
                    }

                    {Boolean(artist.label) && // Label
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Label</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {Boolean(artist.label) ? 
                                    artist.label 
                                : 
                                    <Link 
                                    className={styles.link}
                                    to={`/artist/${artist.id}/edit`}
                                    >
                                        {`Contribute by adding their label...`}
                                    </Link>
                                }
                            </p>
                        </div>
                    }

                    {// Origin
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Origin</h5>
                            </div>
                            
                            <p className="margin-top-4 highDarkGrey" style={{ fontSize: 15 }}>
                                {Boolean(artist.country) ? 
                                    artist.country
                                : 
                                    <Link 
                                    className={styles.link}
                                    to={`/artist/${artist.id}/edit`}
                                    >
                                        {"Contribute by adding their origin..."}
                                    </Link>
                                }
                            </p>
                        </div>
                    }

                    {// Website
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Website</h5>
                            </div>
                            
                            <div className="grid">
                                <p className="margin-top-4 blue one-line" style={{ fontSize: 15 }}>
                                    {Boolean(artist.website) ?
                                        <a 
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={(artist.website.includes("http://") || artist.website.includes("https://")) ? artist.website : `http://${artist.website}`}
                                        className={styles.link}
                                        >
                                            {artist.website.replace("http://", "").replace("https://", "")}
                                        </a>
                                    :  
                                        <Link 
                                        className={styles.link}
                                        to={`/artist/${artist.id}/edit`}
                                        >
                                            {"Do they have an official website?"}
                                        </Link>
                                    }
                                </p>
                            </div>
                        </div>
                    }
                    
                    {// Socials
                        <div className={`padding-around ${styles.container}`}>
                            <div className="grid">
                                <h5 className="black one-line" style={{ fontSize: 15 }}>Socials</h5>
                            </div>
                            
                            <div className="grid">
                                <p className="margin-top-4 highDarkGrey one-line" style={{ fontSize: 15 }}>
                                    {Boolean(artist.twitter || artist.instagram || artist.facebook) ?
                                        socials.map((social, index) => (
                                            <span>
                                                <a 
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={social.link}
                                                className={styles.link}
                                                >
                                                    {social.label}
                                                </a>
                                                {index !== socials.length-1 && ", "}
                                            </span>
                                        ))
                                    : 
                                        <Link 
                                        className={styles.link}
                                        to={`/artist/${artist.id}/edit`}
                                        >
                                            {"Add links to their socials..."}
                                        </Link>
                                    }
                                </p>
                            </div>
                        </div>
                    }
                </div>
            }
            
            {!hasAnyData &&
                <div className={`${styles.container} padding-left-32 padding-right-32 padding-top-24 padding-bottom-32 flex-column flex-center`}>
                    <Icon 
                    color={"transparent"}
                    icon={mode === "light" ? "auto-fix-high" : "person-insight-dark"} 
                    size={96} 
                    style={{ marginLeft: mode === "light" ? 0 : 2 }}  
                    />

                    <p className="highDarkGrey text-center margin-top-24">
                        {addEndingS(artist.name)} details have not been {unknownData.length === 6 ? "filled out" : "completed"} yet. Be the first to contribute to this artist's page with information.
                    </p>
                    
                    <p className="highDarkGrey text-center margin-top-16">
                        Examples: {firstLetterCapital(unknownData.join(", "))}.
                    </p>

                    <Link to={`/artist/${artist.id}/edit`}>
                        <Button
                        className="margin-top-24"
                        type="secondary"
                        text="Contribute"
                        height={40}
                        textStyle={{ fontSize: 14 }}
                        />
                    </Link>
                </div>
            }

        </div>
    )
}
