import React, { useEffect, useState } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

export default function Carousel({
    active, 
    children, 
    height, 
    animationName="slide",
    style,
    className
}) {
    const [lastIndex, setLastIndex] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            setLastIndex(active)
        }, 550)
    }, [ active ])
    return (
        <div className={`${active > lastIndex ? "forward" : "backward"} ${className}`} style={style}>
            <TransitionGroup style={{position: 'relative', height:height}}>
                <CSSTransition key={active} classNames={animationName} timeout={550}>
                    <div className={`step`}>
                        {children[active]}
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </div>
    )
}
