import './StickerSection.css'

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ContentLoader from "react-content-loader"

import { status } from '../../constants/action-types'

import { getMySticker, getStickerCounts } from '../../actions/stickers-actions'

import { usePopup } from '../../hooks/popup/popup'

import Button from '../Button'
import Tooltip from '../Tooltip'
import IconButton from '../IconButton'
import StickerItem from '../StickerItem'

export default function StickerSection({ appendKey, reviewUid=null, ratingUid=null, threadUid=null, listUid=null, className }) {
    //General
    const dispatch = useDispatch()
    const { openPopup } = usePopup()

    //Data
    const objectName = reviewUid ? "reviews" : ratingUid ? "ratings" : threadUid ? "threads" : "lists"
    const objectUid = reviewUid || ratingUid || threadUid || listUid
    const hasFetchedBefore = useSelector(state => state.stickers[objectName][objectUid]?.hasFetchedBefore)
    const stickerCounts = useSelector(state => state.stickers[objectName][objectUid]?.stickerCounts)
    const fetchStickerCountsStatus = useSelector(state => state.stickers[objectName][objectUid]?.fetchStickerCountsStatus)
    const fetchMyStickerStatus = useSelector(state => state.stickers[objectName][objectUid]?.fetchMyStickerStatus)

    //Get data
    useEffect(() => {
        dispatch(
            getStickerCounts(reviewUid, ratingUid, threadUid, listUid)
        )
        
        dispatch(
            getMySticker(reviewUid, ratingUid, threadUid, listUid)
        )
    }, [])

    const isLoading = !hasFetchedBefore && (fetchStickerCountsStatus === status.BEGIN || fetchMyStickerStatus === status.BEGIN)

    return (
        <div className={`margin-top-32 StickerSection-flex-wrap ${className}`}>
            {!isLoading &&
            <>
                {stickerCounts && stickerCounts.filter(item => item.count > 0).length > 0 &&
                    <div id="StickerSection-add-sticker-tip" className='margin-bottom-16 margin-right-16'>
                        <IconButton 
                        icon="add" 
                        size={22} 
                        btnSize={48} 
                        onClick={() => openPopup("AddSticker", { reviewUid, ratingUid, threadUid, listUid })}
                        />
                    </div>
                }

                {stickerCounts && stickerCounts.filter(item => item.count > 0).length > 0 &&
                    <Tooltip place="top" id="StickerSection-add-sticker-tip" text="Add Sticker" offset={8} />
                }
                
                {stickerCounts && stickerCounts.filter(item => item.count > 0).length <= 0 &&
                    <div className='margin-bottom-16 margin-right-16'>
                        <Button 
                        marginClassName="StickerSection-btn-left-margin"
                        showIcon={true}
                        iconName="add" 
                        iconSize={22} 
                        height={42}
                        text="Add Sticker"
                        type="secondary"
                        onClicked={() => openPopup("AddSticker", { reviewUid, ratingUid, threadUid, listUid })}
                        />
                    </div>
                }

                {stickerCounts && 
                stickerCounts
                .filter(item => item.count > 0)
                .map((item, index) => (
                    <StickerItem
                    key={`StickerSection-${appendKey}-sticker-${item.sticker}`}
                    sticker={item.sticker}
                    count={item.count}
                    latest={item.latest}
                    reviewUid={reviewUid}
                    ratingUid={ratingUid}
                    threadUid={threadUid}
                    listUid={listUid}
                    />
                ))}
            </>}

            {isLoading &&
                [1,2,3].map((item, index) => (
                    <div style={{ width: 48, height: 48 }} className='flex-center margin-right-16 margin-bottom-16' key={`StickerSection-${appendKey}-skeleton-${index}`}>
                        <ContentLoader 
                        speed={2}
                        width={48}
                        height={48}
                        backgroundColor="var(--lightGrey)"
                        foregroundColor="var(--darkLightGrey)"
                        >
                            <circle cx="24" cy="24" r="24" /> 
                        </ContentLoader>
                    </div>
                ))
            }
        </div>
    )
}
