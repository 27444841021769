import React from "react"
import ContentLoader from "react-content-loader"

export default function TrackItemLoader({
    size=60,
    artist=false,
    history=false,
    oneLine=false,
}) {
    
    const xo = history ? size+16+8 : 8
    const xco = history ? size+16+(size/2+8) : (size/2+8)

    return(
        <ContentLoader 
        speed={2}
        width={280}
        height={size+16}
        backgroundColor="var(--lightGrey)"
        foregroundColor="var(--darkLightGrey)"
        >
            {history &&
                <rect x="8" y="8" rx="4" ry="4" width={size.toString()} height={size.toString()} />
            }
            {!artist &&
                <rect x={xo.toString()} y="8" rx="4" ry="4" width={size.toString()} height={size.toString()} />
            }
            {artist &&
                <circle cx={xco.toString()} cy={(size/2+8).toString()} r={(size/2).toString()} /> 
            }

            <rect x={(xo+size+16).toString()} y={(size/2-5+(oneLine ? 8 : 0)).toString()} rx="2" ry="2" width="128" height="10" />
            {!oneLine &&
                <rect x={(xo+size+16).toString()} y={(size/2+18-3).toString()} rx="2" ry="2" width="64" height="6" />
            }
            
        </ContentLoader>
    )
}