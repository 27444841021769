import React, { Suspense, lazy } from 'react'

import { Route, Switch, useRouteMatch } from "react-router-dom";

import NotFound from '../../../../routes/NotFound';

const News = lazy(() => import('../../../../routes/News'));
const Article = lazy(() => import('../../../../routes/Article'));

export default function NewsNavigator() {
    const match = useRouteMatch()

    return (
        <Switch>
            <Route exact path={`${match.url}`} component={News} />

            <Route exact path={`${match.url}/:uid`} component={Article} />

            <Route path="*" component={NotFound} />
        </Switch>
    )
}
