import { status, types } from '../constants/action-types'
import { post, put, get, patch, patchRaw, delete_ } from '../utils/fetch'
import { url, deezerHostDomain, deezerHostUrl } from '../config'
import moment from 'moment';
import { getNormal } from '../utils/fetch-normal';


// Genres
export const getGenres = () => get(types.GET_GENRES, `${url}/v2/genres/`)

// Styles
export const getStyles = () => get(types.GET_STYLES, `${url}/v2/styles/`)

// Favorite Genres
export const getFavoriteGenres = () => get(types.GET_FAVORITE_GENRES, `${url}/v2/favorite_genres/`)
export const createFavoriteGenre = (genre) => post(types.CREATE_FAVORITE_GENRE, `${url}/v2/favorite_genres/`, { genre: genre.id }, { genre })
export const deleteFavoriteGenre = (favorite) => delete_(types.DELETE_FAVORITE_GENRE, `${url}/v2/favorite_genres/${favorite.uid}/`, { favorite })
