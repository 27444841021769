import React, { Suspense, lazy } from 'react'

import { BrowserRouter as Router, Route, Switch, Redirect, useRouteMatch } from "react-router-dom";

import NotFound from '../../../../routes/NotFound';

const Search = lazy(() => import('../../../../routes/Search'));

export default function SearchNavigator() {
    const match = useRouteMatch()
    return (
        <Switch>
            <Route exact path={`${match.path}/:query/:type`} component={Search} />

            <Route path="*" component={NotFound} />
        </Switch>
    )
}
