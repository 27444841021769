import styles from './userredirect.module.css'

import React from 'react'
import ProfilePicture from '../ProfilePicture'
import VerifiedIcon from '../VerifiedIcon'
import ProIcon from '../ProIcon'
import { Link } from 'react-router-dom'
import UserProfilePicture from '../UserProfilePicture'

export default function UserRedirect({ 
    user, 
    pictureSize=24, 
    fontSize=14, 
    verifiedSize=12,
    className,
    iconStyles,
    hideIcons
}) {
    return (
        <div className={`flex-row ${className}`}>
            <Link to={`/${user.username}`}>
                <UserProfilePicture 
                clickable 
                size={pictureSize} 
                profilePicture={user.profile_picture?.includes(".gif") ? user.profile_picture : user.profile_picture_small || user.profile_picture} 
                profileFrame={user.is_pro && user.tier === 1 ? user.profile_frame : null} 
                altText={user.name !== "" ? user.name : user.username}
                />
            </Link>
            
            <Link to={`/${user.username}`} className={`${styles.text} ${pictureSize > 32 ? "margin-left-16" : "margin-left-8"} flex-row`}>
                <div className="grid">
                    <h6 className="one-line" style={{ fontSize, maxWidth: 200, marginBottom: 2 }}>{user.username}</h6>
                </div>

                {(user.is_pro || user.is_moderator || user.contributor_tier) && !hideIcons &&
                    <ProIcon 
                    size={"small"} 
                    style={{ marginLeft: 9 }} 
                    is_pro={user.is_pro}
                    is_moderator={user.is_moderator}
                    contributor_tier={user.contributor_tier}
                    />
                }
                
                {user.is_verified && !hideIcons &&
                    <VerifiedIcon size={verifiedSize} style={{ marginLeft: user.is_pro ? 8 : 6 }} />
                }
            </Link>
        </div>
    )
}
