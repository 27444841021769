import moment from "moment";

export default function formatTime(time) {
    return `${Math.floor(time/60)}:${time%60 < 10 ? `0${Math.floor(time%60)}` : Math.floor(time%60)}`
}

export const formatTimeAgo = (time) => {
    const currentTime = moment().utc()
    const itemTime = moment(time).utc()
    let diffMethod = 'minutes';
    if (currentTime.diff(itemTime, 'weeks') >= 1) {
        if(currentTime.diff(itemTime, 'weeks') > 1) {
            diffMethod = 'weeks'
        } else {
            diffMethod = 'week'
        }

    } else if (currentTime.diff(itemTime, 'days') >= 1) {
        if(currentTime.diff(itemTime, 'days') > 1) {
            diffMethod = 'days'
        } else {
            diffMethod = 'day'
        }

    } else if (currentTime.diff(itemTime, 'hours') >= 1) {
        if(currentTime.diff(itemTime, 'hours') > 1) {
            diffMethod = 'hours'
        } else {
            diffMethod = 'hour'
        }

    } else if (currentTime.diff(itemTime, 'minutes') >= 1) {
        if(currentTime.diff(itemTime, 'minutes') > 1) {
            diffMethod = 'minutes'
        } else {
            diffMethod = 'minute'
        }

    } else {
        diffMethod = 'seconds'
    }

    const timeDiff = currentTime.diff(itemTime, diffMethod)

    if (timeDiff < 30 && diffMethod === 'seconds') {
        return "Just now"
    } else {
        return `${timeDiff}${diffMethod.substr(0, 1)}`
    }
}


export const secondsToString = (time) => {
    return new Date(time * 1000).toISOString().substr(11, 8)
}

export const stringToSeconds = (string) => {
    const arr = string.split(":")

    const hours = Number(arr[0])
    const min = Number(arr[1])
    const sec = Number(arr[2])

    if (!isNaN(hours) && !isNaN(min) && !isNaN(sec)) {
        return (hours*3600) + (min*60) + sec
    } else {
        return null
    }
}

export const secondsToFormattedTime = (time) => {
    if (!time || time === 0 || isNaN(time)) {
		return ""
	}
    if (time > 3600) {
        const hours = Number(new Date(time * 1000).toISOString().substr(11, 2));
        const mins = Number(new Date(time * 1000).toISOString().substr(14, 2));
        const secs = Number(new Date(time * 1000).toISOString().substr(17, 2));
        return `${hours} ${hours === 1 ? "hour" : "hours"} ${mins} min ${secs} sec`
    } else {
        return `${Math.floor(time/60)} minutes ${Math.floor(time%60)} seconds`
    }
}

export const stringForSeconds = (str) => {
    const times = str.split(":")
    const hour = Number(times[0])
    const minutes = Number(times[1])
    const seconds = Number(times[2])

    return (hour*3600) + (minutes*60) + seconds;
}