import styles from './optionsmenu.module.css'

import React, { useState } from 'react'

import Icon from '../Icon'
import { Link } from 'react-router-dom'

export default function OptionsMenu({ options, className, textClassName, style, textStyle }) {
    const [hoveringSpan, setHoveringSpan] = useState(false)

    return (
        <div className={`${styles.container} ${className} mobile-block`} style={style}>
            {options.filter(item => item).map((item, index) => {
                let component;
                if (item.element) {
                    component = item.element
                } else {
                    component = (
                        <React.Fragment key={`options-menu-${index}`}>
                            {item.icon && 
                                <Icon
                                className={`padding-left-8 padding-right-8 ${styles.icon} ${!hoveringSpan ? styles.notHoveringSpan : ""}`}
                                icon={item.icon}
                                color={item.iconColor && item.iconColor}
                                size={item.iconSize ? item.iconSize : 16}
                                />
                            }
                            
                            <h5
                            key={`optionsMenuItem-${item.text}-${index}`}
                            className={`${styles.text} ${!hoveringSpan ? styles.notHoveringSpan : ""} ${textClassName} ${item.subText && !item.icon && "padding-left-8"}`}
                            style={textStyle}
                            >
                                {item.text}
                            </h5>
    
                            {item.subText && 
                                <p
                                style={{ fontSize: 16 }}
                                onMouseEnter={() => item.onSpanPress && setHoveringSpan(true)}
                                onMouseLeave={() => item.onSpanPress && setHoveringSpan(false)}
                                onClick={item.onSpanPress ? item.onSpanPress : () => {}} 
                                className={`padding-left-8 padding-right-8 highDarkGrey ${item.onSpanPress ? styles.activeSpan : ""}`}
                                >
                                    {item.subText}
                                </p>
                            }
                        </React.Fragment>
                    )
                }
                
                if (item.target || item.onPress) {
                    return (
                        <a 
                        key={`options-menu-${index}`}
                        href={item.redirect}
                        target={item.target ? item.target : ""}
                        rel="noopener noreferrer"
                        className={` ${styles.item} cursor `}
                        onClick={() => {
                            if (!hoveringSpan && item.onPress) {
                                item.onPress()
                            }
                        }}
                        >
                            {component}
                        </a>
                    )
                }

                if (item.redirect) {
                    return (
                        <Link key={`options-menu-${index}`} to={item.redirect} className={`${styles.item} cursor`}>
                            {component}
                        </Link>
                    )
                }

                return (
                    <div key={`options-menu-${index}`} className={styles.item}>
                        {component}
                    </div>
                )
            })}
        </div>
    )
}
