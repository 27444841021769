import { checkNull } from "./format-text";

export function calculateNewRating(oldAverage, oldCount, latestRating) {
    const useOldAverage = checkNull(oldAverage);
    const useOldCount = checkNull(oldCount);
    const useLatestRating = checkNull(latestRating);

    const val = (((useOldAverage*useOldCount) + useLatestRating) / (useOldCount+1))
    return val
}

export function calculateUpdatedRating(oldAverage, count, oldRating, updatedRating) {
    const useOldAverage = checkNull(oldAverage);
    const useCount = checkNull(count);
    const useUpdatedRating = checkNull(updatedRating);
    const useOldRating = checkNull(oldRating);

    return ((((useOldAverage*useCount) - useOldRating) + useUpdatedRating) / useCount)
}