import { types } from '../constants/action-types'
import { post, put, get, patch, patchRaw, delete_ } from '../utils/fetch'
import { url } from '../config'

export const fetchThread = (uid, threadObj) => 
    get(types.FETCH_THREAD, `${url}/v2/threads/${uid}/`, { uid, threadObj });

export const fetchThreadFromSlug = (urlSlug) => 
    get(types.FETCH_THREAD, `${url}/v2/threads/from_url_slug/?url_slug=${urlSlug}`, { urlSlug });

export const getThreads = ({ limit=4 }) => 
    get(types.GET_THREADS, `${url}/v2/threads/?speedup=true&limit=${limit}`, { speedup: true, reset: true, limit: limit });

export const fetchMyPollAnswers = () => get(types.FETCH_MY_POLL_ANSWERS, `${url}/v2/poll_answers/`)

export const answerPoll = (threadUid, pollOptionUid, removedPollOptionUid) => post(
    types.ANSWER_POLL, 
    `${url}/v2/poll_answers/`, 
    { thread: threadUid, poll_option: pollOptionUid }, 
    { threadUid, pollOptionUid, removedPollOptionUid }
)

export const deletePollAnswer = (pollAnswerUid, threadUid, removedPollOptionUid) => delete_(
    types.DELETE_POLL_ANSWER, 
    `${url}/v2/poll_answers/${pollAnswerUid}/`, 
    { pollAnswerUid, threadUid, removedPollOptionUid }
)
