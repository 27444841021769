import { status, types } from '../constants/action-types'
import { get, post, delete_, patch } from '../utils/fetch'
import { url } from '../config'
import { deleteNormal } from '../utils/fetch-normal';

export const getAllReviewDrafts = () => get(types.GET_ALL_REVIEW_DRAFTS, `${url}/v2/drafts/?type=review&order_by=-modified_at`, { reset: true });

export const getAllListDrafts = () => get(types.GET_ALL_LIST_DRAFTS, `${url}/v2/drafts/?type=list&order_by=-modified_at`, { reset: true });

export const createDraft = (data, type, is_created) => post(types.CREATE_DRAFT, `${url}/v2/drafts/`, { data, type, is_created }, {})

export const deleteDraft = (draftUid, draftType) => delete_(types.DELETE_DRAFT, `${url}/v2/drafts/${draftUid}/`, { draftUid, draftType })

export const deleteMultipleDrafts = (draftUids, draftType) => (dispatch, getState) => {
    dispatch({ type: types.DELETE_DRAFT, meta: { status: status.BEGIN, params: { draftUids, draftType } } });

    Promise.all(
        draftUids.map((draftUid) => {
            return deleteNormal(`${url}/v2/drafts/${draftUid}/`)
        })
    )
    .then((res) => {
        dispatch({ type: types.DELETE_DRAFT, meta: { status: status.SUCCESS, params: { draftUids, draftType } }, payload: res });
        
    })
    .catch((err) => {
        dispatch({ type: types.DELETE_DRAFT, meta: { status: status.ERROR, params: { draftUids, draftType } }, payload: err });

    });
}

export const updateDraft = (draftUid, data) => patch(types.UPDATE_DRAFT, `${url}/v2/drafts/${draftUid}/`, data, { draftUid })
