function hashCode(str) {
    var hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export function createABTest(uid, testSalt) {
    const hash = hashCode(uid + testSalt)
    return hash % 2 === 0 ? "a" : "b"
}

export function createABCTest(uid, testSalt) {
    const hash = hashCode(uid + testSalt)
    return hash % 3 === 0 ? "a" : hash % 3 === 1 ? "b" : "c"
}

export function createABCDTest(uid, testSalt) {
    const hash = hashCode(uid + testSalt)
    return hash % 4 === 0 ? "a" : hash % 4 === 1 ? "b" : hash % 4 === 2 ? "c" : "d"
}

export function createABCDETest(uid, testSalt) {
    const hash = hashCode(uid + testSalt)
    return hash % 5 === 0 ? "a" : hash % 5 === 1 ? "b" : hash % 5 === 2 ? "c" : hash % 5 === 3 ? "d" : "e"
}

export function createOneTwentyTest(uid, testSalt) {
    return false
    /*
    const rand = parseInt(window.CryptoJS.MD5(uid + testSalt).toString().substring(0, 8), 16) / 0xffffffff
    return rand < 0.05 ? true : false
    */
}
