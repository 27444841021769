import React, { useEffect, useMemo } from 'react'

import { 
    light, lightSettings, 
    dark, darkSettings, 
    black, blackSettings, 
    green, greenSettings, 
    red, redSettings, 
    blue, blueSettings, 
    pink, pinkSettings, 
    teal, tealSettings 
} from '../../hooks/theme'

export default function ColorOverwrite({ colorTheme, styleName, setBackground }) {

    const themes = useMemo(() => {
        return ({
            "light": light,
            "dark": dark,
            "black": black,
            "green": green,
            "red": red,
            "blue": blue,
            "pink": pink,
            "teal": teal,
        })[colorTheme]
    }, [colorTheme])
    
    const settings = useMemo(() => {
        return ({
            "light": lightSettings,
            "dark": darkSettings,
            "black": blackSettings,
            "green": greenSettings,
            "red": redSettings,
            "blue": blueSettings,
            "pink": pinkSettings,
            "teal": tealSettings,
        })[colorTheme]
    }, [colorTheme])

    const lightestGreyNumbers = useMemo(() => {
        if (!themes) return null
        return themes.lightestGrey.replace("rgb(", "").replace(")", "")
    }, [themes])

    const whiteNumbers = useMemo(() => {
        if (!themes) return null
        return themes.white.replace("rgb(", "").replace(")", "")
    }, [themes])

    return (
        <style>
            {`
            ${setBackground ? `
            body {
                background: ${themes.lightestGrey} !important;
            }
            ` : ""}

            .${styleName} * {
                ${themes && Object.keys(themes).map(key => {
                    return `
                    --${key}: ${themes[key]};
                    --${key}007: ${themes[key].replace("rgb", "rgba").replace(")", ",0.07)")};
                    --${key}012: ${themes[key].replace("rgb", "rgba").replace(")", ",0.12)")};
                    --${key}020: ${themes[key].replace("rgb", "rgba").replace(")", ",0.20)")};
                    --${key}025: ${themes[key].replace("rgb", "rgba").replace(")", ",0.25)")};
                    --${key}030: ${themes[key].replace("rgb", "rgba").replace(")", ",0.30)")};
                    --${key}036: ${themes[key].replace("rgb", "rgba").replace(")", ",0.36)")};
                    --${key}040: ${themes[key].replace("rgb", "rgba").replace(")", ",0.40)")};
                    --${key}050: ${themes[key].replace("rgb", "rgba").replace(")", ",0.50)")};
                    --${key}060: ${themes[key].replace("rgb", "rgba").replace(")", ",0.60)")};
                    --${key}070: ${themes[key].replace("rgb", "rgba").replace(")", ",0.70)")};
                    --${key}075: ${themes[key].replace("rgb", "rgba").replace(")", ",0.75)")};
                    --${key}080: ${themes[key].replace("rgb", "rgba").replace(")", ",0.80)")};
                    --${key}085: ${themes[key].replace("rgb", "rgba").replace(")", ",0.85)")};
                    --${key}090: ${themes[key].replace("rgb", "rgba").replace(")", ",0.90)")};
                    --${key}092: ${themes[key].replace("rgb", "rgba").replace(")", ",0.92)")};
                    --${key}095: ${themes[key].replace("rgb", "rgba").replace(")", ",0.95)")};
                    --${key}097: ${themes[key].replace("rgb", "rgba").replace(")", ",0.97)")};
                    --${key}Transparent: ${themes[key].replace("rgb", "rgba").replace(")", ",0)")};
                    `
                }).join("\n")}
                
                ${settings && Object.keys(settings).map(key => {
                    return `--${key}: ${settings[key]};`
                }).join("\n")}

                --full-background-dark-sides: linear-gradient(
                    90deg,
                    rgba(${lightestGreyNumbers}, 1.000000) 0%,
                    rgba(${lightestGreyNumbers}, 0.978938) 2%,
                    rgba(${lightestGreyNumbers}, 0.913325) 4%,
                    rgba(${lightestGreyNumbers}, 0.805633) 6%,
                    rgba(${lightestGreyNumbers}, 0.670197) 8%,
                    rgba(${lightestGreyNumbers}, 0.530396) 10%,
                    rgba(${lightestGreyNumbers}, 0.407353) 12%,
                    rgba(${lightestGreyNumbers}, 0.312253) 14%,
                    rgba(${lightestGreyNumbers}, 0.247561) 16%,
                    rgba(${lightestGreyNumbers}, 0.211290) 18%,
                    rgba(${lightestGreyNumbers}, 0.200000) 20%,
                    rgba(${lightestGreyNumbers}, 0.200000) 80%,
                    rgba(${lightestGreyNumbers}, 0.211290) 82%,
                    rgba(${lightestGreyNumbers}, 0.247561) 84%,
                    rgba(${lightestGreyNumbers}, 0.312253) 86%,
                    rgba(${lightestGreyNumbers}, 0.407353) 88%,
                    rgba(${lightestGreyNumbers}, 0.530396) 90%,
                    rgba(${lightestGreyNumbers}, 0.670197) 92%,
                    rgba(${lightestGreyNumbers}, 0.805633) 94%,
                    rgba(${lightestGreyNumbers}, 0.913325) 96%,
                    rgba(${lightestGreyNumbers}, 0.978938) 98%,
                    rgba(${lightestGreyNumbers}, 1.000000) 100%
                ),
                linear-gradient(
                    0deg,
                    rgba(${lightestGreyNumbers}, 1.000000) 0%,
                    rgba(${lightestGreyNumbers}, 0.973673) 6%,
                    rgba(${lightestGreyNumbers}, 0.891657) 12%,
                    rgba(${lightestGreyNumbers}, 0.757042) 18%,
                    rgba(${lightestGreyNumbers}, 0.587746) 24%,
                    rgba(${lightestGreyNumbers}, 0.412995) 30%,
                    rgba(${lightestGreyNumbers}, 0.259191) 36%,
                    rgba(${lightestGreyNumbers}, 0.140316) 42%,
                    rgba(${lightestGreyNumbers}, 0.059451) 48%,
                    rgba(${lightestGreyNumbers}, 0.014113) 54%,
                    rgba(${lightestGreyNumbers}, 0.000000) 60%
                );

                --full-background-light-sides: linear-gradient(
                    90deg,
                    rgba(${whiteNumbers}, 1.000000) 0%,
                    rgba(${whiteNumbers}, 0.978938) 2%,
                    rgba(${whiteNumbers}, 0.913325) 4%,
                    rgba(${whiteNumbers}, 0.805633) 6%,
                    rgba(${whiteNumbers}, 0.670197) 8%,
                    rgba(${whiteNumbers}, 0.530396) 10%,
                    rgba(${whiteNumbers}, 0.407353) 12%,
                    rgba(${whiteNumbers}, 0.312253) 14%,
                    rgba(${whiteNumbers}, 0.247561) 16%,
                    rgba(${whiteNumbers}, 0.211290) 18%,
                    rgba(${whiteNumbers}, 0.200000) 20%,
                    rgba(${whiteNumbers}, 0.200000) 80%,
                    rgba(${whiteNumbers}, 0.211290) 82%,
                    rgba(${whiteNumbers}, 0.247561) 84%,
                    rgba(${whiteNumbers}, 0.312253) 86%,
                    rgba(${whiteNumbers}, 0.407353) 88%,
                    rgba(${whiteNumbers}, 0.530396) 90%,
                    rgba(${whiteNumbers}, 0.670197) 92%,
                    rgba(${whiteNumbers}, 0.805633) 94%,
                    rgba(${whiteNumbers}, 0.913325) 96%,
                    rgba(${whiteNumbers}, 0.978938) 98%,
                    rgba(${whiteNumbers}, 1.000000) 100%
                ),
                linear-gradient(
                    0deg,
                    rgba(${whiteNumbers}, 1.000000) 0%,
                    rgba(${whiteNumbers}, 0.973673) 6%,
                    rgba(${whiteNumbers}, 0.891657) 12%,
                    rgba(${whiteNumbers}, 0.757042) 18%,
                    rgba(${whiteNumbers}, 0.587746) 24%,
                    rgba(${whiteNumbers}, 0.412995) 30%,
                    rgba(${whiteNumbers}, 0.259191) 36%,
                    rgba(${whiteNumbers}, 0.140316) 42%,
                    rgba(${whiteNumbers}, 0.059451) 48%,
                    rgba(${whiteNumbers}, 0.014113) 54%,
                    rgba(${whiteNumbers}, 0.000000) 60%
                );

                --full-background-dark-no-sides: linear-gradient(
                    0deg,
                    rgba(${lightestGreyNumbers}, 1.000000) 0%,
                    rgba(${lightestGreyNumbers}, 0.978938) 6%,
                    rgba(${lightestGreyNumbers}, 0.913325) 12%,
                    rgba(${lightestGreyNumbers}, 0.805633) 18%,
                    rgba(${lightestGreyNumbers}, 0.670197) 24%,
                    rgba(${lightestGreyNumbers}, 0.530396) 30%,
                    rgba(${lightestGreyNumbers}, 0.407353) 36%,
                    rgba(${lightestGreyNumbers}, 0.312253) 42%,
                    rgba(${lightestGreyNumbers}, 0.247561) 48%,
                    rgba(${lightestGreyNumbers}, 0.211290) 54%,
                    rgba(${lightestGreyNumbers}, 0.200000) 60%
                );

                --full-background-light-no-sides: linear-gradient(
                    0deg,
                    rgba(${whiteNumbers}, 1.000000) 0%,
                    rgba(${whiteNumbers}, 0.978938) 6%,
                    rgba(${whiteNumbers}, 0.913325) 12%,
                    rgba(${whiteNumbers}, 0.805633) 18%,
                    rgba(${whiteNumbers}, 0.670197) 24%,
                    rgba(${whiteNumbers}, 0.530396) 30%,
                    rgba(${whiteNumbers}, 0.407353) 36%,
                    rgba(${whiteNumbers}, 0.312253) 42%,
                    rgba(${whiteNumbers}, 0.247561) 48%,
                    rgba(${whiteNumbers}, 0.211290) 54%,
                    rgba(${whiteNumbers}, 0.200000) 60%
                );
            }
            `}
        </style>
    )
}
