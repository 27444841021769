import styles from './newlistitem.module.css'

import React from 'react'
import moment from 'moment'
import Icon from '../../../../components/Icon'
import { usePopup } from '../../../../hooks/popup/popup'
import { Link } from 'react-router-dom'

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export default function NewListItem({ content }) {
    const { openPopup } = usePopup()

    return (
        <Link 
        to={"/list/new"}
        onClick={() => {
            openPopup(null)
        }}
        >
            <div className={`${styles.container} flex-row cursor`}>
                <div className={styles.cover}>
                    <Icon icon="add" />
                </div>

                <div className={`col-1 margin-left-16 margin-right-16`}>
                    <div className="grid">
                        <h6 className={`${styles.title} one-line`}>
                            New List
                        </h6>
                    </div>
                    
                    <div className="grid margin-top-4">
                        <p className={`${styles.artist} one-line`}>
                            Start a new list with {content.type === "artist" ? content.name : content.title} and more...
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    )
}