import styles from './profilepicture.module.css'

import React from 'react'
import Icon from '../Icon'
import { useTheme } from '../../hooks/theme'

export default function ProfilePicture({
    profilePicture=null,
    altText,
    size=60,
    className="",
    noShadow=false,
    clickable,
    forcedClickable,
    style,
    paddingTop,
    noBorder,
    icon="person",
    skipFrameTitle,
    skipBorder
}) {
    const { mode } = useTheme()
    return (
        <div
        className={`${styles.container} ${typeof size === "number" ? "" : styles.sized} ${className} ${clickable ? styles.clickable : ""} ${forcedClickable ? styles.forcedClickable : ""}`}
        style={typeof size === "number" ? {
            height: size,
            width: size,
            minWidth: size,
            paddingTop: paddingTop ? paddingTop : undefined,
            boxShadow: (noShadow || (mode === "light" && !profilePicture)) ? 'none' : undefined,
            ...style
        } : {
            ...size !== "100%" ? {
                height: size,
                width: size,
                minWidth: size,
            } : {},
            paddingTop: paddingTop ? paddingTop : undefined,
            boxShadow: (noShadow || (mode === "light" && !profilePicture)) ? 'none' : undefined,
            ...style
        }}
        >
            <div className={styles.iconContainer}>
                <Icon 
                className={styles.icon}
                icon={icon}
                size={typeof size === "number" ? size/2 : 40}
                color={"var(--darkLightGrey)"}
                />
            </div>

            {profilePicture &&
                <img
                className={styles.cover}
                src={profilePicture}
                alt={altText}
                />
            }
            
            {!skipBorder &&
                <div className={`${styles.border} ${noBorder ? styles.noBorder : ""}`}>
                    {clickable && !skipFrameTitle &&
                        <span className={styles.borderTitle}>{altText}</span>
                    }
                </div>
            }
        </div>
    )
}