import { status, types } from '../constants/action-types'
import { get, post } from '../utils/fetch'
import { url, sgwidgetToken } from '../config'
import { postNormal, postRawNormal } from '../utils/fetch-normal';

export const getAllArticles = () => 
    get(types.GET_ALL_ARTICLES, `${url}/v2/articles/?speedup=true`, { reset: true, speedup: true, limit: 24 });

export const getArticle = (uid) => 
    get(types.GET_ARTICLE, `${url}/v2/articles/${uid}`, { uid });


export const signUpForNewsletter = (email) => (dispatch, getState) => {
    dispatch({ 
        type: types.SIGN_UP_FOR_NEWSLETTER, 
        meta: { status: status.BEGIN } 
    })

    const formData = new FormData()

    formData.append("email", email)
    formData.append("token", sgwidgetToken)

    const uid = getState().auth.uid;
    const username = getState().me.username;
    const is_pro = getState().me.is_pro;

    if (uid && username) {
        formData.append("uid", uid)
        formData.append("username", username)
        formData.append("is_pro", is_pro ? 1 : 0)
    }

    postRawNormal(`https://app.sgwidget.com/v2/api/newsletter-signup`, formData)
    .then((res) => {
        dispatch({ 
            type: types.SIGN_UP_FOR_NEWSLETTER, 
            payload: res,
            meta: { status: status.SUCCESS } 
        })
    })
    .catch((err) => {
        dispatch({ 
            type: types.SIGN_UP_FOR_NEWSLETTER, 
            payload: err,
            meta: { status: status.ERROR } 
        })
    })
}