import styles from './musicdropdown.module.css'

import React, { useState, useEffect, useRef } from 'react'

import { useMediaQuery } from 'react-responsive'
import Dropdown from '../../../../components/Dropdown'
import Button from '../../../../components/Button'
import { Link } from 'react-router-dom'
import Icon from '../../../../components/Icon'

export default function MusicDropdown({ className, style }) {
    //Responsive
    const isBigScreen = useMediaQuery({ maxWidth: 1250 })
    const isMidScreen = useMediaQuery({ maxWidth: 1100 })
    const is1000Screen = useMediaQuery({ maxWidth: 1000 })
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isTablet = useMediaQuery({ maxWidth: 768 })
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Options
    const options = [
        {
            text: 'Top 250 Albums',
            redirect: `/albums?view=detailed&order_by=top&record_type=album`,
            element: (
                <div className={`${styles.icon} ${styles.crown}`} />
            )
        },
        {
            text: 'Hot New Releases',
            redirect: `/albums?order_by=new`,
            element: (
                <div className={`${styles.icon} ${styles.fire}`} />
            )
        },
        {
            text: 'Popular This Week',
            redirect: `/albums?order_by=week`,
            element: (
                <div className={`${styles.icon} ${styles.trend}`} />
            )
        },
        {
            text: 'Top 250 Artists',
            redirect: `/artists?view=detailed&order_by=top`
        },
        {
            text: 'Top 250 Tracks',
            redirect: `/tracks?view=detailed&order_by=top`
        },
        {
            text: 'Most Popular Albums',
            redirect: `/albums`
        },
        {
            text: 'Most Popular Artists',
            redirect: `/artists`
        },
        {
            text: 'Most Popular Tracks',
            redirect: `/tracks`
        },
    ]

    //Visible
    const [visible, setVisible] = useState(false)

    return (
        <div className={`flex-row ${className}`}  style={{ position: 'relative', display: 'flex', justifyContent: 'center', ...style }}>
            <div onClick={() => setVisible(!visible)}>
                <Button
                height={35}
                style={{ borderRadius: isSmallScreen ? 100 : "var(--border-radius-small)" }}
                text="Music"
                textStyle={{ fontSize: 14 }}
                type="transparent"
                />
            </div>

            <Dropdown 
            simpleAnimation
            visible={visible}
            appendId="music" 
            hideDropdown={() => setVisible(false)} 
            offset={44}
            >
                {options.filter(i => i).map(item => (
                    <Link
                    to={item.redirect}
                    onClick={() => setVisible(false)}
                    key={`music-dropdown-option-${item.text}`}
                    className={`flex-row cursor ${styles.menuItem}`}
                    >
                        {item.element && item.element}
                        {!item.element && <div className={styles.icon} />}
                        

                        <h6 className={`${styles.menuItemText} margin-right-16`}>{item.text}</h6>
                    </Link>
                ))}
            </Dropdown>
            
        </div>
    )
}
