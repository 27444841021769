import styles from './boldlistbutton.module.css'

import React from 'react'
import { Link } from 'react-router-dom'

function BoldListButton({ text, redirect, onPress, disabled, subText }) {
    return (
        <Link 
        onClick={(e) => {
            if (disabled) {
                e.preventDefault()
                return
            }
            if (onPress) {
                e.preventDefault()
                onPress()
            }
        }} 
        to={onPress ? "#" : redirect} 
        className={`${(redirect || onPress) ? styles.a : ""} ${onPress ? "cursor" : ""} ${disabled ? styles.disabled : ""}`}
        >
            <div className={`${styles.container} ${disabled ? styles.disabled : ""}`}>
                <h5 className={`${styles.text} ${!redirect && !onPress ? "grey" : ""}`}>{text}<span className={styles.subText}>{subText}</span></h5>
            </div>
        </Link>
    )
}

export default React.memo(BoldListButton)