import React, { useState, useEffect } from 'react';

export function useSize() {
    const [{width, height}, setSize] = useState({ width: window.innerWidth, height: window.innerHeight })

    function handleResize(ev) {
        setSize({ width: ev.target.innerWidth, height: ev.target.innerHeight })
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        return(() => {
            window.removeEventListener("resize", handleResize)
        })
    }, []);

  return ({
    width,
    height
  })
}