import { status, types } from '../constants/action-types'
import { post, put, get, patch, patchRaw, delete_ } from '../utils/fetch'
import { url } from '../config'
import { postNormal } from '../utils/fetch-normal';
import moment from 'moment';

export const getLatestRatings = () => get(types.GET_LATEST_RATINGS, `${url}/v2/ratings/latest/?speedup=true&content_type=album`, {});

export const fetchRating = (uid) => 
    get(types.FETCH_RATING, `${url}/v2/ratings/${uid}/`, {});

export const fetchRatingFromSlug = (urlSlug) => 
    get(types.FETCH_RATING, `${url}/v2/ratings/from_url_slug/?url_slug=${urlSlug}`, {});

export const deleteRating = (uid) => 
    delete_(types.DELETE_RATING, `${url}/v2/ratings/${uid}/`, { uid });

export const updateRating = (uid, body, cacheRating=false, oldRating) => 
    patch(types.UPDATE_RATING, `${url}/v2/ratings/${uid}/`, body, { uid, reset: true, cacheRating, oldRating });

export const createRating = (contentId, contentType, rating,  isPrivate=false, listenedAt, firstListen, showListenedAt, cacheRating=false) => 
    post(types.CREATE_RATING, `${url}/v2/ratings/`, {
        content_id: contentId,
        content_type: contentType,
        rating,
        listened_at: listenedAt,
        private: isPrivate,
        first_listen: firstListen,
        show_listened_at: showListenedAt,
    }, {
        cacheRating
    });

export const createRatingWithContent = (
    contentId,
    contentType,
    fromDeezer,
    rating, 
    isPrivate=false, 
    cacheRating=false
) => (dispatch, getState) => {

    dispatch({
        type: types.CREATE_RATING,
        meta: {
            params: { cacheRating },
            status: status.BEGIN,
        }
    })

    postNormal(`${url}/v2/${fromDeezer ? "dz_" : ""}${contentType}s/`, { id: contentId }).then((res) => {
        postNormal(`${url}/v2/ratings/`, { content_id: res.id, content_type: contentType, rating: rating, private: isPrivate }).then((done) => {
            dispatch({ 
                type: types.CREATE_RATING, 
                payload: done, 
                meta: { 
                    params: { cacheRating },
                    status: status.SUCCESS 
                } 
            })

        }).catch((err) => {
            dispatch({ 
                type: types.CREATE_RATING, 
                payload: err, 
                meta: { 
                    params: { cacheRating },
                    status: status.ERROR 
                } 
            })

        })
    }).catch((err) => {
        dispatch({ 
            type: types.CREATE_RATING, 
            payload: err, 
            meta: {
                params: { cacheRating },
                status: status.ERROR 
            } 
        })

    })
    
}