import { get, post, delete_ } from "../utils/fetch";
import { types } from "../constants/action-types";
import { url } from "../config";

export const likeContent = (contentType, contentUid, detailed, rootCommentContentType=null, rootCommentContentUid=null, threadUid=null) => 
    post(types.LIKE_CONTENT, `${url}/v2/likes/`, {
        content_type: contentType,
        content_uid: contentUid
    }, {
        uid: contentUid,
        contentType,
        rootCommentContentType,
        rootCommentContentUid,
        detailed,
        threadUid
    })

export const deleteLike = (contentType, contentUid, likeUid, detailed, rootCommentContentType=null, rootCommentContentUid=null, threadUid=null) => 
    delete_(types.DELETE_LIKE, `${url}/v2/likes/${likeUid}/`, {
        uid: contentUid,
        contentType,
        likeUid,
        rootCommentContentType,
        rootCommentContentUid,
        detailed,
        threadUid
    })

export const getLikes = ({
    order_by= "-created_at",
    content_uid= "",
    content_type= "",
}) =>
    get(types.GET_LIKES, `${url}/v2/likes/?order_by=${order_by}&content_uid=${content_uid}&content_type=${content_type}&speedup=true`, {
        content_type,
        content_uid,
        reset: true,
        speedup: true,
        limit: 24
    })
