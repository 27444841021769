import { types, status } from '../constants/action-types'
import { post, put, get, patch, patchRaw, delete_, postRaw } from '../utils/fetch'
import { url } from '../config'


export const getRatingsCount = () => get(types.GET_RATINGS_COUNT, `${url}/v2/ratings/`);

// History
export const addToMusicHistory = (content) => (dispatch, getState) => dispatch({ type: types.ADD_TO_MUSIC_HISTORY, payload: content })
export const removeFromMusicHistory = (contentId, contentType) => (dispatch, getState) => dispatch({ type: types.REMOVE_FROM_MUSIC_HISTORY, payload: { id: contentId, type: contentType } })

export const addToListsHistory = (list) => (dispatch, getState) => dispatch({ type: types.ADD_TO_LISTS_HISTORY, payload: list })
export const removeFromListsHistory = (listUid) => (dispatch, getState) => dispatch({ type: types.REMOVE_FROM_LISTS_HISTORY, payload: listUid })
export const clearListsHistory = () => (dispatch, getState) => dispatch({ type: types.CLEAR_LISTS_HISTORY })

export const addToUsersHistory = (user) => (dispatch, getState) => dispatch({ type: types.ADD_TO_USERS_HISTORY, payload: user })
export const removeFromUsersHistory = (userUid) => (dispatch, getState) => dispatch({ type: types.REMOVE_FROM_USERS_HISTORY, payload: userUid })
