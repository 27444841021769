import styles from './truncate.module.css'

import React, { useEffect, useState } from 'react'

export default function Truncate({ 
    text, 
    appendId,
    height=200,
    breakHeight=240,
    disabled,
    textClassName,
    textStyle,
    className,
    style,
    showFade,
}) {

    //State
    const [calculatedHeight, setCalculatedHeight] = useState(null)
    const [open, setOpen] = useState(false)

    //Calculate height
    useEffect(() => {
        const element = document.getElementById(`truncate-${appendId}`)
        if (element) {
            setCalculatedHeight(element.clientHeight)
        }
    }, [])

    const enabled = !open && calculatedHeight && calculatedHeight > breakHeight;

    return (
        <div className={className} style={{ position: 'relative', ...style }}>
            <div
            className={`${styles.container}`}
            style={{ height: enabled ? height : "initial", maxHeight: !calculatedHeight ? height : "initial" }}
            >
                <div id={`truncate-${appendId}`}>
                    <p className={`${textClassName}`} style={textStyle}>{text}</p>
                </div>
            </div>

            {(!disabled || showFade) && enabled &&
                <div className={`${styles.fade} ${styles.dark} ${showFade ? styles.showFade : ""}`} />
            }
            
            {(!disabled || showFade) && enabled &&
                <div className={`${styles.fade} ${styles.hover} ${showFade ? styles.showFade : ""}`} />
            }
            
            {(!disabled || showFade) && enabled &&
                <div className={`${styles.fade} ${styles.active} ${showFade ? styles.showFade : ""}`} />
            }

            {!disabled && enabled &&
                <div className="flex-row">
                    <div onClick={() => setOpen(true)} className={`${styles.btn} link-clickable cursor`}>
                        <p className="black">Read more...</p>
                    </div>
                </div>
            }
        </div>
    )
}
