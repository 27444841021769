import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { handleReducer, handleReducerWithFiltering } from '../utils/reducer-helpers';

const defaultGifs = {
	gifs: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },
    trendingGifs: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },
    categories: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },
    trendingTerms: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },
    
    autoComplete: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },
    suggestedSearch: {
        data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
    },

    cropGif: {
        status: status.DEFAULT,
        result: null,
        type: null,
    },

	errorMessage: null,
};

const gifs = (state = defaultGifs, action) => {
	let reset = action.meta && action.meta.params ? action.meta.params.reset : false
    let uid = action.meta && action.meta.params ? action.meta.params.uid : null

    let cropType = action.meta && action.meta.params ? action.meta.params.cropType : null

	switch (action.type) {
		//Search gifs
        case types.GET_GIF_RESULTS:
			return handleReducer(state, action, 'gifs')
        
		case types.CLEAR_GIF_RESULTS:
			return {
                ...state,
                gifs: {
                    data: [],
                    status: status.DEFAULT,
                    next: null,
                    count: 0,
                },
            }

		//Get list of gif categories
		case types.GET_TRENDING_GIFS:
			return handleReducer(state, action, 'trendingGifs')

		case types.GET_GIF_CATEGORIES:
			return handleReducer(state, action, 'categories')
		
        case types.GET_GIF_TRENDING_TERMS:
			return handleReducer(state, action, 'trendingTerms')

		//Get any users for search and update the users object
		case types.GET_GIF_AUTOCOMPLETE:
			switch (action.meta.status) {
                case status.BEGIN:
                    return {
                        ...state,
                        autoComplete: {
                            ...state.autoComplete,
                            next: null,
                            status: status.BEGIN,
                            controller: (action.meta && action.meta.controller) ? action.meta.controller : null
                        },
                    }
                case status.SUCCESS:
                    return {
                        ...state,
                        autoComplete: {
                            data: action.payload.results,
                            count: action.payload.count,
                            next: action.payload.next,
                            status: status.SUCCESS,
                            controller: null
                        },
                    }
                case status.ERROR:
                    return {
                        ...state,
                        autoComplete: {
                            data: [],
                            count: null,
                            next: null,
                            status: status.ERROR,
                            controller: null
                        },
                    }
            }
		
        case types.GET_GIF_SUGGESTED_SEARCH:
			switch (action.meta.status) {
                case status.BEGIN:
                    return {
                        ...state,
                        suggestedSearch: {
                            ...state.suggestedSearch,
                            next: null,
                            status: status.BEGIN,
                            controller: (action.meta && action.meta.controller) ? action.meta.controller : null
                        },
                    }
                case status.SUCCESS:
                    return {
                        ...state,
                        suggestedSearch: {
                            data: action.payload.results,
                            count: action.payload.count,
                            next: action.payload.next,
                            status: status.SUCCESS,
                            controller: null
                        },
                    }
                case status.ERROR:
                    return {
                        ...state,
                        suggestedSearch: {
                            data: [],
                            count: null,
                            next: null,
                            status: status.ERROR,
                            controller: null
                        },
                    }
            }

		case types.CLEAR_GIF_SEARCHES:
			return {
                ...state,
                autoComplete: {
                    data: [],
                    status: status.DEFAULT,
                    next: null,
                    count: 0,
                },
                suggestedSearch: {
                    data: [],
                    status: status.DEFAULT,
                    next: null,
                    count: 0,
                },
            }

		case types.CROP_GIF:
			switch (action.meta.status) {
                case status.BEGIN:
                    return {
                        ...state,
                        cropGif: {
                            status: status.BEGIN,
                            result: null,
                            type: cropType,
                        },
                    }
                case status.SUCCESS:
                    return {
                        ...state,
                        cropGif: {
                            status: status.SUCCESS,
                            result: action.payload,
                            type: cropType,
                        },
                    }
                case status.ERROR:
                    return {
                        ...state,
                        cropGif: {
                            status: status.ERROR,
                            result: errorToString(action.error),
                            type: cropType,
                        },
                    }
            }
            
		default:
			return state;
	}
};

export default gifs;