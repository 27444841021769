import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error';
import { handleNoPaginationReducer } from '../utils/reducer-helpers';


const defaultDrafts = {
	allReviewDrafts: {
        data: [],
        status: status.DEFAULT,
        next: null,
        count: 0,
    },
	allListDrafts: {
        data: [],
        status: status.DEFAULT,
        next: null,
        count: 0,
    },

	createDraftStatus: status.DEFAULT,
	lastCreatedDraft: null,
	
	deleteDraftStatus: status.DEFAULT,
	lastDeletedDraftType: null,
	
	updateDraftStatus: status.DEFAULT,
	lastUpdatedDraft: null,

	errorMessage: null
};

const drafts = (state = defaultDrafts, action) => {
	switch (action.type) {

		case types.GET_ALL_REVIEW_DRAFTS:
			return handleNoPaginationReducer(state, action, "allReviewDrafts")

		case types.GET_ALL_LIST_DRAFTS:
			return handleNoPaginationReducer(state, action, "allListDrafts")

		case types.CREATE_DRAFT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createDraftStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						createDraftStatus: status.SUCCESS,
						lastCreatedDraft: action.payload
					}
				case status.ERROR:
					return {
						...state,
						createDraftStatus: status.ERROR,
						lastCreatedDraft: null,
						errorMessage: errorToString(action.payload),
					}
			}
		
		case types.DELETE_DRAFT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						deleteDraftStatus: status.BEGIN,
						lastDeletedDraftType: action.meta && action.meta.params ? action.meta.params.draftType : null
					}
				case status.SUCCESS:
					return {
						...state,
						deleteDraftStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						deleteDraftStatus: status.ERROR,
						lastDeletedDraftType: null,
						errorMessage: errorToString(action.payload),
					}
			}
		
		case types.UPDATE_DRAFT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						updateDraftStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						updateDraftStatus: status.SUCCESS,
						lastUpdatedDraft: action.payload
					}
				case status.ERROR:
					return {
						...state,
						updateDraftStatus: status.ERROR,
						lastUpdatedDraft: null,
						errorMessage: errorToString(action.payload),
					}
			}

		default:
			return state;
	}
};

export default drafts;
