import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'
import { handleReducer } from '../utils/reducer-helpers';

const defaultReviews = {
	reviews: {},
	fetchReviewStatus: status.DEFAULT,
	lastFetchedReviewUid: null,

	allReviews: {
		data: [],
		count: null,
		next: null,
		status: status.DEFAULT,
	},

    lastUpdatedReview: null,
    lastUpdatedReviewUid: null,
	updateReviewStatus: status.DEFAULT,

	lastCreatedReview: null,
	createReviewStatus: status.DEFAULT,
	
	lastDeletedReviewUid: null,
	deleteReviewStatus: status.DEFAULT,

	createReviewCommentStatus: status.DEFAULT,
	
	errorMessage: null,

	translatedReviews: {},
	translateReviewStatus: status.DEFAULT,

	detectReviewLanguageStatus: status.DEFAULT,

};

const reviews = (state = defaultReviews, action) => {
	let uid = action.meta && action.meta.params ? action.meta.params.uid : null

	switch (action.type) {
		case types.SET_INIT_REVIEW:
			return {
				...state,
				reviews: {
					...state.reviews,
					[action.payload.uid]: action.payload
				},
				fetchReviewStatus: status.SUCCESS,
				lastFetchedReviewUid: action.payload.uid,
				errorMessage: null,
			}

		case types.FETCH_REVIEW:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        fetchReviewStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
                        reviews: {
							...state.reviews,
							[action.payload.uid]: action.payload
						},
						fetchReviewStatus: status.SUCCESS,
						lastFetchedReviewUid: action.payload.uid,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
                        fetchReviewStatus: status.ERROR,
						lastFetchedReviewUid: null,
                        errorMessage: errorToString(action.payload),
					}
			}

		case types.UPDATE_REVIEW:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
						updateReviewStatus: status.BEGIN,
						lastUpdatedReviewUid: uid
					}
				case status.SUCCESS:
					return {
                        ...state,
                        lastUpdatedReview: action.payload,
						updateReviewStatus: status.SUCCESS,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
                        updateReviewStatus: status.ERROR,
                        errorMessage: errorToString(action.payload),
					}
			}

		case types.CREATE_REVIEW:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						createReviewStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						reviews: {
							...state.reviews,
							[action.payload.uid]: action.payload
						},
						lastCreatedReview: action.payload,
						createReviewStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createReviewStatus: status.ERROR,
					}
			}

		case types.DELETE_REVIEW:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						deleteReviewStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
						...state,
						lastDeletedReviewUid: uid,
						deleteReviewStatus: status.SUCCESS,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						deleteReviewStatus: status.ERROR,
					}
			}

		case types.GET_ALL_REVIEWS:
			return handleReducer(state, action, 'allReviews')

		case types.LIKE_CONTENT:
			if (action.meta.params.contentType !== "review") return state
			if (!action.meta.params.detailed) return state

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						reviews: {
							...state.reviews,
							[action.meta.params.uid]: {
								...state.reviews[action.meta.params.uid],
								like_count: state.reviews[action.meta.params.uid].like_count + 1
							}
						},
					}
				case status.SUCCESS:
                    return state
				case status.ERROR:
					return {
						...state,
						reviews: {
							...state.reviews,
							[action.meta.params.uid]: {
								...state.reviews[action.meta.params.uid],
								like_count: state.reviews[action.meta.params.uid].like_count - 1
							}
						},
					}
			}

		case types.DELETE_LIKE:
			if (action.meta.params.contentType !== "review") return state
			if (!action.meta.params.detailed) return state

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						reviews: {
							...state.reviews,
							[action.meta.params.uid]: {
								...state.reviews[action.meta.params.uid],
								like_count: state.reviews[action.meta.params.uid].like_count - 1
							}
						},
					}
				case status.SUCCESS:
                    return state
				case status.ERROR:
					return {
						...state,
						reviews: {
							...state.reviews,
							[action.meta.params.uid]: {
								...state.reviews[action.meta.params.uid],
								like_count: state.reviews[action.meta.params.uid].like_count + 1
							}
						},
					}
			}

		case types.TRANSLATE_REVIEW:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        translateReviewStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
                        translatedReviews: {
							...state.translatedReviews,
							[uid]: action.payload.translated_text
						},
						translateReviewStatus: status.SUCCESS,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
						translatedReviews: {
							...state.translatedReviews,
							[uid]: null
						},
                        translateReviewStatus: status.ERROR,
                        errorMessage: errorToString(action.payload),
					}
			}

		case types.DETECT_REVIEW_LANGUAGE:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
                        ...state,
                        detectReviewLanguageStatus: status.BEGIN,
					}
				case status.SUCCESS:
					return {
                        ...state,
                        reviews: {
							...state.reviews,
							[uid]: {
								...state.reviews[uid],
								detected_language: action.payload.detected_language
							}
						},
						detectReviewLanguageStatus: status.SUCCESS,
						errorMessage: null,
					}
				case status.ERROR:
					return {
                        ...state,
						reviews: {
							...state.reviews,
							[uid]: {
								...state.reviews[uid],
								detected_language: null
							}
						},
                        detectReviewLanguageStatus: status.ERROR,
                        errorMessage: errorToString(action.payload),
					}
			}
	
		default:
			return state;
	}
};

export default reviews;