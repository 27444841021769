import styles from './polloption.module.css'

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePopup } from '../../../../hooks/popup/popup'

import Icon from '../../../Icon'
import { answerPoll } from '../../../../actions/threads-actions'

function PollOption({
    option, 
    isFirst, 
    threadUid, 
    totalAnswerCount, 
    pollAnswerObj,
    isSmall,
}) {
    //General
    const dispatch = useDispatch()
    const { openPopup } = usePopup()

    //Auth
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

    return (
        <div 
        className={`flex-row link-clickable cursor ${isFirst ? "margin-top-16" : "margin-top-8"} ${styles.container} ${pollAnswerObj?.poll_option === option.uid ? styles.active : ""} ${isSmall ? styles.isSmall : ""}`}
        onClick={() => {
            if (!isLoggedIn) {
                openPopup("SignUp")
                return
            }

            if (pollAnswerObj?.poll_option !== option.uid) {
                dispatch(
                    answerPoll(threadUid, option.uid, pollAnswerObj?.poll_option)
                )
            } else {
                openPopup("DeletePollOption", {
                    pollAnswerUid: pollAnswerObj.uid,
                    threadUid: threadUid,
                    pollOptionUid: option.uid
                })
            }
        }}
        >
            <div className='grid col-1'>
                <p className={`one-line ${styles.option}`}>
                    {option.option}
                </p>
            </div>

            {pollAnswerObj?.poll_option === option.uid &&
                <Icon icon="check" size={16} color={"var(--green)"} className='margin-right-4' />
            }
            
            {pollAnswerObj &&
                <p className={`one-line ${styles.answerCount}`}>
                    {option.answer_count}
                </p>
            }

            <div 
            className={styles.answerCountBar}
            style={{
                width: pollAnswerObj?.poll_option ? `${option.answer_count / Math.max(totalAnswerCount || 1) * 100}%` : 0,
            }}
            />
        </div>
    )
}

export default React.memo(PollOption)