import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import StickyBox from 'react-sticky-box'

import { useAudio } from '../../hooks/audio/audio'

export default function NewStickyScrolling({ enabled, offsetTop, offsetBottom=64, children, className, style }) {
    //Responsive
    const isTablet = useMediaQuery({ maxWidth: 768 })
    
    //Data
    const isShowingBottomAd = useSelector(state => state.local.bottomAdVisible)
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    const isPro = useSelector((state) => state.me.is_pro)
    const allow_web_ads = useSelector((state) => state.auth.healthCheck.allow_web_ads)
    
    //Hooks
    const { isMounted } = useAudio()

    //Variables
    const defaultOffsetTop = 56
    const defaultOffsetBottom = offsetBottom + (isMounted ? 87 : 0) + (isShowingBottomAd ? 72 : !((isLoggedIn && isPro) || !allow_web_ads) ? 54 : 0)
    
    if (!enabled) {
        return (
            <div className={className} style={style}>
                {children}
            </div>
        )
    }
        
    return (
        <StickyBox 
        offsetBottom={defaultOffsetBottom} 
        offsetTop={typeof offsetTop === "number" ? offsetTop : defaultOffsetTop} 
        className={className} 
        style={style}
        >
            {children}
        </StickyBox>
    )
}
