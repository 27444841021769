import './SignInBtn.css'

import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import AppleLogin from 'react-apple-login';

import { status } from '../../../../constants/action-types';

import { logInWithSocial } from '../../../../actions/auth-actions';

import { useAlert } from '../../../../hooks/alert/alert';

import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';

export default function SignInWithApple({ className, isSignIn, fromPage }) {
    //General
    const dispatch = useDispatch()
    const location = useLocation()
    const { openAlert } = useAlert()

    //Data
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const logInStatus = useSelector(state => state.auth.logInStatus)

    //Satte
    const [started, setStarted] = useState(false)

    console.log(window.location.origin + location.pathname)
    return (
        <AppleLogin
        //responseType
        //state
        clientId="com.musicboard.app.web"
        redirectURI={window.location.origin + location.pathname}
        //autoLoad={true}
        scope="email name"
        responseMode="query"
        usePopup={true}
        callback={(res) => {
            console.log({res})
            console.info({res})
            console.warn({res})
            if (res && res.authorization && res.authorization.id_token) {
                setStarted(true)
                dispatch(
                    logInWithSocial(
                        res.authorization.id_token, 
                        "apple-id-web", 
                        null
                    )
                )
            } else {
                if (res && res.error && res.error.error === "popup_closed_by_user") {
                    return
                }
                openAlert({
                    p: "Log in failed. Try again or contact us if the issue persists.",
                    style: "red",
                    timer: 3500,
                })
            }
        }}
        render={({ onClick, disabled }) => (
            <div className={`SignInBtn-wrapper ${className}`}>
                <Button
                type="secondary-two"
                text={isSignIn ? "Sign in with Apple" : "Sign up with Apple"}
                className={`col-1`}
                onClicked={onClick}
                disabled={disabled || logInStatus === status.BEGIN}
                loading={logInStatus === status.BEGIN && started}
                height={41}
                />

                <div className='SignInBtn-icon flex-center' style={{ width: 24, height: 24 }}>
                    <Icon icon={"apple-login"} size={24} />
                </div>
            </div>
        )}
        />
    )
}
