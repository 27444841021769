import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export default function ScrollToTop({ children }) {
    const location = useLocation()

    const [previous, setPrevious] = useState(null)
    const [truePrevious, setTruePrevious] = useState(null)

    function shouldSkipScroll() {
        if (!previous) {
            return false;
        }

        const previousFiltered = previous.pathname
            .replace("reviews/", "").replace("reviews", "")
            .replace("ratings/", "").replace("ratings", "")
            .replace("releases/", "").replace("releases", "")
            .replace("lists/", "").replace("lists", "")
        const locationFiltered = location.pathname
            .replace("reviews/", "").replace("reviews", "")
            .replace("ratings/", "").replace("ratings", "")
            .replace("releases/", "").replace("releases", "")
            .replace("lists/", "").replace("lists", "")

        return (
            (locationFiltered.includes("album") || locationFiltered.includes("artist") || locationFiltered.includes("track")) &&
            previousFiltered === locationFiltered
        )

    }

    function shouldSkipListScroll() {
        return location.pathname.includes("/list/") && previous && location.pathname === previous.pathname
    }

    function shouldSkipGoBack() {
        return truePrevious && location.pathname + location.search === truePrevious.pathname + truePrevious.search
    }

    useEffect(() => {
        if (previous) {
            setTruePrevious(previous)
        }
        setPrevious(location)
        
        if (shouldSkipScroll()) {
            return;
        }

        if (shouldSkipListScroll()) {
            return;
        }

        if (shouldSkipGoBack()) {
            return;
        }

        window.scrollTo(0,0)
    }, [ location ])
    
    return (
        children
    )
}
