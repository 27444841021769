import styles from './readmoretext.module.css'

import React, { useState } from 'react'

export default function ReadMoreText({
    children,
    height=216,
    disabled,
    className=""
}) {

    const [open, setOpen] = useState(false)

    if (disabled) {
        return (
            <div className={className}>
                {children}
            </div>
        )
    }

    return (
        <div
        className={`${styles.container} ${open ? styles.open : ""} ${className}`}
        style={{height: open ? "initial" : height}}
        >
            {children}

            {!open &&
                <div className={styles.fade} />
            }

            {!open &&
                <p onClick={() => setOpen(!open)} className={`${styles.btn} ${open ? styles.open : ""}`}>{open ? "Close" : "Read more"}</p>
            }
        </div>
    )
}
