import styles from './userratingitem.module.css'

import React from 'react'
import ProfilePicture from '../ProfilePicture'
import Stars from '../Stars'
import Icon from '../Icon'
import { useTheme } from '../../hooks/theme'
import { Link } from 'react-router-dom'
import UserProfilePicture from '../UserProfilePicture'

function UserRatingItem({rating, size='100%'}) {
    const { colors } = useTheme()

    let href = null
    if (rating.review_url_slug) {
        href = rating.review_url_slug
    } else if (rating.review_uid) {
        href = `/review/${rating.review_uid}`
    } else {
        href = `/rating/${rating.uid}`
    }

    return (
        <Link to={href} className={`${styles.container} col-1 align-center cursor`}>
            <UserProfilePicture 
            size={size} 
            clickable 
            profilePicture={rating.creator.profile_picture?.includes(".gif") ? rating.creator.profile_picture : rating.creator.profile_picture_small || rating.creator.profile_picture} 
            profileFrame={rating.creator.is_pro && rating.creator.tier === 1 ? rating.creator.profile_frame : null}
            skipOuter 
            altText={rating.creator.name !== "" ? rating.creator.name : rating.creator.username}
            />

            <div className="grid">
                <h6 className="margin-top-8 one-line text-center">{rating.creator.username}</h6>
            </div>

            <div className="flex-row margin-top-4">
                {rating.rating !== null &&
                    <Stars iconSize={14} rating={rating.rating} />
                }
                
                {rating.rating === null &&
                    <Icon icon="headphones" size={14} color={colors.grey} />
                }
            </div>
        </Link>
    )
}

export default React.memo(UserRatingItem)