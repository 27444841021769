import React from "react"
import ContentLoader from "react-content-loader"

export default function RatingDistributionLoader(props) {
    return(
        <ContentLoader 
        speed={2}
        width={'100%'}
        height={63}
        backgroundColor="var(--lightGrey)"
        foregroundColor="var(--darkLightGrey)"
        {...props}
        >
            <rect x="0.25" y={(40-5).toString()} rx="2" ry="2" width="9.5%" height="5" />
            <rect x="10.25%" y={(40-8).toString()} rx="2" ry="2" width="9.5%" height="8" />
            <rect x="20.25%" y={(40-12).toString()} rx="2" ry="2" width="9.5%" height="12" />
            <rect x="30.25%" y={(40-20).toString()} rx="2" ry="2" width="9.5%" height="20" />
            <rect x="40.25%" y={(40-15).toString()} rx="2" ry="2" width="9.5%" height="15" />
            <rect x="50.25%" y={(40-24).toString()} rx="2" ry="2" width="9.5%" height="24" />
            <rect x="60.25%" y={(40-32).toString()} rx="2" ry="2" width="9.5%" height="32" />
            <rect x="70.25%" y={(40-40).toString()} rx="2" ry="2" width="9.5%" height="40" />
            <rect x="80.25%" y={(40-24).toString()} rx="2" ry="2" width="9.5%" height="24" />
            <rect x="90.25%" y={(40-12).toString()} rx="2" ry="2" width="9.5%" height="12" />
        </ContentLoader>
    )
}