import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import AlbumCover from '../../../../components/AlbumCover';
import ProfilePicture from '../../../../components/ProfilePicture';
import styles from './discoveritem.module.css'
import PlayContentButton from '../../../../components/PlayContentButton';
import Icon from '../../../../components/Icon';

function DiscoverItem({ discoverData, className, isMultiple }) {

    const [isOpen, setIsOpen] = useState(false)

    const discoverHref = 
        discoverData ? 
            discoverData.content.type === "artist" ? 
                discoverData.content.url_slug
            : discoverData.content.type === "album" ? 
                discoverData.content.url_slug
            : 
                `${discoverData.content.album.url_slug}?tracks=${discoverData.content.id}` 
        : 
            null;

    return (
        <div className={`link-wrapper section-block ${styles.discover} ${className}`}>
            <Link to={discoverHref} className="link-overlay">
                <span className='link-overlay-title'>{discoverData.content.type === "artist" ? discoverData.content.name : discoverData.content.title}</span>
            </Link>

            <div className={`link-inner padding-around`}>
                <div className="flex-row">
                    <Link to={discoverHref}>
                        {discoverData.content.type !== "artist" &&
                            <AlbumCover
                            clickable
                            albumCover={discoverData.content.type === "album" ? discoverData.content.cover_small || discoverData.content.cover : discoverData.content.album.cover_small || discoverData.content.album.cover}
                            altText={discoverData.content.type === "album" ? discoverData.content.title : discoverData.content.title}
                            size={64}
                            />
                        }

                        {discoverData.content.type === "artist" &&
                            <ProfilePicture
                            clickable
                            profilePicture={discoverData.content.picture_small || discoverData.content.picture}
                            altText={discoverData.content.name}
                            size={64}
                            />
                        }
                    </Link>

                    <div className="col-1 grid margin-left-16">
                        <Link to={discoverHref} style={{ display: "grid" }}>
                            <h5 className={`one-line black ${styles.a}`}>{discoverData.content.type === "artist" ? discoverData.content.name : discoverData.content.title}</h5>
                        </Link>

                        <p className="highDarkGrey margin-top-4">
                            {discoverData.content.type === "artist" ? 
                                "Artist" 
                            : 
                                <Link to={discoverData.content.artist.url_slug} className={styles.artistLink}>
                                    {discoverData.content.artist.name}
                                </Link>
                            }
                        </p>
                    </div>
                    
                    {discoverData.tag !== "" &&
                        <div className={`${styles.featuredBox} ${styles[discoverData.tag_color]} margin-left-16`}>
                            <div>
                                <h6>
                                    {discoverData.tag}
                                </h6>
                            </div>
                        </div>
                    }

                    {isMultiple && discoverData.description !== "" &&
                            <div
                            className={`${styles.multiple} ${isOpen ? styles.open : ""} cursor link-clickable`}
                            onClick={() => {
                                setIsOpen(!isOpen)
                            }}
                            >
                                <Icon icon="chevron-right" size={24} />
                            </div>
                        }
                </div>
                
                {discoverData.description !== "" &&
                    <div className={`${styles.description} ${isOpen || !isMultiple ? styles.open : ""}`}>
                        <p className="margin-top-12 black">{discoverData.description}</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default React.memo(DiscoverItem)