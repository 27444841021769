import styles from './listitem.module.css'

import React, { Component, useState } from 'react'
import ClampLines from 'react-clamp-lines';

import Stars from '../Stars';
import Icon from '../Icon';
import ProfilePicture from '../ProfilePicture';
import AlbumCover from '../AlbumCover';
import UserRedirect from '../UserRedirect';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { status } from '../../constants/action-types';
import { deleteLike, likeContent } from '../../actions/likes-actions';
import { usePopup } from '../../hooks/popup/popup';
import ShareDropdown from '../ShareDropdown';
import { Link } from 'react-router-dom';
import { filterAndFill } from '../../utils/format-array';
import { checkNull, checkNullNumber } from '../../utils/format-text';
import Truncate from '../Truncate';
import { useTrack } from '../../hooks/tracking';

function ListItem({ 
    list,

    hideUser,
    hideSocials,
    disableReadMore,
    hideBorder,
    isLast,
    isFirst,
    isSmall,
    lighterStyle,
    active,
    
    className
}) {
    //General
    const { openPopup } = usePopup()
    const dispatch = useDispatch()
    const track = useTrack()

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

    //Responsive
    const isTablet = useMediaQuery({ maxWidth: 768 })

    const smallList = (isSmall || isTablet)

    //State
    const [extraLikeCount, setExtraLikeCount] = useState(0)
    
    //likeObj
    const deleteLikeContentUid = useSelector(state => state.likes.deleteLikeContentUid)
    const deleteLikeStatus = useSelector(state => state.likes.deleteLikeStatus)
    const myLikes = useSelector(state => state.me.likes);
    const cachedLikes = useSelector(state => state.me.cachedLikes);
    const likes = [ ...myLikes, ...cachedLikes ];
    const likeIndex = likes.findIndex(like => like.content_ct === "list" && like.content_uid === list.uid);
    const likeObj = likeIndex !== -1 ? likes[likeIndex] : null;

    //Blocks
    const myBlocks = useSelector(state => state.me.blocks)
    const cachedBlocks = useSelector(state => state.me.cachedBlocks)
    const blocks = [...myBlocks, ...cachedBlocks]
    const blockObj = blocks.findIndex(block => block.blocked_user === list.creator.uid) !== -1 ? blocks[blocks.findIndex(block => block.blocked_user === list.creator.uid)] : null

    //blockedBy
    const blockedByObj = useSelector(state => 
        state.me.blockedBy && state.me.blockedBy.find(block => block.creator === list.creator.uid)
    )

    if (blockedByObj) {
        return null
    }

    if (blockObj) {
        return null
    }
    
    if (!list) {
        return null;
    }

    //Variables
    const totalLikes = checkNullNumber(list.like_count) + extraLikeCount;

    return (
        <div 
        className={`flex-column col-1 ${styles.container} ${hideBorder ? styles.hideBorder : ""} ${isLast ? styles.last : ""} ${isFirst ? styles.first : ""} ${lighterStyle ? styles.lighterStyle : ""} ${active ? styles.active : ""} truncate-wrapper link-wrapper ${className}`}
        >
            <Link to={list.url_slug} className="link-overlay">
                <span className='link-overlay-title'>{list.title}</span>
            </Link>

            <div className="link-inner flex-column col-1">
                {!smallList &&
                    <div className="flex-row" style={{ flexDirection: "row", width: 360-(35*3) }}>
                        {list.list_connections.results.filter((_, i) => i < 10).map((item, index) => {
                            const content = item.content
                            const style = {
                                marginLeft: index > 0 ? -35 : 0,
                                zIndex: (index*-1)+1000
                            }

                            let href = ""
                            if (content.type === "album") {
                                href = content.url_slug
                            } else if (content.type === "track") {
                                href = `${content.album.url_slug}?tracks=${content.id}`
                            } else if (content.type === "artist") {
                                href = content.url_slug
                            }
                            
                            return (
                                <Link to={href} key={`list-${list.uid}-content-${item ? item.uid : index}`}>
                                    {content.type === "artist" &&
                                        <ProfilePicture style={style} clickable size={88} profilePicture={content.picture_small || content.picture} altText={content.name} />
                                    }

                                    {content.type === "album" &&
                                        <AlbumCover style={style} clickable size={88} albumCover={content.cover_small || content.cover} altText={content.title} />
                                    }

                                    {content.type === "track" &&
                                        <AlbumCover style={style} clickable size={88} albumCover={content.album.cover_small || content.album.cover} altText={content.title} />
                                    }
                                </Link>
                            )
                        })}
                    </div>
                }
                
                {smallList &&
                    <div className={styles.coverContainer}>
                        <div className="col-1 flex-row space-between">
                            {filterAndFill(list.list_connections.results, 6).map((item, index) => {
                                const style = {
                                    zIndex: (index*-1)+1000,
                                }
                                
                                return (
                                    <div key={`list-${list.uid}-smallcontent-${item ? item.uid : index}`} className="col-1">
                                        {item &&
                                            <div>
                                                {item.content.type === "artist" &&
                                                    <ProfilePicture style={style} size={'200%'} paddingTop={'200%'} profilePicture={item.content.picture_medium || item.content.picture} altText={item.content.name} />
                                                }
                                                {item.content.type === "album" &&
                                                    <AlbumCover style={style}  size={'200%'} paddingTop={'200%'} albumCover={item.content.cover_medium || item.content.cover} altText={item.content.title} />
                                                }
                                                {item.content.type === "track" &&
                                                    <AlbumCover style={style}  size={'200%'} paddingTop={'200%'} albumCover={item.content.album.cover_medium || item.content.album.cover} altText={item.content.title} />
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            })}

                            <div className="col-1" />
                        </div>

                        {/*
                        <div 
                        className={styles.coverBorder}
                        style={{ 
                            borderTopLeftRadius: list.list_connections.results[0] && list.list_connections.results[0].content.type === "artist" ? 100 : 4,
                            borderBottomLeftRadius: list.list_connections.results[0] && list.list_connections.results[0].content.type === "artist" ? 100 : 4,
                            borderTopRightRadius: list.list_connections.results[5] && list.list_connections.results[5].content.type === "artist" ? 100 : 4,
                            borderBottomRightRadius: list.list_connections.results[5] && list.list_connections.results[5].content.type === "artist" ? 100 : 4,
                        }}
                        />
                        */}
                    </div>
                }

                {list.featured && 
                    <div className={`${styles.featuredBox} ${smallList ? styles.smallList : ""}`}>
                        <div>
                            <h6>
                                FEATURED
                            </h6>
                        </div>
                    </div>
                }

                {list.title !== "" &&
                    <div className="flex-row" style={{ marginTop: 14 }}>
                        <h5 className="textColor">{list.title}</h5>

                        {(list.private || list.private_locked) &&
                            <Icon icon="lock" size={16} style={{ marginLeft: 6 }} color={"var(--grey)"} />
                        }
                    </div>
                }
                
                {list.description !== "" &&
                    <div className='col-1' style={{ marginTop: 8 }}>
                        {list.description !== "" &&
                            <Truncate
                            text={list.description}
                            textClassName={`textColor`}
                            appendId={list.uid}
                            disabled={disableReadMore}
                            height={disableReadMore ? 100 : 200}
                            breakHeight={disableReadMore ? 120 : 240}
                            />
                        }
                </div>
                }

                {!hideUser &&
                    <div className="margin-top-12 flex-row">
                        <UserRedirect pictureSize={32} fontSize={16} user={list.creator} />
                    </div>
                }

                {!hideSocials &&
                    <div className="flex-row justify-between margin-top-16">
                        <div className="flex-row">
                            <div 
                            onClick={() => {
                                if (isLoggedIn) {
                                    if (likeObj) {
                                        setExtraLikeCount(extraLikeCount-1)
                                        
                                        dispatch(
                                            deleteLike("list", list.uid, likeObj.uid, false)
                                        )
                                    } else {
                                        setExtraLikeCount(extraLikeCount+1)

                                        track("Liked content", { type: "list", referrer: "ListItem" })

                                        dispatch(
                                            likeContent("list", list.uid, false)
                                        )
                                    }
                                } else {
                                    openPopup("SignUp")
                                }
                            }} 
                            className="link-clickable"
                            style={{ height: 20 }}
                            >
                                <Icon size={20} icon={(deleteLikeStatus === status.BEGIN && deleteLikeContentUid === list.uid) ? "loading" : "favorite"} className={`padding-right-6 ${styles.icon} ${likeObj ? styles.active : ""}`} />
                            </div>

                            <Link style={{ height: 20 }} to={`${list.url_slug}?comments=true`}>
                                <Icon size={20} icon="chat" className={`padding-left-6 padding-right-6 ${styles.icon}`} />
                            </Link>
                            
                            <Link to={`${list.url_slug}?comments=true`}>
                                <p className={`padding-left-6 padding-right-6 ${styles.icon} ${styles.counts}`}>{totalLikes} {totalLikes === 1 ? "like" : "likes"}, {checkNull(list.comment_count)} {list.comment_count === 1 ? "comment" : "comments"}</p>
                            </Link>
                        </div>
                    
                        <div className='flex-row'>
                            <div 
                            style={{ height: 20 }} 
                            className='link-clickable' 
                            onClick={() => {
                                if (!isLoggedIn) {
                                    openPopup("SignUp")
                                } else {
                                    openPopup("SendContent", { type: "list", content: list })
                                }
                            }}
                            >
                                <Icon size={20} icon="forum" className={`padding-left-6 padding-right-6 ${styles.icon}`} />
                            </div>
                        
                            <ShareDropdown
                            type="list" 
                            list={list}
                            uid={list.uid}
                            isPrivate={(list.private || list.private_locked)}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default React.memo(ListItem)