import styles from './searchcontent.module.css'

import React, { useState, useEffect, useRef } from 'react'
import { usePopup } from '../../hooks/popup/popup'
import { Link, useLocation } from 'react-router-dom'
import SearchContentInput from '../../components/SearchContentInput'
import Button from '../../components/Button'
import { useMediaQuery } from 'react-responsive'

export default function SearchContentPopup({ options }) {
    //General
    const { openPopup } = usePopup()

    //Responsive
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Params
    const inputProps = (options && options.inputProps) ? options.inputProps : {}
    const onContentPressed = (options && options.onContentPressed) ? options.onContentPressed : null

    //onClick
    function onClick(content) {
        if (onContentPressed) {
            onContentPressed(content)
        } else {
            openPopup("CreateReview", { content: content })
        }

    }

    return (
        <div className={`col-1 flex-column`} style={{ position: 'relative', zIndex: 100 }}>
            <SearchContentInput
            onContentPressed={onClick}
            autoFocus
            lighterStyle
            {...inputProps}
            />

            {!(options && options.overwriteButton) &&
                <div className="flex-row flex-center margin-top-16" style={{ zIndex: -2}}>
                    <Button 
                    text={"My drafts"} 
                    className="col-1 margin-right-16" 
                    type='secondary-two' 
                    textStyle={{ fontSize: 14 }} 
                    onClicked={() => openPopup("ManageDrafts", { initialTab: "review" })}
                    />

                    <Link className='col-1' to="/list/new">
                        <Button 
                        text={"Create a new list"} 
                        type='secondary-two' 
                        textStyle={{ fontSize: 14 }} 
                        />
                    </Link>
                </div>
            }
            
            {(options && options.overwriteButton) &&
                <Button 
                text={options.overwriteButton.text} 
                className="margin-top-16" 
                type='secondary-two' 
                textStyle={{ fontSize: 14 }} 
                onClicked={options.overwriteButton.onPress}
                />
            }
        </div>
    )
}
