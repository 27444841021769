import styles from './dateinput.module.css'

import React, { useState } from 'react'

import Icon from '../Icon';

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

export default function DateInput({ 
    value, 
    onChange=()=>{}, 
    className, 
    style,
    appendId,
}) {
    //Responsive
    const onMobile = /Mobi/.test(navigator.userAgent)

    //State
    const [visible, setVisible] = useState(false)

    return (
        <div>
            <div className={`${styles.clearRatingWrapper} flex-row`}>
                <div className={`${styles.input} ${visible ? styles.active : styles.disabled}`}>
                    <DatePicker 
                    onChange={onChange} 
                    value={value} 
                    clearIcon={null}
                    calendarIcon={null}
                    onCalendarOpen={() => setVisible(true)}
                    onCalendarClose={() => setVisible(false)}
                    />
                </div>

                <div onClick={() => onChange(null)} className={`${styles.clearRatingBtn} ${!visible && Boolean(value) ? styles.active : styles.disabled}`}>
                    <Icon icon='close' size={20} className={styles.clearRatingIcon} />
                </div>
            </div>
        </div>
    )
}
