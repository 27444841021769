import React, { Suspense, lazy } from 'react'

import { Route, Switch, useRouteMatch } from "react-router-dom";

import Album from '../../../../routes/Album';

const UpdateAlbum = lazy(() => import('../../../../routes/UpdateAlbum'));
const CreateAlbum = lazy(() => import('../../../../routes/CreateAlbum'));

export default function AlbumNavigator(props) {
    const match = useRouteMatch()
    return (
        <Switch>
            <Route exact path={`${match.url}/new`} component={CreateAlbum} />

            <Route exact path={`${match.url}/:id/edit`} component={UpdateAlbum} />

            <Route path={`${match.url}/:id/reviews`} component={Album} />
            <Route path={`${match.url}/:id/ratings`} component={Album} />
            <Route path={`${match.url}/:id/lists`} component={Album} />

            <Route path={`${match.url}/:nameSlug/:artistSlug`} component={Album} />

            <Route path={`${match.url}/:id`} component={Album} />
        </Switch>
    )
}
